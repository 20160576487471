/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Strykerlabs API
 * Strykerlabs API
 * OpenAPI spec version: 0.1
 */
import {
  useMutation,
  useQuery,
  useSuspenseQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult
} from '@tanstack/react-query'
import axios from 'axios'
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  AnyApiBodyDto,
  AuthControllerRequestPasswordChange200,
  AuthOIDCControllerLoginParams,
  CompleteFileUploadPutDto,
  CreateScoutingPlayerTableConfigFilterDto,
  CreateScoutingPlayerTableConfigLayoutDto,
  CreateUpdateScoutingSquadDto,
  DatabaseSizeGetDto,
  EnvironmentEventControllerGetEnvironmentEvent200,
  EnvironmentEventControllerGetEnvironmentEvents200Item,
  EnvironmentEventControllerGetEnvironmentEventsParams,
  EnvironmentEventCreateMatchDto,
  EnvironmentEventCreateMedicalDto,
  EnvironmentEventCreateSocialDto,
  EnvironmentEventCreateTrainingDto,
  EnvironmentEventMatchGetDto,
  EnvironmentEventMedicalGetDto,
  EnvironmentEventSocialGetDto,
  EnvironmentEventTrainingGetDto,
  EnvironmentEventUpdateMatchDto,
  EnvironmentEventUpdateMedicalDto,
  EnvironmentEventUpdateSocialDto,
  EnvironmentEventUpdateTrainingDto,
  EnvironmentExerciseTagGetDto,
  EnvironmentGetAllByTenantDto,
  EnvironmentGetAllDto,
  EnvironmentLocationBaseCreateDto,
  EnvironmentLocationControllerGetLocation200,
  EnvironmentLocationControllerGetLocations200Item,
  EnvironmentLocationControllerGetLocationsByType200,
  EnvironmentLocationControllerUpdateLocation200,
  EnvironmentLocationControllerUpdateLocationBody,
  EnvironmentLocationMatchCreateDto,
  EnvironmentLocationMatchGetDto,
  EnvironmentLocationMedicalGetDto,
  EnvironmentLocationTrainingGetDto,
  EnvironmentMatchDayTagLogicGetDto,
  EnvironmentMatchDayTagLogicPutDto,
  EnvironmentPermissionGetDto,
  EnvironmentPlayerGetDto,
  EnvironmentPlayerPostDto,
  EnvironmentSessionTagGetDto,
  ExerciseAttachmentDownloadUrlDto,
  ExerciseAttachmentGetDto,
  ExerciseAttachmentPostDto,
  ExerciseAttachmentPostReturnValueDto,
  ExerciseControllerGetExerciseDetails200,
  ExerciseCreateByTemplateDto,
  ExerciseCreateUpdateDto,
  ExerciseCreateUpdateResponseDto,
  ExerciseGetDto,
  ExercisePatchDto,
  ExerciseTagGetDto,
  GetCompetitionReferenceDto,
  GetPlayerComparisonDto,
  MatchControllerSearchParams,
  MatchDto,
  MeWyscoutPlayerAlertCreateDto,
  MeWyscoutPlayerAlertPatchDto,
  MedicalTreatmentCategoryGetDto,
  MedicalTreatmentTypeGetDto,
  PlayerAdditionaldataExternalPutDto,
  PlayerAdditionaldataGetDto,
  PlayerAdditionaldataPutDto,
  PlayerCoredataGetDto,
  PlayerMedicalEntryCreateDto,
  PlayerMedicalEntryDocumentControllerGetDocument200,
  PlayerMedicalEntryDocumentDownloadUrlDto,
  PlayerMedicalEntryDocumentUploadPostDto,
  PlayerMedicalEntryDocumentUploadReturnDto,
  PlayerMedicalEntryGetDto,
  PlayerMedicalEntryUpdateDto,
  PlayerProfileDto,
  PlayerScoutingProfileDto,
  SavedFilterGetDto,
  SavedFilterPostDto,
  SavedFilterPutDto,
  SavedLayoutGetDto,
  SavedLayoutPostDto,
  SavedLayoutPutDto,
  ScoutingMatchControllerFindAllParams,
  ScoutingMatchDto,
  ScoutingMatchPlayerNoteControllerFindAllParams,
  ScoutingMatchPlayerNoteDto,
  ScoutingMatchPlayerNotePostDto,
  ScoutingMatchPostDto,
  ScoutingPlayerTableConfigFilterDto,
  ScoutingPlayerTableConfigLayoutDto,
  ScoutingPlayerTableControllerFindAllFilterConfigsParams,
  ScoutingPlayerTableControllerFindAllLayoutConfigsParams,
  ScoutingPlayerTableControllerGetPlayerSeasonsByYearParams,
  ScoutingPlayerTableGetByIdsDto,
  ScoutingPlayerTableGetResponseDto,
  ScoutingPlayerTablePostBodyParamsDto,
  ScoutingPlayerTablePostReturnValueDto,
  ScoutingSquadDto,
  ScoutingSquadPlayerOptionDto,
  ScoutingSquadsCreatePostBodyDto,
  ScoutingSquadsGetGetReturnValueDto,
  ScoutingSquadsListGetReturnValueDto,
  ScoutingSquadsPlayersownGetReturnValueDto,
  ScoutingSquadsPlayersworkflowGetReturnValueDto,
  ScoutingSquadsUpdatePutBodyDto,
  ScoutingTenantWorkflowTagDto,
  ScoutingTenantWorkflowTagPostDto,
  ScoutingTenantWorkflowTagPutDto,
  ScoutingWorkflowArchivePutBodyDto,
  ScoutingWorkflowAssignPutBodyDto,
  ScoutingWorkflowByPlayerGetDto,
  ScoutingWorkflowCancelPutBodyDto,
  ScoutingWorkflowControllerGetLatestScoutingWorkflowPlayersParams,
  ScoutingWorkflowFileDto,
  ScoutingWorkflowFileuploadPostBodyDto,
  ScoutingWorkflowFileuploadPostReturnValueDto,
  ScoutingWorkflowNotesGetReturnValueDto,
  ScoutingWorkflowNotesPostBodyDto,
  ScoutingWorkflowNotesPutBodyDto,
  ScoutingWorkflowPlayersGetLatestDto,
  ScoutingWorkflowRequestPutBodyDto,
  ScoutingWorkflowSuggestPostBodyDto,
  ScoutingWorkflowSuggestwyscoutPostBodyDto,
  ScoutingWorkflowTagPutDto,
  ScoutingWorkflowTagsPutDto,
  ScoutingWorkflowTemplatesGetDto,
  ScoutingWorkflowTemplatesUpdateDto,
  SessionAttachmentDownloadUrlDto,
  SessionAttachmentGetDto,
  SessionAttachmentPostDto,
  SessionAttachmentPostReturnValueDto,
  SessionByTemplateCreateDto,
  SessionCreateUpdateDetailsDto,
  SessionDto,
  SessionPlayerGetDto,
  SessionTagGetDto,
  SessionUpdateDto,
  SuperadminTenantControllerGetAllTenantsParams,
  SuperadminUserControllerGetAllUsersByTenantParams,
  SuperadminUserControllerGetAllUsersParams,
  TableSizeGetDto,
  TemplateExerciseControllerGetTemplateExerciseDetails200,
  TemplateExerciseCreateUpdateDto,
  TemplateExerciseCreateUpdateResponseDto,
  TemplateExerciseFileGetDto,
  TemplateExerciseFileUploadPostDto,
  TemplateExerciseFileUploadReturnDto,
  TemplateExerciseGetDto,
  TemplateExerciseTagGetDto,
  TemplateSessionControllerGetTemplateSessionsParams,
  TemplateSessionCreateUpdateDto,
  TemplateSessionCreateUpdateResponseDto,
  TemplateSessionExerciseGetDto,
  TemplateSessionFileGetDto,
  TemplateSessionFileUploadPostDto,
  TemplateSessionFileUploadReturnDto,
  TemplateSessionGetDto,
  TemplateSessionTagGetDto,
  TenantCreateDto,
  TenantDemoSettingsDto,
  TenantDocumentCategoryCreateDto,
  TenantDocumentCategoryGetDto,
  TenantDocumentCategoryPatchDto,
  TenantEmployeeCreateDto,
  TenantEmployeeDepartmentCreateDto,
  TenantEmployeeDepartmentGetDto,
  TenantEmployeeDepartmentPatchDto,
  TenantEmployeeDocumentDownloadUrlDto,
  TenantEmployeeDocumentGetDto,
  TenantEmployeeDocumentUploadPostDto,
  TenantEmployeeDocumentUploadReturnDto,
  TenantEmployeeGetDto,
  TenantEmployeePatchDto,
  TenantEmployeeProfileImageUploadPostDto,
  TenantEmployeeProfileImageUploadReturnDto,
  TenantEmployeeQualificationCreateDto,
  TenantEmployeeQualificationGetDto,
  TenantEmployeeQualificationPatchDto,
  TenantEventControllerGetTenantEvent200,
  TenantEventControllerGetTenantEvents200Item,
  TenantEventControllerGetTenantEventsParams,
  TenantEventCreateMatchDto,
  TenantEventCreateMedicalDto,
  TenantEventCreateSocialDto,
  TenantEventCreateTrainingDto,
  TenantEventMatchGetDto,
  TenantEventMedicalGetDto,
  TenantEventSocialGetDto,
  TenantEventTrainingGetDto,
  TenantEventUpdateMatchDto,
  TenantEventUpdateMedicalDto,
  TenantEventUpdateSocialDto,
  TenantEventUpdateTrainingDto,
  TenantExerciseTagCreateDto,
  TenantExerciseTagGetDto,
  TenantExerciseTagUpdateDto,
  TenantFeatureFlagGetDto,
  TenantGetAllDto,
  TenantGetSingleDto,
  TenantLocationControllerGetTenantLocationsParams,
  TenantLocationCreateDto,
  TenantLocationGetDto,
  TenantLocationUpdateDto,
  TenantMatchDayTagLogicGetDto,
  TenantMatchDayTagLogicPutDto,
  TenantPerformanceTestCreateDto,
  TenantPerformanceTestGetDto,
  TenantPerformanceTestMeasurementParamCreateDto,
  TenantPerformanceTestMeasurementParamGetDto,
  TenantPerformanceTestMeasurementParamPatchDto,
  TenantPerformanceTestPatchDto,
  TenantPlayerCreateDto,
  TenantPlayerDeleteDto,
  TenantPlayerFileDownloadUrlDto,
  TenantPlayerFileGetDto,
  TenantPlayerFileUploadPostDto,
  TenantPlayerFileUploadReturnDto,
  TenantPlayerGetDto,
  TenantPlayerMedicalInformationCreateDto,
  TenantPlayerMedicalInformationGetDto,
  TenantPlayerMedicalInformationPatchDto,
  TenantPlayerMedicalInformationPutDto,
  TenantPlayerPerformanceTestControllerGetPerformanceTests200,
  TenantPlayerPerformanceTestControllerGetPerformanceTestsParams,
  TenantPlayerPerformanceTestCreateDto,
  TenantPlayerPerformanceTestPatchDto,
  TenantPlayerPerformanceTestResultGetDto,
  TenantPlayerUpdateDto,
  TenantScoutingUiControllerGet200,
  TenantScoutingUiDto,
  TenantScoutingUiPutDto,
  TenantSessionTagCreateDto,
  TenantSessionTagGetDto,
  TenantSessionTagUpdateDto,
  TenantSkillcornerAccessGetDto,
  TenantUpdateDto,
  TenantUserCreateDto,
  TenantUserGetAllDto,
  TenantUserUpdateDto,
  TenantWyscoutIndexCreateDto,
  TenantWyscoutIndexGetDto,
  TenantWyscoutIndexPatchDto,
  TransfermarktPlayerMatcherApprovePostDto,
  TransfermarktPlayerMatcherGetDto,
  TransfermarktTeamMatcherApprovePostDto,
  TransfermarktTeamMatcherGetDto,
  UserCreateDto,
  UserGetAllByTenantDto,
  UserGetAllDto,
  UserGetAllDtoSingle,
  UserGetAllPublicDto,
  UserInfoDto,
  UserPermissionGetDto,
  UserSessionDto,
  WyscoutAreasGetDto,
  WyscoutCompetitionSeasonsGetReturnValueDto,
  WyscoutControllerSearchCompetitions200,
  WyscoutControllerSearchCompetitionsParams,
  WyscoutControllerSearchMatchesParams,
  WyscoutControllerSearchPlayersParams,
  WyscoutControllerSearchTeams200,
  WyscoutControllerSearchTeamsParams,
  WyscoutMatchDto,
  WyscoutMatchPlayerAppearance,
  WyscoutPlayerAlertGetDto,
  WyscoutPlayerMatcherApprovePostDto,
  WyscoutPlayerMatcherGetDto,
  WyscoutPlayerNextGamesGetDto,
  WyscoutPlayerSeasonsGetDto,
  WyscoutSearchCompetitionsGetDto,
  WyscoutTeamMatcherApprovePostDto,
  WyscoutTeamMatcherGetDto
} from '../schemas'




export const authOIDCControllerLogin = (
    params: AuthOIDCControllerLoginParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.get(
      `/v1/auth/oidc/login`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getAuthOIDCControllerLoginQueryKey = (params: AuthOIDCControllerLoginParams,) => {
    return [`/v1/auth/oidc/login`, ...(params ? [params]: [])] as const;
    }

    
export const getAuthOIDCControllerLoginQueryOptions = <TData = Awaited<ReturnType<typeof authOIDCControllerLogin>>, TError = AxiosError<unknown>>(params: AuthOIDCControllerLoginParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerLogin>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAuthOIDCControllerLoginQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof authOIDCControllerLogin>>> = ({ signal }) => authOIDCControllerLogin(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerLogin>>, TError, TData> & { queryKey: QueryKey }
}

export type AuthOIDCControllerLoginQueryResult = NonNullable<Awaited<ReturnType<typeof authOIDCControllerLogin>>>
export type AuthOIDCControllerLoginQueryError = AxiosError<unknown>


export function useAuthOIDCControllerLogin<TData = Awaited<ReturnType<typeof authOIDCControllerLogin>>, TError = AxiosError<unknown>>(
 params: AuthOIDCControllerLoginParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerLogin>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof authOIDCControllerLogin>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthOIDCControllerLogin<TData = Awaited<ReturnType<typeof authOIDCControllerLogin>>, TError = AxiosError<unknown>>(
 params: AuthOIDCControllerLoginParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerLogin>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof authOIDCControllerLogin>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthOIDCControllerLogin<TData = Awaited<ReturnType<typeof authOIDCControllerLogin>>, TError = AxiosError<unknown>>(
 params: AuthOIDCControllerLoginParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerLogin>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useAuthOIDCControllerLogin<TData = Awaited<ReturnType<typeof authOIDCControllerLogin>>, TError = AxiosError<unknown>>(
 params: AuthOIDCControllerLoginParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerLogin>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getAuthOIDCControllerLoginQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAuthOIDCControllerLoginSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof authOIDCControllerLogin>>, TError = AxiosError<unknown>>(params: AuthOIDCControllerLoginParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerLogin>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAuthOIDCControllerLoginQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof authOIDCControllerLogin>>> = ({ signal }) => authOIDCControllerLogin(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerLogin>>, TError, TData> & { queryKey: QueryKey }
}

export type AuthOIDCControllerLoginSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof authOIDCControllerLogin>>>
export type AuthOIDCControllerLoginSuspenseQueryError = AxiosError<unknown>


export function useAuthOIDCControllerLoginSuspense<TData = Awaited<ReturnType<typeof authOIDCControllerLogin>>, TError = AxiosError<unknown>>(
 params: AuthOIDCControllerLoginParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerLogin>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthOIDCControllerLoginSuspense<TData = Awaited<ReturnType<typeof authOIDCControllerLogin>>, TError = AxiosError<unknown>>(
 params: AuthOIDCControllerLoginParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerLogin>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthOIDCControllerLoginSuspense<TData = Awaited<ReturnType<typeof authOIDCControllerLogin>>, TError = AxiosError<unknown>>(
 params: AuthOIDCControllerLoginParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerLogin>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useAuthOIDCControllerLoginSuspense<TData = Awaited<ReturnType<typeof authOIDCControllerLogin>>, TError = AxiosError<unknown>>(
 params: AuthOIDCControllerLoginParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerLogin>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getAuthOIDCControllerLoginSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const authOIDCControllerLoginCallback = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.get(
      `/v1/auth/oidc/callback`,options
    );
  }


export const getAuthOIDCControllerLoginCallbackQueryKey = () => {
    return [`/v1/auth/oidc/callback`] as const;
    }

    
export const getAuthOIDCControllerLoginCallbackQueryOptions = <TData = Awaited<ReturnType<typeof authOIDCControllerLoginCallback>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerLoginCallback>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAuthOIDCControllerLoginCallbackQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof authOIDCControllerLoginCallback>>> = ({ signal }) => authOIDCControllerLoginCallback({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerLoginCallback>>, TError, TData> & { queryKey: QueryKey }
}

export type AuthOIDCControllerLoginCallbackQueryResult = NonNullable<Awaited<ReturnType<typeof authOIDCControllerLoginCallback>>>
export type AuthOIDCControllerLoginCallbackQueryError = AxiosError<unknown>


export function useAuthOIDCControllerLoginCallback<TData = Awaited<ReturnType<typeof authOIDCControllerLoginCallback>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerLoginCallback>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof authOIDCControllerLoginCallback>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthOIDCControllerLoginCallback<TData = Awaited<ReturnType<typeof authOIDCControllerLoginCallback>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerLoginCallback>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof authOIDCControllerLoginCallback>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthOIDCControllerLoginCallback<TData = Awaited<ReturnType<typeof authOIDCControllerLoginCallback>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerLoginCallback>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useAuthOIDCControllerLoginCallback<TData = Awaited<ReturnType<typeof authOIDCControllerLoginCallback>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerLoginCallback>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getAuthOIDCControllerLoginCallbackQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAuthOIDCControllerLoginCallbackSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof authOIDCControllerLoginCallback>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerLoginCallback>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAuthOIDCControllerLoginCallbackQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof authOIDCControllerLoginCallback>>> = ({ signal }) => authOIDCControllerLoginCallback({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerLoginCallback>>, TError, TData> & { queryKey: QueryKey }
}

export type AuthOIDCControllerLoginCallbackSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof authOIDCControllerLoginCallback>>>
export type AuthOIDCControllerLoginCallbackSuspenseQueryError = AxiosError<unknown>


export function useAuthOIDCControllerLoginCallbackSuspense<TData = Awaited<ReturnType<typeof authOIDCControllerLoginCallback>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerLoginCallback>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthOIDCControllerLoginCallbackSuspense<TData = Awaited<ReturnType<typeof authOIDCControllerLoginCallback>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerLoginCallback>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthOIDCControllerLoginCallbackSuspense<TData = Awaited<ReturnType<typeof authOIDCControllerLoginCallback>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerLoginCallback>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useAuthOIDCControllerLoginCallbackSuspense<TData = Awaited<ReturnType<typeof authOIDCControllerLoginCallback>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerLoginCallback>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getAuthOIDCControllerLoginCallbackSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const authOIDCControllerAfterLogout = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.get(
      `/v1/auth/oidc/afterlogout`,options
    );
  }


export const getAuthOIDCControllerAfterLogoutQueryKey = () => {
    return [`/v1/auth/oidc/afterlogout`] as const;
    }

    
export const getAuthOIDCControllerAfterLogoutQueryOptions = <TData = Awaited<ReturnType<typeof authOIDCControllerAfterLogout>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerAfterLogout>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAuthOIDCControllerAfterLogoutQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof authOIDCControllerAfterLogout>>> = ({ signal }) => authOIDCControllerAfterLogout({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerAfterLogout>>, TError, TData> & { queryKey: QueryKey }
}

export type AuthOIDCControllerAfterLogoutQueryResult = NonNullable<Awaited<ReturnType<typeof authOIDCControllerAfterLogout>>>
export type AuthOIDCControllerAfterLogoutQueryError = AxiosError<unknown>


export function useAuthOIDCControllerAfterLogout<TData = Awaited<ReturnType<typeof authOIDCControllerAfterLogout>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerAfterLogout>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof authOIDCControllerAfterLogout>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthOIDCControllerAfterLogout<TData = Awaited<ReturnType<typeof authOIDCControllerAfterLogout>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerAfterLogout>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof authOIDCControllerAfterLogout>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthOIDCControllerAfterLogout<TData = Awaited<ReturnType<typeof authOIDCControllerAfterLogout>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerAfterLogout>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useAuthOIDCControllerAfterLogout<TData = Awaited<ReturnType<typeof authOIDCControllerAfterLogout>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerAfterLogout>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getAuthOIDCControllerAfterLogoutQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAuthOIDCControllerAfterLogoutSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof authOIDCControllerAfterLogout>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerAfterLogout>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAuthOIDCControllerAfterLogoutQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof authOIDCControllerAfterLogout>>> = ({ signal }) => authOIDCControllerAfterLogout({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerAfterLogout>>, TError, TData> & { queryKey: QueryKey }
}

export type AuthOIDCControllerAfterLogoutSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof authOIDCControllerAfterLogout>>>
export type AuthOIDCControllerAfterLogoutSuspenseQueryError = AxiosError<unknown>


export function useAuthOIDCControllerAfterLogoutSuspense<TData = Awaited<ReturnType<typeof authOIDCControllerAfterLogout>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerAfterLogout>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthOIDCControllerAfterLogoutSuspense<TData = Awaited<ReturnType<typeof authOIDCControllerAfterLogout>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerAfterLogout>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthOIDCControllerAfterLogoutSuspense<TData = Awaited<ReturnType<typeof authOIDCControllerAfterLogout>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerAfterLogout>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useAuthOIDCControllerAfterLogoutSuspense<TData = Awaited<ReturnType<typeof authOIDCControllerAfterLogout>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerAfterLogout>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getAuthOIDCControllerAfterLogoutSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const authOIDCControllerLogout = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.get(
      `/v1/auth/oidc/logout`,options
    );
  }


export const getAuthOIDCControllerLogoutQueryKey = () => {
    return [`/v1/auth/oidc/logout`] as const;
    }

    
export const getAuthOIDCControllerLogoutQueryOptions = <TData = Awaited<ReturnType<typeof authOIDCControllerLogout>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerLogout>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAuthOIDCControllerLogoutQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof authOIDCControllerLogout>>> = ({ signal }) => authOIDCControllerLogout({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerLogout>>, TError, TData> & { queryKey: QueryKey }
}

export type AuthOIDCControllerLogoutQueryResult = NonNullable<Awaited<ReturnType<typeof authOIDCControllerLogout>>>
export type AuthOIDCControllerLogoutQueryError = AxiosError<unknown>


export function useAuthOIDCControllerLogout<TData = Awaited<ReturnType<typeof authOIDCControllerLogout>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerLogout>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof authOIDCControllerLogout>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthOIDCControllerLogout<TData = Awaited<ReturnType<typeof authOIDCControllerLogout>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerLogout>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof authOIDCControllerLogout>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthOIDCControllerLogout<TData = Awaited<ReturnType<typeof authOIDCControllerLogout>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerLogout>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useAuthOIDCControllerLogout<TData = Awaited<ReturnType<typeof authOIDCControllerLogout>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerLogout>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getAuthOIDCControllerLogoutQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAuthOIDCControllerLogoutSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof authOIDCControllerLogout>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerLogout>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAuthOIDCControllerLogoutQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof authOIDCControllerLogout>>> = ({ signal }) => authOIDCControllerLogout({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerLogout>>, TError, TData> & { queryKey: QueryKey }
}

export type AuthOIDCControllerLogoutSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof authOIDCControllerLogout>>>
export type AuthOIDCControllerLogoutSuspenseQueryError = AxiosError<unknown>


export function useAuthOIDCControllerLogoutSuspense<TData = Awaited<ReturnType<typeof authOIDCControllerLogout>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerLogout>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthOIDCControllerLogoutSuspense<TData = Awaited<ReturnType<typeof authOIDCControllerLogout>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerLogout>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthOIDCControllerLogoutSuspense<TData = Awaited<ReturnType<typeof authOIDCControllerLogout>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerLogout>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useAuthOIDCControllerLogoutSuspense<TData = Awaited<ReturnType<typeof authOIDCControllerLogout>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authOIDCControllerLogout>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getAuthOIDCControllerLogoutSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const authControllerGetAllTenantFeatureFlags = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantFeatureFlagGetDto[]>> => {
    
    return axios.get(
      `/v1/auth/featureflags`,options
    );
  }


export const getAuthControllerGetAllTenantFeatureFlagsQueryKey = () => {
    return [`/v1/auth/featureflags`] as const;
    }

    
export const getAuthControllerGetAllTenantFeatureFlagsQueryOptions = <TData = Awaited<ReturnType<typeof authControllerGetAllTenantFeatureFlags>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerGetAllTenantFeatureFlags>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAuthControllerGetAllTenantFeatureFlagsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof authControllerGetAllTenantFeatureFlags>>> = ({ signal }) => authControllerGetAllTenantFeatureFlags({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof authControllerGetAllTenantFeatureFlags>>, TError, TData> & { queryKey: QueryKey }
}

export type AuthControllerGetAllTenantFeatureFlagsQueryResult = NonNullable<Awaited<ReturnType<typeof authControllerGetAllTenantFeatureFlags>>>
export type AuthControllerGetAllTenantFeatureFlagsQueryError = AxiosError<unknown>


export function useAuthControllerGetAllTenantFeatureFlags<TData = Awaited<ReturnType<typeof authControllerGetAllTenantFeatureFlags>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerGetAllTenantFeatureFlags>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof authControllerGetAllTenantFeatureFlags>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthControllerGetAllTenantFeatureFlags<TData = Awaited<ReturnType<typeof authControllerGetAllTenantFeatureFlags>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerGetAllTenantFeatureFlags>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof authControllerGetAllTenantFeatureFlags>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthControllerGetAllTenantFeatureFlags<TData = Awaited<ReturnType<typeof authControllerGetAllTenantFeatureFlags>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerGetAllTenantFeatureFlags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useAuthControllerGetAllTenantFeatureFlags<TData = Awaited<ReturnType<typeof authControllerGetAllTenantFeatureFlags>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerGetAllTenantFeatureFlags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getAuthControllerGetAllTenantFeatureFlagsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAuthControllerGetAllTenantFeatureFlagsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof authControllerGetAllTenantFeatureFlags>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerGetAllTenantFeatureFlags>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAuthControllerGetAllTenantFeatureFlagsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof authControllerGetAllTenantFeatureFlags>>> = ({ signal }) => authControllerGetAllTenantFeatureFlags({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerGetAllTenantFeatureFlags>>, TError, TData> & { queryKey: QueryKey }
}

export type AuthControllerGetAllTenantFeatureFlagsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof authControllerGetAllTenantFeatureFlags>>>
export type AuthControllerGetAllTenantFeatureFlagsSuspenseQueryError = AxiosError<unknown>


export function useAuthControllerGetAllTenantFeatureFlagsSuspense<TData = Awaited<ReturnType<typeof authControllerGetAllTenantFeatureFlags>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerGetAllTenantFeatureFlags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthControllerGetAllTenantFeatureFlagsSuspense<TData = Awaited<ReturnType<typeof authControllerGetAllTenantFeatureFlags>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerGetAllTenantFeatureFlags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthControllerGetAllTenantFeatureFlagsSuspense<TData = Awaited<ReturnType<typeof authControllerGetAllTenantFeatureFlags>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerGetAllTenantFeatureFlags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useAuthControllerGetAllTenantFeatureFlagsSuspense<TData = Awaited<ReturnType<typeof authControllerGetAllTenantFeatureFlags>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerGetAllTenantFeatureFlags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getAuthControllerGetAllTenantFeatureFlagsSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const authControllerGetAllUserPermissions = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<UserPermissionGetDto[]>> => {
    
    return axios.get(
      `/v1/auth/userpermissions`,options
    );
  }


export const getAuthControllerGetAllUserPermissionsQueryKey = () => {
    return [`/v1/auth/userpermissions`] as const;
    }

    
export const getAuthControllerGetAllUserPermissionsQueryOptions = <TData = Awaited<ReturnType<typeof authControllerGetAllUserPermissions>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerGetAllUserPermissions>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAuthControllerGetAllUserPermissionsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof authControllerGetAllUserPermissions>>> = ({ signal }) => authControllerGetAllUserPermissions({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof authControllerGetAllUserPermissions>>, TError, TData> & { queryKey: QueryKey }
}

export type AuthControllerGetAllUserPermissionsQueryResult = NonNullable<Awaited<ReturnType<typeof authControllerGetAllUserPermissions>>>
export type AuthControllerGetAllUserPermissionsQueryError = AxiosError<unknown>


export function useAuthControllerGetAllUserPermissions<TData = Awaited<ReturnType<typeof authControllerGetAllUserPermissions>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerGetAllUserPermissions>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof authControllerGetAllUserPermissions>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthControllerGetAllUserPermissions<TData = Awaited<ReturnType<typeof authControllerGetAllUserPermissions>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerGetAllUserPermissions>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof authControllerGetAllUserPermissions>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthControllerGetAllUserPermissions<TData = Awaited<ReturnType<typeof authControllerGetAllUserPermissions>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerGetAllUserPermissions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useAuthControllerGetAllUserPermissions<TData = Awaited<ReturnType<typeof authControllerGetAllUserPermissions>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerGetAllUserPermissions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getAuthControllerGetAllUserPermissionsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAuthControllerGetAllUserPermissionsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof authControllerGetAllUserPermissions>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerGetAllUserPermissions>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAuthControllerGetAllUserPermissionsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof authControllerGetAllUserPermissions>>> = ({ signal }) => authControllerGetAllUserPermissions({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerGetAllUserPermissions>>, TError, TData> & { queryKey: QueryKey }
}

export type AuthControllerGetAllUserPermissionsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof authControllerGetAllUserPermissions>>>
export type AuthControllerGetAllUserPermissionsSuspenseQueryError = AxiosError<unknown>


export function useAuthControllerGetAllUserPermissionsSuspense<TData = Awaited<ReturnType<typeof authControllerGetAllUserPermissions>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerGetAllUserPermissions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthControllerGetAllUserPermissionsSuspense<TData = Awaited<ReturnType<typeof authControllerGetAllUserPermissions>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerGetAllUserPermissions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthControllerGetAllUserPermissionsSuspense<TData = Awaited<ReturnType<typeof authControllerGetAllUserPermissions>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerGetAllUserPermissions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useAuthControllerGetAllUserPermissionsSuspense<TData = Awaited<ReturnType<typeof authControllerGetAllUserPermissions>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerGetAllUserPermissions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getAuthControllerGetAllUserPermissionsSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const authControllerGetAllEnvironmentPermissions = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EnvironmentPermissionGetDto[]>> => {
    
    return axios.get(
      `/v1/auth/environmentpermissions`,options
    );
  }


export const getAuthControllerGetAllEnvironmentPermissionsQueryKey = () => {
    return [`/v1/auth/environmentpermissions`] as const;
    }

    
export const getAuthControllerGetAllEnvironmentPermissionsQueryOptions = <TData = Awaited<ReturnType<typeof authControllerGetAllEnvironmentPermissions>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerGetAllEnvironmentPermissions>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAuthControllerGetAllEnvironmentPermissionsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof authControllerGetAllEnvironmentPermissions>>> = ({ signal }) => authControllerGetAllEnvironmentPermissions({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof authControllerGetAllEnvironmentPermissions>>, TError, TData> & { queryKey: QueryKey }
}

export type AuthControllerGetAllEnvironmentPermissionsQueryResult = NonNullable<Awaited<ReturnType<typeof authControllerGetAllEnvironmentPermissions>>>
export type AuthControllerGetAllEnvironmentPermissionsQueryError = AxiosError<unknown>


export function useAuthControllerGetAllEnvironmentPermissions<TData = Awaited<ReturnType<typeof authControllerGetAllEnvironmentPermissions>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerGetAllEnvironmentPermissions>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof authControllerGetAllEnvironmentPermissions>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthControllerGetAllEnvironmentPermissions<TData = Awaited<ReturnType<typeof authControllerGetAllEnvironmentPermissions>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerGetAllEnvironmentPermissions>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof authControllerGetAllEnvironmentPermissions>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthControllerGetAllEnvironmentPermissions<TData = Awaited<ReturnType<typeof authControllerGetAllEnvironmentPermissions>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerGetAllEnvironmentPermissions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useAuthControllerGetAllEnvironmentPermissions<TData = Awaited<ReturnType<typeof authControllerGetAllEnvironmentPermissions>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerGetAllEnvironmentPermissions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getAuthControllerGetAllEnvironmentPermissionsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAuthControllerGetAllEnvironmentPermissionsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof authControllerGetAllEnvironmentPermissions>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerGetAllEnvironmentPermissions>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAuthControllerGetAllEnvironmentPermissionsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof authControllerGetAllEnvironmentPermissions>>> = ({ signal }) => authControllerGetAllEnvironmentPermissions({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerGetAllEnvironmentPermissions>>, TError, TData> & { queryKey: QueryKey }
}

export type AuthControllerGetAllEnvironmentPermissionsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof authControllerGetAllEnvironmentPermissions>>>
export type AuthControllerGetAllEnvironmentPermissionsSuspenseQueryError = AxiosError<unknown>


export function useAuthControllerGetAllEnvironmentPermissionsSuspense<TData = Awaited<ReturnType<typeof authControllerGetAllEnvironmentPermissions>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerGetAllEnvironmentPermissions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthControllerGetAllEnvironmentPermissionsSuspense<TData = Awaited<ReturnType<typeof authControllerGetAllEnvironmentPermissions>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerGetAllEnvironmentPermissions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthControllerGetAllEnvironmentPermissionsSuspense<TData = Awaited<ReturnType<typeof authControllerGetAllEnvironmentPermissions>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerGetAllEnvironmentPermissions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useAuthControllerGetAllEnvironmentPermissionsSuspense<TData = Awaited<ReturnType<typeof authControllerGetAllEnvironmentPermissions>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerGetAllEnvironmentPermissions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getAuthControllerGetAllEnvironmentPermissionsSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const authControllerGetUserInfo = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<UserInfoDto>> => {
    
    return axios.get(
      `/v1/auth/userinfo`,options
    );
  }


export const getAuthControllerGetUserInfoQueryKey = () => {
    return [`/v1/auth/userinfo`] as const;
    }

    
export const getAuthControllerGetUserInfoQueryOptions = <TData = Awaited<ReturnType<typeof authControllerGetUserInfo>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerGetUserInfo>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAuthControllerGetUserInfoQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof authControllerGetUserInfo>>> = ({ signal }) => authControllerGetUserInfo({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof authControllerGetUserInfo>>, TError, TData> & { queryKey: QueryKey }
}

export type AuthControllerGetUserInfoQueryResult = NonNullable<Awaited<ReturnType<typeof authControllerGetUserInfo>>>
export type AuthControllerGetUserInfoQueryError = AxiosError<unknown>


export function useAuthControllerGetUserInfo<TData = Awaited<ReturnType<typeof authControllerGetUserInfo>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerGetUserInfo>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof authControllerGetUserInfo>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthControllerGetUserInfo<TData = Awaited<ReturnType<typeof authControllerGetUserInfo>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerGetUserInfo>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof authControllerGetUserInfo>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthControllerGetUserInfo<TData = Awaited<ReturnType<typeof authControllerGetUserInfo>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerGetUserInfo>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useAuthControllerGetUserInfo<TData = Awaited<ReturnType<typeof authControllerGetUserInfo>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerGetUserInfo>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getAuthControllerGetUserInfoQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAuthControllerGetUserInfoSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof authControllerGetUserInfo>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerGetUserInfo>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAuthControllerGetUserInfoQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof authControllerGetUserInfo>>> = ({ signal }) => authControllerGetUserInfo({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerGetUserInfo>>, TError, TData> & { queryKey: QueryKey }
}

export type AuthControllerGetUserInfoSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof authControllerGetUserInfo>>>
export type AuthControllerGetUserInfoSuspenseQueryError = AxiosError<unknown>


export function useAuthControllerGetUserInfoSuspense<TData = Awaited<ReturnType<typeof authControllerGetUserInfo>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerGetUserInfo>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthControllerGetUserInfoSuspense<TData = Awaited<ReturnType<typeof authControllerGetUserInfo>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerGetUserInfo>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthControllerGetUserInfoSuspense<TData = Awaited<ReturnType<typeof authControllerGetUserInfo>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerGetUserInfo>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useAuthControllerGetUserInfoSuspense<TData = Awaited<ReturnType<typeof authControllerGetUserInfo>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerGetUserInfo>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getAuthControllerGetUserInfoSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const authControllerGetUserSessions = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<UserSessionDto[]>> => {
    
    return axios.get(
      `/v1/auth/sessions`,options
    );
  }


export const getAuthControllerGetUserSessionsQueryKey = () => {
    return [`/v1/auth/sessions`] as const;
    }

    
export const getAuthControllerGetUserSessionsQueryOptions = <TData = Awaited<ReturnType<typeof authControllerGetUserSessions>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerGetUserSessions>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAuthControllerGetUserSessionsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof authControllerGetUserSessions>>> = ({ signal }) => authControllerGetUserSessions({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof authControllerGetUserSessions>>, TError, TData> & { queryKey: QueryKey }
}

export type AuthControllerGetUserSessionsQueryResult = NonNullable<Awaited<ReturnType<typeof authControllerGetUserSessions>>>
export type AuthControllerGetUserSessionsQueryError = AxiosError<unknown>


export function useAuthControllerGetUserSessions<TData = Awaited<ReturnType<typeof authControllerGetUserSessions>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerGetUserSessions>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof authControllerGetUserSessions>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthControllerGetUserSessions<TData = Awaited<ReturnType<typeof authControllerGetUserSessions>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerGetUserSessions>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof authControllerGetUserSessions>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthControllerGetUserSessions<TData = Awaited<ReturnType<typeof authControllerGetUserSessions>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerGetUserSessions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useAuthControllerGetUserSessions<TData = Awaited<ReturnType<typeof authControllerGetUserSessions>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerGetUserSessions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getAuthControllerGetUserSessionsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAuthControllerGetUserSessionsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof authControllerGetUserSessions>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerGetUserSessions>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAuthControllerGetUserSessionsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof authControllerGetUserSessions>>> = ({ signal }) => authControllerGetUserSessions({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerGetUserSessions>>, TError, TData> & { queryKey: QueryKey }
}

export type AuthControllerGetUserSessionsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof authControllerGetUserSessions>>>
export type AuthControllerGetUserSessionsSuspenseQueryError = AxiosError<unknown>


export function useAuthControllerGetUserSessionsSuspense<TData = Awaited<ReturnType<typeof authControllerGetUserSessions>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerGetUserSessions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthControllerGetUserSessionsSuspense<TData = Awaited<ReturnType<typeof authControllerGetUserSessions>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerGetUserSessions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthControllerGetUserSessionsSuspense<TData = Awaited<ReturnType<typeof authControllerGetUserSessions>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerGetUserSessions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useAuthControllerGetUserSessionsSuspense<TData = Awaited<ReturnType<typeof authControllerGetUserSessions>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerGetUserSessions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getAuthControllerGetUserSessionsSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const authControllerChangeTenant = (
    newTenantId: string,
    newEnvironmentId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.get(
      `/v1/auth/changeTenant/${newTenantId}/${newEnvironmentId}`,options
    );
  }


export const getAuthControllerChangeTenantQueryKey = (newTenantId: string,
    newEnvironmentId: string,) => {
    return [`/v1/auth/changeTenant/${newTenantId}/${newEnvironmentId}`] as const;
    }

    
export const getAuthControllerChangeTenantQueryOptions = <TData = Awaited<ReturnType<typeof authControllerChangeTenant>>, TError = AxiosError<unknown>>(newTenantId: string,
    newEnvironmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerChangeTenant>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAuthControllerChangeTenantQueryKey(newTenantId,newEnvironmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof authControllerChangeTenant>>> = ({ signal }) => authControllerChangeTenant(newTenantId,newEnvironmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(newTenantId && newEnvironmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof authControllerChangeTenant>>, TError, TData> & { queryKey: QueryKey }
}

export type AuthControllerChangeTenantQueryResult = NonNullable<Awaited<ReturnType<typeof authControllerChangeTenant>>>
export type AuthControllerChangeTenantQueryError = AxiosError<unknown>


export function useAuthControllerChangeTenant<TData = Awaited<ReturnType<typeof authControllerChangeTenant>>, TError = AxiosError<unknown>>(
 newTenantId: string,
    newEnvironmentId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerChangeTenant>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof authControllerChangeTenant>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthControllerChangeTenant<TData = Awaited<ReturnType<typeof authControllerChangeTenant>>, TError = AxiosError<unknown>>(
 newTenantId: string,
    newEnvironmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerChangeTenant>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof authControllerChangeTenant>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthControllerChangeTenant<TData = Awaited<ReturnType<typeof authControllerChangeTenant>>, TError = AxiosError<unknown>>(
 newTenantId: string,
    newEnvironmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerChangeTenant>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useAuthControllerChangeTenant<TData = Awaited<ReturnType<typeof authControllerChangeTenant>>, TError = AxiosError<unknown>>(
 newTenantId: string,
    newEnvironmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerChangeTenant>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getAuthControllerChangeTenantQueryOptions(newTenantId,newEnvironmentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAuthControllerChangeTenantSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof authControllerChangeTenant>>, TError = AxiosError<unknown>>(newTenantId: string,
    newEnvironmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerChangeTenant>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAuthControllerChangeTenantQueryKey(newTenantId,newEnvironmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof authControllerChangeTenant>>> = ({ signal }) => authControllerChangeTenant(newTenantId,newEnvironmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(newTenantId && newEnvironmentId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerChangeTenant>>, TError, TData> & { queryKey: QueryKey }
}

export type AuthControllerChangeTenantSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof authControllerChangeTenant>>>
export type AuthControllerChangeTenantSuspenseQueryError = AxiosError<unknown>


export function useAuthControllerChangeTenantSuspense<TData = Awaited<ReturnType<typeof authControllerChangeTenant>>, TError = AxiosError<unknown>>(
 newTenantId: string,
    newEnvironmentId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerChangeTenant>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthControllerChangeTenantSuspense<TData = Awaited<ReturnType<typeof authControllerChangeTenant>>, TError = AxiosError<unknown>>(
 newTenantId: string,
    newEnvironmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerChangeTenant>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthControllerChangeTenantSuspense<TData = Awaited<ReturnType<typeof authControllerChangeTenant>>, TError = AxiosError<unknown>>(
 newTenantId: string,
    newEnvironmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerChangeTenant>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useAuthControllerChangeTenantSuspense<TData = Awaited<ReturnType<typeof authControllerChangeTenant>>, TError = AxiosError<unknown>>(
 newTenantId: string,
    newEnvironmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerChangeTenant>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getAuthControllerChangeTenantSuspenseQueryOptions(newTenantId,newEnvironmentId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const authControllerRequestPasswordChange = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AuthControllerRequestPasswordChange200>> => {
    
    return axios.get(
      `/v1/auth/ChangePassword`,options
    );
  }


export const getAuthControllerRequestPasswordChangeQueryKey = () => {
    return [`/v1/auth/ChangePassword`] as const;
    }

    
export const getAuthControllerRequestPasswordChangeQueryOptions = <TData = Awaited<ReturnType<typeof authControllerRequestPasswordChange>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerRequestPasswordChange>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAuthControllerRequestPasswordChangeQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof authControllerRequestPasswordChange>>> = ({ signal }) => authControllerRequestPasswordChange({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof authControllerRequestPasswordChange>>, TError, TData> & { queryKey: QueryKey }
}

export type AuthControllerRequestPasswordChangeQueryResult = NonNullable<Awaited<ReturnType<typeof authControllerRequestPasswordChange>>>
export type AuthControllerRequestPasswordChangeQueryError = AxiosError<unknown>


export function useAuthControllerRequestPasswordChange<TData = Awaited<ReturnType<typeof authControllerRequestPasswordChange>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerRequestPasswordChange>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof authControllerRequestPasswordChange>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthControllerRequestPasswordChange<TData = Awaited<ReturnType<typeof authControllerRequestPasswordChange>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerRequestPasswordChange>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof authControllerRequestPasswordChange>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthControllerRequestPasswordChange<TData = Awaited<ReturnType<typeof authControllerRequestPasswordChange>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerRequestPasswordChange>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useAuthControllerRequestPasswordChange<TData = Awaited<ReturnType<typeof authControllerRequestPasswordChange>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof authControllerRequestPasswordChange>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getAuthControllerRequestPasswordChangeQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAuthControllerRequestPasswordChangeSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof authControllerRequestPasswordChange>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerRequestPasswordChange>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAuthControllerRequestPasswordChangeQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof authControllerRequestPasswordChange>>> = ({ signal }) => authControllerRequestPasswordChange({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerRequestPasswordChange>>, TError, TData> & { queryKey: QueryKey }
}

export type AuthControllerRequestPasswordChangeSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof authControllerRequestPasswordChange>>>
export type AuthControllerRequestPasswordChangeSuspenseQueryError = AxiosError<unknown>


export function useAuthControllerRequestPasswordChangeSuspense<TData = Awaited<ReturnType<typeof authControllerRequestPasswordChange>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerRequestPasswordChange>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthControllerRequestPasswordChangeSuspense<TData = Awaited<ReturnType<typeof authControllerRequestPasswordChange>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerRequestPasswordChange>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useAuthControllerRequestPasswordChangeSuspense<TData = Awaited<ReturnType<typeof authControllerRequestPasswordChange>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerRequestPasswordChange>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useAuthControllerRequestPasswordChangeSuspense<TData = Awaited<ReturnType<typeof authControllerRequestPasswordChange>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof authControllerRequestPasswordChange>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getAuthControllerRequestPasswordChangeSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * List tenants
 */
export const superadminTenantControllerGetAllTenants = (
    params?: SuperadminTenantControllerGetAllTenantsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantGetAllDto>> => {
    
    return axios.get(
      `/v1/superadmin/tenants`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getSuperadminTenantControllerGetAllTenantsQueryKey = (params?: SuperadminTenantControllerGetAllTenantsParams,) => {
    return [`/v1/superadmin/tenants`, ...(params ? [params]: [])] as const;
    }

    
export const getSuperadminTenantControllerGetAllTenantsQueryOptions = <TData = Awaited<ReturnType<typeof superadminTenantControllerGetAllTenants>>, TError = AxiosError<unknown>>(params?: SuperadminTenantControllerGetAllTenantsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminTenantControllerGetAllTenants>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSuperadminTenantControllerGetAllTenantsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof superadminTenantControllerGetAllTenants>>> = ({ signal }) => superadminTenantControllerGetAllTenants(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof superadminTenantControllerGetAllTenants>>, TError, TData> & { queryKey: QueryKey }
}

export type SuperadminTenantControllerGetAllTenantsQueryResult = NonNullable<Awaited<ReturnType<typeof superadminTenantControllerGetAllTenants>>>
export type SuperadminTenantControllerGetAllTenantsQueryError = AxiosError<unknown>


export function useSuperadminTenantControllerGetAllTenants<TData = Awaited<ReturnType<typeof superadminTenantControllerGetAllTenants>>, TError = AxiosError<unknown>>(
 params: undefined |  SuperadminTenantControllerGetAllTenantsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminTenantControllerGetAllTenants>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof superadminTenantControllerGetAllTenants>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSuperadminTenantControllerGetAllTenants<TData = Awaited<ReturnType<typeof superadminTenantControllerGetAllTenants>>, TError = AxiosError<unknown>>(
 params?: SuperadminTenantControllerGetAllTenantsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminTenantControllerGetAllTenants>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof superadminTenantControllerGetAllTenants>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSuperadminTenantControllerGetAllTenants<TData = Awaited<ReturnType<typeof superadminTenantControllerGetAllTenants>>, TError = AxiosError<unknown>>(
 params?: SuperadminTenantControllerGetAllTenantsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminTenantControllerGetAllTenants>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useSuperadminTenantControllerGetAllTenants<TData = Awaited<ReturnType<typeof superadminTenantControllerGetAllTenants>>, TError = AxiosError<unknown>>(
 params?: SuperadminTenantControllerGetAllTenantsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminTenantControllerGetAllTenants>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getSuperadminTenantControllerGetAllTenantsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getSuperadminTenantControllerGetAllTenantsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof superadminTenantControllerGetAllTenants>>, TError = AxiosError<unknown>>(params?: SuperadminTenantControllerGetAllTenantsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminTenantControllerGetAllTenants>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSuperadminTenantControllerGetAllTenantsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof superadminTenantControllerGetAllTenants>>> = ({ signal }) => superadminTenantControllerGetAllTenants(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminTenantControllerGetAllTenants>>, TError, TData> & { queryKey: QueryKey }
}

export type SuperadminTenantControllerGetAllTenantsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof superadminTenantControllerGetAllTenants>>>
export type SuperadminTenantControllerGetAllTenantsSuspenseQueryError = AxiosError<unknown>


export function useSuperadminTenantControllerGetAllTenantsSuspense<TData = Awaited<ReturnType<typeof superadminTenantControllerGetAllTenants>>, TError = AxiosError<unknown>>(
 params: undefined |  SuperadminTenantControllerGetAllTenantsParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminTenantControllerGetAllTenants>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSuperadminTenantControllerGetAllTenantsSuspense<TData = Awaited<ReturnType<typeof superadminTenantControllerGetAllTenants>>, TError = AxiosError<unknown>>(
 params?: SuperadminTenantControllerGetAllTenantsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminTenantControllerGetAllTenants>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSuperadminTenantControllerGetAllTenantsSuspense<TData = Awaited<ReturnType<typeof superadminTenantControllerGetAllTenants>>, TError = AxiosError<unknown>>(
 params?: SuperadminTenantControllerGetAllTenantsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminTenantControllerGetAllTenants>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useSuperadminTenantControllerGetAllTenantsSuspense<TData = Awaited<ReturnType<typeof superadminTenantControllerGetAllTenants>>, TError = AxiosError<unknown>>(
 params?: SuperadminTenantControllerGetAllTenantsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminTenantControllerGetAllTenants>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getSuperadminTenantControllerGetAllTenantsSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Create a new tenant
 */
export const superadminTenantControllerCreateTenant = (
    tenantCreateDto: TenantCreateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantGetSingleDto>> => {
    
    return axios.post(
      `/v1/superadmin/tenants`,
      tenantCreateDto,options
    );
  }



export const getSuperadminTenantControllerCreateTenantMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof superadminTenantControllerCreateTenant>>, TError,{data: TenantCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof superadminTenantControllerCreateTenant>>, TError,{data: TenantCreateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof superadminTenantControllerCreateTenant>>, {data: TenantCreateDto}> = (props) => {
          const {data} = props ?? {};

          return  superadminTenantControllerCreateTenant(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SuperadminTenantControllerCreateTenantMutationResult = NonNullable<Awaited<ReturnType<typeof superadminTenantControllerCreateTenant>>>
    export type SuperadminTenantControllerCreateTenantMutationBody = TenantCreateDto
    export type SuperadminTenantControllerCreateTenantMutationError = AxiosError<unknown>

    export const useSuperadminTenantControllerCreateTenant = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof superadminTenantControllerCreateTenant>>, TError,{data: TenantCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof superadminTenantControllerCreateTenant>>,
        TError,
        {data: TenantCreateDto},
        TContext
      > => {

      const mutationOptions = getSuperadminTenantControllerCreateTenantMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get Tenant Details
 */
export const superadminTenantControllerGetSingleTenant = (
    paramTenantId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantGetSingleDto>> => {
    
    return axios.get(
      `/v1/superadmin/tenants/${paramTenantId}`,options
    );
  }


export const getSuperadminTenantControllerGetSingleTenantQueryKey = (paramTenantId: string,) => {
    return [`/v1/superadmin/tenants/${paramTenantId}`] as const;
    }

    
export const getSuperadminTenantControllerGetSingleTenantQueryOptions = <TData = Awaited<ReturnType<typeof superadminTenantControllerGetSingleTenant>>, TError = AxiosError<unknown>>(paramTenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminTenantControllerGetSingleTenant>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSuperadminTenantControllerGetSingleTenantQueryKey(paramTenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof superadminTenantControllerGetSingleTenant>>> = ({ signal }) => superadminTenantControllerGetSingleTenant(paramTenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(paramTenantId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof superadminTenantControllerGetSingleTenant>>, TError, TData> & { queryKey: QueryKey }
}

export type SuperadminTenantControllerGetSingleTenantQueryResult = NonNullable<Awaited<ReturnType<typeof superadminTenantControllerGetSingleTenant>>>
export type SuperadminTenantControllerGetSingleTenantQueryError = AxiosError<unknown>


export function useSuperadminTenantControllerGetSingleTenant<TData = Awaited<ReturnType<typeof superadminTenantControllerGetSingleTenant>>, TError = AxiosError<unknown>>(
 paramTenantId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminTenantControllerGetSingleTenant>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof superadminTenantControllerGetSingleTenant>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSuperadminTenantControllerGetSingleTenant<TData = Awaited<ReturnType<typeof superadminTenantControllerGetSingleTenant>>, TError = AxiosError<unknown>>(
 paramTenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminTenantControllerGetSingleTenant>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof superadminTenantControllerGetSingleTenant>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSuperadminTenantControllerGetSingleTenant<TData = Awaited<ReturnType<typeof superadminTenantControllerGetSingleTenant>>, TError = AxiosError<unknown>>(
 paramTenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminTenantControllerGetSingleTenant>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useSuperadminTenantControllerGetSingleTenant<TData = Awaited<ReturnType<typeof superadminTenantControllerGetSingleTenant>>, TError = AxiosError<unknown>>(
 paramTenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminTenantControllerGetSingleTenant>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getSuperadminTenantControllerGetSingleTenantQueryOptions(paramTenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getSuperadminTenantControllerGetSingleTenantSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof superadminTenantControllerGetSingleTenant>>, TError = AxiosError<unknown>>(paramTenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminTenantControllerGetSingleTenant>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSuperadminTenantControllerGetSingleTenantQueryKey(paramTenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof superadminTenantControllerGetSingleTenant>>> = ({ signal }) => superadminTenantControllerGetSingleTenant(paramTenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(paramTenantId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminTenantControllerGetSingleTenant>>, TError, TData> & { queryKey: QueryKey }
}

export type SuperadminTenantControllerGetSingleTenantSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof superadminTenantControllerGetSingleTenant>>>
export type SuperadminTenantControllerGetSingleTenantSuspenseQueryError = AxiosError<unknown>


export function useSuperadminTenantControllerGetSingleTenantSuspense<TData = Awaited<ReturnType<typeof superadminTenantControllerGetSingleTenant>>, TError = AxiosError<unknown>>(
 paramTenantId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminTenantControllerGetSingleTenant>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSuperadminTenantControllerGetSingleTenantSuspense<TData = Awaited<ReturnType<typeof superadminTenantControllerGetSingleTenant>>, TError = AxiosError<unknown>>(
 paramTenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminTenantControllerGetSingleTenant>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSuperadminTenantControllerGetSingleTenantSuspense<TData = Awaited<ReturnType<typeof superadminTenantControllerGetSingleTenant>>, TError = AxiosError<unknown>>(
 paramTenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminTenantControllerGetSingleTenant>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useSuperadminTenantControllerGetSingleTenantSuspense<TData = Awaited<ReturnType<typeof superadminTenantControllerGetSingleTenant>>, TError = AxiosError<unknown>>(
 paramTenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminTenantControllerGetSingleTenant>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getSuperadminTenantControllerGetSingleTenantSuspenseQueryOptions(paramTenantId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Update an existing tenant
 */
export const superadminTenantControllerUpdateTenant = (
    paramTenantId: string,
    tenantUpdateDto: TenantUpdateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantGetSingleDto>> => {
    
    return axios.put(
      `/v1/superadmin/tenants/${paramTenantId}`,
      tenantUpdateDto,options
    );
  }



export const getSuperadminTenantControllerUpdateTenantMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof superadminTenantControllerUpdateTenant>>, TError,{paramTenantId: string;data: TenantUpdateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof superadminTenantControllerUpdateTenant>>, TError,{paramTenantId: string;data: TenantUpdateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof superadminTenantControllerUpdateTenant>>, {paramTenantId: string;data: TenantUpdateDto}> = (props) => {
          const {paramTenantId,data} = props ?? {};

          return  superadminTenantControllerUpdateTenant(paramTenantId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SuperadminTenantControllerUpdateTenantMutationResult = NonNullable<Awaited<ReturnType<typeof superadminTenantControllerUpdateTenant>>>
    export type SuperadminTenantControllerUpdateTenantMutationBody = TenantUpdateDto
    export type SuperadminTenantControllerUpdateTenantMutationError = AxiosError<unknown>

    export const useSuperadminTenantControllerUpdateTenant = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof superadminTenantControllerUpdateTenant>>, TError,{paramTenantId: string;data: TenantUpdateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof superadminTenantControllerUpdateTenant>>,
        TError,
        {paramTenantId: string;data: TenantUpdateDto},
        TContext
      > => {

      const mutationOptions = getSuperadminTenantControllerUpdateTenantMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * List all Users
 */
export const superadminUserControllerGetAllUsers = (
    params?: SuperadminUserControllerGetAllUsersParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<UserGetAllDto>> => {
    
    return axios.get(
      `/v1/superadmin/users`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getSuperadminUserControllerGetAllUsersQueryKey = (params?: SuperadminUserControllerGetAllUsersParams,) => {
    return [`/v1/superadmin/users`, ...(params ? [params]: [])] as const;
    }

    
export const getSuperadminUserControllerGetAllUsersQueryOptions = <TData = Awaited<ReturnType<typeof superadminUserControllerGetAllUsers>>, TError = AxiosError<unknown>>(params?: SuperadminUserControllerGetAllUsersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminUserControllerGetAllUsers>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSuperadminUserControllerGetAllUsersQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof superadminUserControllerGetAllUsers>>> = ({ signal }) => superadminUserControllerGetAllUsers(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof superadminUserControllerGetAllUsers>>, TError, TData> & { queryKey: QueryKey }
}

export type SuperadminUserControllerGetAllUsersQueryResult = NonNullable<Awaited<ReturnType<typeof superadminUserControllerGetAllUsers>>>
export type SuperadminUserControllerGetAllUsersQueryError = AxiosError<unknown>


export function useSuperadminUserControllerGetAllUsers<TData = Awaited<ReturnType<typeof superadminUserControllerGetAllUsers>>, TError = AxiosError<unknown>>(
 params: undefined |  SuperadminUserControllerGetAllUsersParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminUserControllerGetAllUsers>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof superadminUserControllerGetAllUsers>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSuperadminUserControllerGetAllUsers<TData = Awaited<ReturnType<typeof superadminUserControllerGetAllUsers>>, TError = AxiosError<unknown>>(
 params?: SuperadminUserControllerGetAllUsersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminUserControllerGetAllUsers>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof superadminUserControllerGetAllUsers>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSuperadminUserControllerGetAllUsers<TData = Awaited<ReturnType<typeof superadminUserControllerGetAllUsers>>, TError = AxiosError<unknown>>(
 params?: SuperadminUserControllerGetAllUsersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminUserControllerGetAllUsers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useSuperadminUserControllerGetAllUsers<TData = Awaited<ReturnType<typeof superadminUserControllerGetAllUsers>>, TError = AxiosError<unknown>>(
 params?: SuperadminUserControllerGetAllUsersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminUserControllerGetAllUsers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getSuperadminUserControllerGetAllUsersQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getSuperadminUserControllerGetAllUsersSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof superadminUserControllerGetAllUsers>>, TError = AxiosError<unknown>>(params?: SuperadminUserControllerGetAllUsersParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminUserControllerGetAllUsers>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSuperadminUserControllerGetAllUsersQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof superadminUserControllerGetAllUsers>>> = ({ signal }) => superadminUserControllerGetAllUsers(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminUserControllerGetAllUsers>>, TError, TData> & { queryKey: QueryKey }
}

export type SuperadminUserControllerGetAllUsersSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof superadminUserControllerGetAllUsers>>>
export type SuperadminUserControllerGetAllUsersSuspenseQueryError = AxiosError<unknown>


export function useSuperadminUserControllerGetAllUsersSuspense<TData = Awaited<ReturnType<typeof superadminUserControllerGetAllUsers>>, TError = AxiosError<unknown>>(
 params: undefined |  SuperadminUserControllerGetAllUsersParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminUserControllerGetAllUsers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSuperadminUserControllerGetAllUsersSuspense<TData = Awaited<ReturnType<typeof superadminUserControllerGetAllUsers>>, TError = AxiosError<unknown>>(
 params?: SuperadminUserControllerGetAllUsersParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminUserControllerGetAllUsers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSuperadminUserControllerGetAllUsersSuspense<TData = Awaited<ReturnType<typeof superadminUserControllerGetAllUsers>>, TError = AxiosError<unknown>>(
 params?: SuperadminUserControllerGetAllUsersParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminUserControllerGetAllUsers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useSuperadminUserControllerGetAllUsersSuspense<TData = Awaited<ReturnType<typeof superadminUserControllerGetAllUsers>>, TError = AxiosError<unknown>>(
 params?: SuperadminUserControllerGetAllUsersParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminUserControllerGetAllUsers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getSuperadminUserControllerGetAllUsersSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const superadminUserControllerCreateUser = (
    userCreateDto: UserCreateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<UserGetAllDtoSingle>> => {
    
    return axios.post(
      `/v1/superadmin/users`,
      userCreateDto,options
    );
  }



export const getSuperadminUserControllerCreateUserMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof superadminUserControllerCreateUser>>, TError,{data: UserCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof superadminUserControllerCreateUser>>, TError,{data: UserCreateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof superadminUserControllerCreateUser>>, {data: UserCreateDto}> = (props) => {
          const {data} = props ?? {};

          return  superadminUserControllerCreateUser(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SuperadminUserControllerCreateUserMutationResult = NonNullable<Awaited<ReturnType<typeof superadminUserControllerCreateUser>>>
    export type SuperadminUserControllerCreateUserMutationBody = UserCreateDto
    export type SuperadminUserControllerCreateUserMutationError = AxiosError<unknown>

    export const useSuperadminUserControllerCreateUser = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof superadminUserControllerCreateUser>>, TError,{data: UserCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof superadminUserControllerCreateUser>>,
        TError,
        {data: UserCreateDto},
        TContext
      > => {

      const mutationOptions = getSuperadminUserControllerCreateUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const superadminUserControllerGetAllUsersByTenant = (
    paramTenantId: string,
    params?: SuperadminUserControllerGetAllUsersByTenantParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<UserGetAllByTenantDto>> => {
    
    return axios.get(
      `/v1/superadmin/users/byTenant/${paramTenantId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getSuperadminUserControllerGetAllUsersByTenantQueryKey = (paramTenantId: string,
    params?: SuperadminUserControllerGetAllUsersByTenantParams,) => {
    return [`/v1/superadmin/users/byTenant/${paramTenantId}`, ...(params ? [params]: [])] as const;
    }

    
export const getSuperadminUserControllerGetAllUsersByTenantQueryOptions = <TData = Awaited<ReturnType<typeof superadminUserControllerGetAllUsersByTenant>>, TError = AxiosError<unknown>>(paramTenantId: string,
    params?: SuperadminUserControllerGetAllUsersByTenantParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminUserControllerGetAllUsersByTenant>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSuperadminUserControllerGetAllUsersByTenantQueryKey(paramTenantId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof superadminUserControllerGetAllUsersByTenant>>> = ({ signal }) => superadminUserControllerGetAllUsersByTenant(paramTenantId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(paramTenantId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof superadminUserControllerGetAllUsersByTenant>>, TError, TData> & { queryKey: QueryKey }
}

export type SuperadminUserControllerGetAllUsersByTenantQueryResult = NonNullable<Awaited<ReturnType<typeof superadminUserControllerGetAllUsersByTenant>>>
export type SuperadminUserControllerGetAllUsersByTenantQueryError = AxiosError<unknown>


export function useSuperadminUserControllerGetAllUsersByTenant<TData = Awaited<ReturnType<typeof superadminUserControllerGetAllUsersByTenant>>, TError = AxiosError<unknown>>(
 paramTenantId: string,
    params: undefined |  SuperadminUserControllerGetAllUsersByTenantParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminUserControllerGetAllUsersByTenant>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof superadminUserControllerGetAllUsersByTenant>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSuperadminUserControllerGetAllUsersByTenant<TData = Awaited<ReturnType<typeof superadminUserControllerGetAllUsersByTenant>>, TError = AxiosError<unknown>>(
 paramTenantId: string,
    params?: SuperadminUserControllerGetAllUsersByTenantParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminUserControllerGetAllUsersByTenant>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof superadminUserControllerGetAllUsersByTenant>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSuperadminUserControllerGetAllUsersByTenant<TData = Awaited<ReturnType<typeof superadminUserControllerGetAllUsersByTenant>>, TError = AxiosError<unknown>>(
 paramTenantId: string,
    params?: SuperadminUserControllerGetAllUsersByTenantParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminUserControllerGetAllUsersByTenant>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useSuperadminUserControllerGetAllUsersByTenant<TData = Awaited<ReturnType<typeof superadminUserControllerGetAllUsersByTenant>>, TError = AxiosError<unknown>>(
 paramTenantId: string,
    params?: SuperadminUserControllerGetAllUsersByTenantParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminUserControllerGetAllUsersByTenant>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getSuperadminUserControllerGetAllUsersByTenantQueryOptions(paramTenantId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getSuperadminUserControllerGetAllUsersByTenantSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof superadminUserControllerGetAllUsersByTenant>>, TError = AxiosError<unknown>>(paramTenantId: string,
    params?: SuperadminUserControllerGetAllUsersByTenantParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminUserControllerGetAllUsersByTenant>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSuperadminUserControllerGetAllUsersByTenantQueryKey(paramTenantId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof superadminUserControllerGetAllUsersByTenant>>> = ({ signal }) => superadminUserControllerGetAllUsersByTenant(paramTenantId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(paramTenantId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminUserControllerGetAllUsersByTenant>>, TError, TData> & { queryKey: QueryKey }
}

export type SuperadminUserControllerGetAllUsersByTenantSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof superadminUserControllerGetAllUsersByTenant>>>
export type SuperadminUserControllerGetAllUsersByTenantSuspenseQueryError = AxiosError<unknown>


export function useSuperadminUserControllerGetAllUsersByTenantSuspense<TData = Awaited<ReturnType<typeof superadminUserControllerGetAllUsersByTenant>>, TError = AxiosError<unknown>>(
 paramTenantId: string,
    params: undefined |  SuperadminUserControllerGetAllUsersByTenantParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminUserControllerGetAllUsersByTenant>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSuperadminUserControllerGetAllUsersByTenantSuspense<TData = Awaited<ReturnType<typeof superadminUserControllerGetAllUsersByTenant>>, TError = AxiosError<unknown>>(
 paramTenantId: string,
    params?: SuperadminUserControllerGetAllUsersByTenantParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminUserControllerGetAllUsersByTenant>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSuperadminUserControllerGetAllUsersByTenantSuspense<TData = Awaited<ReturnType<typeof superadminUserControllerGetAllUsersByTenant>>, TError = AxiosError<unknown>>(
 paramTenantId: string,
    params?: SuperadminUserControllerGetAllUsersByTenantParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminUserControllerGetAllUsersByTenant>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useSuperadminUserControllerGetAllUsersByTenantSuspense<TData = Awaited<ReturnType<typeof superadminUserControllerGetAllUsersByTenant>>, TError = AxiosError<unknown>>(
 paramTenantId: string,
    params?: SuperadminUserControllerGetAllUsersByTenantParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminUserControllerGetAllUsersByTenant>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getSuperadminUserControllerGetAllUsersByTenantSuspenseQueryOptions(paramTenantId,params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get all Environments
 */
export const superadminEnvironmentControllerGetAllEnvironments = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EnvironmentGetAllDto[]>> => {
    
    return axios.get(
      `/v1/superadmin/environments`,options
    );
  }


export const getSuperadminEnvironmentControllerGetAllEnvironmentsQueryKey = () => {
    return [`/v1/superadmin/environments`] as const;
    }

    
export const getSuperadminEnvironmentControllerGetAllEnvironmentsQueryOptions = <TData = Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironments>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironments>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSuperadminEnvironmentControllerGetAllEnvironmentsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironments>>> = ({ signal }) => superadminEnvironmentControllerGetAllEnvironments({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironments>>, TError, TData> & { queryKey: QueryKey }
}

export type SuperadminEnvironmentControllerGetAllEnvironmentsQueryResult = NonNullable<Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironments>>>
export type SuperadminEnvironmentControllerGetAllEnvironmentsQueryError = AxiosError<unknown>


export function useSuperadminEnvironmentControllerGetAllEnvironments<TData = Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironments>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironments>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironments>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSuperadminEnvironmentControllerGetAllEnvironments<TData = Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironments>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironments>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironments>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSuperadminEnvironmentControllerGetAllEnvironments<TData = Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironments>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useSuperadminEnvironmentControllerGetAllEnvironments<TData = Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironments>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getSuperadminEnvironmentControllerGetAllEnvironmentsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getSuperadminEnvironmentControllerGetAllEnvironmentsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironments>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironments>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSuperadminEnvironmentControllerGetAllEnvironmentsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironments>>> = ({ signal }) => superadminEnvironmentControllerGetAllEnvironments({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironments>>, TError, TData> & { queryKey: QueryKey }
}

export type SuperadminEnvironmentControllerGetAllEnvironmentsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironments>>>
export type SuperadminEnvironmentControllerGetAllEnvironmentsSuspenseQueryError = AxiosError<unknown>


export function useSuperadminEnvironmentControllerGetAllEnvironmentsSuspense<TData = Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironments>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSuperadminEnvironmentControllerGetAllEnvironmentsSuspense<TData = Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironments>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSuperadminEnvironmentControllerGetAllEnvironmentsSuspense<TData = Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironments>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useSuperadminEnvironmentControllerGetAllEnvironmentsSuspense<TData = Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironments>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getSuperadminEnvironmentControllerGetAllEnvironmentsSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get all Environments by Tenant ID
 */
export const superadminEnvironmentControllerGetAllEnvironmentsByTenant = (
    paramTenantId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EnvironmentGetAllByTenantDto[]>> => {
    
    return axios.get(
      `/v1/superadmin/environments/byTenant/${paramTenantId}`,options
    );
  }


export const getSuperadminEnvironmentControllerGetAllEnvironmentsByTenantQueryKey = (paramTenantId: string,) => {
    return [`/v1/superadmin/environments/byTenant/${paramTenantId}`] as const;
    }

    
export const getSuperadminEnvironmentControllerGetAllEnvironmentsByTenantQueryOptions = <TData = Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironmentsByTenant>>, TError = AxiosError<unknown>>(paramTenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironmentsByTenant>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSuperadminEnvironmentControllerGetAllEnvironmentsByTenantQueryKey(paramTenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironmentsByTenant>>> = ({ signal }) => superadminEnvironmentControllerGetAllEnvironmentsByTenant(paramTenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(paramTenantId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironmentsByTenant>>, TError, TData> & { queryKey: QueryKey }
}

export type SuperadminEnvironmentControllerGetAllEnvironmentsByTenantQueryResult = NonNullable<Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironmentsByTenant>>>
export type SuperadminEnvironmentControllerGetAllEnvironmentsByTenantQueryError = AxiosError<unknown>


export function useSuperadminEnvironmentControllerGetAllEnvironmentsByTenant<TData = Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironmentsByTenant>>, TError = AxiosError<unknown>>(
 paramTenantId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironmentsByTenant>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironmentsByTenant>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSuperadminEnvironmentControllerGetAllEnvironmentsByTenant<TData = Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironmentsByTenant>>, TError = AxiosError<unknown>>(
 paramTenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironmentsByTenant>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironmentsByTenant>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSuperadminEnvironmentControllerGetAllEnvironmentsByTenant<TData = Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironmentsByTenant>>, TError = AxiosError<unknown>>(
 paramTenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironmentsByTenant>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useSuperadminEnvironmentControllerGetAllEnvironmentsByTenant<TData = Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironmentsByTenant>>, TError = AxiosError<unknown>>(
 paramTenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironmentsByTenant>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getSuperadminEnvironmentControllerGetAllEnvironmentsByTenantQueryOptions(paramTenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getSuperadminEnvironmentControllerGetAllEnvironmentsByTenantSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironmentsByTenant>>, TError = AxiosError<unknown>>(paramTenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironmentsByTenant>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSuperadminEnvironmentControllerGetAllEnvironmentsByTenantQueryKey(paramTenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironmentsByTenant>>> = ({ signal }) => superadminEnvironmentControllerGetAllEnvironmentsByTenant(paramTenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(paramTenantId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironmentsByTenant>>, TError, TData> & { queryKey: QueryKey }
}

export type SuperadminEnvironmentControllerGetAllEnvironmentsByTenantSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironmentsByTenant>>>
export type SuperadminEnvironmentControllerGetAllEnvironmentsByTenantSuspenseQueryError = AxiosError<unknown>


export function useSuperadminEnvironmentControllerGetAllEnvironmentsByTenantSuspense<TData = Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironmentsByTenant>>, TError = AxiosError<unknown>>(
 paramTenantId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironmentsByTenant>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSuperadminEnvironmentControllerGetAllEnvironmentsByTenantSuspense<TData = Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironmentsByTenant>>, TError = AxiosError<unknown>>(
 paramTenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironmentsByTenant>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSuperadminEnvironmentControllerGetAllEnvironmentsByTenantSuspense<TData = Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironmentsByTenant>>, TError = AxiosError<unknown>>(
 paramTenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironmentsByTenant>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useSuperadminEnvironmentControllerGetAllEnvironmentsByTenantSuspense<TData = Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironmentsByTenant>>, TError = AxiosError<unknown>>(
 paramTenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminEnvironmentControllerGetAllEnvironmentsByTenant>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getSuperadminEnvironmentControllerGetAllEnvironmentsByTenantSuspenseQueryOptions(paramTenantId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get Database Statistics
 */
export const superadminDatabaseControllerGetDatabaseStatistics = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<DatabaseSizeGetDto[]>> => {
    
    return axios.get(
      `/v1/superadmin/database/database`,options
    );
  }


export const getSuperadminDatabaseControllerGetDatabaseStatisticsQueryKey = () => {
    return [`/v1/superadmin/database/database`] as const;
    }

    
export const getSuperadminDatabaseControllerGetDatabaseStatisticsQueryOptions = <TData = Awaited<ReturnType<typeof superadminDatabaseControllerGetDatabaseStatistics>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminDatabaseControllerGetDatabaseStatistics>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSuperadminDatabaseControllerGetDatabaseStatisticsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof superadminDatabaseControllerGetDatabaseStatistics>>> = ({ signal }) => superadminDatabaseControllerGetDatabaseStatistics({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof superadminDatabaseControllerGetDatabaseStatistics>>, TError, TData> & { queryKey: QueryKey }
}

export type SuperadminDatabaseControllerGetDatabaseStatisticsQueryResult = NonNullable<Awaited<ReturnType<typeof superadminDatabaseControllerGetDatabaseStatistics>>>
export type SuperadminDatabaseControllerGetDatabaseStatisticsQueryError = AxiosError<unknown>


export function useSuperadminDatabaseControllerGetDatabaseStatistics<TData = Awaited<ReturnType<typeof superadminDatabaseControllerGetDatabaseStatistics>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminDatabaseControllerGetDatabaseStatistics>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof superadminDatabaseControllerGetDatabaseStatistics>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSuperadminDatabaseControllerGetDatabaseStatistics<TData = Awaited<ReturnType<typeof superadminDatabaseControllerGetDatabaseStatistics>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminDatabaseControllerGetDatabaseStatistics>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof superadminDatabaseControllerGetDatabaseStatistics>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSuperadminDatabaseControllerGetDatabaseStatistics<TData = Awaited<ReturnType<typeof superadminDatabaseControllerGetDatabaseStatistics>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminDatabaseControllerGetDatabaseStatistics>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useSuperadminDatabaseControllerGetDatabaseStatistics<TData = Awaited<ReturnType<typeof superadminDatabaseControllerGetDatabaseStatistics>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminDatabaseControllerGetDatabaseStatistics>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getSuperadminDatabaseControllerGetDatabaseStatisticsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getSuperadminDatabaseControllerGetDatabaseStatisticsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof superadminDatabaseControllerGetDatabaseStatistics>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminDatabaseControllerGetDatabaseStatistics>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSuperadminDatabaseControllerGetDatabaseStatisticsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof superadminDatabaseControllerGetDatabaseStatistics>>> = ({ signal }) => superadminDatabaseControllerGetDatabaseStatistics({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminDatabaseControllerGetDatabaseStatistics>>, TError, TData> & { queryKey: QueryKey }
}

export type SuperadminDatabaseControllerGetDatabaseStatisticsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof superadminDatabaseControllerGetDatabaseStatistics>>>
export type SuperadminDatabaseControllerGetDatabaseStatisticsSuspenseQueryError = AxiosError<unknown>


export function useSuperadminDatabaseControllerGetDatabaseStatisticsSuspense<TData = Awaited<ReturnType<typeof superadminDatabaseControllerGetDatabaseStatistics>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminDatabaseControllerGetDatabaseStatistics>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSuperadminDatabaseControllerGetDatabaseStatisticsSuspense<TData = Awaited<ReturnType<typeof superadminDatabaseControllerGetDatabaseStatistics>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminDatabaseControllerGetDatabaseStatistics>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSuperadminDatabaseControllerGetDatabaseStatisticsSuspense<TData = Awaited<ReturnType<typeof superadminDatabaseControllerGetDatabaseStatistics>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminDatabaseControllerGetDatabaseStatistics>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useSuperadminDatabaseControllerGetDatabaseStatisticsSuspense<TData = Awaited<ReturnType<typeof superadminDatabaseControllerGetDatabaseStatistics>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminDatabaseControllerGetDatabaseStatistics>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getSuperadminDatabaseControllerGetDatabaseStatisticsSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get current table statistics
 */
export const superadminDatabaseControllerGetCurrentTableStatistics = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TableSizeGetDto[]>> => {
    
    return axios.get(
      `/v1/superadmin/database/tables`,options
    );
  }


export const getSuperadminDatabaseControllerGetCurrentTableStatisticsQueryKey = () => {
    return [`/v1/superadmin/database/tables`] as const;
    }

    
export const getSuperadminDatabaseControllerGetCurrentTableStatisticsQueryOptions = <TData = Awaited<ReturnType<typeof superadminDatabaseControllerGetCurrentTableStatistics>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminDatabaseControllerGetCurrentTableStatistics>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSuperadminDatabaseControllerGetCurrentTableStatisticsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof superadminDatabaseControllerGetCurrentTableStatistics>>> = ({ signal }) => superadminDatabaseControllerGetCurrentTableStatistics({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof superadminDatabaseControllerGetCurrentTableStatistics>>, TError, TData> & { queryKey: QueryKey }
}

export type SuperadminDatabaseControllerGetCurrentTableStatisticsQueryResult = NonNullable<Awaited<ReturnType<typeof superadminDatabaseControllerGetCurrentTableStatistics>>>
export type SuperadminDatabaseControllerGetCurrentTableStatisticsQueryError = AxiosError<unknown>


export function useSuperadminDatabaseControllerGetCurrentTableStatistics<TData = Awaited<ReturnType<typeof superadminDatabaseControllerGetCurrentTableStatistics>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminDatabaseControllerGetCurrentTableStatistics>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof superadminDatabaseControllerGetCurrentTableStatistics>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSuperadminDatabaseControllerGetCurrentTableStatistics<TData = Awaited<ReturnType<typeof superadminDatabaseControllerGetCurrentTableStatistics>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminDatabaseControllerGetCurrentTableStatistics>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof superadminDatabaseControllerGetCurrentTableStatistics>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSuperadminDatabaseControllerGetCurrentTableStatistics<TData = Awaited<ReturnType<typeof superadminDatabaseControllerGetCurrentTableStatistics>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminDatabaseControllerGetCurrentTableStatistics>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useSuperadminDatabaseControllerGetCurrentTableStatistics<TData = Awaited<ReturnType<typeof superadminDatabaseControllerGetCurrentTableStatistics>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof superadminDatabaseControllerGetCurrentTableStatistics>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getSuperadminDatabaseControllerGetCurrentTableStatisticsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getSuperadminDatabaseControllerGetCurrentTableStatisticsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof superadminDatabaseControllerGetCurrentTableStatistics>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminDatabaseControllerGetCurrentTableStatistics>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSuperadminDatabaseControllerGetCurrentTableStatisticsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof superadminDatabaseControllerGetCurrentTableStatistics>>> = ({ signal }) => superadminDatabaseControllerGetCurrentTableStatistics({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminDatabaseControllerGetCurrentTableStatistics>>, TError, TData> & { queryKey: QueryKey }
}

export type SuperadminDatabaseControllerGetCurrentTableStatisticsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof superadminDatabaseControllerGetCurrentTableStatistics>>>
export type SuperadminDatabaseControllerGetCurrentTableStatisticsSuspenseQueryError = AxiosError<unknown>


export function useSuperadminDatabaseControllerGetCurrentTableStatisticsSuspense<TData = Awaited<ReturnType<typeof superadminDatabaseControllerGetCurrentTableStatistics>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminDatabaseControllerGetCurrentTableStatistics>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSuperadminDatabaseControllerGetCurrentTableStatisticsSuspense<TData = Awaited<ReturnType<typeof superadminDatabaseControllerGetCurrentTableStatistics>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminDatabaseControllerGetCurrentTableStatistics>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSuperadminDatabaseControllerGetCurrentTableStatisticsSuspense<TData = Awaited<ReturnType<typeof superadminDatabaseControllerGetCurrentTableStatistics>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminDatabaseControllerGetCurrentTableStatistics>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useSuperadminDatabaseControllerGetCurrentTableStatisticsSuspense<TData = Awaited<ReturnType<typeof superadminDatabaseControllerGetCurrentTableStatistics>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof superadminDatabaseControllerGetCurrentTableStatistics>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getSuperadminDatabaseControllerGetCurrentTableStatisticsSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const environmentLocationControllerGetLocations = (
    tenantId: string,
    environmentId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EnvironmentLocationControllerGetLocations200Item[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/settings/location`,options
    );
  }


export const getEnvironmentLocationControllerGetLocationsQueryKey = (tenantId: string,
    environmentId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/settings/location`] as const;
    }

    
export const getEnvironmentLocationControllerGetLocationsQueryOptions = <TData = Awaited<ReturnType<typeof environmentLocationControllerGetLocations>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentLocationControllerGetLocations>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getEnvironmentLocationControllerGetLocationsQueryKey(tenantId,environmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof environmentLocationControllerGetLocations>>> = ({ signal }) => environmentLocationControllerGetLocations(tenantId,environmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof environmentLocationControllerGetLocations>>, TError, TData> & { queryKey: QueryKey }
}

export type EnvironmentLocationControllerGetLocationsQueryResult = NonNullable<Awaited<ReturnType<typeof environmentLocationControllerGetLocations>>>
export type EnvironmentLocationControllerGetLocationsQueryError = AxiosError<unknown>


export function useEnvironmentLocationControllerGetLocations<TData = Awaited<ReturnType<typeof environmentLocationControllerGetLocations>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentLocationControllerGetLocations>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof environmentLocationControllerGetLocations>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentLocationControllerGetLocations<TData = Awaited<ReturnType<typeof environmentLocationControllerGetLocations>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentLocationControllerGetLocations>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof environmentLocationControllerGetLocations>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentLocationControllerGetLocations<TData = Awaited<ReturnType<typeof environmentLocationControllerGetLocations>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentLocationControllerGetLocations>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useEnvironmentLocationControllerGetLocations<TData = Awaited<ReturnType<typeof environmentLocationControllerGetLocations>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentLocationControllerGetLocations>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getEnvironmentLocationControllerGetLocationsQueryOptions(tenantId,environmentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getEnvironmentLocationControllerGetLocationsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof environmentLocationControllerGetLocations>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentLocationControllerGetLocations>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getEnvironmentLocationControllerGetLocationsQueryKey(tenantId,environmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof environmentLocationControllerGetLocations>>> = ({ signal }) => environmentLocationControllerGetLocations(tenantId,environmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentLocationControllerGetLocations>>, TError, TData> & { queryKey: QueryKey }
}

export type EnvironmentLocationControllerGetLocationsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof environmentLocationControllerGetLocations>>>
export type EnvironmentLocationControllerGetLocationsSuspenseQueryError = AxiosError<unknown>


export function useEnvironmentLocationControllerGetLocationsSuspense<TData = Awaited<ReturnType<typeof environmentLocationControllerGetLocations>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentLocationControllerGetLocations>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentLocationControllerGetLocationsSuspense<TData = Awaited<ReturnType<typeof environmentLocationControllerGetLocations>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentLocationControllerGetLocations>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentLocationControllerGetLocationsSuspense<TData = Awaited<ReturnType<typeof environmentLocationControllerGetLocations>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentLocationControllerGetLocations>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useEnvironmentLocationControllerGetLocationsSuspense<TData = Awaited<ReturnType<typeof environmentLocationControllerGetLocations>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentLocationControllerGetLocations>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getEnvironmentLocationControllerGetLocationsSuspenseQueryOptions(tenantId,environmentId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const environmentLocationControllerGetLocationsByType = (
    tenantId: string,
    environmentId: string,
    type: 'match' | 'training' | 'medical', options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EnvironmentLocationControllerGetLocationsByType200>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/settings/location/${type}`,options
    );
  }


export const getEnvironmentLocationControllerGetLocationsByTypeQueryKey = (tenantId: string,
    environmentId: string,
    type: 'match' | 'training' | 'medical',) => {
    return [`/v1/${tenantId}/${environmentId}/settings/location/${type}`] as const;
    }

    
export const getEnvironmentLocationControllerGetLocationsByTypeQueryOptions = <TData = Awaited<ReturnType<typeof environmentLocationControllerGetLocationsByType>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    type: 'match' | 'training' | 'medical', options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentLocationControllerGetLocationsByType>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getEnvironmentLocationControllerGetLocationsByTypeQueryKey(tenantId,environmentId,type);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof environmentLocationControllerGetLocationsByType>>> = ({ signal }) => environmentLocationControllerGetLocationsByType(tenantId,environmentId,type, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && type), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof environmentLocationControllerGetLocationsByType>>, TError, TData> & { queryKey: QueryKey }
}

export type EnvironmentLocationControllerGetLocationsByTypeQueryResult = NonNullable<Awaited<ReturnType<typeof environmentLocationControllerGetLocationsByType>>>
export type EnvironmentLocationControllerGetLocationsByTypeQueryError = AxiosError<unknown>


export function useEnvironmentLocationControllerGetLocationsByType<TData = Awaited<ReturnType<typeof environmentLocationControllerGetLocationsByType>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    type: 'match' | 'training' | 'medical', options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentLocationControllerGetLocationsByType>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof environmentLocationControllerGetLocationsByType>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentLocationControllerGetLocationsByType<TData = Awaited<ReturnType<typeof environmentLocationControllerGetLocationsByType>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    type: 'match' | 'training' | 'medical', options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentLocationControllerGetLocationsByType>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof environmentLocationControllerGetLocationsByType>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentLocationControllerGetLocationsByType<TData = Awaited<ReturnType<typeof environmentLocationControllerGetLocationsByType>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    type: 'match' | 'training' | 'medical', options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentLocationControllerGetLocationsByType>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useEnvironmentLocationControllerGetLocationsByType<TData = Awaited<ReturnType<typeof environmentLocationControllerGetLocationsByType>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    type: 'match' | 'training' | 'medical', options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentLocationControllerGetLocationsByType>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getEnvironmentLocationControllerGetLocationsByTypeQueryOptions(tenantId,environmentId,type,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getEnvironmentLocationControllerGetLocationsByTypeSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof environmentLocationControllerGetLocationsByType>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    type: 'match' | 'training' | 'medical', options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentLocationControllerGetLocationsByType>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getEnvironmentLocationControllerGetLocationsByTypeQueryKey(tenantId,environmentId,type);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof environmentLocationControllerGetLocationsByType>>> = ({ signal }) => environmentLocationControllerGetLocationsByType(tenantId,environmentId,type, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && type), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentLocationControllerGetLocationsByType>>, TError, TData> & { queryKey: QueryKey }
}

export type EnvironmentLocationControllerGetLocationsByTypeSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof environmentLocationControllerGetLocationsByType>>>
export type EnvironmentLocationControllerGetLocationsByTypeSuspenseQueryError = AxiosError<unknown>


export function useEnvironmentLocationControllerGetLocationsByTypeSuspense<TData = Awaited<ReturnType<typeof environmentLocationControllerGetLocationsByType>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    type: 'match' | 'training' | 'medical', options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentLocationControllerGetLocationsByType>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentLocationControllerGetLocationsByTypeSuspense<TData = Awaited<ReturnType<typeof environmentLocationControllerGetLocationsByType>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    type: 'match' | 'training' | 'medical', options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentLocationControllerGetLocationsByType>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentLocationControllerGetLocationsByTypeSuspense<TData = Awaited<ReturnType<typeof environmentLocationControllerGetLocationsByType>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    type: 'match' | 'training' | 'medical', options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentLocationControllerGetLocationsByType>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useEnvironmentLocationControllerGetLocationsByTypeSuspense<TData = Awaited<ReturnType<typeof environmentLocationControllerGetLocationsByType>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    type: 'match' | 'training' | 'medical', options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentLocationControllerGetLocationsByType>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getEnvironmentLocationControllerGetLocationsByTypeSuspenseQueryOptions(tenantId,environmentId,type,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const environmentLocationControllerGetLocation = (
    tenantId: string,
    environmentId: string,
    type: 'match' | 'training' | 'medical',
    environmentLocationId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EnvironmentLocationControllerGetLocation200>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/settings/location/${type}/${environmentLocationId}`,options
    );
  }


export const getEnvironmentLocationControllerGetLocationQueryKey = (tenantId: string,
    environmentId: string,
    type: 'match' | 'training' | 'medical',
    environmentLocationId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/settings/location/${type}/${environmentLocationId}`] as const;
    }

    
export const getEnvironmentLocationControllerGetLocationQueryOptions = <TData = Awaited<ReturnType<typeof environmentLocationControllerGetLocation>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    type: 'match' | 'training' | 'medical',
    environmentLocationId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentLocationControllerGetLocation>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getEnvironmentLocationControllerGetLocationQueryKey(tenantId,environmentId,type,environmentLocationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof environmentLocationControllerGetLocation>>> = ({ signal }) => environmentLocationControllerGetLocation(tenantId,environmentId,type,environmentLocationId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && type && environmentLocationId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof environmentLocationControllerGetLocation>>, TError, TData> & { queryKey: QueryKey }
}

export type EnvironmentLocationControllerGetLocationQueryResult = NonNullable<Awaited<ReturnType<typeof environmentLocationControllerGetLocation>>>
export type EnvironmentLocationControllerGetLocationQueryError = AxiosError<unknown>


export function useEnvironmentLocationControllerGetLocation<TData = Awaited<ReturnType<typeof environmentLocationControllerGetLocation>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    type: 'match' | 'training' | 'medical',
    environmentLocationId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentLocationControllerGetLocation>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof environmentLocationControllerGetLocation>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentLocationControllerGetLocation<TData = Awaited<ReturnType<typeof environmentLocationControllerGetLocation>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    type: 'match' | 'training' | 'medical',
    environmentLocationId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentLocationControllerGetLocation>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof environmentLocationControllerGetLocation>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentLocationControllerGetLocation<TData = Awaited<ReturnType<typeof environmentLocationControllerGetLocation>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    type: 'match' | 'training' | 'medical',
    environmentLocationId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentLocationControllerGetLocation>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useEnvironmentLocationControllerGetLocation<TData = Awaited<ReturnType<typeof environmentLocationControllerGetLocation>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    type: 'match' | 'training' | 'medical',
    environmentLocationId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentLocationControllerGetLocation>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getEnvironmentLocationControllerGetLocationQueryOptions(tenantId,environmentId,type,environmentLocationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getEnvironmentLocationControllerGetLocationSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof environmentLocationControllerGetLocation>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    type: 'match' | 'training' | 'medical',
    environmentLocationId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentLocationControllerGetLocation>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getEnvironmentLocationControllerGetLocationQueryKey(tenantId,environmentId,type,environmentLocationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof environmentLocationControllerGetLocation>>> = ({ signal }) => environmentLocationControllerGetLocation(tenantId,environmentId,type,environmentLocationId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && type && environmentLocationId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentLocationControllerGetLocation>>, TError, TData> & { queryKey: QueryKey }
}

export type EnvironmentLocationControllerGetLocationSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof environmentLocationControllerGetLocation>>>
export type EnvironmentLocationControllerGetLocationSuspenseQueryError = AxiosError<unknown>


export function useEnvironmentLocationControllerGetLocationSuspense<TData = Awaited<ReturnType<typeof environmentLocationControllerGetLocation>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    type: 'match' | 'training' | 'medical',
    environmentLocationId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentLocationControllerGetLocation>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentLocationControllerGetLocationSuspense<TData = Awaited<ReturnType<typeof environmentLocationControllerGetLocation>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    type: 'match' | 'training' | 'medical',
    environmentLocationId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentLocationControllerGetLocation>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentLocationControllerGetLocationSuspense<TData = Awaited<ReturnType<typeof environmentLocationControllerGetLocation>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    type: 'match' | 'training' | 'medical',
    environmentLocationId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentLocationControllerGetLocation>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useEnvironmentLocationControllerGetLocationSuspense<TData = Awaited<ReturnType<typeof environmentLocationControllerGetLocation>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    type: 'match' | 'training' | 'medical',
    environmentLocationId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentLocationControllerGetLocation>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getEnvironmentLocationControllerGetLocationSuspenseQueryOptions(tenantId,environmentId,type,environmentLocationId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const environmentLocationControllerUpdateLocation = (
    tenantId: string,
    environmentId: string,
    type: 'match' | 'training' | 'medical',
    environmentLocationId: string,
    environmentLocationControllerUpdateLocationBody: EnvironmentLocationControllerUpdateLocationBody, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EnvironmentLocationControllerUpdateLocation200>> => {
    
    return axios.put(
      `/v1/${tenantId}/${environmentId}/settings/location/${type}/${environmentLocationId}`,
      environmentLocationControllerUpdateLocationBody,options
    );
  }



export const getEnvironmentLocationControllerUpdateLocationMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentLocationControllerUpdateLocation>>, TError,{tenantId: string;environmentId: string;type: 'match' | 'training' | 'medical';environmentLocationId: string;data: EnvironmentLocationControllerUpdateLocationBody}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof environmentLocationControllerUpdateLocation>>, TError,{tenantId: string;environmentId: string;type: 'match' | 'training' | 'medical';environmentLocationId: string;data: EnvironmentLocationControllerUpdateLocationBody}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof environmentLocationControllerUpdateLocation>>, {tenantId: string;environmentId: string;type: 'match' | 'training' | 'medical';environmentLocationId: string;data: EnvironmentLocationControllerUpdateLocationBody}> = (props) => {
          const {tenantId,environmentId,type,environmentLocationId,data} = props ?? {};

          return  environmentLocationControllerUpdateLocation(tenantId,environmentId,type,environmentLocationId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type EnvironmentLocationControllerUpdateLocationMutationResult = NonNullable<Awaited<ReturnType<typeof environmentLocationControllerUpdateLocation>>>
    export type EnvironmentLocationControllerUpdateLocationMutationBody = EnvironmentLocationControllerUpdateLocationBody
    export type EnvironmentLocationControllerUpdateLocationMutationError = AxiosError<unknown>

    export const useEnvironmentLocationControllerUpdateLocation = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentLocationControllerUpdateLocation>>, TError,{tenantId: string;environmentId: string;type: 'match' | 'training' | 'medical';environmentLocationId: string;data: EnvironmentLocationControllerUpdateLocationBody}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof environmentLocationControllerUpdateLocation>>,
        TError,
        {tenantId: string;environmentId: string;type: 'match' | 'training' | 'medical';environmentLocationId: string;data: EnvironmentLocationControllerUpdateLocationBody},
        TContext
      > => {

      const mutationOptions = getEnvironmentLocationControllerUpdateLocationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const environmentLocationControllerCreateMatchEnvironmentLocation = (
    tenantId: string,
    environmentId: string,
    environmentLocationMatchCreateDto: EnvironmentLocationMatchCreateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EnvironmentLocationMatchGetDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/${environmentId}/settings/location/match`,
      environmentLocationMatchCreateDto,options
    );
  }



export const getEnvironmentLocationControllerCreateMatchEnvironmentLocationMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentLocationControllerCreateMatchEnvironmentLocation>>, TError,{tenantId: string;environmentId: string;data: EnvironmentLocationMatchCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof environmentLocationControllerCreateMatchEnvironmentLocation>>, TError,{tenantId: string;environmentId: string;data: EnvironmentLocationMatchCreateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof environmentLocationControllerCreateMatchEnvironmentLocation>>, {tenantId: string;environmentId: string;data: EnvironmentLocationMatchCreateDto}> = (props) => {
          const {tenantId,environmentId,data} = props ?? {};

          return  environmentLocationControllerCreateMatchEnvironmentLocation(tenantId,environmentId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type EnvironmentLocationControllerCreateMatchEnvironmentLocationMutationResult = NonNullable<Awaited<ReturnType<typeof environmentLocationControllerCreateMatchEnvironmentLocation>>>
    export type EnvironmentLocationControllerCreateMatchEnvironmentLocationMutationBody = EnvironmentLocationMatchCreateDto
    export type EnvironmentLocationControllerCreateMatchEnvironmentLocationMutationError = AxiosError<unknown>

    export const useEnvironmentLocationControllerCreateMatchEnvironmentLocation = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentLocationControllerCreateMatchEnvironmentLocation>>, TError,{tenantId: string;environmentId: string;data: EnvironmentLocationMatchCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof environmentLocationControllerCreateMatchEnvironmentLocation>>,
        TError,
        {tenantId: string;environmentId: string;data: EnvironmentLocationMatchCreateDto},
        TContext
      > => {

      const mutationOptions = getEnvironmentLocationControllerCreateMatchEnvironmentLocationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const environmentLocationControllerCreateTrainingEnvironmentLocation = (
    tenantId: string,
    environmentId: string,
    environmentLocationBaseCreateDto: EnvironmentLocationBaseCreateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EnvironmentLocationTrainingGetDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/${environmentId}/settings/location/training`,
      environmentLocationBaseCreateDto,options
    );
  }



export const getEnvironmentLocationControllerCreateTrainingEnvironmentLocationMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentLocationControllerCreateTrainingEnvironmentLocation>>, TError,{tenantId: string;environmentId: string;data: EnvironmentLocationBaseCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof environmentLocationControllerCreateTrainingEnvironmentLocation>>, TError,{tenantId: string;environmentId: string;data: EnvironmentLocationBaseCreateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof environmentLocationControllerCreateTrainingEnvironmentLocation>>, {tenantId: string;environmentId: string;data: EnvironmentLocationBaseCreateDto}> = (props) => {
          const {tenantId,environmentId,data} = props ?? {};

          return  environmentLocationControllerCreateTrainingEnvironmentLocation(tenantId,environmentId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type EnvironmentLocationControllerCreateTrainingEnvironmentLocationMutationResult = NonNullable<Awaited<ReturnType<typeof environmentLocationControllerCreateTrainingEnvironmentLocation>>>
    export type EnvironmentLocationControllerCreateTrainingEnvironmentLocationMutationBody = EnvironmentLocationBaseCreateDto
    export type EnvironmentLocationControllerCreateTrainingEnvironmentLocationMutationError = AxiosError<unknown>

    export const useEnvironmentLocationControllerCreateTrainingEnvironmentLocation = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentLocationControllerCreateTrainingEnvironmentLocation>>, TError,{tenantId: string;environmentId: string;data: EnvironmentLocationBaseCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof environmentLocationControllerCreateTrainingEnvironmentLocation>>,
        TError,
        {tenantId: string;environmentId: string;data: EnvironmentLocationBaseCreateDto},
        TContext
      > => {

      const mutationOptions = getEnvironmentLocationControllerCreateTrainingEnvironmentLocationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const environmentLocationControllerCreateMedicalEnvironmentLocation = (
    tenantId: string,
    environmentId: string,
    environmentLocationBaseCreateDto: EnvironmentLocationBaseCreateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EnvironmentLocationMedicalGetDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/${environmentId}/settings/location/medical`,
      environmentLocationBaseCreateDto,options
    );
  }



export const getEnvironmentLocationControllerCreateMedicalEnvironmentLocationMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentLocationControllerCreateMedicalEnvironmentLocation>>, TError,{tenantId: string;environmentId: string;data: EnvironmentLocationBaseCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof environmentLocationControllerCreateMedicalEnvironmentLocation>>, TError,{tenantId: string;environmentId: string;data: EnvironmentLocationBaseCreateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof environmentLocationControllerCreateMedicalEnvironmentLocation>>, {tenantId: string;environmentId: string;data: EnvironmentLocationBaseCreateDto}> = (props) => {
          const {tenantId,environmentId,data} = props ?? {};

          return  environmentLocationControllerCreateMedicalEnvironmentLocation(tenantId,environmentId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type EnvironmentLocationControllerCreateMedicalEnvironmentLocationMutationResult = NonNullable<Awaited<ReturnType<typeof environmentLocationControllerCreateMedicalEnvironmentLocation>>>
    export type EnvironmentLocationControllerCreateMedicalEnvironmentLocationMutationBody = EnvironmentLocationBaseCreateDto
    export type EnvironmentLocationControllerCreateMedicalEnvironmentLocationMutationError = AxiosError<unknown>

    export const useEnvironmentLocationControllerCreateMedicalEnvironmentLocation = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentLocationControllerCreateMedicalEnvironmentLocation>>, TError,{tenantId: string;environmentId: string;data: EnvironmentLocationBaseCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof environmentLocationControllerCreateMedicalEnvironmentLocation>>,
        TError,
        {tenantId: string;environmentId: string;data: EnvironmentLocationBaseCreateDto},
        TContext
      > => {

      const mutationOptions = getEnvironmentLocationControllerCreateMedicalEnvironmentLocationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const environmentMatchDayTagLogicControllerGetMatchDayTagLogic = (
    tenantId: string,
    environmentId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EnvironmentMatchDayTagLogicGetDto>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/settings/match-day-tag-logic`,options
    );
  }


export const getEnvironmentMatchDayTagLogicControllerGetMatchDayTagLogicQueryKey = (tenantId: string,
    environmentId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/settings/match-day-tag-logic`] as const;
    }

    
export const getEnvironmentMatchDayTagLogicControllerGetMatchDayTagLogicQueryOptions = <TData = Awaited<ReturnType<typeof environmentMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getEnvironmentMatchDayTagLogicControllerGetMatchDayTagLogicQueryKey(tenantId,environmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof environmentMatchDayTagLogicControllerGetMatchDayTagLogic>>> = ({ signal }) => environmentMatchDayTagLogicControllerGetMatchDayTagLogic(tenantId,environmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof environmentMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError, TData> & { queryKey: QueryKey }
}

export type EnvironmentMatchDayTagLogicControllerGetMatchDayTagLogicQueryResult = NonNullable<Awaited<ReturnType<typeof environmentMatchDayTagLogicControllerGetMatchDayTagLogic>>>
export type EnvironmentMatchDayTagLogicControllerGetMatchDayTagLogicQueryError = AxiosError<unknown>


export function useEnvironmentMatchDayTagLogicControllerGetMatchDayTagLogic<TData = Awaited<ReturnType<typeof environmentMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof environmentMatchDayTagLogicControllerGetMatchDayTagLogic>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentMatchDayTagLogicControllerGetMatchDayTagLogic<TData = Awaited<ReturnType<typeof environmentMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof environmentMatchDayTagLogicControllerGetMatchDayTagLogic>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentMatchDayTagLogicControllerGetMatchDayTagLogic<TData = Awaited<ReturnType<typeof environmentMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useEnvironmentMatchDayTagLogicControllerGetMatchDayTagLogic<TData = Awaited<ReturnType<typeof environmentMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getEnvironmentMatchDayTagLogicControllerGetMatchDayTagLogicQueryOptions(tenantId,environmentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getEnvironmentMatchDayTagLogicControllerGetMatchDayTagLogicSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof environmentMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getEnvironmentMatchDayTagLogicControllerGetMatchDayTagLogicQueryKey(tenantId,environmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof environmentMatchDayTagLogicControllerGetMatchDayTagLogic>>> = ({ signal }) => environmentMatchDayTagLogicControllerGetMatchDayTagLogic(tenantId,environmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError, TData> & { queryKey: QueryKey }
}

export type EnvironmentMatchDayTagLogicControllerGetMatchDayTagLogicSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof environmentMatchDayTagLogicControllerGetMatchDayTagLogic>>>
export type EnvironmentMatchDayTagLogicControllerGetMatchDayTagLogicSuspenseQueryError = AxiosError<unknown>


export function useEnvironmentMatchDayTagLogicControllerGetMatchDayTagLogicSuspense<TData = Awaited<ReturnType<typeof environmentMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentMatchDayTagLogicControllerGetMatchDayTagLogicSuspense<TData = Awaited<ReturnType<typeof environmentMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentMatchDayTagLogicControllerGetMatchDayTagLogicSuspense<TData = Awaited<ReturnType<typeof environmentMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useEnvironmentMatchDayTagLogicControllerGetMatchDayTagLogicSuspense<TData = Awaited<ReturnType<typeof environmentMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getEnvironmentMatchDayTagLogicControllerGetMatchDayTagLogicSuspenseQueryOptions(tenantId,environmentId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const environmentMatchDayTagLogicControllerUpdateMatchDayTagLogic = (
    tenantId: string,
    environmentId: string,
    environmentMatchDayTagLogicPutDto: EnvironmentMatchDayTagLogicPutDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EnvironmentMatchDayTagLogicGetDto>> => {
    
    return axios.put(
      `/v1/${tenantId}/${environmentId}/settings/match-day-tag-logic`,
      environmentMatchDayTagLogicPutDto,options
    );
  }



export const getEnvironmentMatchDayTagLogicControllerUpdateMatchDayTagLogicMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentMatchDayTagLogicControllerUpdateMatchDayTagLogic>>, TError,{tenantId: string;environmentId: string;data: EnvironmentMatchDayTagLogicPutDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof environmentMatchDayTagLogicControllerUpdateMatchDayTagLogic>>, TError,{tenantId: string;environmentId: string;data: EnvironmentMatchDayTagLogicPutDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof environmentMatchDayTagLogicControllerUpdateMatchDayTagLogic>>, {tenantId: string;environmentId: string;data: EnvironmentMatchDayTagLogicPutDto}> = (props) => {
          const {tenantId,environmentId,data} = props ?? {};

          return  environmentMatchDayTagLogicControllerUpdateMatchDayTagLogic(tenantId,environmentId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type EnvironmentMatchDayTagLogicControllerUpdateMatchDayTagLogicMutationResult = NonNullable<Awaited<ReturnType<typeof environmentMatchDayTagLogicControllerUpdateMatchDayTagLogic>>>
    export type EnvironmentMatchDayTagLogicControllerUpdateMatchDayTagLogicMutationBody = EnvironmentMatchDayTagLogicPutDto
    export type EnvironmentMatchDayTagLogicControllerUpdateMatchDayTagLogicMutationError = AxiosError<unknown>

    export const useEnvironmentMatchDayTagLogicControllerUpdateMatchDayTagLogic = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentMatchDayTagLogicControllerUpdateMatchDayTagLogic>>, TError,{tenantId: string;environmentId: string;data: EnvironmentMatchDayTagLogicPutDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof environmentMatchDayTagLogicControllerUpdateMatchDayTagLogic>>,
        TError,
        {tenantId: string;environmentId: string;data: EnvironmentMatchDayTagLogicPutDto},
        TContext
      > => {

      const mutationOptions = getEnvironmentMatchDayTagLogicControllerUpdateMatchDayTagLogicMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const environmentSessionTagControllerGetAllEnvironmentSessionTags = (
    tenantId: string,
    environmentId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EnvironmentSessionTagGetDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/settings/session-tag`,options
    );
  }


export const getEnvironmentSessionTagControllerGetAllEnvironmentSessionTagsQueryKey = (tenantId: string,
    environmentId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/settings/session-tag`] as const;
    }

    
export const getEnvironmentSessionTagControllerGetAllEnvironmentSessionTagsQueryOptions = <TData = Awaited<ReturnType<typeof environmentSessionTagControllerGetAllEnvironmentSessionTags>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentSessionTagControllerGetAllEnvironmentSessionTags>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getEnvironmentSessionTagControllerGetAllEnvironmentSessionTagsQueryKey(tenantId,environmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof environmentSessionTagControllerGetAllEnvironmentSessionTags>>> = ({ signal }) => environmentSessionTagControllerGetAllEnvironmentSessionTags(tenantId,environmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof environmentSessionTagControllerGetAllEnvironmentSessionTags>>, TError, TData> & { queryKey: QueryKey }
}

export type EnvironmentSessionTagControllerGetAllEnvironmentSessionTagsQueryResult = NonNullable<Awaited<ReturnType<typeof environmentSessionTagControllerGetAllEnvironmentSessionTags>>>
export type EnvironmentSessionTagControllerGetAllEnvironmentSessionTagsQueryError = AxiosError<unknown>


export function useEnvironmentSessionTagControllerGetAllEnvironmentSessionTags<TData = Awaited<ReturnType<typeof environmentSessionTagControllerGetAllEnvironmentSessionTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentSessionTagControllerGetAllEnvironmentSessionTags>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof environmentSessionTagControllerGetAllEnvironmentSessionTags>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentSessionTagControllerGetAllEnvironmentSessionTags<TData = Awaited<ReturnType<typeof environmentSessionTagControllerGetAllEnvironmentSessionTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentSessionTagControllerGetAllEnvironmentSessionTags>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof environmentSessionTagControllerGetAllEnvironmentSessionTags>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentSessionTagControllerGetAllEnvironmentSessionTags<TData = Awaited<ReturnType<typeof environmentSessionTagControllerGetAllEnvironmentSessionTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentSessionTagControllerGetAllEnvironmentSessionTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useEnvironmentSessionTagControllerGetAllEnvironmentSessionTags<TData = Awaited<ReturnType<typeof environmentSessionTagControllerGetAllEnvironmentSessionTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentSessionTagControllerGetAllEnvironmentSessionTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getEnvironmentSessionTagControllerGetAllEnvironmentSessionTagsQueryOptions(tenantId,environmentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getEnvironmentSessionTagControllerGetAllEnvironmentSessionTagsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof environmentSessionTagControllerGetAllEnvironmentSessionTags>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentSessionTagControllerGetAllEnvironmentSessionTags>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getEnvironmentSessionTagControllerGetAllEnvironmentSessionTagsQueryKey(tenantId,environmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof environmentSessionTagControllerGetAllEnvironmentSessionTags>>> = ({ signal }) => environmentSessionTagControllerGetAllEnvironmentSessionTags(tenantId,environmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentSessionTagControllerGetAllEnvironmentSessionTags>>, TError, TData> & { queryKey: QueryKey }
}

export type EnvironmentSessionTagControllerGetAllEnvironmentSessionTagsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof environmentSessionTagControllerGetAllEnvironmentSessionTags>>>
export type EnvironmentSessionTagControllerGetAllEnvironmentSessionTagsSuspenseQueryError = AxiosError<unknown>


export function useEnvironmentSessionTagControllerGetAllEnvironmentSessionTagsSuspense<TData = Awaited<ReturnType<typeof environmentSessionTagControllerGetAllEnvironmentSessionTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentSessionTagControllerGetAllEnvironmentSessionTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentSessionTagControllerGetAllEnvironmentSessionTagsSuspense<TData = Awaited<ReturnType<typeof environmentSessionTagControllerGetAllEnvironmentSessionTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentSessionTagControllerGetAllEnvironmentSessionTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentSessionTagControllerGetAllEnvironmentSessionTagsSuspense<TData = Awaited<ReturnType<typeof environmentSessionTagControllerGetAllEnvironmentSessionTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentSessionTagControllerGetAllEnvironmentSessionTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useEnvironmentSessionTagControllerGetAllEnvironmentSessionTagsSuspense<TData = Awaited<ReturnType<typeof environmentSessionTagControllerGetAllEnvironmentSessionTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentSessionTagControllerGetAllEnvironmentSessionTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getEnvironmentSessionTagControllerGetAllEnvironmentSessionTagsSuspenseQueryOptions(tenantId,environmentId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const environmentSessionTagControllerCreateSessionTag = (
    tenantId: string,
    environmentId: string,
    tenantSessionTagCreateDto: TenantSessionTagCreateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EnvironmentSessionTagGetDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/${environmentId}/settings/session-tag`,
      tenantSessionTagCreateDto,options
    );
  }



export const getEnvironmentSessionTagControllerCreateSessionTagMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentSessionTagControllerCreateSessionTag>>, TError,{tenantId: string;environmentId: string;data: TenantSessionTagCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof environmentSessionTagControllerCreateSessionTag>>, TError,{tenantId: string;environmentId: string;data: TenantSessionTagCreateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof environmentSessionTagControllerCreateSessionTag>>, {tenantId: string;environmentId: string;data: TenantSessionTagCreateDto}> = (props) => {
          const {tenantId,environmentId,data} = props ?? {};

          return  environmentSessionTagControllerCreateSessionTag(tenantId,environmentId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type EnvironmentSessionTagControllerCreateSessionTagMutationResult = NonNullable<Awaited<ReturnType<typeof environmentSessionTagControllerCreateSessionTag>>>
    export type EnvironmentSessionTagControllerCreateSessionTagMutationBody = TenantSessionTagCreateDto
    export type EnvironmentSessionTagControllerCreateSessionTagMutationError = AxiosError<unknown>

    export const useEnvironmentSessionTagControllerCreateSessionTag = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentSessionTagControllerCreateSessionTag>>, TError,{tenantId: string;environmentId: string;data: TenantSessionTagCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof environmentSessionTagControllerCreateSessionTag>>,
        TError,
        {tenantId: string;environmentId: string;data: TenantSessionTagCreateDto},
        TContext
      > => {

      const mutationOptions = getEnvironmentSessionTagControllerCreateSessionTagMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const environmentSessionTagControllerGetSessionTag = (
    tenantId: string,
    environmentId: string,
    sessionTagId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EnvironmentSessionTagGetDto>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/settings/session-tag/${sessionTagId}`,options
    );
  }


export const getEnvironmentSessionTagControllerGetSessionTagQueryKey = (tenantId: string,
    environmentId: string,
    sessionTagId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/settings/session-tag/${sessionTagId}`] as const;
    }

    
export const getEnvironmentSessionTagControllerGetSessionTagQueryOptions = <TData = Awaited<ReturnType<typeof environmentSessionTagControllerGetSessionTag>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    sessionTagId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentSessionTagControllerGetSessionTag>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getEnvironmentSessionTagControllerGetSessionTagQueryKey(tenantId,environmentId,sessionTagId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof environmentSessionTagControllerGetSessionTag>>> = ({ signal }) => environmentSessionTagControllerGetSessionTag(tenantId,environmentId,sessionTagId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && sessionTagId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof environmentSessionTagControllerGetSessionTag>>, TError, TData> & { queryKey: QueryKey }
}

export type EnvironmentSessionTagControllerGetSessionTagQueryResult = NonNullable<Awaited<ReturnType<typeof environmentSessionTagControllerGetSessionTag>>>
export type EnvironmentSessionTagControllerGetSessionTagQueryError = AxiosError<unknown>


export function useEnvironmentSessionTagControllerGetSessionTag<TData = Awaited<ReturnType<typeof environmentSessionTagControllerGetSessionTag>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionTagId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentSessionTagControllerGetSessionTag>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof environmentSessionTagControllerGetSessionTag>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentSessionTagControllerGetSessionTag<TData = Awaited<ReturnType<typeof environmentSessionTagControllerGetSessionTag>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionTagId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentSessionTagControllerGetSessionTag>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof environmentSessionTagControllerGetSessionTag>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentSessionTagControllerGetSessionTag<TData = Awaited<ReturnType<typeof environmentSessionTagControllerGetSessionTag>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionTagId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentSessionTagControllerGetSessionTag>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useEnvironmentSessionTagControllerGetSessionTag<TData = Awaited<ReturnType<typeof environmentSessionTagControllerGetSessionTag>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionTagId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentSessionTagControllerGetSessionTag>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getEnvironmentSessionTagControllerGetSessionTagQueryOptions(tenantId,environmentId,sessionTagId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getEnvironmentSessionTagControllerGetSessionTagSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof environmentSessionTagControllerGetSessionTag>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    sessionTagId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentSessionTagControllerGetSessionTag>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getEnvironmentSessionTagControllerGetSessionTagQueryKey(tenantId,environmentId,sessionTagId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof environmentSessionTagControllerGetSessionTag>>> = ({ signal }) => environmentSessionTagControllerGetSessionTag(tenantId,environmentId,sessionTagId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && sessionTagId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentSessionTagControllerGetSessionTag>>, TError, TData> & { queryKey: QueryKey }
}

export type EnvironmentSessionTagControllerGetSessionTagSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof environmentSessionTagControllerGetSessionTag>>>
export type EnvironmentSessionTagControllerGetSessionTagSuspenseQueryError = AxiosError<unknown>


export function useEnvironmentSessionTagControllerGetSessionTagSuspense<TData = Awaited<ReturnType<typeof environmentSessionTagControllerGetSessionTag>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionTagId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentSessionTagControllerGetSessionTag>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentSessionTagControllerGetSessionTagSuspense<TData = Awaited<ReturnType<typeof environmentSessionTagControllerGetSessionTag>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionTagId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentSessionTagControllerGetSessionTag>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentSessionTagControllerGetSessionTagSuspense<TData = Awaited<ReturnType<typeof environmentSessionTagControllerGetSessionTag>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionTagId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentSessionTagControllerGetSessionTag>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useEnvironmentSessionTagControllerGetSessionTagSuspense<TData = Awaited<ReturnType<typeof environmentSessionTagControllerGetSessionTag>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionTagId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentSessionTagControllerGetSessionTag>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getEnvironmentSessionTagControllerGetSessionTagSuspenseQueryOptions(tenantId,environmentId,sessionTagId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const environmentSessionTagControllerUpdateSessionTag = (
    tenantId: string,
    environmentId: string,
    sessionTagId: string,
    tenantSessionTagUpdateDto: TenantSessionTagUpdateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EnvironmentSessionTagGetDto>> => {
    
    return axios.put(
      `/v1/${tenantId}/${environmentId}/settings/session-tag/${sessionTagId}`,
      tenantSessionTagUpdateDto,options
    );
  }



export const getEnvironmentSessionTagControllerUpdateSessionTagMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentSessionTagControllerUpdateSessionTag>>, TError,{tenantId: string;environmentId: string;sessionTagId: string;data: TenantSessionTagUpdateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof environmentSessionTagControllerUpdateSessionTag>>, TError,{tenantId: string;environmentId: string;sessionTagId: string;data: TenantSessionTagUpdateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof environmentSessionTagControllerUpdateSessionTag>>, {tenantId: string;environmentId: string;sessionTagId: string;data: TenantSessionTagUpdateDto}> = (props) => {
          const {tenantId,environmentId,sessionTagId,data} = props ?? {};

          return  environmentSessionTagControllerUpdateSessionTag(tenantId,environmentId,sessionTagId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type EnvironmentSessionTagControllerUpdateSessionTagMutationResult = NonNullable<Awaited<ReturnType<typeof environmentSessionTagControllerUpdateSessionTag>>>
    export type EnvironmentSessionTagControllerUpdateSessionTagMutationBody = TenantSessionTagUpdateDto
    export type EnvironmentSessionTagControllerUpdateSessionTagMutationError = AxiosError<unknown>

    export const useEnvironmentSessionTagControllerUpdateSessionTag = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentSessionTagControllerUpdateSessionTag>>, TError,{tenantId: string;environmentId: string;sessionTagId: string;data: TenantSessionTagUpdateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof environmentSessionTagControllerUpdateSessionTag>>,
        TError,
        {tenantId: string;environmentId: string;sessionTagId: string;data: TenantSessionTagUpdateDto},
        TContext
      > => {

      const mutationOptions = getEnvironmentSessionTagControllerUpdateSessionTagMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const environmentSessionTagControllerDeleteSessionTag = (
    tenantId: string,
    environmentId: string,
    sessionTagId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/v1/${tenantId}/${environmentId}/settings/session-tag/${sessionTagId}`,options
    );
  }



export const getEnvironmentSessionTagControllerDeleteSessionTagMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentSessionTagControllerDeleteSessionTag>>, TError,{tenantId: string;environmentId: string;sessionTagId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof environmentSessionTagControllerDeleteSessionTag>>, TError,{tenantId: string;environmentId: string;sessionTagId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof environmentSessionTagControllerDeleteSessionTag>>, {tenantId: string;environmentId: string;sessionTagId: string}> = (props) => {
          const {tenantId,environmentId,sessionTagId} = props ?? {};

          return  environmentSessionTagControllerDeleteSessionTag(tenantId,environmentId,sessionTagId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type EnvironmentSessionTagControllerDeleteSessionTagMutationResult = NonNullable<Awaited<ReturnType<typeof environmentSessionTagControllerDeleteSessionTag>>>
    
    export type EnvironmentSessionTagControllerDeleteSessionTagMutationError = AxiosError<unknown>

    export const useEnvironmentSessionTagControllerDeleteSessionTag = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentSessionTagControllerDeleteSessionTag>>, TError,{tenantId: string;environmentId: string;sessionTagId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof environmentSessionTagControllerDeleteSessionTag>>,
        TError,
        {tenantId: string;environmentId: string;sessionTagId: string},
        TContext
      > => {

      const mutationOptions = getEnvironmentSessionTagControllerDeleteSessionTagMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const environmentExerciseTagControllerGetAllEnvironmentExerciseTags = (
    tenantId: string,
    environmentId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EnvironmentExerciseTagGetDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/settings/exercise-tag`,options
    );
  }


export const getEnvironmentExerciseTagControllerGetAllEnvironmentExerciseTagsQueryKey = (tenantId: string,
    environmentId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/settings/exercise-tag`] as const;
    }

    
export const getEnvironmentExerciseTagControllerGetAllEnvironmentExerciseTagsQueryOptions = <TData = Awaited<ReturnType<typeof environmentExerciseTagControllerGetAllEnvironmentExerciseTags>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentExerciseTagControllerGetAllEnvironmentExerciseTags>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getEnvironmentExerciseTagControllerGetAllEnvironmentExerciseTagsQueryKey(tenantId,environmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof environmentExerciseTagControllerGetAllEnvironmentExerciseTags>>> = ({ signal }) => environmentExerciseTagControllerGetAllEnvironmentExerciseTags(tenantId,environmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof environmentExerciseTagControllerGetAllEnvironmentExerciseTags>>, TError, TData> & { queryKey: QueryKey }
}

export type EnvironmentExerciseTagControllerGetAllEnvironmentExerciseTagsQueryResult = NonNullable<Awaited<ReturnType<typeof environmentExerciseTagControllerGetAllEnvironmentExerciseTags>>>
export type EnvironmentExerciseTagControllerGetAllEnvironmentExerciseTagsQueryError = AxiosError<unknown>


export function useEnvironmentExerciseTagControllerGetAllEnvironmentExerciseTags<TData = Awaited<ReturnType<typeof environmentExerciseTagControllerGetAllEnvironmentExerciseTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentExerciseTagControllerGetAllEnvironmentExerciseTags>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof environmentExerciseTagControllerGetAllEnvironmentExerciseTags>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentExerciseTagControllerGetAllEnvironmentExerciseTags<TData = Awaited<ReturnType<typeof environmentExerciseTagControllerGetAllEnvironmentExerciseTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentExerciseTagControllerGetAllEnvironmentExerciseTags>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof environmentExerciseTagControllerGetAllEnvironmentExerciseTags>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentExerciseTagControllerGetAllEnvironmentExerciseTags<TData = Awaited<ReturnType<typeof environmentExerciseTagControllerGetAllEnvironmentExerciseTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentExerciseTagControllerGetAllEnvironmentExerciseTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useEnvironmentExerciseTagControllerGetAllEnvironmentExerciseTags<TData = Awaited<ReturnType<typeof environmentExerciseTagControllerGetAllEnvironmentExerciseTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentExerciseTagControllerGetAllEnvironmentExerciseTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getEnvironmentExerciseTagControllerGetAllEnvironmentExerciseTagsQueryOptions(tenantId,environmentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getEnvironmentExerciseTagControllerGetAllEnvironmentExerciseTagsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof environmentExerciseTagControllerGetAllEnvironmentExerciseTags>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentExerciseTagControllerGetAllEnvironmentExerciseTags>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getEnvironmentExerciseTagControllerGetAllEnvironmentExerciseTagsQueryKey(tenantId,environmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof environmentExerciseTagControllerGetAllEnvironmentExerciseTags>>> = ({ signal }) => environmentExerciseTagControllerGetAllEnvironmentExerciseTags(tenantId,environmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentExerciseTagControllerGetAllEnvironmentExerciseTags>>, TError, TData> & { queryKey: QueryKey }
}

export type EnvironmentExerciseTagControllerGetAllEnvironmentExerciseTagsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof environmentExerciseTagControllerGetAllEnvironmentExerciseTags>>>
export type EnvironmentExerciseTagControllerGetAllEnvironmentExerciseTagsSuspenseQueryError = AxiosError<unknown>


export function useEnvironmentExerciseTagControllerGetAllEnvironmentExerciseTagsSuspense<TData = Awaited<ReturnType<typeof environmentExerciseTagControllerGetAllEnvironmentExerciseTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentExerciseTagControllerGetAllEnvironmentExerciseTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentExerciseTagControllerGetAllEnvironmentExerciseTagsSuspense<TData = Awaited<ReturnType<typeof environmentExerciseTagControllerGetAllEnvironmentExerciseTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentExerciseTagControllerGetAllEnvironmentExerciseTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentExerciseTagControllerGetAllEnvironmentExerciseTagsSuspense<TData = Awaited<ReturnType<typeof environmentExerciseTagControllerGetAllEnvironmentExerciseTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentExerciseTagControllerGetAllEnvironmentExerciseTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useEnvironmentExerciseTagControllerGetAllEnvironmentExerciseTagsSuspense<TData = Awaited<ReturnType<typeof environmentExerciseTagControllerGetAllEnvironmentExerciseTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentExerciseTagControllerGetAllEnvironmentExerciseTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getEnvironmentExerciseTagControllerGetAllEnvironmentExerciseTagsSuspenseQueryOptions(tenantId,environmentId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const environmentExerciseTagControllerCreateExerciseTag = (
    tenantId: string,
    environmentId: string,
    tenantExerciseTagCreateDto: TenantExerciseTagCreateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EnvironmentExerciseTagGetDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/${environmentId}/settings/exercise-tag`,
      tenantExerciseTagCreateDto,options
    );
  }



export const getEnvironmentExerciseTagControllerCreateExerciseTagMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentExerciseTagControllerCreateExerciseTag>>, TError,{tenantId: string;environmentId: string;data: TenantExerciseTagCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof environmentExerciseTagControllerCreateExerciseTag>>, TError,{tenantId: string;environmentId: string;data: TenantExerciseTagCreateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof environmentExerciseTagControllerCreateExerciseTag>>, {tenantId: string;environmentId: string;data: TenantExerciseTagCreateDto}> = (props) => {
          const {tenantId,environmentId,data} = props ?? {};

          return  environmentExerciseTagControllerCreateExerciseTag(tenantId,environmentId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type EnvironmentExerciseTagControllerCreateExerciseTagMutationResult = NonNullable<Awaited<ReturnType<typeof environmentExerciseTagControllerCreateExerciseTag>>>
    export type EnvironmentExerciseTagControllerCreateExerciseTagMutationBody = TenantExerciseTagCreateDto
    export type EnvironmentExerciseTagControllerCreateExerciseTagMutationError = AxiosError<unknown>

    export const useEnvironmentExerciseTagControllerCreateExerciseTag = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentExerciseTagControllerCreateExerciseTag>>, TError,{tenantId: string;environmentId: string;data: TenantExerciseTagCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof environmentExerciseTagControllerCreateExerciseTag>>,
        TError,
        {tenantId: string;environmentId: string;data: TenantExerciseTagCreateDto},
        TContext
      > => {

      const mutationOptions = getEnvironmentExerciseTagControllerCreateExerciseTagMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const environmentExerciseTagControllerGetExerciseTag = (
    tenantId: string,
    environmentId: string,
    exerciseTagId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EnvironmentExerciseTagGetDto>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/settings/exercise-tag/${exerciseTagId}`,options
    );
  }


export const getEnvironmentExerciseTagControllerGetExerciseTagQueryKey = (tenantId: string,
    environmentId: string,
    exerciseTagId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/settings/exercise-tag/${exerciseTagId}`] as const;
    }

    
export const getEnvironmentExerciseTagControllerGetExerciseTagQueryOptions = <TData = Awaited<ReturnType<typeof environmentExerciseTagControllerGetExerciseTag>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    exerciseTagId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentExerciseTagControllerGetExerciseTag>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getEnvironmentExerciseTagControllerGetExerciseTagQueryKey(tenantId,environmentId,exerciseTagId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof environmentExerciseTagControllerGetExerciseTag>>> = ({ signal }) => environmentExerciseTagControllerGetExerciseTag(tenantId,environmentId,exerciseTagId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && exerciseTagId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof environmentExerciseTagControllerGetExerciseTag>>, TError, TData> & { queryKey: QueryKey }
}

export type EnvironmentExerciseTagControllerGetExerciseTagQueryResult = NonNullable<Awaited<ReturnType<typeof environmentExerciseTagControllerGetExerciseTag>>>
export type EnvironmentExerciseTagControllerGetExerciseTagQueryError = AxiosError<unknown>


export function useEnvironmentExerciseTagControllerGetExerciseTag<TData = Awaited<ReturnType<typeof environmentExerciseTagControllerGetExerciseTag>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    exerciseTagId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentExerciseTagControllerGetExerciseTag>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof environmentExerciseTagControllerGetExerciseTag>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentExerciseTagControllerGetExerciseTag<TData = Awaited<ReturnType<typeof environmentExerciseTagControllerGetExerciseTag>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    exerciseTagId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentExerciseTagControllerGetExerciseTag>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof environmentExerciseTagControllerGetExerciseTag>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentExerciseTagControllerGetExerciseTag<TData = Awaited<ReturnType<typeof environmentExerciseTagControllerGetExerciseTag>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    exerciseTagId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentExerciseTagControllerGetExerciseTag>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useEnvironmentExerciseTagControllerGetExerciseTag<TData = Awaited<ReturnType<typeof environmentExerciseTagControllerGetExerciseTag>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    exerciseTagId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentExerciseTagControllerGetExerciseTag>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getEnvironmentExerciseTagControllerGetExerciseTagQueryOptions(tenantId,environmentId,exerciseTagId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getEnvironmentExerciseTagControllerGetExerciseTagSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof environmentExerciseTagControllerGetExerciseTag>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    exerciseTagId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentExerciseTagControllerGetExerciseTag>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getEnvironmentExerciseTagControllerGetExerciseTagQueryKey(tenantId,environmentId,exerciseTagId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof environmentExerciseTagControllerGetExerciseTag>>> = ({ signal }) => environmentExerciseTagControllerGetExerciseTag(tenantId,environmentId,exerciseTagId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && exerciseTagId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentExerciseTagControllerGetExerciseTag>>, TError, TData> & { queryKey: QueryKey }
}

export type EnvironmentExerciseTagControllerGetExerciseTagSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof environmentExerciseTagControllerGetExerciseTag>>>
export type EnvironmentExerciseTagControllerGetExerciseTagSuspenseQueryError = AxiosError<unknown>


export function useEnvironmentExerciseTagControllerGetExerciseTagSuspense<TData = Awaited<ReturnType<typeof environmentExerciseTagControllerGetExerciseTag>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    exerciseTagId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentExerciseTagControllerGetExerciseTag>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentExerciseTagControllerGetExerciseTagSuspense<TData = Awaited<ReturnType<typeof environmentExerciseTagControllerGetExerciseTag>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    exerciseTagId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentExerciseTagControllerGetExerciseTag>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentExerciseTagControllerGetExerciseTagSuspense<TData = Awaited<ReturnType<typeof environmentExerciseTagControllerGetExerciseTag>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    exerciseTagId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentExerciseTagControllerGetExerciseTag>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useEnvironmentExerciseTagControllerGetExerciseTagSuspense<TData = Awaited<ReturnType<typeof environmentExerciseTagControllerGetExerciseTag>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    exerciseTagId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentExerciseTagControllerGetExerciseTag>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getEnvironmentExerciseTagControllerGetExerciseTagSuspenseQueryOptions(tenantId,environmentId,exerciseTagId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const environmentExerciseTagControllerUpdateExerciseTag = (
    tenantId: string,
    environmentId: string,
    exerciseTagId: string,
    tenantExerciseTagUpdateDto: TenantExerciseTagUpdateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EnvironmentExerciseTagGetDto>> => {
    
    return axios.put(
      `/v1/${tenantId}/${environmentId}/settings/exercise-tag/${exerciseTagId}`,
      tenantExerciseTagUpdateDto,options
    );
  }



export const getEnvironmentExerciseTagControllerUpdateExerciseTagMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentExerciseTagControllerUpdateExerciseTag>>, TError,{tenantId: string;environmentId: string;exerciseTagId: string;data: TenantExerciseTagUpdateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof environmentExerciseTagControllerUpdateExerciseTag>>, TError,{tenantId: string;environmentId: string;exerciseTagId: string;data: TenantExerciseTagUpdateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof environmentExerciseTagControllerUpdateExerciseTag>>, {tenantId: string;environmentId: string;exerciseTagId: string;data: TenantExerciseTagUpdateDto}> = (props) => {
          const {tenantId,environmentId,exerciseTagId,data} = props ?? {};

          return  environmentExerciseTagControllerUpdateExerciseTag(tenantId,environmentId,exerciseTagId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type EnvironmentExerciseTagControllerUpdateExerciseTagMutationResult = NonNullable<Awaited<ReturnType<typeof environmentExerciseTagControllerUpdateExerciseTag>>>
    export type EnvironmentExerciseTagControllerUpdateExerciseTagMutationBody = TenantExerciseTagUpdateDto
    export type EnvironmentExerciseTagControllerUpdateExerciseTagMutationError = AxiosError<unknown>

    export const useEnvironmentExerciseTagControllerUpdateExerciseTag = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentExerciseTagControllerUpdateExerciseTag>>, TError,{tenantId: string;environmentId: string;exerciseTagId: string;data: TenantExerciseTagUpdateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof environmentExerciseTagControllerUpdateExerciseTag>>,
        TError,
        {tenantId: string;environmentId: string;exerciseTagId: string;data: TenantExerciseTagUpdateDto},
        TContext
      > => {

      const mutationOptions = getEnvironmentExerciseTagControllerUpdateExerciseTagMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const environmentExerciseTagControllerDeleteExerciseTag = (
    tenantId: string,
    environmentId: string,
    exerciseTagId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/v1/${tenantId}/${environmentId}/settings/exercise-tag/${exerciseTagId}`,options
    );
  }



export const getEnvironmentExerciseTagControllerDeleteExerciseTagMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentExerciseTagControllerDeleteExerciseTag>>, TError,{tenantId: string;environmentId: string;exerciseTagId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof environmentExerciseTagControllerDeleteExerciseTag>>, TError,{tenantId: string;environmentId: string;exerciseTagId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof environmentExerciseTagControllerDeleteExerciseTag>>, {tenantId: string;environmentId: string;exerciseTagId: string}> = (props) => {
          const {tenantId,environmentId,exerciseTagId} = props ?? {};

          return  environmentExerciseTagControllerDeleteExerciseTag(tenantId,environmentId,exerciseTagId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type EnvironmentExerciseTagControllerDeleteExerciseTagMutationResult = NonNullable<Awaited<ReturnType<typeof environmentExerciseTagControllerDeleteExerciseTag>>>
    
    export type EnvironmentExerciseTagControllerDeleteExerciseTagMutationError = AxiosError<unknown>

    export const useEnvironmentExerciseTagControllerDeleteExerciseTag = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentExerciseTagControllerDeleteExerciseTag>>, TError,{tenantId: string;environmentId: string;exerciseTagId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof environmentExerciseTagControllerDeleteExerciseTag>>,
        TError,
        {tenantId: string;environmentId: string;exerciseTagId: string},
        TContext
      > => {

      const mutationOptions = getEnvironmentExerciseTagControllerDeleteExerciseTagMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const environmentEventControllerGetEnvironmentEvents = (
    tenantId: string,
    environmentId: string,
    params: EnvironmentEventControllerGetEnvironmentEventsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EnvironmentEventControllerGetEnvironmentEvents200Item[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/event`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getEnvironmentEventControllerGetEnvironmentEventsQueryKey = (tenantId: string,
    environmentId: string,
    params: EnvironmentEventControllerGetEnvironmentEventsParams,) => {
    return [`/v1/${tenantId}/${environmentId}/event`, ...(params ? [params]: [])] as const;
    }

    
export const getEnvironmentEventControllerGetEnvironmentEventsQueryOptions = <TData = Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvents>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    params: EnvironmentEventControllerGetEnvironmentEventsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvents>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getEnvironmentEventControllerGetEnvironmentEventsQueryKey(tenantId,environmentId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvents>>> = ({ signal }) => environmentEventControllerGetEnvironmentEvents(tenantId,environmentId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvents>>, TError, TData> & { queryKey: QueryKey }
}

export type EnvironmentEventControllerGetEnvironmentEventsQueryResult = NonNullable<Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvents>>>
export type EnvironmentEventControllerGetEnvironmentEventsQueryError = AxiosError<unknown>


export function useEnvironmentEventControllerGetEnvironmentEvents<TData = Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvents>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: EnvironmentEventControllerGetEnvironmentEventsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvents>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvents>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentEventControllerGetEnvironmentEvents<TData = Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvents>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: EnvironmentEventControllerGetEnvironmentEventsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvents>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvents>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentEventControllerGetEnvironmentEvents<TData = Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvents>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: EnvironmentEventControllerGetEnvironmentEventsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvents>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useEnvironmentEventControllerGetEnvironmentEvents<TData = Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvents>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: EnvironmentEventControllerGetEnvironmentEventsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvents>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getEnvironmentEventControllerGetEnvironmentEventsQueryOptions(tenantId,environmentId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getEnvironmentEventControllerGetEnvironmentEventsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvents>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    params: EnvironmentEventControllerGetEnvironmentEventsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvents>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getEnvironmentEventControllerGetEnvironmentEventsQueryKey(tenantId,environmentId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvents>>> = ({ signal }) => environmentEventControllerGetEnvironmentEvents(tenantId,environmentId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvents>>, TError, TData> & { queryKey: QueryKey }
}

export type EnvironmentEventControllerGetEnvironmentEventsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvents>>>
export type EnvironmentEventControllerGetEnvironmentEventsSuspenseQueryError = AxiosError<unknown>


export function useEnvironmentEventControllerGetEnvironmentEventsSuspense<TData = Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvents>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: EnvironmentEventControllerGetEnvironmentEventsParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvents>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentEventControllerGetEnvironmentEventsSuspense<TData = Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvents>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: EnvironmentEventControllerGetEnvironmentEventsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvents>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentEventControllerGetEnvironmentEventsSuspense<TData = Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvents>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: EnvironmentEventControllerGetEnvironmentEventsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvents>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useEnvironmentEventControllerGetEnvironmentEventsSuspense<TData = Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvents>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: EnvironmentEventControllerGetEnvironmentEventsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvents>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getEnvironmentEventControllerGetEnvironmentEventsSuspenseQueryOptions(tenantId,environmentId,params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const environmentEventControllerGetEnvironmentEvent = (
    tenantId: string,
    environmentId: string,
    eventId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EnvironmentEventControllerGetEnvironmentEvent200>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/event/${eventId}`,options
    );
  }


export const getEnvironmentEventControllerGetEnvironmentEventQueryKey = (tenantId: string,
    environmentId: string,
    eventId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/event/${eventId}`] as const;
    }

    
export const getEnvironmentEventControllerGetEnvironmentEventQueryOptions = <TData = Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvent>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    eventId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvent>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getEnvironmentEventControllerGetEnvironmentEventQueryKey(tenantId,environmentId,eventId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvent>>> = ({ signal }) => environmentEventControllerGetEnvironmentEvent(tenantId,environmentId,eventId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && eventId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvent>>, TError, TData> & { queryKey: QueryKey }
}

export type EnvironmentEventControllerGetEnvironmentEventQueryResult = NonNullable<Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvent>>>
export type EnvironmentEventControllerGetEnvironmentEventQueryError = AxiosError<unknown>


export function useEnvironmentEventControllerGetEnvironmentEvent<TData = Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvent>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    eventId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvent>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvent>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentEventControllerGetEnvironmentEvent<TData = Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvent>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    eventId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvent>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvent>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentEventControllerGetEnvironmentEvent<TData = Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvent>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    eventId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvent>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useEnvironmentEventControllerGetEnvironmentEvent<TData = Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvent>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    eventId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvent>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getEnvironmentEventControllerGetEnvironmentEventQueryOptions(tenantId,environmentId,eventId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getEnvironmentEventControllerGetEnvironmentEventSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvent>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    eventId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvent>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getEnvironmentEventControllerGetEnvironmentEventQueryKey(tenantId,environmentId,eventId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvent>>> = ({ signal }) => environmentEventControllerGetEnvironmentEvent(tenantId,environmentId,eventId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && eventId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvent>>, TError, TData> & { queryKey: QueryKey }
}

export type EnvironmentEventControllerGetEnvironmentEventSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvent>>>
export type EnvironmentEventControllerGetEnvironmentEventSuspenseQueryError = AxiosError<unknown>


export function useEnvironmentEventControllerGetEnvironmentEventSuspense<TData = Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvent>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    eventId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvent>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentEventControllerGetEnvironmentEventSuspense<TData = Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvent>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    eventId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvent>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentEventControllerGetEnvironmentEventSuspense<TData = Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvent>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    eventId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvent>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useEnvironmentEventControllerGetEnvironmentEventSuspense<TData = Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvent>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    eventId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentEventControllerGetEnvironmentEvent>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getEnvironmentEventControllerGetEnvironmentEventSuspenseQueryOptions(tenantId,environmentId,eventId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const environmentEventControllerDeleteEnvironmentEvent = (
    tenantId: string,
    environmentId: string,
    eventId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/v1/${tenantId}/${environmentId}/event/${eventId}`,options
    );
  }



export const getEnvironmentEventControllerDeleteEnvironmentEventMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentEventControllerDeleteEnvironmentEvent>>, TError,{tenantId: string;environmentId: string;eventId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof environmentEventControllerDeleteEnvironmentEvent>>, TError,{tenantId: string;environmentId: string;eventId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof environmentEventControllerDeleteEnvironmentEvent>>, {tenantId: string;environmentId: string;eventId: string}> = (props) => {
          const {tenantId,environmentId,eventId} = props ?? {};

          return  environmentEventControllerDeleteEnvironmentEvent(tenantId,environmentId,eventId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type EnvironmentEventControllerDeleteEnvironmentEventMutationResult = NonNullable<Awaited<ReturnType<typeof environmentEventControllerDeleteEnvironmentEvent>>>
    
    export type EnvironmentEventControllerDeleteEnvironmentEventMutationError = AxiosError<unknown>

    export const useEnvironmentEventControllerDeleteEnvironmentEvent = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentEventControllerDeleteEnvironmentEvent>>, TError,{tenantId: string;environmentId: string;eventId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof environmentEventControllerDeleteEnvironmentEvent>>,
        TError,
        {tenantId: string;environmentId: string;eventId: string},
        TContext
      > => {

      const mutationOptions = getEnvironmentEventControllerDeleteEnvironmentEventMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const environmentEventControllerUpdateEnvironmentEventMatch = (
    tenantId: string,
    environmentId: string,
    eventId: string,
    environmentEventUpdateMatchDto: EnvironmentEventUpdateMatchDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EnvironmentEventMatchGetDto>> => {
    
    return axios.put(
      `/v1/${tenantId}/${environmentId}/event/match/${eventId}`,
      environmentEventUpdateMatchDto,options
    );
  }



export const getEnvironmentEventControllerUpdateEnvironmentEventMatchMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentEventControllerUpdateEnvironmentEventMatch>>, TError,{tenantId: string;environmentId: string;eventId: string;data: EnvironmentEventUpdateMatchDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof environmentEventControllerUpdateEnvironmentEventMatch>>, TError,{tenantId: string;environmentId: string;eventId: string;data: EnvironmentEventUpdateMatchDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof environmentEventControllerUpdateEnvironmentEventMatch>>, {tenantId: string;environmentId: string;eventId: string;data: EnvironmentEventUpdateMatchDto}> = (props) => {
          const {tenantId,environmentId,eventId,data} = props ?? {};

          return  environmentEventControllerUpdateEnvironmentEventMatch(tenantId,environmentId,eventId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type EnvironmentEventControllerUpdateEnvironmentEventMatchMutationResult = NonNullable<Awaited<ReturnType<typeof environmentEventControllerUpdateEnvironmentEventMatch>>>
    export type EnvironmentEventControllerUpdateEnvironmentEventMatchMutationBody = EnvironmentEventUpdateMatchDto
    export type EnvironmentEventControllerUpdateEnvironmentEventMatchMutationError = AxiosError<unknown>

    export const useEnvironmentEventControllerUpdateEnvironmentEventMatch = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentEventControllerUpdateEnvironmentEventMatch>>, TError,{tenantId: string;environmentId: string;eventId: string;data: EnvironmentEventUpdateMatchDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof environmentEventControllerUpdateEnvironmentEventMatch>>,
        TError,
        {tenantId: string;environmentId: string;eventId: string;data: EnvironmentEventUpdateMatchDto},
        TContext
      > => {

      const mutationOptions = getEnvironmentEventControllerUpdateEnvironmentEventMatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const environmentEventControllerUpdateEnvironmentEventTraining = (
    tenantId: string,
    environmentId: string,
    eventId: string,
    environmentEventUpdateTrainingDto: EnvironmentEventUpdateTrainingDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EnvironmentEventTrainingGetDto>> => {
    
    return axios.put(
      `/v1/${tenantId}/${environmentId}/event/training/${eventId}`,
      environmentEventUpdateTrainingDto,options
    );
  }



export const getEnvironmentEventControllerUpdateEnvironmentEventTrainingMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentEventControllerUpdateEnvironmentEventTraining>>, TError,{tenantId: string;environmentId: string;eventId: string;data: EnvironmentEventUpdateTrainingDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof environmentEventControllerUpdateEnvironmentEventTraining>>, TError,{tenantId: string;environmentId: string;eventId: string;data: EnvironmentEventUpdateTrainingDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof environmentEventControllerUpdateEnvironmentEventTraining>>, {tenantId: string;environmentId: string;eventId: string;data: EnvironmentEventUpdateTrainingDto}> = (props) => {
          const {tenantId,environmentId,eventId,data} = props ?? {};

          return  environmentEventControllerUpdateEnvironmentEventTraining(tenantId,environmentId,eventId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type EnvironmentEventControllerUpdateEnvironmentEventTrainingMutationResult = NonNullable<Awaited<ReturnType<typeof environmentEventControllerUpdateEnvironmentEventTraining>>>
    export type EnvironmentEventControllerUpdateEnvironmentEventTrainingMutationBody = EnvironmentEventUpdateTrainingDto
    export type EnvironmentEventControllerUpdateEnvironmentEventTrainingMutationError = AxiosError<unknown>

    export const useEnvironmentEventControllerUpdateEnvironmentEventTraining = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentEventControllerUpdateEnvironmentEventTraining>>, TError,{tenantId: string;environmentId: string;eventId: string;data: EnvironmentEventUpdateTrainingDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof environmentEventControllerUpdateEnvironmentEventTraining>>,
        TError,
        {tenantId: string;environmentId: string;eventId: string;data: EnvironmentEventUpdateTrainingDto},
        TContext
      > => {

      const mutationOptions = getEnvironmentEventControllerUpdateEnvironmentEventTrainingMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const environmentEventControllerUpdateEnvironmentEventMedical = (
    tenantId: string,
    environmentId: string,
    eventId: string,
    environmentEventUpdateMedicalDto: EnvironmentEventUpdateMedicalDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EnvironmentEventMedicalGetDto>> => {
    
    return axios.put(
      `/v1/${tenantId}/${environmentId}/event/medical/${eventId}`,
      environmentEventUpdateMedicalDto,options
    );
  }



export const getEnvironmentEventControllerUpdateEnvironmentEventMedicalMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentEventControllerUpdateEnvironmentEventMedical>>, TError,{tenantId: string;environmentId: string;eventId: string;data: EnvironmentEventUpdateMedicalDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof environmentEventControllerUpdateEnvironmentEventMedical>>, TError,{tenantId: string;environmentId: string;eventId: string;data: EnvironmentEventUpdateMedicalDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof environmentEventControllerUpdateEnvironmentEventMedical>>, {tenantId: string;environmentId: string;eventId: string;data: EnvironmentEventUpdateMedicalDto}> = (props) => {
          const {tenantId,environmentId,eventId,data} = props ?? {};

          return  environmentEventControllerUpdateEnvironmentEventMedical(tenantId,environmentId,eventId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type EnvironmentEventControllerUpdateEnvironmentEventMedicalMutationResult = NonNullable<Awaited<ReturnType<typeof environmentEventControllerUpdateEnvironmentEventMedical>>>
    export type EnvironmentEventControllerUpdateEnvironmentEventMedicalMutationBody = EnvironmentEventUpdateMedicalDto
    export type EnvironmentEventControllerUpdateEnvironmentEventMedicalMutationError = AxiosError<unknown>

    export const useEnvironmentEventControllerUpdateEnvironmentEventMedical = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentEventControllerUpdateEnvironmentEventMedical>>, TError,{tenantId: string;environmentId: string;eventId: string;data: EnvironmentEventUpdateMedicalDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof environmentEventControllerUpdateEnvironmentEventMedical>>,
        TError,
        {tenantId: string;environmentId: string;eventId: string;data: EnvironmentEventUpdateMedicalDto},
        TContext
      > => {

      const mutationOptions = getEnvironmentEventControllerUpdateEnvironmentEventMedicalMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const environmentEventControllerUpdateEnvironmentEventSocial = (
    tenantId: string,
    environmentId: string,
    eventId: string,
    environmentEventUpdateSocialDto: EnvironmentEventUpdateSocialDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EnvironmentEventSocialGetDto>> => {
    
    return axios.put(
      `/v1/${tenantId}/${environmentId}/event/social/${eventId}`,
      environmentEventUpdateSocialDto,options
    );
  }



export const getEnvironmentEventControllerUpdateEnvironmentEventSocialMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentEventControllerUpdateEnvironmentEventSocial>>, TError,{tenantId: string;environmentId: string;eventId: string;data: EnvironmentEventUpdateSocialDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof environmentEventControllerUpdateEnvironmentEventSocial>>, TError,{tenantId: string;environmentId: string;eventId: string;data: EnvironmentEventUpdateSocialDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof environmentEventControllerUpdateEnvironmentEventSocial>>, {tenantId: string;environmentId: string;eventId: string;data: EnvironmentEventUpdateSocialDto}> = (props) => {
          const {tenantId,environmentId,eventId,data} = props ?? {};

          return  environmentEventControllerUpdateEnvironmentEventSocial(tenantId,environmentId,eventId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type EnvironmentEventControllerUpdateEnvironmentEventSocialMutationResult = NonNullable<Awaited<ReturnType<typeof environmentEventControllerUpdateEnvironmentEventSocial>>>
    export type EnvironmentEventControllerUpdateEnvironmentEventSocialMutationBody = EnvironmentEventUpdateSocialDto
    export type EnvironmentEventControllerUpdateEnvironmentEventSocialMutationError = AxiosError<unknown>

    export const useEnvironmentEventControllerUpdateEnvironmentEventSocial = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentEventControllerUpdateEnvironmentEventSocial>>, TError,{tenantId: string;environmentId: string;eventId: string;data: EnvironmentEventUpdateSocialDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof environmentEventControllerUpdateEnvironmentEventSocial>>,
        TError,
        {tenantId: string;environmentId: string;eventId: string;data: EnvironmentEventUpdateSocialDto},
        TContext
      > => {

      const mutationOptions = getEnvironmentEventControllerUpdateEnvironmentEventSocialMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const environmentEventControllerCreateEnvironmentEventMatch = (
    tenantId: string,
    environmentId: string,
    environmentEventCreateMatchDto: EnvironmentEventCreateMatchDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EnvironmentEventMatchGetDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/${environmentId}/event/match`,
      environmentEventCreateMatchDto,options
    );
  }



export const getEnvironmentEventControllerCreateEnvironmentEventMatchMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentEventControllerCreateEnvironmentEventMatch>>, TError,{tenantId: string;environmentId: string;data: EnvironmentEventCreateMatchDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof environmentEventControllerCreateEnvironmentEventMatch>>, TError,{tenantId: string;environmentId: string;data: EnvironmentEventCreateMatchDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof environmentEventControllerCreateEnvironmentEventMatch>>, {tenantId: string;environmentId: string;data: EnvironmentEventCreateMatchDto}> = (props) => {
          const {tenantId,environmentId,data} = props ?? {};

          return  environmentEventControllerCreateEnvironmentEventMatch(tenantId,environmentId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type EnvironmentEventControllerCreateEnvironmentEventMatchMutationResult = NonNullable<Awaited<ReturnType<typeof environmentEventControllerCreateEnvironmentEventMatch>>>
    export type EnvironmentEventControllerCreateEnvironmentEventMatchMutationBody = EnvironmentEventCreateMatchDto
    export type EnvironmentEventControllerCreateEnvironmentEventMatchMutationError = AxiosError<unknown>

    export const useEnvironmentEventControllerCreateEnvironmentEventMatch = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentEventControllerCreateEnvironmentEventMatch>>, TError,{tenantId: string;environmentId: string;data: EnvironmentEventCreateMatchDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof environmentEventControllerCreateEnvironmentEventMatch>>,
        TError,
        {tenantId: string;environmentId: string;data: EnvironmentEventCreateMatchDto},
        TContext
      > => {

      const mutationOptions = getEnvironmentEventControllerCreateEnvironmentEventMatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const environmentEventControllerCreateEnvironmentEventTraining = (
    tenantId: string,
    environmentId: string,
    environmentEventCreateTrainingDto: EnvironmentEventCreateTrainingDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EnvironmentEventTrainingGetDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/${environmentId}/event/training`,
      environmentEventCreateTrainingDto,options
    );
  }



export const getEnvironmentEventControllerCreateEnvironmentEventTrainingMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentEventControllerCreateEnvironmentEventTraining>>, TError,{tenantId: string;environmentId: string;data: EnvironmentEventCreateTrainingDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof environmentEventControllerCreateEnvironmentEventTraining>>, TError,{tenantId: string;environmentId: string;data: EnvironmentEventCreateTrainingDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof environmentEventControllerCreateEnvironmentEventTraining>>, {tenantId: string;environmentId: string;data: EnvironmentEventCreateTrainingDto}> = (props) => {
          const {tenantId,environmentId,data} = props ?? {};

          return  environmentEventControllerCreateEnvironmentEventTraining(tenantId,environmentId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type EnvironmentEventControllerCreateEnvironmentEventTrainingMutationResult = NonNullable<Awaited<ReturnType<typeof environmentEventControllerCreateEnvironmentEventTraining>>>
    export type EnvironmentEventControllerCreateEnvironmentEventTrainingMutationBody = EnvironmentEventCreateTrainingDto
    export type EnvironmentEventControllerCreateEnvironmentEventTrainingMutationError = AxiosError<unknown>

    export const useEnvironmentEventControllerCreateEnvironmentEventTraining = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentEventControllerCreateEnvironmentEventTraining>>, TError,{tenantId: string;environmentId: string;data: EnvironmentEventCreateTrainingDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof environmentEventControllerCreateEnvironmentEventTraining>>,
        TError,
        {tenantId: string;environmentId: string;data: EnvironmentEventCreateTrainingDto},
        TContext
      > => {

      const mutationOptions = getEnvironmentEventControllerCreateEnvironmentEventTrainingMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const environmentEventControllerCreateEnvironmentEventMedical = (
    tenantId: string,
    environmentId: string,
    environmentEventCreateMedicalDto: EnvironmentEventCreateMedicalDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EnvironmentEventMedicalGetDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/${environmentId}/event/medical`,
      environmentEventCreateMedicalDto,options
    );
  }



export const getEnvironmentEventControllerCreateEnvironmentEventMedicalMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentEventControllerCreateEnvironmentEventMedical>>, TError,{tenantId: string;environmentId: string;data: EnvironmentEventCreateMedicalDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof environmentEventControllerCreateEnvironmentEventMedical>>, TError,{tenantId: string;environmentId: string;data: EnvironmentEventCreateMedicalDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof environmentEventControllerCreateEnvironmentEventMedical>>, {tenantId: string;environmentId: string;data: EnvironmentEventCreateMedicalDto}> = (props) => {
          const {tenantId,environmentId,data} = props ?? {};

          return  environmentEventControllerCreateEnvironmentEventMedical(tenantId,environmentId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type EnvironmentEventControllerCreateEnvironmentEventMedicalMutationResult = NonNullable<Awaited<ReturnType<typeof environmentEventControllerCreateEnvironmentEventMedical>>>
    export type EnvironmentEventControllerCreateEnvironmentEventMedicalMutationBody = EnvironmentEventCreateMedicalDto
    export type EnvironmentEventControllerCreateEnvironmentEventMedicalMutationError = AxiosError<unknown>

    export const useEnvironmentEventControllerCreateEnvironmentEventMedical = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentEventControllerCreateEnvironmentEventMedical>>, TError,{tenantId: string;environmentId: string;data: EnvironmentEventCreateMedicalDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof environmentEventControllerCreateEnvironmentEventMedical>>,
        TError,
        {tenantId: string;environmentId: string;data: EnvironmentEventCreateMedicalDto},
        TContext
      > => {

      const mutationOptions = getEnvironmentEventControllerCreateEnvironmentEventMedicalMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const environmentEventControllerCreateEnvironmentEventSocial = (
    tenantId: string,
    environmentId: string,
    environmentEventCreateSocialDto: EnvironmentEventCreateSocialDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EnvironmentEventSocialGetDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/${environmentId}/event/social`,
      environmentEventCreateSocialDto,options
    );
  }



export const getEnvironmentEventControllerCreateEnvironmentEventSocialMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentEventControllerCreateEnvironmentEventSocial>>, TError,{tenantId: string;environmentId: string;data: EnvironmentEventCreateSocialDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof environmentEventControllerCreateEnvironmentEventSocial>>, TError,{tenantId: string;environmentId: string;data: EnvironmentEventCreateSocialDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof environmentEventControllerCreateEnvironmentEventSocial>>, {tenantId: string;environmentId: string;data: EnvironmentEventCreateSocialDto}> = (props) => {
          const {tenantId,environmentId,data} = props ?? {};

          return  environmentEventControllerCreateEnvironmentEventSocial(tenantId,environmentId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type EnvironmentEventControllerCreateEnvironmentEventSocialMutationResult = NonNullable<Awaited<ReturnType<typeof environmentEventControllerCreateEnvironmentEventSocial>>>
    export type EnvironmentEventControllerCreateEnvironmentEventSocialMutationBody = EnvironmentEventCreateSocialDto
    export type EnvironmentEventControllerCreateEnvironmentEventSocialMutationError = AxiosError<unknown>

    export const useEnvironmentEventControllerCreateEnvironmentEventSocial = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentEventControllerCreateEnvironmentEventSocial>>, TError,{tenantId: string;environmentId: string;data: EnvironmentEventCreateSocialDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof environmentEventControllerCreateEnvironmentEventSocial>>,
        TError,
        {tenantId: string;environmentId: string;data: EnvironmentEventCreateSocialDto},
        TContext
      > => {

      const mutationOptions = getEnvironmentEventControllerCreateEnvironmentEventSocialMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const environmentEventControllerGetSessionsForEvent = (
    tenantId: string,
    environmentId: string,
    eventId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SessionDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/event/${eventId}/sessions`,options
    );
  }


export const getEnvironmentEventControllerGetSessionsForEventQueryKey = (tenantId: string,
    environmentId: string,
    eventId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/event/${eventId}/sessions`] as const;
    }

    
export const getEnvironmentEventControllerGetSessionsForEventQueryOptions = <TData = Awaited<ReturnType<typeof environmentEventControllerGetSessionsForEvent>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    eventId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentEventControllerGetSessionsForEvent>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getEnvironmentEventControllerGetSessionsForEventQueryKey(tenantId,environmentId,eventId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof environmentEventControllerGetSessionsForEvent>>> = ({ signal }) => environmentEventControllerGetSessionsForEvent(tenantId,environmentId,eventId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && eventId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof environmentEventControllerGetSessionsForEvent>>, TError, TData> & { queryKey: QueryKey }
}

export type EnvironmentEventControllerGetSessionsForEventQueryResult = NonNullable<Awaited<ReturnType<typeof environmentEventControllerGetSessionsForEvent>>>
export type EnvironmentEventControllerGetSessionsForEventQueryError = AxiosError<unknown>


export function useEnvironmentEventControllerGetSessionsForEvent<TData = Awaited<ReturnType<typeof environmentEventControllerGetSessionsForEvent>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    eventId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentEventControllerGetSessionsForEvent>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof environmentEventControllerGetSessionsForEvent>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentEventControllerGetSessionsForEvent<TData = Awaited<ReturnType<typeof environmentEventControllerGetSessionsForEvent>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    eventId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentEventControllerGetSessionsForEvent>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof environmentEventControllerGetSessionsForEvent>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentEventControllerGetSessionsForEvent<TData = Awaited<ReturnType<typeof environmentEventControllerGetSessionsForEvent>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    eventId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentEventControllerGetSessionsForEvent>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useEnvironmentEventControllerGetSessionsForEvent<TData = Awaited<ReturnType<typeof environmentEventControllerGetSessionsForEvent>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    eventId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentEventControllerGetSessionsForEvent>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getEnvironmentEventControllerGetSessionsForEventQueryOptions(tenantId,environmentId,eventId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getEnvironmentEventControllerGetSessionsForEventSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof environmentEventControllerGetSessionsForEvent>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    eventId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentEventControllerGetSessionsForEvent>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getEnvironmentEventControllerGetSessionsForEventQueryKey(tenantId,environmentId,eventId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof environmentEventControllerGetSessionsForEvent>>> = ({ signal }) => environmentEventControllerGetSessionsForEvent(tenantId,environmentId,eventId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && eventId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentEventControllerGetSessionsForEvent>>, TError, TData> & { queryKey: QueryKey }
}

export type EnvironmentEventControllerGetSessionsForEventSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof environmentEventControllerGetSessionsForEvent>>>
export type EnvironmentEventControllerGetSessionsForEventSuspenseQueryError = AxiosError<unknown>


export function useEnvironmentEventControllerGetSessionsForEventSuspense<TData = Awaited<ReturnType<typeof environmentEventControllerGetSessionsForEvent>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    eventId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentEventControllerGetSessionsForEvent>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentEventControllerGetSessionsForEventSuspense<TData = Awaited<ReturnType<typeof environmentEventControllerGetSessionsForEvent>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    eventId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentEventControllerGetSessionsForEvent>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentEventControllerGetSessionsForEventSuspense<TData = Awaited<ReturnType<typeof environmentEventControllerGetSessionsForEvent>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    eventId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentEventControllerGetSessionsForEvent>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useEnvironmentEventControllerGetSessionsForEventSuspense<TData = Awaited<ReturnType<typeof environmentEventControllerGetSessionsForEvent>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    eventId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentEventControllerGetSessionsForEvent>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getEnvironmentEventControllerGetSessionsForEventSuspenseQueryOptions(tenantId,environmentId,eventId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const sessionControllerGetSessions = (
    tenantId: string,
    environmentId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SessionDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/session`,options
    );
  }


export const getSessionControllerGetSessionsQueryKey = (tenantId: string,
    environmentId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/session`] as const;
    }

    
export const getSessionControllerGetSessionsQueryOptions = <TData = Awaited<ReturnType<typeof sessionControllerGetSessions>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSessions>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSessionControllerGetSessionsQueryKey(tenantId,environmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionControllerGetSessions>>> = ({ signal }) => sessionControllerGetSessions(tenantId,environmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSessions>>, TError, TData> & { queryKey: QueryKey }
}

export type SessionControllerGetSessionsQueryResult = NonNullable<Awaited<ReturnType<typeof sessionControllerGetSessions>>>
export type SessionControllerGetSessionsQueryError = AxiosError<unknown>


export function useSessionControllerGetSessions<TData = Awaited<ReturnType<typeof sessionControllerGetSessions>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSessions>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof sessionControllerGetSessions>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSessionControllerGetSessions<TData = Awaited<ReturnType<typeof sessionControllerGetSessions>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSessions>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof sessionControllerGetSessions>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSessionControllerGetSessions<TData = Awaited<ReturnType<typeof sessionControllerGetSessions>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSessions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useSessionControllerGetSessions<TData = Awaited<ReturnType<typeof sessionControllerGetSessions>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSessions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getSessionControllerGetSessionsQueryOptions(tenantId,environmentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getSessionControllerGetSessionsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof sessionControllerGetSessions>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSessions>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSessionControllerGetSessionsQueryKey(tenantId,environmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionControllerGetSessions>>> = ({ signal }) => sessionControllerGetSessions(tenantId,environmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSessions>>, TError, TData> & { queryKey: QueryKey }
}

export type SessionControllerGetSessionsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof sessionControllerGetSessions>>>
export type SessionControllerGetSessionsSuspenseQueryError = AxiosError<unknown>


export function useSessionControllerGetSessionsSuspense<TData = Awaited<ReturnType<typeof sessionControllerGetSessions>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSessions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSessionControllerGetSessionsSuspense<TData = Awaited<ReturnType<typeof sessionControllerGetSessions>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSessions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSessionControllerGetSessionsSuspense<TData = Awaited<ReturnType<typeof sessionControllerGetSessions>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSessions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useSessionControllerGetSessionsSuspense<TData = Awaited<ReturnType<typeof sessionControllerGetSessions>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSessions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getSessionControllerGetSessionsSuspenseQueryOptions(tenantId,environmentId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const sessionControllerCreateSession = (
    tenantId: string,
    environmentId: string,
    sessionCreateUpdateDetailsDto: SessionCreateUpdateDetailsDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SessionDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/${environmentId}/session`,
      sessionCreateUpdateDetailsDto,options
    );
  }



export const getSessionControllerCreateSessionMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerCreateSession>>, TError,{tenantId: string;environmentId: string;data: SessionCreateUpdateDetailsDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof sessionControllerCreateSession>>, TError,{tenantId: string;environmentId: string;data: SessionCreateUpdateDetailsDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerCreateSession>>, {tenantId: string;environmentId: string;data: SessionCreateUpdateDetailsDto}> = (props) => {
          const {tenantId,environmentId,data} = props ?? {};

          return  sessionControllerCreateSession(tenantId,environmentId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SessionControllerCreateSessionMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerCreateSession>>>
    export type SessionControllerCreateSessionMutationBody = SessionCreateUpdateDetailsDto
    export type SessionControllerCreateSessionMutationError = AxiosError<unknown>

    export const useSessionControllerCreateSession = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerCreateSession>>, TError,{tenantId: string;environmentId: string;data: SessionCreateUpdateDetailsDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof sessionControllerCreateSession>>,
        TError,
        {tenantId: string;environmentId: string;data: SessionCreateUpdateDetailsDto},
        TContext
      > => {

      const mutationOptions = getSessionControllerCreateSessionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const sessionControllerGetSession = (
    tenantId: string,
    environmentId: string,
    sessionId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SessionDto>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/session/${sessionId}`,options
    );
  }


export const getSessionControllerGetSessionQueryKey = (tenantId: string,
    environmentId: string,
    sessionId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/session/${sessionId}`] as const;
    }

    
export const getSessionControllerGetSessionQueryOptions = <TData = Awaited<ReturnType<typeof sessionControllerGetSession>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSession>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSessionControllerGetSessionQueryKey(tenantId,environmentId,sessionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionControllerGetSession>>> = ({ signal }) => sessionControllerGetSession(tenantId,environmentId,sessionId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && sessionId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSession>>, TError, TData> & { queryKey: QueryKey }
}

export type SessionControllerGetSessionQueryResult = NonNullable<Awaited<ReturnType<typeof sessionControllerGetSession>>>
export type SessionControllerGetSessionQueryError = AxiosError<unknown>


export function useSessionControllerGetSession<TData = Awaited<ReturnType<typeof sessionControllerGetSession>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSession>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof sessionControllerGetSession>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSessionControllerGetSession<TData = Awaited<ReturnType<typeof sessionControllerGetSession>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSession>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof sessionControllerGetSession>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSessionControllerGetSession<TData = Awaited<ReturnType<typeof sessionControllerGetSession>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSession>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useSessionControllerGetSession<TData = Awaited<ReturnType<typeof sessionControllerGetSession>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSession>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getSessionControllerGetSessionQueryOptions(tenantId,environmentId,sessionId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getSessionControllerGetSessionSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof sessionControllerGetSession>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSession>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSessionControllerGetSessionQueryKey(tenantId,environmentId,sessionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionControllerGetSession>>> = ({ signal }) => sessionControllerGetSession(tenantId,environmentId,sessionId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && sessionId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSession>>, TError, TData> & { queryKey: QueryKey }
}

export type SessionControllerGetSessionSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof sessionControllerGetSession>>>
export type SessionControllerGetSessionSuspenseQueryError = AxiosError<unknown>


export function useSessionControllerGetSessionSuspense<TData = Awaited<ReturnType<typeof sessionControllerGetSession>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSession>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSessionControllerGetSessionSuspense<TData = Awaited<ReturnType<typeof sessionControllerGetSession>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSession>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSessionControllerGetSessionSuspense<TData = Awaited<ReturnType<typeof sessionControllerGetSession>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSession>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useSessionControllerGetSessionSuspense<TData = Awaited<ReturnType<typeof sessionControllerGetSession>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSession>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getSessionControllerGetSessionSuspenseQueryOptions(tenantId,environmentId,sessionId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const sessionControllerUpdateSession = (
    tenantId: string,
    environmentId: string,
    sessionId: string,
    sessionUpdateDto: SessionUpdateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SessionDto>> => {
    
    return axios.put(
      `/v1/${tenantId}/${environmentId}/session/${sessionId}`,
      sessionUpdateDto,options
    );
  }



export const getSessionControllerUpdateSessionMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerUpdateSession>>, TError,{tenantId: string;environmentId: string;sessionId: string;data: SessionUpdateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof sessionControllerUpdateSession>>, TError,{tenantId: string;environmentId: string;sessionId: string;data: SessionUpdateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerUpdateSession>>, {tenantId: string;environmentId: string;sessionId: string;data: SessionUpdateDto}> = (props) => {
          const {tenantId,environmentId,sessionId,data} = props ?? {};

          return  sessionControllerUpdateSession(tenantId,environmentId,sessionId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SessionControllerUpdateSessionMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerUpdateSession>>>
    export type SessionControllerUpdateSessionMutationBody = SessionUpdateDto
    export type SessionControllerUpdateSessionMutationError = AxiosError<unknown>

    export const useSessionControllerUpdateSession = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerUpdateSession>>, TError,{tenantId: string;environmentId: string;sessionId: string;data: SessionUpdateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof sessionControllerUpdateSession>>,
        TError,
        {tenantId: string;environmentId: string;sessionId: string;data: SessionUpdateDto},
        TContext
      > => {

      const mutationOptions = getSessionControllerUpdateSessionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const sessionControllerDeleteSession = (
    tenantId: string,
    environmentId: string,
    sessionId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/v1/${tenantId}/${environmentId}/session/${sessionId}`,options
    );
  }



export const getSessionControllerDeleteSessionMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerDeleteSession>>, TError,{tenantId: string;environmentId: string;sessionId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof sessionControllerDeleteSession>>, TError,{tenantId: string;environmentId: string;sessionId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerDeleteSession>>, {tenantId: string;environmentId: string;sessionId: string}> = (props) => {
          const {tenantId,environmentId,sessionId} = props ?? {};

          return  sessionControllerDeleteSession(tenantId,environmentId,sessionId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SessionControllerDeleteSessionMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerDeleteSession>>>
    
    export type SessionControllerDeleteSessionMutationError = AxiosError<unknown>

    export const useSessionControllerDeleteSession = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerDeleteSession>>, TError,{tenantId: string;environmentId: string;sessionId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof sessionControllerDeleteSession>>,
        TError,
        {tenantId: string;environmentId: string;sessionId: string},
        TContext
      > => {

      const mutationOptions = getSessionControllerDeleteSessionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const sessionControllerUpdateSessionDetails = (
    tenantId: string,
    environmentId: string,
    sessionId: string,
    sessionCreateUpdateDetailsDto: SessionCreateUpdateDetailsDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SessionDto>> => {
    
    return axios.put(
      `/v1/${tenantId}/${environmentId}/session/${sessionId}/details`,
      sessionCreateUpdateDetailsDto,options
    );
  }



export const getSessionControllerUpdateSessionDetailsMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerUpdateSessionDetails>>, TError,{tenantId: string;environmentId: string;sessionId: string;data: SessionCreateUpdateDetailsDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof sessionControllerUpdateSessionDetails>>, TError,{tenantId: string;environmentId: string;sessionId: string;data: SessionCreateUpdateDetailsDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerUpdateSessionDetails>>, {tenantId: string;environmentId: string;sessionId: string;data: SessionCreateUpdateDetailsDto}> = (props) => {
          const {tenantId,environmentId,sessionId,data} = props ?? {};

          return  sessionControllerUpdateSessionDetails(tenantId,environmentId,sessionId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SessionControllerUpdateSessionDetailsMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerUpdateSessionDetails>>>
    export type SessionControllerUpdateSessionDetailsMutationBody = SessionCreateUpdateDetailsDto
    export type SessionControllerUpdateSessionDetailsMutationError = AxiosError<unknown>

    export const useSessionControllerUpdateSessionDetails = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerUpdateSessionDetails>>, TError,{tenantId: string;environmentId: string;sessionId: string;data: SessionCreateUpdateDetailsDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof sessionControllerUpdateSessionDetails>>,
        TError,
        {tenantId: string;environmentId: string;sessionId: string;data: SessionCreateUpdateDetailsDto},
        TContext
      > => {

      const mutationOptions = getSessionControllerUpdateSessionDetailsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const sessionControllerGetSessionTags = (
    tenantId: string,
    environmentId: string,
    sessionId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SessionTagGetDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/session/${sessionId}/tags`,options
    );
  }


export const getSessionControllerGetSessionTagsQueryKey = (tenantId: string,
    environmentId: string,
    sessionId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/session/${sessionId}/tags`] as const;
    }

    
export const getSessionControllerGetSessionTagsQueryOptions = <TData = Awaited<ReturnType<typeof sessionControllerGetSessionTags>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSessionTags>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSessionControllerGetSessionTagsQueryKey(tenantId,environmentId,sessionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionControllerGetSessionTags>>> = ({ signal }) => sessionControllerGetSessionTags(tenantId,environmentId,sessionId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && sessionId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSessionTags>>, TError, TData> & { queryKey: QueryKey }
}

export type SessionControllerGetSessionTagsQueryResult = NonNullable<Awaited<ReturnType<typeof sessionControllerGetSessionTags>>>
export type SessionControllerGetSessionTagsQueryError = AxiosError<unknown>


export function useSessionControllerGetSessionTags<TData = Awaited<ReturnType<typeof sessionControllerGetSessionTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSessionTags>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof sessionControllerGetSessionTags>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSessionControllerGetSessionTags<TData = Awaited<ReturnType<typeof sessionControllerGetSessionTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSessionTags>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof sessionControllerGetSessionTags>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSessionControllerGetSessionTags<TData = Awaited<ReturnType<typeof sessionControllerGetSessionTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSessionTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useSessionControllerGetSessionTags<TData = Awaited<ReturnType<typeof sessionControllerGetSessionTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSessionTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getSessionControllerGetSessionTagsQueryOptions(tenantId,environmentId,sessionId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getSessionControllerGetSessionTagsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof sessionControllerGetSessionTags>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSessionTags>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSessionControllerGetSessionTagsQueryKey(tenantId,environmentId,sessionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionControllerGetSessionTags>>> = ({ signal }) => sessionControllerGetSessionTags(tenantId,environmentId,sessionId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && sessionId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSessionTags>>, TError, TData> & { queryKey: QueryKey }
}

export type SessionControllerGetSessionTagsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof sessionControllerGetSessionTags>>>
export type SessionControllerGetSessionTagsSuspenseQueryError = AxiosError<unknown>


export function useSessionControllerGetSessionTagsSuspense<TData = Awaited<ReturnType<typeof sessionControllerGetSessionTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSessionTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSessionControllerGetSessionTagsSuspense<TData = Awaited<ReturnType<typeof sessionControllerGetSessionTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSessionTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSessionControllerGetSessionTagsSuspense<TData = Awaited<ReturnType<typeof sessionControllerGetSessionTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSessionTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useSessionControllerGetSessionTagsSuspense<TData = Awaited<ReturnType<typeof sessionControllerGetSessionTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSessionTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getSessionControllerGetSessionTagsSuspenseQueryOptions(tenantId,environmentId,sessionId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const sessionControllerAttachmentUploadRequest = (
    tenantId: string,
    environmentId: string,
    sessionId: string,
    sessionAttachmentPostDto: SessionAttachmentPostDto[], options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SessionAttachmentPostReturnValueDto[]>> => {
    
    return axios.post(
      `/v1/${tenantId}/${environmentId}/session/${sessionId}/attachments/uploadrequest`,
      sessionAttachmentPostDto,options
    );
  }



export const getSessionControllerAttachmentUploadRequestMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerAttachmentUploadRequest>>, TError,{tenantId: string;environmentId: string;sessionId: string;data: SessionAttachmentPostDto[]}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof sessionControllerAttachmentUploadRequest>>, TError,{tenantId: string;environmentId: string;sessionId: string;data: SessionAttachmentPostDto[]}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerAttachmentUploadRequest>>, {tenantId: string;environmentId: string;sessionId: string;data: SessionAttachmentPostDto[]}> = (props) => {
          const {tenantId,environmentId,sessionId,data} = props ?? {};

          return  sessionControllerAttachmentUploadRequest(tenantId,environmentId,sessionId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SessionControllerAttachmentUploadRequestMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerAttachmentUploadRequest>>>
    export type SessionControllerAttachmentUploadRequestMutationBody = SessionAttachmentPostDto[]
    export type SessionControllerAttachmentUploadRequestMutationError = AxiosError<unknown>

    export const useSessionControllerAttachmentUploadRequest = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerAttachmentUploadRequest>>, TError,{tenantId: string;environmentId: string;sessionId: string;data: SessionAttachmentPostDto[]}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof sessionControllerAttachmentUploadRequest>>,
        TError,
        {tenantId: string;environmentId: string;sessionId: string;data: SessionAttachmentPostDto[]},
        TContext
      > => {

      const mutationOptions = getSessionControllerAttachmentUploadRequestMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const sessionControllerGetAttachments = (
    tenantId: string,
    environmentId: string,
    sessionId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SessionAttachmentGetDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/session/${sessionId}/attachments`,options
    );
  }


export const getSessionControllerGetAttachmentsQueryKey = (tenantId: string,
    environmentId: string,
    sessionId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/session/${sessionId}/attachments`] as const;
    }

    
export const getSessionControllerGetAttachmentsQueryOptions = <TData = Awaited<ReturnType<typeof sessionControllerGetAttachments>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetAttachments>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSessionControllerGetAttachmentsQueryKey(tenantId,environmentId,sessionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionControllerGetAttachments>>> = ({ signal }) => sessionControllerGetAttachments(tenantId,environmentId,sessionId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && sessionId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetAttachments>>, TError, TData> & { queryKey: QueryKey }
}

export type SessionControllerGetAttachmentsQueryResult = NonNullable<Awaited<ReturnType<typeof sessionControllerGetAttachments>>>
export type SessionControllerGetAttachmentsQueryError = AxiosError<unknown>


export function useSessionControllerGetAttachments<TData = Awaited<ReturnType<typeof sessionControllerGetAttachments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetAttachments>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof sessionControllerGetAttachments>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSessionControllerGetAttachments<TData = Awaited<ReturnType<typeof sessionControllerGetAttachments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetAttachments>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof sessionControllerGetAttachments>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSessionControllerGetAttachments<TData = Awaited<ReturnType<typeof sessionControllerGetAttachments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetAttachments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useSessionControllerGetAttachments<TData = Awaited<ReturnType<typeof sessionControllerGetAttachments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetAttachments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getSessionControllerGetAttachmentsQueryOptions(tenantId,environmentId,sessionId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getSessionControllerGetAttachmentsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof sessionControllerGetAttachments>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetAttachments>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSessionControllerGetAttachmentsQueryKey(tenantId,environmentId,sessionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionControllerGetAttachments>>> = ({ signal }) => sessionControllerGetAttachments(tenantId,environmentId,sessionId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && sessionId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetAttachments>>, TError, TData> & { queryKey: QueryKey }
}

export type SessionControllerGetAttachmentsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof sessionControllerGetAttachments>>>
export type SessionControllerGetAttachmentsSuspenseQueryError = AxiosError<unknown>


export function useSessionControllerGetAttachmentsSuspense<TData = Awaited<ReturnType<typeof sessionControllerGetAttachments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetAttachments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSessionControllerGetAttachmentsSuspense<TData = Awaited<ReturnType<typeof sessionControllerGetAttachments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetAttachments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSessionControllerGetAttachmentsSuspense<TData = Awaited<ReturnType<typeof sessionControllerGetAttachments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetAttachments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useSessionControllerGetAttachmentsSuspense<TData = Awaited<ReturnType<typeof sessionControllerGetAttachments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetAttachments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getSessionControllerGetAttachmentsSuspenseQueryOptions(tenantId,environmentId,sessionId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const sessionControllerGetDownloadUrl = (
    tenantId: string,
    environmentId: string,
    sessionId: string,
    attachmentId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SessionAttachmentDownloadUrlDto>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/session/${sessionId}/attachments/${attachmentId}/downloadrequest`,options
    );
  }


export const getSessionControllerGetDownloadUrlQueryKey = (tenantId: string,
    environmentId: string,
    sessionId: string,
    attachmentId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/session/${sessionId}/attachments/${attachmentId}/downloadrequest`] as const;
    }

    
export const getSessionControllerGetDownloadUrlQueryOptions = <TData = Awaited<ReturnType<typeof sessionControllerGetDownloadUrl>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    sessionId: string,
    attachmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetDownloadUrl>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSessionControllerGetDownloadUrlQueryKey(tenantId,environmentId,sessionId,attachmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionControllerGetDownloadUrl>>> = ({ signal }) => sessionControllerGetDownloadUrl(tenantId,environmentId,sessionId,attachmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && sessionId && attachmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetDownloadUrl>>, TError, TData> & { queryKey: QueryKey }
}

export type SessionControllerGetDownloadUrlQueryResult = NonNullable<Awaited<ReturnType<typeof sessionControllerGetDownloadUrl>>>
export type SessionControllerGetDownloadUrlQueryError = AxiosError<unknown>


export function useSessionControllerGetDownloadUrl<TData = Awaited<ReturnType<typeof sessionControllerGetDownloadUrl>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    attachmentId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetDownloadUrl>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof sessionControllerGetDownloadUrl>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSessionControllerGetDownloadUrl<TData = Awaited<ReturnType<typeof sessionControllerGetDownloadUrl>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    attachmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetDownloadUrl>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof sessionControllerGetDownloadUrl>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSessionControllerGetDownloadUrl<TData = Awaited<ReturnType<typeof sessionControllerGetDownloadUrl>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    attachmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetDownloadUrl>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useSessionControllerGetDownloadUrl<TData = Awaited<ReturnType<typeof sessionControllerGetDownloadUrl>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    attachmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetDownloadUrl>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getSessionControllerGetDownloadUrlQueryOptions(tenantId,environmentId,sessionId,attachmentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getSessionControllerGetDownloadUrlSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof sessionControllerGetDownloadUrl>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    sessionId: string,
    attachmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetDownloadUrl>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSessionControllerGetDownloadUrlQueryKey(tenantId,environmentId,sessionId,attachmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionControllerGetDownloadUrl>>> = ({ signal }) => sessionControllerGetDownloadUrl(tenantId,environmentId,sessionId,attachmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && sessionId && attachmentId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetDownloadUrl>>, TError, TData> & { queryKey: QueryKey }
}

export type SessionControllerGetDownloadUrlSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof sessionControllerGetDownloadUrl>>>
export type SessionControllerGetDownloadUrlSuspenseQueryError = AxiosError<unknown>


export function useSessionControllerGetDownloadUrlSuspense<TData = Awaited<ReturnType<typeof sessionControllerGetDownloadUrl>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    attachmentId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetDownloadUrl>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSessionControllerGetDownloadUrlSuspense<TData = Awaited<ReturnType<typeof sessionControllerGetDownloadUrl>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    attachmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetDownloadUrl>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSessionControllerGetDownloadUrlSuspense<TData = Awaited<ReturnType<typeof sessionControllerGetDownloadUrl>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    attachmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetDownloadUrl>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useSessionControllerGetDownloadUrlSuspense<TData = Awaited<ReturnType<typeof sessionControllerGetDownloadUrl>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    attachmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetDownloadUrl>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getSessionControllerGetDownloadUrlSuspenseQueryOptions(tenantId,environmentId,sessionId,attachmentId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const sessionControllerDeleteAttachment = (
    tenantId: string,
    environmentId: string,
    sessionId: string,
    attachmentId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/v1/${tenantId}/${environmentId}/session/${sessionId}/attachments/${attachmentId}`,options
    );
  }



export const getSessionControllerDeleteAttachmentMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerDeleteAttachment>>, TError,{tenantId: string;environmentId: string;sessionId: string;attachmentId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof sessionControllerDeleteAttachment>>, TError,{tenantId: string;environmentId: string;sessionId: string;attachmentId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerDeleteAttachment>>, {tenantId: string;environmentId: string;sessionId: string;attachmentId: string}> = (props) => {
          const {tenantId,environmentId,sessionId,attachmentId} = props ?? {};

          return  sessionControllerDeleteAttachment(tenantId,environmentId,sessionId,attachmentId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SessionControllerDeleteAttachmentMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerDeleteAttachment>>>
    
    export type SessionControllerDeleteAttachmentMutationError = AxiosError<unknown>

    export const useSessionControllerDeleteAttachment = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerDeleteAttachment>>, TError,{tenantId: string;environmentId: string;sessionId: string;attachmentId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof sessionControllerDeleteAttachment>>,
        TError,
        {tenantId: string;environmentId: string;sessionId: string;attachmentId: string},
        TContext
      > => {

      const mutationOptions = getSessionControllerDeleteAttachmentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const sessionControllerGetPlayers = (
    tenantId: string,
    environmentId: string,
    sessionId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SessionPlayerGetDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/session/${sessionId}/player`,options
    );
  }


export const getSessionControllerGetPlayersQueryKey = (tenantId: string,
    environmentId: string,
    sessionId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/session/${sessionId}/player`] as const;
    }

    
export const getSessionControllerGetPlayersQueryOptions = <TData = Awaited<ReturnType<typeof sessionControllerGetPlayers>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSessionControllerGetPlayersQueryKey(tenantId,environmentId,sessionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionControllerGetPlayers>>> = ({ signal }) => sessionControllerGetPlayers(tenantId,environmentId,sessionId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && sessionId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetPlayers>>, TError, TData> & { queryKey: QueryKey }
}

export type SessionControllerGetPlayersQueryResult = NonNullable<Awaited<ReturnType<typeof sessionControllerGetPlayers>>>
export type SessionControllerGetPlayersQueryError = AxiosError<unknown>


export function useSessionControllerGetPlayers<TData = Awaited<ReturnType<typeof sessionControllerGetPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetPlayers>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof sessionControllerGetPlayers>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSessionControllerGetPlayers<TData = Awaited<ReturnType<typeof sessionControllerGetPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetPlayers>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof sessionControllerGetPlayers>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSessionControllerGetPlayers<TData = Awaited<ReturnType<typeof sessionControllerGetPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useSessionControllerGetPlayers<TData = Awaited<ReturnType<typeof sessionControllerGetPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getSessionControllerGetPlayersQueryOptions(tenantId,environmentId,sessionId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getSessionControllerGetPlayersSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof sessionControllerGetPlayers>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSessionControllerGetPlayersQueryKey(tenantId,environmentId,sessionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionControllerGetPlayers>>> = ({ signal }) => sessionControllerGetPlayers(tenantId,environmentId,sessionId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && sessionId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetPlayers>>, TError, TData> & { queryKey: QueryKey }
}

export type SessionControllerGetPlayersSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof sessionControllerGetPlayers>>>
export type SessionControllerGetPlayersSuspenseQueryError = AxiosError<unknown>


export function useSessionControllerGetPlayersSuspense<TData = Awaited<ReturnType<typeof sessionControllerGetPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSessionControllerGetPlayersSuspense<TData = Awaited<ReturnType<typeof sessionControllerGetPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSessionControllerGetPlayersSuspense<TData = Awaited<ReturnType<typeof sessionControllerGetPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useSessionControllerGetPlayersSuspense<TData = Awaited<ReturnType<typeof sessionControllerGetPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getSessionControllerGetPlayersSuspenseQueryOptions(tenantId,environmentId,sessionId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const sessionControllerCreateSessionByTemplate = (
    tenantId: string,
    environmentId: string,
    sessionByTemplateCreateDto: SessionByTemplateCreateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SessionDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/${environmentId}/session/byTemplate`,
      sessionByTemplateCreateDto,options
    );
  }



export const getSessionControllerCreateSessionByTemplateMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerCreateSessionByTemplate>>, TError,{tenantId: string;environmentId: string;data: SessionByTemplateCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof sessionControllerCreateSessionByTemplate>>, TError,{tenantId: string;environmentId: string;data: SessionByTemplateCreateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerCreateSessionByTemplate>>, {tenantId: string;environmentId: string;data: SessionByTemplateCreateDto}> = (props) => {
          const {tenantId,environmentId,data} = props ?? {};

          return  sessionControllerCreateSessionByTemplate(tenantId,environmentId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SessionControllerCreateSessionByTemplateMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerCreateSessionByTemplate>>>
    export type SessionControllerCreateSessionByTemplateMutationBody = SessionByTemplateCreateDto
    export type SessionControllerCreateSessionByTemplateMutationError = AxiosError<unknown>

    export const useSessionControllerCreateSessionByTemplate = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerCreateSessionByTemplate>>, TError,{tenantId: string;environmentId: string;data: SessionByTemplateCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof sessionControllerCreateSessionByTemplate>>,
        TError,
        {tenantId: string;environmentId: string;data: SessionByTemplateCreateDto},
        TContext
      > => {

      const mutationOptions = getSessionControllerCreateSessionByTemplateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const templateSessionControllerGetTemplateSessions = (
    tenantId: string,
    environmentId: string,
    params?: TemplateSessionControllerGetTemplateSessionsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TemplateSessionGetDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/template-session`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getTemplateSessionControllerGetTemplateSessionsQueryKey = (tenantId: string,
    environmentId: string,
    params?: TemplateSessionControllerGetTemplateSessionsParams,) => {
    return [`/v1/${tenantId}/${environmentId}/template-session`, ...(params ? [params]: [])] as const;
    }

    
export const getTemplateSessionControllerGetTemplateSessionsQueryOptions = <TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessions>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    params?: TemplateSessionControllerGetTemplateSessionsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessions>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTemplateSessionControllerGetTemplateSessionsQueryKey(tenantId,environmentId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessions>>> = ({ signal }) => templateSessionControllerGetTemplateSessions(tenantId,environmentId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessions>>, TError, TData> & { queryKey: QueryKey }
}

export type TemplateSessionControllerGetTemplateSessionsQueryResult = NonNullable<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessions>>>
export type TemplateSessionControllerGetTemplateSessionsQueryError = AxiosError<unknown>


export function useTemplateSessionControllerGetTemplateSessions<TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessions>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: undefined |  TemplateSessionControllerGetTemplateSessionsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessions>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessions>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateSessionControllerGetTemplateSessions<TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessions>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params?: TemplateSessionControllerGetTemplateSessionsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessions>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessions>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateSessionControllerGetTemplateSessions<TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessions>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params?: TemplateSessionControllerGetTemplateSessionsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTemplateSessionControllerGetTemplateSessions<TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessions>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params?: TemplateSessionControllerGetTemplateSessionsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTemplateSessionControllerGetTemplateSessionsQueryOptions(tenantId,environmentId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTemplateSessionControllerGetTemplateSessionsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessions>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    params?: TemplateSessionControllerGetTemplateSessionsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessions>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTemplateSessionControllerGetTemplateSessionsQueryKey(tenantId,environmentId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessions>>> = ({ signal }) => templateSessionControllerGetTemplateSessions(tenantId,environmentId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessions>>, TError, TData> & { queryKey: QueryKey }
}

export type TemplateSessionControllerGetTemplateSessionsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessions>>>
export type TemplateSessionControllerGetTemplateSessionsSuspenseQueryError = AxiosError<unknown>


export function useTemplateSessionControllerGetTemplateSessionsSuspense<TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessions>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: undefined |  TemplateSessionControllerGetTemplateSessionsParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateSessionControllerGetTemplateSessionsSuspense<TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessions>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params?: TemplateSessionControllerGetTemplateSessionsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateSessionControllerGetTemplateSessionsSuspense<TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessions>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params?: TemplateSessionControllerGetTemplateSessionsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTemplateSessionControllerGetTemplateSessionsSuspense<TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessions>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params?: TemplateSessionControllerGetTemplateSessionsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTemplateSessionControllerGetTemplateSessionsSuspenseQueryOptions(tenantId,environmentId,params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const templateSessionControllerCreateTemplateSession = (
    tenantId: string,
    environmentId: string,
    templateSessionCreateUpdateDto: TemplateSessionCreateUpdateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TemplateSessionCreateUpdateResponseDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/${environmentId}/template-session`,
      templateSessionCreateUpdateDto,options
    );
  }



export const getTemplateSessionControllerCreateTemplateSessionMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof templateSessionControllerCreateTemplateSession>>, TError,{tenantId: string;environmentId: string;data: TemplateSessionCreateUpdateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof templateSessionControllerCreateTemplateSession>>, TError,{tenantId: string;environmentId: string;data: TemplateSessionCreateUpdateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof templateSessionControllerCreateTemplateSession>>, {tenantId: string;environmentId: string;data: TemplateSessionCreateUpdateDto}> = (props) => {
          const {tenantId,environmentId,data} = props ?? {};

          return  templateSessionControllerCreateTemplateSession(tenantId,environmentId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TemplateSessionControllerCreateTemplateSessionMutationResult = NonNullable<Awaited<ReturnType<typeof templateSessionControllerCreateTemplateSession>>>
    export type TemplateSessionControllerCreateTemplateSessionMutationBody = TemplateSessionCreateUpdateDto
    export type TemplateSessionControllerCreateTemplateSessionMutationError = AxiosError<unknown>

    export const useTemplateSessionControllerCreateTemplateSession = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof templateSessionControllerCreateTemplateSession>>, TError,{tenantId: string;environmentId: string;data: TemplateSessionCreateUpdateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof templateSessionControllerCreateTemplateSession>>,
        TError,
        {tenantId: string;environmentId: string;data: TemplateSessionCreateUpdateDto},
        TContext
      > => {

      const mutationOptions = getTemplateSessionControllerCreateTemplateSessionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const templateSessionControllerGetTemplateSession = (
    tenantId: string,
    environmentId: string,
    templateSessionId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TemplateSessionGetDto>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/template-session/${templateSessionId}`,options
    );
  }


export const getTemplateSessionControllerGetTemplateSessionQueryKey = (tenantId: string,
    environmentId: string,
    templateSessionId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/template-session/${templateSessionId}`] as const;
    }

    
export const getTemplateSessionControllerGetTemplateSessionQueryOptions = <TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSession>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    templateSessionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSession>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTemplateSessionControllerGetTemplateSessionQueryKey(tenantId,environmentId,templateSessionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSession>>> = ({ signal }) => templateSessionControllerGetTemplateSession(tenantId,environmentId,templateSessionId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && templateSessionId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSession>>, TError, TData> & { queryKey: QueryKey }
}

export type TemplateSessionControllerGetTemplateSessionQueryResult = NonNullable<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSession>>>
export type TemplateSessionControllerGetTemplateSessionQueryError = AxiosError<unknown>


export function useTemplateSessionControllerGetTemplateSession<TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSession>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateSessionId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSession>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof templateSessionControllerGetTemplateSession>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateSessionControllerGetTemplateSession<TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSession>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateSessionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSession>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof templateSessionControllerGetTemplateSession>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateSessionControllerGetTemplateSession<TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSession>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateSessionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSession>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTemplateSessionControllerGetTemplateSession<TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSession>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateSessionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSession>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTemplateSessionControllerGetTemplateSessionQueryOptions(tenantId,environmentId,templateSessionId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTemplateSessionControllerGetTemplateSessionSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSession>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    templateSessionId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSession>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTemplateSessionControllerGetTemplateSessionQueryKey(tenantId,environmentId,templateSessionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSession>>> = ({ signal }) => templateSessionControllerGetTemplateSession(tenantId,environmentId,templateSessionId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && templateSessionId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSession>>, TError, TData> & { queryKey: QueryKey }
}

export type TemplateSessionControllerGetTemplateSessionSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSession>>>
export type TemplateSessionControllerGetTemplateSessionSuspenseQueryError = AxiosError<unknown>


export function useTemplateSessionControllerGetTemplateSessionSuspense<TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSession>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateSessionId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSession>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateSessionControllerGetTemplateSessionSuspense<TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSession>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateSessionId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSession>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateSessionControllerGetTemplateSessionSuspense<TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSession>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateSessionId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSession>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTemplateSessionControllerGetTemplateSessionSuspense<TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSession>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateSessionId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSession>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTemplateSessionControllerGetTemplateSessionSuspenseQueryOptions(tenantId,environmentId,templateSessionId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const templateSessionControllerUpdateTemplateSession = (
    tenantId: string,
    environmentId: string,
    templateSessionId: string,
    templateSessionCreateUpdateDto: TemplateSessionCreateUpdateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TemplateSessionCreateUpdateResponseDto>> => {
    
    return axios.put(
      `/v1/${tenantId}/${environmentId}/template-session/${templateSessionId}`,
      templateSessionCreateUpdateDto,options
    );
  }



export const getTemplateSessionControllerUpdateTemplateSessionMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof templateSessionControllerUpdateTemplateSession>>, TError,{tenantId: string;environmentId: string;templateSessionId: string;data: TemplateSessionCreateUpdateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof templateSessionControllerUpdateTemplateSession>>, TError,{tenantId: string;environmentId: string;templateSessionId: string;data: TemplateSessionCreateUpdateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof templateSessionControllerUpdateTemplateSession>>, {tenantId: string;environmentId: string;templateSessionId: string;data: TemplateSessionCreateUpdateDto}> = (props) => {
          const {tenantId,environmentId,templateSessionId,data} = props ?? {};

          return  templateSessionControllerUpdateTemplateSession(tenantId,environmentId,templateSessionId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TemplateSessionControllerUpdateTemplateSessionMutationResult = NonNullable<Awaited<ReturnType<typeof templateSessionControllerUpdateTemplateSession>>>
    export type TemplateSessionControllerUpdateTemplateSessionMutationBody = TemplateSessionCreateUpdateDto
    export type TemplateSessionControllerUpdateTemplateSessionMutationError = AxiosError<unknown>

    export const useTemplateSessionControllerUpdateTemplateSession = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof templateSessionControllerUpdateTemplateSession>>, TError,{tenantId: string;environmentId: string;templateSessionId: string;data: TemplateSessionCreateUpdateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof templateSessionControllerUpdateTemplateSession>>,
        TError,
        {tenantId: string;environmentId: string;templateSessionId: string;data: TemplateSessionCreateUpdateDto},
        TContext
      > => {

      const mutationOptions = getTemplateSessionControllerUpdateTemplateSessionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const templateSessionControllerDeleteTemplateSession = (
    tenantId: string,
    environmentId: string,
    templateSessionId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/v1/${tenantId}/${environmentId}/template-session/${templateSessionId}`,options
    );
  }



export const getTemplateSessionControllerDeleteTemplateSessionMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof templateSessionControllerDeleteTemplateSession>>, TError,{tenantId: string;environmentId: string;templateSessionId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof templateSessionControllerDeleteTemplateSession>>, TError,{tenantId: string;environmentId: string;templateSessionId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof templateSessionControllerDeleteTemplateSession>>, {tenantId: string;environmentId: string;templateSessionId: string}> = (props) => {
          const {tenantId,environmentId,templateSessionId} = props ?? {};

          return  templateSessionControllerDeleteTemplateSession(tenantId,environmentId,templateSessionId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TemplateSessionControllerDeleteTemplateSessionMutationResult = NonNullable<Awaited<ReturnType<typeof templateSessionControllerDeleteTemplateSession>>>
    
    export type TemplateSessionControllerDeleteTemplateSessionMutationError = AxiosError<unknown>

    export const useTemplateSessionControllerDeleteTemplateSession = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof templateSessionControllerDeleteTemplateSession>>, TError,{tenantId: string;environmentId: string;templateSessionId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof templateSessionControllerDeleteTemplateSession>>,
        TError,
        {tenantId: string;environmentId: string;templateSessionId: string},
        TContext
      > => {

      const mutationOptions = getTemplateSessionControllerDeleteTemplateSessionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const templateSessionControllerGetTemplateSessionExercises = (
    tenantId: string,
    environmentId: string,
    templateSessionId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TemplateSessionExerciseGetDto[][]>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/template-session/${templateSessionId}/exercises`,options
    );
  }


export const getTemplateSessionControllerGetTemplateSessionExercisesQueryKey = (tenantId: string,
    environmentId: string,
    templateSessionId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/template-session/${templateSessionId}/exercises`] as const;
    }

    
export const getTemplateSessionControllerGetTemplateSessionExercisesQueryOptions = <TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionExercises>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    templateSessionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionExercises>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTemplateSessionControllerGetTemplateSessionExercisesQueryKey(tenantId,environmentId,templateSessionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionExercises>>> = ({ signal }) => templateSessionControllerGetTemplateSessionExercises(tenantId,environmentId,templateSessionId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && templateSessionId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionExercises>>, TError, TData> & { queryKey: QueryKey }
}

export type TemplateSessionControllerGetTemplateSessionExercisesQueryResult = NonNullable<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionExercises>>>
export type TemplateSessionControllerGetTemplateSessionExercisesQueryError = AxiosError<unknown>


export function useTemplateSessionControllerGetTemplateSessionExercises<TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionExercises>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateSessionId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionExercises>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionExercises>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateSessionControllerGetTemplateSessionExercises<TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionExercises>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateSessionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionExercises>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionExercises>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateSessionControllerGetTemplateSessionExercises<TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionExercises>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateSessionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionExercises>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTemplateSessionControllerGetTemplateSessionExercises<TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionExercises>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateSessionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionExercises>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTemplateSessionControllerGetTemplateSessionExercisesQueryOptions(tenantId,environmentId,templateSessionId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTemplateSessionControllerGetTemplateSessionExercisesSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionExercises>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    templateSessionId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionExercises>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTemplateSessionControllerGetTemplateSessionExercisesQueryKey(tenantId,environmentId,templateSessionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionExercises>>> = ({ signal }) => templateSessionControllerGetTemplateSessionExercises(tenantId,environmentId,templateSessionId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && templateSessionId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionExercises>>, TError, TData> & { queryKey: QueryKey }
}

export type TemplateSessionControllerGetTemplateSessionExercisesSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionExercises>>>
export type TemplateSessionControllerGetTemplateSessionExercisesSuspenseQueryError = AxiosError<unknown>


export function useTemplateSessionControllerGetTemplateSessionExercisesSuspense<TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionExercises>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateSessionId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionExercises>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateSessionControllerGetTemplateSessionExercisesSuspense<TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionExercises>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateSessionId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionExercises>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateSessionControllerGetTemplateSessionExercisesSuspense<TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionExercises>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateSessionId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionExercises>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTemplateSessionControllerGetTemplateSessionExercisesSuspense<TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionExercises>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateSessionId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionExercises>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTemplateSessionControllerGetTemplateSessionExercisesSuspenseQueryOptions(tenantId,environmentId,templateSessionId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const templateSessionControllerGetTemplateSessionTags = (
    tenantId: string,
    environmentId: string,
    templateSessionId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TemplateSessionTagGetDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/template-session/${templateSessionId}/tags`,options
    );
  }


export const getTemplateSessionControllerGetTemplateSessionTagsQueryKey = (tenantId: string,
    environmentId: string,
    templateSessionId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/template-session/${templateSessionId}/tags`] as const;
    }

    
export const getTemplateSessionControllerGetTemplateSessionTagsQueryOptions = <TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionTags>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    templateSessionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionTags>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTemplateSessionControllerGetTemplateSessionTagsQueryKey(tenantId,environmentId,templateSessionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionTags>>> = ({ signal }) => templateSessionControllerGetTemplateSessionTags(tenantId,environmentId,templateSessionId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && templateSessionId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionTags>>, TError, TData> & { queryKey: QueryKey }
}

export type TemplateSessionControllerGetTemplateSessionTagsQueryResult = NonNullable<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionTags>>>
export type TemplateSessionControllerGetTemplateSessionTagsQueryError = AxiosError<unknown>


export function useTemplateSessionControllerGetTemplateSessionTags<TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateSessionId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionTags>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionTags>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateSessionControllerGetTemplateSessionTags<TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateSessionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionTags>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionTags>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateSessionControllerGetTemplateSessionTags<TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateSessionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTemplateSessionControllerGetTemplateSessionTags<TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateSessionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTemplateSessionControllerGetTemplateSessionTagsQueryOptions(tenantId,environmentId,templateSessionId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTemplateSessionControllerGetTemplateSessionTagsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionTags>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    templateSessionId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionTags>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTemplateSessionControllerGetTemplateSessionTagsQueryKey(tenantId,environmentId,templateSessionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionTags>>> = ({ signal }) => templateSessionControllerGetTemplateSessionTags(tenantId,environmentId,templateSessionId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && templateSessionId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionTags>>, TError, TData> & { queryKey: QueryKey }
}

export type TemplateSessionControllerGetTemplateSessionTagsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionTags>>>
export type TemplateSessionControllerGetTemplateSessionTagsSuspenseQueryError = AxiosError<unknown>


export function useTemplateSessionControllerGetTemplateSessionTagsSuspense<TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateSessionId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateSessionControllerGetTemplateSessionTagsSuspense<TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateSessionId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateSessionControllerGetTemplateSessionTagsSuspense<TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateSessionId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTemplateSessionControllerGetTemplateSessionTagsSuspense<TData = Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateSessionId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateSessionControllerGetTemplateSessionTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTemplateSessionControllerGetTemplateSessionTagsSuspenseQueryOptions(tenantId,environmentId,templateSessionId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const templateSessionFileControllerGetDocuments = (
    tenantId: string,
    environmentId: string,
    templateSessionId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TemplateSessionFileGetDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/template-session/${templateSessionId}/file`,options
    );
  }


export const getTemplateSessionFileControllerGetDocumentsQueryKey = (tenantId: string,
    environmentId: string,
    templateSessionId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/template-session/${templateSessionId}/file`] as const;
    }

    
export const getTemplateSessionFileControllerGetDocumentsQueryOptions = <TData = Awaited<ReturnType<typeof templateSessionFileControllerGetDocuments>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    templateSessionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateSessionFileControllerGetDocuments>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTemplateSessionFileControllerGetDocumentsQueryKey(tenantId,environmentId,templateSessionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof templateSessionFileControllerGetDocuments>>> = ({ signal }) => templateSessionFileControllerGetDocuments(tenantId,environmentId,templateSessionId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && templateSessionId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof templateSessionFileControllerGetDocuments>>, TError, TData> & { queryKey: QueryKey }
}

export type TemplateSessionFileControllerGetDocumentsQueryResult = NonNullable<Awaited<ReturnType<typeof templateSessionFileControllerGetDocuments>>>
export type TemplateSessionFileControllerGetDocumentsQueryError = AxiosError<unknown>


export function useTemplateSessionFileControllerGetDocuments<TData = Awaited<ReturnType<typeof templateSessionFileControllerGetDocuments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateSessionId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateSessionFileControllerGetDocuments>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof templateSessionFileControllerGetDocuments>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateSessionFileControllerGetDocuments<TData = Awaited<ReturnType<typeof templateSessionFileControllerGetDocuments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateSessionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateSessionFileControllerGetDocuments>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof templateSessionFileControllerGetDocuments>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateSessionFileControllerGetDocuments<TData = Awaited<ReturnType<typeof templateSessionFileControllerGetDocuments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateSessionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateSessionFileControllerGetDocuments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTemplateSessionFileControllerGetDocuments<TData = Awaited<ReturnType<typeof templateSessionFileControllerGetDocuments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateSessionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateSessionFileControllerGetDocuments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTemplateSessionFileControllerGetDocumentsQueryOptions(tenantId,environmentId,templateSessionId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTemplateSessionFileControllerGetDocumentsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof templateSessionFileControllerGetDocuments>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    templateSessionId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateSessionFileControllerGetDocuments>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTemplateSessionFileControllerGetDocumentsQueryKey(tenantId,environmentId,templateSessionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof templateSessionFileControllerGetDocuments>>> = ({ signal }) => templateSessionFileControllerGetDocuments(tenantId,environmentId,templateSessionId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && templateSessionId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateSessionFileControllerGetDocuments>>, TError, TData> & { queryKey: QueryKey }
}

export type TemplateSessionFileControllerGetDocumentsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof templateSessionFileControllerGetDocuments>>>
export type TemplateSessionFileControllerGetDocumentsSuspenseQueryError = AxiosError<unknown>


export function useTemplateSessionFileControllerGetDocumentsSuspense<TData = Awaited<ReturnType<typeof templateSessionFileControllerGetDocuments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateSessionId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateSessionFileControllerGetDocuments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateSessionFileControllerGetDocumentsSuspense<TData = Awaited<ReturnType<typeof templateSessionFileControllerGetDocuments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateSessionId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateSessionFileControllerGetDocuments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateSessionFileControllerGetDocumentsSuspense<TData = Awaited<ReturnType<typeof templateSessionFileControllerGetDocuments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateSessionId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateSessionFileControllerGetDocuments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTemplateSessionFileControllerGetDocumentsSuspense<TData = Awaited<ReturnType<typeof templateSessionFileControllerGetDocuments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateSessionId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateSessionFileControllerGetDocuments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTemplateSessionFileControllerGetDocumentsSuspenseQueryOptions(tenantId,environmentId,templateSessionId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const templateSessionFileControllerCreate = (
    tenantId: string,
    environmentId: string,
    templateSessionId: string,
    templateSessionFileUploadPostDto: TemplateSessionFileUploadPostDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TemplateSessionFileUploadReturnDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/${environmentId}/template-session/${templateSessionId}/file`,
      templateSessionFileUploadPostDto,options
    );
  }



export const getTemplateSessionFileControllerCreateMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof templateSessionFileControllerCreate>>, TError,{tenantId: string;environmentId: string;templateSessionId: string;data: TemplateSessionFileUploadPostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof templateSessionFileControllerCreate>>, TError,{tenantId: string;environmentId: string;templateSessionId: string;data: TemplateSessionFileUploadPostDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof templateSessionFileControllerCreate>>, {tenantId: string;environmentId: string;templateSessionId: string;data: TemplateSessionFileUploadPostDto}> = (props) => {
          const {tenantId,environmentId,templateSessionId,data} = props ?? {};

          return  templateSessionFileControllerCreate(tenantId,environmentId,templateSessionId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TemplateSessionFileControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof templateSessionFileControllerCreate>>>
    export type TemplateSessionFileControllerCreateMutationBody = TemplateSessionFileUploadPostDto
    export type TemplateSessionFileControllerCreateMutationError = AxiosError<unknown>

    export const useTemplateSessionFileControllerCreate = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof templateSessionFileControllerCreate>>, TError,{tenantId: string;environmentId: string;templateSessionId: string;data: TemplateSessionFileUploadPostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof templateSessionFileControllerCreate>>,
        TError,
        {tenantId: string;environmentId: string;templateSessionId: string;data: TemplateSessionFileUploadPostDto},
        TContext
      > => {

      const mutationOptions = getTemplateSessionFileControllerCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const templateSessionFileControllerDeleteDocument = (
    tenantId: string,
    environmentId: string,
    templateSessionId: string,
    templateSessionFileId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/v1/${tenantId}/${environmentId}/template-session/${templateSessionId}/file/${templateSessionFileId}`,options
    );
  }



export const getTemplateSessionFileControllerDeleteDocumentMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof templateSessionFileControllerDeleteDocument>>, TError,{tenantId: string;environmentId: string;templateSessionId: string;templateSessionFileId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof templateSessionFileControllerDeleteDocument>>, TError,{tenantId: string;environmentId: string;templateSessionId: string;templateSessionFileId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof templateSessionFileControllerDeleteDocument>>, {tenantId: string;environmentId: string;templateSessionId: string;templateSessionFileId: string}> = (props) => {
          const {tenantId,environmentId,templateSessionId,templateSessionFileId} = props ?? {};

          return  templateSessionFileControllerDeleteDocument(tenantId,environmentId,templateSessionId,templateSessionFileId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TemplateSessionFileControllerDeleteDocumentMutationResult = NonNullable<Awaited<ReturnType<typeof templateSessionFileControllerDeleteDocument>>>
    
    export type TemplateSessionFileControllerDeleteDocumentMutationError = AxiosError<unknown>

    export const useTemplateSessionFileControllerDeleteDocument = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof templateSessionFileControllerDeleteDocument>>, TError,{tenantId: string;environmentId: string;templateSessionId: string;templateSessionFileId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof templateSessionFileControllerDeleteDocument>>,
        TError,
        {tenantId: string;environmentId: string;templateSessionId: string;templateSessionFileId: string},
        TContext
      > => {

      const mutationOptions = getTemplateSessionFileControllerDeleteDocumentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const exerciseControllerGetExercises = (
    tenantId: string,
    environmentId: string,
    sessionId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ExerciseGetDto[][]>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/session/${sessionId}/exercise`,options
    );
  }


export const getExerciseControllerGetExercisesQueryKey = (tenantId: string,
    environmentId: string,
    sessionId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/session/${sessionId}/exercise`] as const;
    }

    
export const getExerciseControllerGetExercisesQueryOptions = <TData = Awaited<ReturnType<typeof exerciseControllerGetExercises>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExercises>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExerciseControllerGetExercisesQueryKey(tenantId,environmentId,sessionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof exerciseControllerGetExercises>>> = ({ signal }) => exerciseControllerGetExercises(tenantId,environmentId,sessionId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && sessionId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExercises>>, TError, TData> & { queryKey: QueryKey }
}

export type ExerciseControllerGetExercisesQueryResult = NonNullable<Awaited<ReturnType<typeof exerciseControllerGetExercises>>>
export type ExerciseControllerGetExercisesQueryError = AxiosError<unknown>


export function useExerciseControllerGetExercises<TData = Awaited<ReturnType<typeof exerciseControllerGetExercises>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExercises>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof exerciseControllerGetExercises>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useExerciseControllerGetExercises<TData = Awaited<ReturnType<typeof exerciseControllerGetExercises>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExercises>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof exerciseControllerGetExercises>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useExerciseControllerGetExercises<TData = Awaited<ReturnType<typeof exerciseControllerGetExercises>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExercises>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useExerciseControllerGetExercises<TData = Awaited<ReturnType<typeof exerciseControllerGetExercises>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExercises>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getExerciseControllerGetExercisesQueryOptions(tenantId,environmentId,sessionId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getExerciseControllerGetExercisesSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof exerciseControllerGetExercises>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExercises>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExerciseControllerGetExercisesQueryKey(tenantId,environmentId,sessionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof exerciseControllerGetExercises>>> = ({ signal }) => exerciseControllerGetExercises(tenantId,environmentId,sessionId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && sessionId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExercises>>, TError, TData> & { queryKey: QueryKey }
}

export type ExerciseControllerGetExercisesSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof exerciseControllerGetExercises>>>
export type ExerciseControllerGetExercisesSuspenseQueryError = AxiosError<unknown>


export function useExerciseControllerGetExercisesSuspense<TData = Awaited<ReturnType<typeof exerciseControllerGetExercises>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExercises>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useExerciseControllerGetExercisesSuspense<TData = Awaited<ReturnType<typeof exerciseControllerGetExercises>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExercises>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useExerciseControllerGetExercisesSuspense<TData = Awaited<ReturnType<typeof exerciseControllerGetExercises>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExercises>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useExerciseControllerGetExercisesSuspense<TData = Awaited<ReturnType<typeof exerciseControllerGetExercises>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExercises>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getExerciseControllerGetExercisesSuspenseQueryOptions(tenantId,environmentId,sessionId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const exerciseControllerCreateExercise = (
    tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseCreateUpdateDto: ExerciseCreateUpdateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ExerciseCreateUpdateResponseDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/${environmentId}/session/${sessionId}/exercise`,
      exerciseCreateUpdateDto,options
    );
  }



export const getExerciseControllerCreateExerciseMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof exerciseControllerCreateExercise>>, TError,{tenantId: string;environmentId: string;sessionId: string;data: ExerciseCreateUpdateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof exerciseControllerCreateExercise>>, TError,{tenantId: string;environmentId: string;sessionId: string;data: ExerciseCreateUpdateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof exerciseControllerCreateExercise>>, {tenantId: string;environmentId: string;sessionId: string;data: ExerciseCreateUpdateDto}> = (props) => {
          const {tenantId,environmentId,sessionId,data} = props ?? {};

          return  exerciseControllerCreateExercise(tenantId,environmentId,sessionId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ExerciseControllerCreateExerciseMutationResult = NonNullable<Awaited<ReturnType<typeof exerciseControllerCreateExercise>>>
    export type ExerciseControllerCreateExerciseMutationBody = ExerciseCreateUpdateDto
    export type ExerciseControllerCreateExerciseMutationError = AxiosError<unknown>

    export const useExerciseControllerCreateExercise = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof exerciseControllerCreateExercise>>, TError,{tenantId: string;environmentId: string;sessionId: string;data: ExerciseCreateUpdateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof exerciseControllerCreateExercise>>,
        TError,
        {tenantId: string;environmentId: string;sessionId: string;data: ExerciseCreateUpdateDto},
        TContext
      > => {

      const mutationOptions = getExerciseControllerCreateExerciseMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const exerciseControllerGetExercise = (
    tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ExerciseGetDto>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/session/${sessionId}/exercise/${exerciseId}`,options
    );
  }


export const getExerciseControllerGetExerciseQueryKey = (tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/session/${sessionId}/exercise/${exerciseId}`] as const;
    }

    
export const getExerciseControllerGetExerciseQueryOptions = <TData = Awaited<ReturnType<typeof exerciseControllerGetExercise>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExercise>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExerciseControllerGetExerciseQueryKey(tenantId,environmentId,sessionId,exerciseId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof exerciseControllerGetExercise>>> = ({ signal }) => exerciseControllerGetExercise(tenantId,environmentId,sessionId,exerciseId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && sessionId && exerciseId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExercise>>, TError, TData> & { queryKey: QueryKey }
}

export type ExerciseControllerGetExerciseQueryResult = NonNullable<Awaited<ReturnType<typeof exerciseControllerGetExercise>>>
export type ExerciseControllerGetExerciseQueryError = AxiosError<unknown>


export function useExerciseControllerGetExercise<TData = Awaited<ReturnType<typeof exerciseControllerGetExercise>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExercise>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof exerciseControllerGetExercise>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useExerciseControllerGetExercise<TData = Awaited<ReturnType<typeof exerciseControllerGetExercise>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExercise>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof exerciseControllerGetExercise>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useExerciseControllerGetExercise<TData = Awaited<ReturnType<typeof exerciseControllerGetExercise>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExercise>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useExerciseControllerGetExercise<TData = Awaited<ReturnType<typeof exerciseControllerGetExercise>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExercise>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getExerciseControllerGetExerciseQueryOptions(tenantId,environmentId,sessionId,exerciseId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getExerciseControllerGetExerciseSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof exerciseControllerGetExercise>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExercise>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExerciseControllerGetExerciseQueryKey(tenantId,environmentId,sessionId,exerciseId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof exerciseControllerGetExercise>>> = ({ signal }) => exerciseControllerGetExercise(tenantId,environmentId,sessionId,exerciseId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && sessionId && exerciseId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExercise>>, TError, TData> & { queryKey: QueryKey }
}

export type ExerciseControllerGetExerciseSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof exerciseControllerGetExercise>>>
export type ExerciseControllerGetExerciseSuspenseQueryError = AxiosError<unknown>


export function useExerciseControllerGetExerciseSuspense<TData = Awaited<ReturnType<typeof exerciseControllerGetExercise>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExercise>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useExerciseControllerGetExerciseSuspense<TData = Awaited<ReturnType<typeof exerciseControllerGetExercise>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExercise>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useExerciseControllerGetExerciseSuspense<TData = Awaited<ReturnType<typeof exerciseControllerGetExercise>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExercise>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useExerciseControllerGetExerciseSuspense<TData = Awaited<ReturnType<typeof exerciseControllerGetExercise>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExercise>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getExerciseControllerGetExerciseSuspenseQueryOptions(tenantId,environmentId,sessionId,exerciseId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const exerciseControllerUpdateExercise = (
    tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string,
    exerciseCreateUpdateDto: ExerciseCreateUpdateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ExerciseCreateUpdateResponseDto>> => {
    
    return axios.put(
      `/v1/${tenantId}/${environmentId}/session/${sessionId}/exercise/${exerciseId}`,
      exerciseCreateUpdateDto,options
    );
  }



export const getExerciseControllerUpdateExerciseMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof exerciseControllerUpdateExercise>>, TError,{tenantId: string;environmentId: string;sessionId: string;exerciseId: string;data: ExerciseCreateUpdateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof exerciseControllerUpdateExercise>>, TError,{tenantId: string;environmentId: string;sessionId: string;exerciseId: string;data: ExerciseCreateUpdateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof exerciseControllerUpdateExercise>>, {tenantId: string;environmentId: string;sessionId: string;exerciseId: string;data: ExerciseCreateUpdateDto}> = (props) => {
          const {tenantId,environmentId,sessionId,exerciseId,data} = props ?? {};

          return  exerciseControllerUpdateExercise(tenantId,environmentId,sessionId,exerciseId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ExerciseControllerUpdateExerciseMutationResult = NonNullable<Awaited<ReturnType<typeof exerciseControllerUpdateExercise>>>
    export type ExerciseControllerUpdateExerciseMutationBody = ExerciseCreateUpdateDto
    export type ExerciseControllerUpdateExerciseMutationError = AxiosError<unknown>

    export const useExerciseControllerUpdateExercise = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof exerciseControllerUpdateExercise>>, TError,{tenantId: string;environmentId: string;sessionId: string;exerciseId: string;data: ExerciseCreateUpdateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof exerciseControllerUpdateExercise>>,
        TError,
        {tenantId: string;environmentId: string;sessionId: string;exerciseId: string;data: ExerciseCreateUpdateDto},
        TContext
      > => {

      const mutationOptions = getExerciseControllerUpdateExerciseMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const exerciseControllerDeleteExercise = (
    tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/v1/${tenantId}/${environmentId}/session/${sessionId}/exercise/${exerciseId}`,options
    );
  }



export const getExerciseControllerDeleteExerciseMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof exerciseControllerDeleteExercise>>, TError,{tenantId: string;environmentId: string;sessionId: string;exerciseId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof exerciseControllerDeleteExercise>>, TError,{tenantId: string;environmentId: string;sessionId: string;exerciseId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof exerciseControllerDeleteExercise>>, {tenantId: string;environmentId: string;sessionId: string;exerciseId: string}> = (props) => {
          const {tenantId,environmentId,sessionId,exerciseId} = props ?? {};

          return  exerciseControllerDeleteExercise(tenantId,environmentId,sessionId,exerciseId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ExerciseControllerDeleteExerciseMutationResult = NonNullable<Awaited<ReturnType<typeof exerciseControllerDeleteExercise>>>
    
    export type ExerciseControllerDeleteExerciseMutationError = AxiosError<unknown>

    export const useExerciseControllerDeleteExercise = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof exerciseControllerDeleteExercise>>, TError,{tenantId: string;environmentId: string;sessionId: string;exerciseId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof exerciseControllerDeleteExercise>>,
        TError,
        {tenantId: string;environmentId: string;sessionId: string;exerciseId: string},
        TContext
      > => {

      const mutationOptions = getExerciseControllerDeleteExerciseMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const exerciseControllerPatchExercise = (
    tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string,
    exercisePatchDto: ExercisePatchDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ExerciseCreateUpdateResponseDto>> => {
    
    return axios.patch(
      `/v1/${tenantId}/${environmentId}/session/${sessionId}/exercise/${exerciseId}`,
      exercisePatchDto,options
    );
  }



export const getExerciseControllerPatchExerciseMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof exerciseControllerPatchExercise>>, TError,{tenantId: string;environmentId: string;sessionId: string;exerciseId: string;data: ExercisePatchDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof exerciseControllerPatchExercise>>, TError,{tenantId: string;environmentId: string;sessionId: string;exerciseId: string;data: ExercisePatchDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof exerciseControllerPatchExercise>>, {tenantId: string;environmentId: string;sessionId: string;exerciseId: string;data: ExercisePatchDto}> = (props) => {
          const {tenantId,environmentId,sessionId,exerciseId,data} = props ?? {};

          return  exerciseControllerPatchExercise(tenantId,environmentId,sessionId,exerciseId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ExerciseControllerPatchExerciseMutationResult = NonNullable<Awaited<ReturnType<typeof exerciseControllerPatchExercise>>>
    export type ExerciseControllerPatchExerciseMutationBody = ExercisePatchDto
    export type ExerciseControllerPatchExerciseMutationError = AxiosError<unknown>

    export const useExerciseControllerPatchExercise = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof exerciseControllerPatchExercise>>, TError,{tenantId: string;environmentId: string;sessionId: string;exerciseId: string;data: ExercisePatchDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof exerciseControllerPatchExercise>>,
        TError,
        {tenantId: string;environmentId: string;sessionId: string;exerciseId: string;data: ExercisePatchDto},
        TContext
      > => {

      const mutationOptions = getExerciseControllerPatchExerciseMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const exerciseControllerGetExerciseDetails = (
    tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ExerciseControllerGetExerciseDetails200>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/session/${sessionId}/exercise/${exerciseId}/details`,options
    );
  }


export const getExerciseControllerGetExerciseDetailsQueryKey = (tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/session/${sessionId}/exercise/${exerciseId}/details`] as const;
    }

    
export const getExerciseControllerGetExerciseDetailsQueryOptions = <TData = Awaited<ReturnType<typeof exerciseControllerGetExerciseDetails>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExerciseDetails>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExerciseControllerGetExerciseDetailsQueryKey(tenantId,environmentId,sessionId,exerciseId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof exerciseControllerGetExerciseDetails>>> = ({ signal }) => exerciseControllerGetExerciseDetails(tenantId,environmentId,sessionId,exerciseId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && sessionId && exerciseId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExerciseDetails>>, TError, TData> & { queryKey: QueryKey }
}

export type ExerciseControllerGetExerciseDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof exerciseControllerGetExerciseDetails>>>
export type ExerciseControllerGetExerciseDetailsQueryError = AxiosError<unknown>


export function useExerciseControllerGetExerciseDetails<TData = Awaited<ReturnType<typeof exerciseControllerGetExerciseDetails>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExerciseDetails>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof exerciseControllerGetExerciseDetails>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useExerciseControllerGetExerciseDetails<TData = Awaited<ReturnType<typeof exerciseControllerGetExerciseDetails>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExerciseDetails>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof exerciseControllerGetExerciseDetails>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useExerciseControllerGetExerciseDetails<TData = Awaited<ReturnType<typeof exerciseControllerGetExerciseDetails>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExerciseDetails>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useExerciseControllerGetExerciseDetails<TData = Awaited<ReturnType<typeof exerciseControllerGetExerciseDetails>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExerciseDetails>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getExerciseControllerGetExerciseDetailsQueryOptions(tenantId,environmentId,sessionId,exerciseId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getExerciseControllerGetExerciseDetailsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof exerciseControllerGetExerciseDetails>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExerciseDetails>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExerciseControllerGetExerciseDetailsQueryKey(tenantId,environmentId,sessionId,exerciseId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof exerciseControllerGetExerciseDetails>>> = ({ signal }) => exerciseControllerGetExerciseDetails(tenantId,environmentId,sessionId,exerciseId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && sessionId && exerciseId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExerciseDetails>>, TError, TData> & { queryKey: QueryKey }
}

export type ExerciseControllerGetExerciseDetailsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof exerciseControllerGetExerciseDetails>>>
export type ExerciseControllerGetExerciseDetailsSuspenseQueryError = AxiosError<unknown>


export function useExerciseControllerGetExerciseDetailsSuspense<TData = Awaited<ReturnType<typeof exerciseControllerGetExerciseDetails>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExerciseDetails>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useExerciseControllerGetExerciseDetailsSuspense<TData = Awaited<ReturnType<typeof exerciseControllerGetExerciseDetails>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExerciseDetails>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useExerciseControllerGetExerciseDetailsSuspense<TData = Awaited<ReturnType<typeof exerciseControllerGetExerciseDetails>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExerciseDetails>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useExerciseControllerGetExerciseDetailsSuspense<TData = Awaited<ReturnType<typeof exerciseControllerGetExerciseDetails>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExerciseDetails>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getExerciseControllerGetExerciseDetailsSuspenseQueryOptions(tenantId,environmentId,sessionId,exerciseId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const exerciseControllerGetExerciseTags = (
    tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ExerciseTagGetDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/session/${sessionId}/exercise/${exerciseId}/tags`,options
    );
  }


export const getExerciseControllerGetExerciseTagsQueryKey = (tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/session/${sessionId}/exercise/${exerciseId}/tags`] as const;
    }

    
export const getExerciseControllerGetExerciseTagsQueryOptions = <TData = Awaited<ReturnType<typeof exerciseControllerGetExerciseTags>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExerciseTags>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExerciseControllerGetExerciseTagsQueryKey(tenantId,environmentId,sessionId,exerciseId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof exerciseControllerGetExerciseTags>>> = ({ signal }) => exerciseControllerGetExerciseTags(tenantId,environmentId,sessionId,exerciseId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && sessionId && exerciseId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExerciseTags>>, TError, TData> & { queryKey: QueryKey }
}

export type ExerciseControllerGetExerciseTagsQueryResult = NonNullable<Awaited<ReturnType<typeof exerciseControllerGetExerciseTags>>>
export type ExerciseControllerGetExerciseTagsQueryError = AxiosError<unknown>


export function useExerciseControllerGetExerciseTags<TData = Awaited<ReturnType<typeof exerciseControllerGetExerciseTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExerciseTags>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof exerciseControllerGetExerciseTags>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useExerciseControllerGetExerciseTags<TData = Awaited<ReturnType<typeof exerciseControllerGetExerciseTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExerciseTags>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof exerciseControllerGetExerciseTags>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useExerciseControllerGetExerciseTags<TData = Awaited<ReturnType<typeof exerciseControllerGetExerciseTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExerciseTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useExerciseControllerGetExerciseTags<TData = Awaited<ReturnType<typeof exerciseControllerGetExerciseTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExerciseTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getExerciseControllerGetExerciseTagsQueryOptions(tenantId,environmentId,sessionId,exerciseId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getExerciseControllerGetExerciseTagsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof exerciseControllerGetExerciseTags>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExerciseTags>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExerciseControllerGetExerciseTagsQueryKey(tenantId,environmentId,sessionId,exerciseId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof exerciseControllerGetExerciseTags>>> = ({ signal }) => exerciseControllerGetExerciseTags(tenantId,environmentId,sessionId,exerciseId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && sessionId && exerciseId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExerciseTags>>, TError, TData> & { queryKey: QueryKey }
}

export type ExerciseControllerGetExerciseTagsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof exerciseControllerGetExerciseTags>>>
export type ExerciseControllerGetExerciseTagsSuspenseQueryError = AxiosError<unknown>


export function useExerciseControllerGetExerciseTagsSuspense<TData = Awaited<ReturnType<typeof exerciseControllerGetExerciseTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExerciseTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useExerciseControllerGetExerciseTagsSuspense<TData = Awaited<ReturnType<typeof exerciseControllerGetExerciseTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExerciseTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useExerciseControllerGetExerciseTagsSuspense<TData = Awaited<ReturnType<typeof exerciseControllerGetExerciseTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExerciseTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useExerciseControllerGetExerciseTagsSuspense<TData = Awaited<ReturnType<typeof exerciseControllerGetExerciseTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetExerciseTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getExerciseControllerGetExerciseTagsSuspenseQueryOptions(tenantId,environmentId,sessionId,exerciseId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const exerciseControllerAttachmentUploadRequest = (
    tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string,
    exerciseAttachmentPostDto: ExerciseAttachmentPostDto[], options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ExerciseAttachmentPostReturnValueDto[]>> => {
    
    return axios.post(
      `/v1/${tenantId}/${environmentId}/session/${sessionId}/exercise/${exerciseId}/attachments/uploadrequest`,
      exerciseAttachmentPostDto,options
    );
  }



export const getExerciseControllerAttachmentUploadRequestMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof exerciseControllerAttachmentUploadRequest>>, TError,{tenantId: string;environmentId: string;sessionId: string;exerciseId: string;data: ExerciseAttachmentPostDto[]}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof exerciseControllerAttachmentUploadRequest>>, TError,{tenantId: string;environmentId: string;sessionId: string;exerciseId: string;data: ExerciseAttachmentPostDto[]}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof exerciseControllerAttachmentUploadRequest>>, {tenantId: string;environmentId: string;sessionId: string;exerciseId: string;data: ExerciseAttachmentPostDto[]}> = (props) => {
          const {tenantId,environmentId,sessionId,exerciseId,data} = props ?? {};

          return  exerciseControllerAttachmentUploadRequest(tenantId,environmentId,sessionId,exerciseId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ExerciseControllerAttachmentUploadRequestMutationResult = NonNullable<Awaited<ReturnType<typeof exerciseControllerAttachmentUploadRequest>>>
    export type ExerciseControllerAttachmentUploadRequestMutationBody = ExerciseAttachmentPostDto[]
    export type ExerciseControllerAttachmentUploadRequestMutationError = AxiosError<unknown>

    export const useExerciseControllerAttachmentUploadRequest = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof exerciseControllerAttachmentUploadRequest>>, TError,{tenantId: string;environmentId: string;sessionId: string;exerciseId: string;data: ExerciseAttachmentPostDto[]}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof exerciseControllerAttachmentUploadRequest>>,
        TError,
        {tenantId: string;environmentId: string;sessionId: string;exerciseId: string;data: ExerciseAttachmentPostDto[]},
        TContext
      > => {

      const mutationOptions = getExerciseControllerAttachmentUploadRequestMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const exerciseControllerGetAttachments = (
    tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ExerciseAttachmentGetDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/session/${sessionId}/exercise/${exerciseId}/attachments`,options
    );
  }


export const getExerciseControllerGetAttachmentsQueryKey = (tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/session/${sessionId}/exercise/${exerciseId}/attachments`] as const;
    }

    
export const getExerciseControllerGetAttachmentsQueryOptions = <TData = Awaited<ReturnType<typeof exerciseControllerGetAttachments>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetAttachments>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExerciseControllerGetAttachmentsQueryKey(tenantId,environmentId,sessionId,exerciseId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof exerciseControllerGetAttachments>>> = ({ signal }) => exerciseControllerGetAttachments(tenantId,environmentId,sessionId,exerciseId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && sessionId && exerciseId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetAttachments>>, TError, TData> & { queryKey: QueryKey }
}

export type ExerciseControllerGetAttachmentsQueryResult = NonNullable<Awaited<ReturnType<typeof exerciseControllerGetAttachments>>>
export type ExerciseControllerGetAttachmentsQueryError = AxiosError<unknown>


export function useExerciseControllerGetAttachments<TData = Awaited<ReturnType<typeof exerciseControllerGetAttachments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetAttachments>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof exerciseControllerGetAttachments>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useExerciseControllerGetAttachments<TData = Awaited<ReturnType<typeof exerciseControllerGetAttachments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetAttachments>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof exerciseControllerGetAttachments>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useExerciseControllerGetAttachments<TData = Awaited<ReturnType<typeof exerciseControllerGetAttachments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetAttachments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useExerciseControllerGetAttachments<TData = Awaited<ReturnType<typeof exerciseControllerGetAttachments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetAttachments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getExerciseControllerGetAttachmentsQueryOptions(tenantId,environmentId,sessionId,exerciseId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getExerciseControllerGetAttachmentsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof exerciseControllerGetAttachments>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetAttachments>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExerciseControllerGetAttachmentsQueryKey(tenantId,environmentId,sessionId,exerciseId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof exerciseControllerGetAttachments>>> = ({ signal }) => exerciseControllerGetAttachments(tenantId,environmentId,sessionId,exerciseId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && sessionId && exerciseId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetAttachments>>, TError, TData> & { queryKey: QueryKey }
}

export type ExerciseControllerGetAttachmentsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof exerciseControllerGetAttachments>>>
export type ExerciseControllerGetAttachmentsSuspenseQueryError = AxiosError<unknown>


export function useExerciseControllerGetAttachmentsSuspense<TData = Awaited<ReturnType<typeof exerciseControllerGetAttachments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetAttachments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useExerciseControllerGetAttachmentsSuspense<TData = Awaited<ReturnType<typeof exerciseControllerGetAttachments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetAttachments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useExerciseControllerGetAttachmentsSuspense<TData = Awaited<ReturnType<typeof exerciseControllerGetAttachments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetAttachments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useExerciseControllerGetAttachmentsSuspense<TData = Awaited<ReturnType<typeof exerciseControllerGetAttachments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetAttachments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getExerciseControllerGetAttachmentsSuspenseQueryOptions(tenantId,environmentId,sessionId,exerciseId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const exerciseControllerGetDownloadUrl = (
    tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string,
    attachmentId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ExerciseAttachmentDownloadUrlDto>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/session/${sessionId}/exercise/${exerciseId}/attachments/${attachmentId}/downloadrequest`,options
    );
  }


export const getExerciseControllerGetDownloadUrlQueryKey = (tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string,
    attachmentId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/session/${sessionId}/exercise/${exerciseId}/attachments/${attachmentId}/downloadrequest`] as const;
    }

    
export const getExerciseControllerGetDownloadUrlQueryOptions = <TData = Awaited<ReturnType<typeof exerciseControllerGetDownloadUrl>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string,
    attachmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetDownloadUrl>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExerciseControllerGetDownloadUrlQueryKey(tenantId,environmentId,sessionId,exerciseId,attachmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof exerciseControllerGetDownloadUrl>>> = ({ signal }) => exerciseControllerGetDownloadUrl(tenantId,environmentId,sessionId,exerciseId,attachmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && sessionId && exerciseId && attachmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetDownloadUrl>>, TError, TData> & { queryKey: QueryKey }
}

export type ExerciseControllerGetDownloadUrlQueryResult = NonNullable<Awaited<ReturnType<typeof exerciseControllerGetDownloadUrl>>>
export type ExerciseControllerGetDownloadUrlQueryError = AxiosError<unknown>


export function useExerciseControllerGetDownloadUrl<TData = Awaited<ReturnType<typeof exerciseControllerGetDownloadUrl>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string,
    attachmentId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetDownloadUrl>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof exerciseControllerGetDownloadUrl>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useExerciseControllerGetDownloadUrl<TData = Awaited<ReturnType<typeof exerciseControllerGetDownloadUrl>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string,
    attachmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetDownloadUrl>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof exerciseControllerGetDownloadUrl>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useExerciseControllerGetDownloadUrl<TData = Awaited<ReturnType<typeof exerciseControllerGetDownloadUrl>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string,
    attachmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetDownloadUrl>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useExerciseControllerGetDownloadUrl<TData = Awaited<ReturnType<typeof exerciseControllerGetDownloadUrl>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string,
    attachmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetDownloadUrl>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getExerciseControllerGetDownloadUrlQueryOptions(tenantId,environmentId,sessionId,exerciseId,attachmentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getExerciseControllerGetDownloadUrlSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof exerciseControllerGetDownloadUrl>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string,
    attachmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetDownloadUrl>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExerciseControllerGetDownloadUrlQueryKey(tenantId,environmentId,sessionId,exerciseId,attachmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof exerciseControllerGetDownloadUrl>>> = ({ signal }) => exerciseControllerGetDownloadUrl(tenantId,environmentId,sessionId,exerciseId,attachmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && sessionId && exerciseId && attachmentId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetDownloadUrl>>, TError, TData> & { queryKey: QueryKey }
}

export type ExerciseControllerGetDownloadUrlSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof exerciseControllerGetDownloadUrl>>>
export type ExerciseControllerGetDownloadUrlSuspenseQueryError = AxiosError<unknown>


export function useExerciseControllerGetDownloadUrlSuspense<TData = Awaited<ReturnType<typeof exerciseControllerGetDownloadUrl>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string,
    attachmentId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetDownloadUrl>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useExerciseControllerGetDownloadUrlSuspense<TData = Awaited<ReturnType<typeof exerciseControllerGetDownloadUrl>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string,
    attachmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetDownloadUrl>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useExerciseControllerGetDownloadUrlSuspense<TData = Awaited<ReturnType<typeof exerciseControllerGetDownloadUrl>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string,
    attachmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetDownloadUrl>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useExerciseControllerGetDownloadUrlSuspense<TData = Awaited<ReturnType<typeof exerciseControllerGetDownloadUrl>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string,
    attachmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof exerciseControllerGetDownloadUrl>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getExerciseControllerGetDownloadUrlSuspenseQueryOptions(tenantId,environmentId,sessionId,exerciseId,attachmentId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const exerciseControllerDeleteAttachment = (
    tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseId: string,
    attachmentId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/v1/${tenantId}/${environmentId}/session/${sessionId}/exercise/${exerciseId}/attachments/${attachmentId}`,options
    );
  }



export const getExerciseControllerDeleteAttachmentMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof exerciseControllerDeleteAttachment>>, TError,{tenantId: string;environmentId: string;sessionId: string;exerciseId: string;attachmentId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof exerciseControllerDeleteAttachment>>, TError,{tenantId: string;environmentId: string;sessionId: string;exerciseId: string;attachmentId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof exerciseControllerDeleteAttachment>>, {tenantId: string;environmentId: string;sessionId: string;exerciseId: string;attachmentId: string}> = (props) => {
          const {tenantId,environmentId,sessionId,exerciseId,attachmentId} = props ?? {};

          return  exerciseControllerDeleteAttachment(tenantId,environmentId,sessionId,exerciseId,attachmentId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ExerciseControllerDeleteAttachmentMutationResult = NonNullable<Awaited<ReturnType<typeof exerciseControllerDeleteAttachment>>>
    
    export type ExerciseControllerDeleteAttachmentMutationError = AxiosError<unknown>

    export const useExerciseControllerDeleteAttachment = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof exerciseControllerDeleteAttachment>>, TError,{tenantId: string;environmentId: string;sessionId: string;exerciseId: string;attachmentId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof exerciseControllerDeleteAttachment>>,
        TError,
        {tenantId: string;environmentId: string;sessionId: string;exerciseId: string;attachmentId: string},
        TContext
      > => {

      const mutationOptions = getExerciseControllerDeleteAttachmentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const exerciseControllerCreateByTemplate = (
    tenantId: string,
    environmentId: string,
    sessionId: string,
    exerciseCreateByTemplateDto: ExerciseCreateByTemplateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ExerciseCreateUpdateResponseDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/${environmentId}/session/${sessionId}/exercise/byTemplate`,
      exerciseCreateByTemplateDto,options
    );
  }



export const getExerciseControllerCreateByTemplateMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof exerciseControllerCreateByTemplate>>, TError,{tenantId: string;environmentId: string;sessionId: string;data: ExerciseCreateByTemplateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof exerciseControllerCreateByTemplate>>, TError,{tenantId: string;environmentId: string;sessionId: string;data: ExerciseCreateByTemplateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof exerciseControllerCreateByTemplate>>, {tenantId: string;environmentId: string;sessionId: string;data: ExerciseCreateByTemplateDto}> = (props) => {
          const {tenantId,environmentId,sessionId,data} = props ?? {};

          return  exerciseControllerCreateByTemplate(tenantId,environmentId,sessionId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ExerciseControllerCreateByTemplateMutationResult = NonNullable<Awaited<ReturnType<typeof exerciseControllerCreateByTemplate>>>
    export type ExerciseControllerCreateByTemplateMutationBody = ExerciseCreateByTemplateDto
    export type ExerciseControllerCreateByTemplateMutationError = AxiosError<unknown>

    export const useExerciseControllerCreateByTemplate = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof exerciseControllerCreateByTemplate>>, TError,{tenantId: string;environmentId: string;sessionId: string;data: ExerciseCreateByTemplateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof exerciseControllerCreateByTemplate>>,
        TError,
        {tenantId: string;environmentId: string;sessionId: string;data: ExerciseCreateByTemplateDto},
        TContext
      > => {

      const mutationOptions = getExerciseControllerCreateByTemplateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const templateExerciseControllerGetTemplateExercises = (
    tenantId: string,
    environmentId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TemplateExerciseGetDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/template-exercise`,options
    );
  }


export const getTemplateExerciseControllerGetTemplateExercisesQueryKey = (tenantId: string,
    environmentId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/template-exercise`] as const;
    }

    
export const getTemplateExerciseControllerGetTemplateExercisesQueryOptions = <TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercises>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercises>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTemplateExerciseControllerGetTemplateExercisesQueryKey(tenantId,environmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercises>>> = ({ signal }) => templateExerciseControllerGetTemplateExercises(tenantId,environmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercises>>, TError, TData> & { queryKey: QueryKey }
}

export type TemplateExerciseControllerGetTemplateExercisesQueryResult = NonNullable<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercises>>>
export type TemplateExerciseControllerGetTemplateExercisesQueryError = AxiosError<unknown>


export function useTemplateExerciseControllerGetTemplateExercises<TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercises>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercises>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercises>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateExerciseControllerGetTemplateExercises<TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercises>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercises>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercises>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateExerciseControllerGetTemplateExercises<TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercises>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercises>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTemplateExerciseControllerGetTemplateExercises<TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercises>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercises>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTemplateExerciseControllerGetTemplateExercisesQueryOptions(tenantId,environmentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTemplateExerciseControllerGetTemplateExercisesSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercises>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercises>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTemplateExerciseControllerGetTemplateExercisesQueryKey(tenantId,environmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercises>>> = ({ signal }) => templateExerciseControllerGetTemplateExercises(tenantId,environmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercises>>, TError, TData> & { queryKey: QueryKey }
}

export type TemplateExerciseControllerGetTemplateExercisesSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercises>>>
export type TemplateExerciseControllerGetTemplateExercisesSuspenseQueryError = AxiosError<unknown>


export function useTemplateExerciseControllerGetTemplateExercisesSuspense<TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercises>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercises>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateExerciseControllerGetTemplateExercisesSuspense<TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercises>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercises>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateExerciseControllerGetTemplateExercisesSuspense<TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercises>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercises>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTemplateExerciseControllerGetTemplateExercisesSuspense<TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercises>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercises>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTemplateExerciseControllerGetTemplateExercisesSuspenseQueryOptions(tenantId,environmentId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const templateExerciseControllerCreateTemplateExercise = (
    tenantId: string,
    environmentId: string,
    templateExerciseCreateUpdateDto: TemplateExerciseCreateUpdateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TemplateExerciseCreateUpdateResponseDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/${environmentId}/template-exercise`,
      templateExerciseCreateUpdateDto,options
    );
  }



export const getTemplateExerciseControllerCreateTemplateExerciseMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof templateExerciseControllerCreateTemplateExercise>>, TError,{tenantId: string;environmentId: string;data: TemplateExerciseCreateUpdateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof templateExerciseControllerCreateTemplateExercise>>, TError,{tenantId: string;environmentId: string;data: TemplateExerciseCreateUpdateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof templateExerciseControllerCreateTemplateExercise>>, {tenantId: string;environmentId: string;data: TemplateExerciseCreateUpdateDto}> = (props) => {
          const {tenantId,environmentId,data} = props ?? {};

          return  templateExerciseControllerCreateTemplateExercise(tenantId,environmentId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TemplateExerciseControllerCreateTemplateExerciseMutationResult = NonNullable<Awaited<ReturnType<typeof templateExerciseControllerCreateTemplateExercise>>>
    export type TemplateExerciseControllerCreateTemplateExerciseMutationBody = TemplateExerciseCreateUpdateDto
    export type TemplateExerciseControllerCreateTemplateExerciseMutationError = AxiosError<unknown>

    export const useTemplateExerciseControllerCreateTemplateExercise = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof templateExerciseControllerCreateTemplateExercise>>, TError,{tenantId: string;environmentId: string;data: TemplateExerciseCreateUpdateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof templateExerciseControllerCreateTemplateExercise>>,
        TError,
        {tenantId: string;environmentId: string;data: TemplateExerciseCreateUpdateDto},
        TContext
      > => {

      const mutationOptions = getTemplateExerciseControllerCreateTemplateExerciseMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const templateExerciseControllerGetTemplateExercise = (
    tenantId: string,
    environmentId: string,
    templateExerciseId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TemplateExerciseGetDto>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/template-exercise/${templateExerciseId}`,options
    );
  }


export const getTemplateExerciseControllerGetTemplateExerciseQueryKey = (tenantId: string,
    environmentId: string,
    templateExerciseId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/template-exercise/${templateExerciseId}`] as const;
    }

    
export const getTemplateExerciseControllerGetTemplateExerciseQueryOptions = <TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercise>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    templateExerciseId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercise>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTemplateExerciseControllerGetTemplateExerciseQueryKey(tenantId,environmentId,templateExerciseId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercise>>> = ({ signal }) => templateExerciseControllerGetTemplateExercise(tenantId,environmentId,templateExerciseId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && templateExerciseId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercise>>, TError, TData> & { queryKey: QueryKey }
}

export type TemplateExerciseControllerGetTemplateExerciseQueryResult = NonNullable<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercise>>>
export type TemplateExerciseControllerGetTemplateExerciseQueryError = AxiosError<unknown>


export function useTemplateExerciseControllerGetTemplateExercise<TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercise>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateExerciseId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercise>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercise>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateExerciseControllerGetTemplateExercise<TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercise>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateExerciseId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercise>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercise>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateExerciseControllerGetTemplateExercise<TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercise>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateExerciseId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercise>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTemplateExerciseControllerGetTemplateExercise<TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercise>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateExerciseId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercise>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTemplateExerciseControllerGetTemplateExerciseQueryOptions(tenantId,environmentId,templateExerciseId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTemplateExerciseControllerGetTemplateExerciseSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercise>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    templateExerciseId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercise>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTemplateExerciseControllerGetTemplateExerciseQueryKey(tenantId,environmentId,templateExerciseId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercise>>> = ({ signal }) => templateExerciseControllerGetTemplateExercise(tenantId,environmentId,templateExerciseId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && templateExerciseId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercise>>, TError, TData> & { queryKey: QueryKey }
}

export type TemplateExerciseControllerGetTemplateExerciseSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercise>>>
export type TemplateExerciseControllerGetTemplateExerciseSuspenseQueryError = AxiosError<unknown>


export function useTemplateExerciseControllerGetTemplateExerciseSuspense<TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercise>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateExerciseId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercise>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateExerciseControllerGetTemplateExerciseSuspense<TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercise>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateExerciseId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercise>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateExerciseControllerGetTemplateExerciseSuspense<TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercise>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateExerciseId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercise>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTemplateExerciseControllerGetTemplateExerciseSuspense<TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercise>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateExerciseId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExercise>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTemplateExerciseControllerGetTemplateExerciseSuspenseQueryOptions(tenantId,environmentId,templateExerciseId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const templateExerciseControllerUpdateTemplateExercise = (
    tenantId: string,
    environmentId: string,
    templateExerciseId: string,
    templateExerciseCreateUpdateDto: TemplateExerciseCreateUpdateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TemplateExerciseCreateUpdateResponseDto>> => {
    
    return axios.put(
      `/v1/${tenantId}/${environmentId}/template-exercise/${templateExerciseId}`,
      templateExerciseCreateUpdateDto,options
    );
  }



export const getTemplateExerciseControllerUpdateTemplateExerciseMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof templateExerciseControllerUpdateTemplateExercise>>, TError,{tenantId: string;environmentId: string;templateExerciseId: string;data: TemplateExerciseCreateUpdateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof templateExerciseControllerUpdateTemplateExercise>>, TError,{tenantId: string;environmentId: string;templateExerciseId: string;data: TemplateExerciseCreateUpdateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof templateExerciseControllerUpdateTemplateExercise>>, {tenantId: string;environmentId: string;templateExerciseId: string;data: TemplateExerciseCreateUpdateDto}> = (props) => {
          const {tenantId,environmentId,templateExerciseId,data} = props ?? {};

          return  templateExerciseControllerUpdateTemplateExercise(tenantId,environmentId,templateExerciseId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TemplateExerciseControllerUpdateTemplateExerciseMutationResult = NonNullable<Awaited<ReturnType<typeof templateExerciseControllerUpdateTemplateExercise>>>
    export type TemplateExerciseControllerUpdateTemplateExerciseMutationBody = TemplateExerciseCreateUpdateDto
    export type TemplateExerciseControllerUpdateTemplateExerciseMutationError = AxiosError<unknown>

    export const useTemplateExerciseControllerUpdateTemplateExercise = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof templateExerciseControllerUpdateTemplateExercise>>, TError,{tenantId: string;environmentId: string;templateExerciseId: string;data: TemplateExerciseCreateUpdateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof templateExerciseControllerUpdateTemplateExercise>>,
        TError,
        {tenantId: string;environmentId: string;templateExerciseId: string;data: TemplateExerciseCreateUpdateDto},
        TContext
      > => {

      const mutationOptions = getTemplateExerciseControllerUpdateTemplateExerciseMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const templateExerciseControllerDeleteTemplateExercise = (
    tenantId: string,
    environmentId: string,
    templateExerciseId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/v1/${tenantId}/${environmentId}/template-exercise/${templateExerciseId}`,options
    );
  }



export const getTemplateExerciseControllerDeleteTemplateExerciseMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof templateExerciseControllerDeleteTemplateExercise>>, TError,{tenantId: string;environmentId: string;templateExerciseId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof templateExerciseControllerDeleteTemplateExercise>>, TError,{tenantId: string;environmentId: string;templateExerciseId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof templateExerciseControllerDeleteTemplateExercise>>, {tenantId: string;environmentId: string;templateExerciseId: string}> = (props) => {
          const {tenantId,environmentId,templateExerciseId} = props ?? {};

          return  templateExerciseControllerDeleteTemplateExercise(tenantId,environmentId,templateExerciseId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TemplateExerciseControllerDeleteTemplateExerciseMutationResult = NonNullable<Awaited<ReturnType<typeof templateExerciseControllerDeleteTemplateExercise>>>
    
    export type TemplateExerciseControllerDeleteTemplateExerciseMutationError = AxiosError<unknown>

    export const useTemplateExerciseControllerDeleteTemplateExercise = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof templateExerciseControllerDeleteTemplateExercise>>, TError,{tenantId: string;environmentId: string;templateExerciseId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof templateExerciseControllerDeleteTemplateExercise>>,
        TError,
        {tenantId: string;environmentId: string;templateExerciseId: string},
        TContext
      > => {

      const mutationOptions = getTemplateExerciseControllerDeleteTemplateExerciseMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const templateExerciseControllerGetTemplateExerciseDetails = (
    tenantId: string,
    environmentId: string,
    templateExerciseId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TemplateExerciseControllerGetTemplateExerciseDetails200>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/template-exercise/${templateExerciseId}/details`,options
    );
  }


export const getTemplateExerciseControllerGetTemplateExerciseDetailsQueryKey = (tenantId: string,
    environmentId: string,
    templateExerciseId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/template-exercise/${templateExerciseId}/details`] as const;
    }

    
export const getTemplateExerciseControllerGetTemplateExerciseDetailsQueryOptions = <TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseDetails>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    templateExerciseId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseDetails>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTemplateExerciseControllerGetTemplateExerciseDetailsQueryKey(tenantId,environmentId,templateExerciseId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseDetails>>> = ({ signal }) => templateExerciseControllerGetTemplateExerciseDetails(tenantId,environmentId,templateExerciseId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && templateExerciseId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseDetails>>, TError, TData> & { queryKey: QueryKey }
}

export type TemplateExerciseControllerGetTemplateExerciseDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseDetails>>>
export type TemplateExerciseControllerGetTemplateExerciseDetailsQueryError = AxiosError<unknown>


export function useTemplateExerciseControllerGetTemplateExerciseDetails<TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseDetails>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateExerciseId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseDetails>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseDetails>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateExerciseControllerGetTemplateExerciseDetails<TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseDetails>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateExerciseId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseDetails>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseDetails>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateExerciseControllerGetTemplateExerciseDetails<TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseDetails>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateExerciseId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseDetails>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTemplateExerciseControllerGetTemplateExerciseDetails<TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseDetails>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateExerciseId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseDetails>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTemplateExerciseControllerGetTemplateExerciseDetailsQueryOptions(tenantId,environmentId,templateExerciseId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTemplateExerciseControllerGetTemplateExerciseDetailsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseDetails>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    templateExerciseId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseDetails>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTemplateExerciseControllerGetTemplateExerciseDetailsQueryKey(tenantId,environmentId,templateExerciseId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseDetails>>> = ({ signal }) => templateExerciseControllerGetTemplateExerciseDetails(tenantId,environmentId,templateExerciseId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && templateExerciseId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseDetails>>, TError, TData> & { queryKey: QueryKey }
}

export type TemplateExerciseControllerGetTemplateExerciseDetailsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseDetails>>>
export type TemplateExerciseControllerGetTemplateExerciseDetailsSuspenseQueryError = AxiosError<unknown>


export function useTemplateExerciseControllerGetTemplateExerciseDetailsSuspense<TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseDetails>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateExerciseId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseDetails>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateExerciseControllerGetTemplateExerciseDetailsSuspense<TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseDetails>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateExerciseId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseDetails>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateExerciseControllerGetTemplateExerciseDetailsSuspense<TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseDetails>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateExerciseId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseDetails>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTemplateExerciseControllerGetTemplateExerciseDetailsSuspense<TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseDetails>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateExerciseId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseDetails>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTemplateExerciseControllerGetTemplateExerciseDetailsSuspenseQueryOptions(tenantId,environmentId,templateExerciseId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const templateExerciseControllerGetTemplateExerciseTags = (
    tenantId: string,
    environmentId: string,
    templateExerciseId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TemplateExerciseTagGetDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/template-exercise/${templateExerciseId}/tags`,options
    );
  }


export const getTemplateExerciseControllerGetTemplateExerciseTagsQueryKey = (tenantId: string,
    environmentId: string,
    templateExerciseId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/template-exercise/${templateExerciseId}/tags`] as const;
    }

    
export const getTemplateExerciseControllerGetTemplateExerciseTagsQueryOptions = <TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseTags>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    templateExerciseId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseTags>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTemplateExerciseControllerGetTemplateExerciseTagsQueryKey(tenantId,environmentId,templateExerciseId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseTags>>> = ({ signal }) => templateExerciseControllerGetTemplateExerciseTags(tenantId,environmentId,templateExerciseId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && templateExerciseId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseTags>>, TError, TData> & { queryKey: QueryKey }
}

export type TemplateExerciseControllerGetTemplateExerciseTagsQueryResult = NonNullable<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseTags>>>
export type TemplateExerciseControllerGetTemplateExerciseTagsQueryError = AxiosError<unknown>


export function useTemplateExerciseControllerGetTemplateExerciseTags<TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateExerciseId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseTags>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseTags>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateExerciseControllerGetTemplateExerciseTags<TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateExerciseId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseTags>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseTags>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateExerciseControllerGetTemplateExerciseTags<TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateExerciseId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTemplateExerciseControllerGetTemplateExerciseTags<TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateExerciseId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTemplateExerciseControllerGetTemplateExerciseTagsQueryOptions(tenantId,environmentId,templateExerciseId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTemplateExerciseControllerGetTemplateExerciseTagsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseTags>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    templateExerciseId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseTags>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTemplateExerciseControllerGetTemplateExerciseTagsQueryKey(tenantId,environmentId,templateExerciseId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseTags>>> = ({ signal }) => templateExerciseControllerGetTemplateExerciseTags(tenantId,environmentId,templateExerciseId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && templateExerciseId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseTags>>, TError, TData> & { queryKey: QueryKey }
}

export type TemplateExerciseControllerGetTemplateExerciseTagsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseTags>>>
export type TemplateExerciseControllerGetTemplateExerciseTagsSuspenseQueryError = AxiosError<unknown>


export function useTemplateExerciseControllerGetTemplateExerciseTagsSuspense<TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateExerciseId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateExerciseControllerGetTemplateExerciseTagsSuspense<TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateExerciseId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateExerciseControllerGetTemplateExerciseTagsSuspense<TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateExerciseId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTemplateExerciseControllerGetTemplateExerciseTagsSuspense<TData = Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateExerciseId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateExerciseControllerGetTemplateExerciseTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTemplateExerciseControllerGetTemplateExerciseTagsSuspenseQueryOptions(tenantId,environmentId,templateExerciseId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const templateExerciseFileControllerGetDocuments = (
    tenantId: string,
    environmentId: string,
    templateExerciseId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TemplateExerciseFileGetDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/template-exercise/${templateExerciseId}/file`,options
    );
  }


export const getTemplateExerciseFileControllerGetDocumentsQueryKey = (tenantId: string,
    environmentId: string,
    templateExerciseId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/template-exercise/${templateExerciseId}/file`] as const;
    }

    
export const getTemplateExerciseFileControllerGetDocumentsQueryOptions = <TData = Awaited<ReturnType<typeof templateExerciseFileControllerGetDocuments>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    templateExerciseId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateExerciseFileControllerGetDocuments>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTemplateExerciseFileControllerGetDocumentsQueryKey(tenantId,environmentId,templateExerciseId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof templateExerciseFileControllerGetDocuments>>> = ({ signal }) => templateExerciseFileControllerGetDocuments(tenantId,environmentId,templateExerciseId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && templateExerciseId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof templateExerciseFileControllerGetDocuments>>, TError, TData> & { queryKey: QueryKey }
}

export type TemplateExerciseFileControllerGetDocumentsQueryResult = NonNullable<Awaited<ReturnType<typeof templateExerciseFileControllerGetDocuments>>>
export type TemplateExerciseFileControllerGetDocumentsQueryError = AxiosError<unknown>


export function useTemplateExerciseFileControllerGetDocuments<TData = Awaited<ReturnType<typeof templateExerciseFileControllerGetDocuments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateExerciseId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateExerciseFileControllerGetDocuments>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof templateExerciseFileControllerGetDocuments>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateExerciseFileControllerGetDocuments<TData = Awaited<ReturnType<typeof templateExerciseFileControllerGetDocuments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateExerciseId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateExerciseFileControllerGetDocuments>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof templateExerciseFileControllerGetDocuments>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateExerciseFileControllerGetDocuments<TData = Awaited<ReturnType<typeof templateExerciseFileControllerGetDocuments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateExerciseId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateExerciseFileControllerGetDocuments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTemplateExerciseFileControllerGetDocuments<TData = Awaited<ReturnType<typeof templateExerciseFileControllerGetDocuments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateExerciseId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof templateExerciseFileControllerGetDocuments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTemplateExerciseFileControllerGetDocumentsQueryOptions(tenantId,environmentId,templateExerciseId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTemplateExerciseFileControllerGetDocumentsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof templateExerciseFileControllerGetDocuments>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    templateExerciseId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateExerciseFileControllerGetDocuments>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTemplateExerciseFileControllerGetDocumentsQueryKey(tenantId,environmentId,templateExerciseId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof templateExerciseFileControllerGetDocuments>>> = ({ signal }) => templateExerciseFileControllerGetDocuments(tenantId,environmentId,templateExerciseId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && templateExerciseId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateExerciseFileControllerGetDocuments>>, TError, TData> & { queryKey: QueryKey }
}

export type TemplateExerciseFileControllerGetDocumentsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof templateExerciseFileControllerGetDocuments>>>
export type TemplateExerciseFileControllerGetDocumentsSuspenseQueryError = AxiosError<unknown>


export function useTemplateExerciseFileControllerGetDocumentsSuspense<TData = Awaited<ReturnType<typeof templateExerciseFileControllerGetDocuments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateExerciseId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateExerciseFileControllerGetDocuments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateExerciseFileControllerGetDocumentsSuspense<TData = Awaited<ReturnType<typeof templateExerciseFileControllerGetDocuments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateExerciseId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateExerciseFileControllerGetDocuments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTemplateExerciseFileControllerGetDocumentsSuspense<TData = Awaited<ReturnType<typeof templateExerciseFileControllerGetDocuments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateExerciseId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateExerciseFileControllerGetDocuments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTemplateExerciseFileControllerGetDocumentsSuspense<TData = Awaited<ReturnType<typeof templateExerciseFileControllerGetDocuments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    templateExerciseId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof templateExerciseFileControllerGetDocuments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTemplateExerciseFileControllerGetDocumentsSuspenseQueryOptions(tenantId,environmentId,templateExerciseId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const templateExerciseFileControllerCreate = (
    tenantId: string,
    environmentId: string,
    templateExerciseId: string,
    templateExerciseFileUploadPostDto: TemplateExerciseFileUploadPostDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TemplateExerciseFileUploadReturnDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/${environmentId}/template-exercise/${templateExerciseId}/file/uploadrequest`,
      templateExerciseFileUploadPostDto,options
    );
  }



export const getTemplateExerciseFileControllerCreateMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof templateExerciseFileControllerCreate>>, TError,{tenantId: string;environmentId: string;templateExerciseId: string;data: TemplateExerciseFileUploadPostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof templateExerciseFileControllerCreate>>, TError,{tenantId: string;environmentId: string;templateExerciseId: string;data: TemplateExerciseFileUploadPostDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof templateExerciseFileControllerCreate>>, {tenantId: string;environmentId: string;templateExerciseId: string;data: TemplateExerciseFileUploadPostDto}> = (props) => {
          const {tenantId,environmentId,templateExerciseId,data} = props ?? {};

          return  templateExerciseFileControllerCreate(tenantId,environmentId,templateExerciseId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TemplateExerciseFileControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof templateExerciseFileControllerCreate>>>
    export type TemplateExerciseFileControllerCreateMutationBody = TemplateExerciseFileUploadPostDto
    export type TemplateExerciseFileControllerCreateMutationError = AxiosError<unknown>

    export const useTemplateExerciseFileControllerCreate = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof templateExerciseFileControllerCreate>>, TError,{tenantId: string;environmentId: string;templateExerciseId: string;data: TemplateExerciseFileUploadPostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof templateExerciseFileControllerCreate>>,
        TError,
        {tenantId: string;environmentId: string;templateExerciseId: string;data: TemplateExerciseFileUploadPostDto},
        TContext
      > => {

      const mutationOptions = getTemplateExerciseFileControllerCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const templateExerciseFileControllerDeleteDocument = (
    tenantId: string,
    environmentId: string,
    templateExerciseId: string,
    templateExerciseFileId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/v1/${tenantId}/${environmentId}/template-exercise/${templateExerciseId}/file/${templateExerciseFileId}`,options
    );
  }



export const getTemplateExerciseFileControllerDeleteDocumentMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof templateExerciseFileControllerDeleteDocument>>, TError,{tenantId: string;environmentId: string;templateExerciseId: string;templateExerciseFileId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof templateExerciseFileControllerDeleteDocument>>, TError,{tenantId: string;environmentId: string;templateExerciseId: string;templateExerciseFileId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof templateExerciseFileControllerDeleteDocument>>, {tenantId: string;environmentId: string;templateExerciseId: string;templateExerciseFileId: string}> = (props) => {
          const {tenantId,environmentId,templateExerciseId,templateExerciseFileId} = props ?? {};

          return  templateExerciseFileControllerDeleteDocument(tenantId,environmentId,templateExerciseId,templateExerciseFileId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TemplateExerciseFileControllerDeleteDocumentMutationResult = NonNullable<Awaited<ReturnType<typeof templateExerciseFileControllerDeleteDocument>>>
    
    export type TemplateExerciseFileControllerDeleteDocumentMutationError = AxiosError<unknown>

    export const useTemplateExerciseFileControllerDeleteDocument = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof templateExerciseFileControllerDeleteDocument>>, TError,{tenantId: string;environmentId: string;templateExerciseId: string;templateExerciseFileId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof templateExerciseFileControllerDeleteDocument>>,
        TError,
        {tenantId: string;environmentId: string;templateExerciseId: string;templateExerciseFileId: string},
        TContext
      > => {

      const mutationOptions = getTemplateExerciseFileControllerDeleteDocumentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantLocationControllerGetTenantLocations = (
    tenantId: string,
    params?: TenantLocationControllerGetTenantLocationsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantLocationGetDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/settings/location`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getTenantLocationControllerGetTenantLocationsQueryKey = (tenantId: string,
    params?: TenantLocationControllerGetTenantLocationsParams,) => {
    return [`/v1/${tenantId}/settings/location`, ...(params ? [params]: [])] as const;
    }

    
export const getTenantLocationControllerGetTenantLocationsQueryOptions = <TData = Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocations>>, TError = AxiosError<unknown>>(tenantId: string,
    params?: TenantLocationControllerGetTenantLocationsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocations>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantLocationControllerGetTenantLocationsQueryKey(tenantId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocations>>> = ({ signal }) => tenantLocationControllerGetTenantLocations(tenantId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocations>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantLocationControllerGetTenantLocationsQueryResult = NonNullable<Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocations>>>
export type TenantLocationControllerGetTenantLocationsQueryError = AxiosError<unknown>


export function useTenantLocationControllerGetTenantLocations<TData = Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocations>>, TError = AxiosError<unknown>>(
 tenantId: string,
    params: undefined |  TenantLocationControllerGetTenantLocationsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocations>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocations>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantLocationControllerGetTenantLocations<TData = Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocations>>, TError = AxiosError<unknown>>(
 tenantId: string,
    params?: TenantLocationControllerGetTenantLocationsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocations>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocations>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantLocationControllerGetTenantLocations<TData = Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocations>>, TError = AxiosError<unknown>>(
 tenantId: string,
    params?: TenantLocationControllerGetTenantLocationsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocations>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantLocationControllerGetTenantLocations<TData = Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocations>>, TError = AxiosError<unknown>>(
 tenantId: string,
    params?: TenantLocationControllerGetTenantLocationsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocations>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantLocationControllerGetTenantLocationsQueryOptions(tenantId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantLocationControllerGetTenantLocationsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocations>>, TError = AxiosError<unknown>>(tenantId: string,
    params?: TenantLocationControllerGetTenantLocationsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocations>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantLocationControllerGetTenantLocationsQueryKey(tenantId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocations>>> = ({ signal }) => tenantLocationControllerGetTenantLocations(tenantId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocations>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantLocationControllerGetTenantLocationsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocations>>>
export type TenantLocationControllerGetTenantLocationsSuspenseQueryError = AxiosError<unknown>


export function useTenantLocationControllerGetTenantLocationsSuspense<TData = Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocations>>, TError = AxiosError<unknown>>(
 tenantId: string,
    params: undefined |  TenantLocationControllerGetTenantLocationsParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocations>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantLocationControllerGetTenantLocationsSuspense<TData = Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocations>>, TError = AxiosError<unknown>>(
 tenantId: string,
    params?: TenantLocationControllerGetTenantLocationsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocations>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantLocationControllerGetTenantLocationsSuspense<TData = Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocations>>, TError = AxiosError<unknown>>(
 tenantId: string,
    params?: TenantLocationControllerGetTenantLocationsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocations>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantLocationControllerGetTenantLocationsSuspense<TData = Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocations>>, TError = AxiosError<unknown>>(
 tenantId: string,
    params?: TenantLocationControllerGetTenantLocationsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocations>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantLocationControllerGetTenantLocationsSuspenseQueryOptions(tenantId,params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantLocationControllerCreateTenantLocation = (
    tenantId: string,
    tenantLocationCreateDto: TenantLocationCreateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantLocationGetDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/settings/location`,
      tenantLocationCreateDto,options
    );
  }



export const getTenantLocationControllerCreateTenantLocationMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantLocationControllerCreateTenantLocation>>, TError,{tenantId: string;data: TenantLocationCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantLocationControllerCreateTenantLocation>>, TError,{tenantId: string;data: TenantLocationCreateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantLocationControllerCreateTenantLocation>>, {tenantId: string;data: TenantLocationCreateDto}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  tenantLocationControllerCreateTenantLocation(tenantId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantLocationControllerCreateTenantLocationMutationResult = NonNullable<Awaited<ReturnType<typeof tenantLocationControllerCreateTenantLocation>>>
    export type TenantLocationControllerCreateTenantLocationMutationBody = TenantLocationCreateDto
    export type TenantLocationControllerCreateTenantLocationMutationError = AxiosError<unknown>

    export const useTenantLocationControllerCreateTenantLocation = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantLocationControllerCreateTenantLocation>>, TError,{tenantId: string;data: TenantLocationCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantLocationControllerCreateTenantLocation>>,
        TError,
        {tenantId: string;data: TenantLocationCreateDto},
        TContext
      > => {

      const mutationOptions = getTenantLocationControllerCreateTenantLocationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantLocationControllerGetTenantLocation = (
    tenantId: string,
    tenantLocationId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantLocationGetDto>> => {
    
    return axios.get(
      `/v1/${tenantId}/settings/location/${tenantLocationId}`,options
    );
  }


export const getTenantLocationControllerGetTenantLocationQueryKey = (tenantId: string,
    tenantLocationId: string,) => {
    return [`/v1/${tenantId}/settings/location/${tenantLocationId}`] as const;
    }

    
export const getTenantLocationControllerGetTenantLocationQueryOptions = <TData = Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocation>>, TError = AxiosError<unknown>>(tenantId: string,
    tenantLocationId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocation>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantLocationControllerGetTenantLocationQueryKey(tenantId,tenantLocationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocation>>> = ({ signal }) => tenantLocationControllerGetTenantLocation(tenantId,tenantLocationId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && tenantLocationId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocation>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantLocationControllerGetTenantLocationQueryResult = NonNullable<Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocation>>>
export type TenantLocationControllerGetTenantLocationQueryError = AxiosError<unknown>


export function useTenantLocationControllerGetTenantLocation<TData = Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocation>>, TError = AxiosError<unknown>>(
 tenantId: string,
    tenantLocationId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocation>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocation>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantLocationControllerGetTenantLocation<TData = Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocation>>, TError = AxiosError<unknown>>(
 tenantId: string,
    tenantLocationId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocation>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocation>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantLocationControllerGetTenantLocation<TData = Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocation>>, TError = AxiosError<unknown>>(
 tenantId: string,
    tenantLocationId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocation>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantLocationControllerGetTenantLocation<TData = Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocation>>, TError = AxiosError<unknown>>(
 tenantId: string,
    tenantLocationId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocation>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantLocationControllerGetTenantLocationQueryOptions(tenantId,tenantLocationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantLocationControllerGetTenantLocationSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocation>>, TError = AxiosError<unknown>>(tenantId: string,
    tenantLocationId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocation>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantLocationControllerGetTenantLocationQueryKey(tenantId,tenantLocationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocation>>> = ({ signal }) => tenantLocationControllerGetTenantLocation(tenantId,tenantLocationId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && tenantLocationId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocation>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantLocationControllerGetTenantLocationSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocation>>>
export type TenantLocationControllerGetTenantLocationSuspenseQueryError = AxiosError<unknown>


export function useTenantLocationControllerGetTenantLocationSuspense<TData = Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocation>>, TError = AxiosError<unknown>>(
 tenantId: string,
    tenantLocationId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocation>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantLocationControllerGetTenantLocationSuspense<TData = Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocation>>, TError = AxiosError<unknown>>(
 tenantId: string,
    tenantLocationId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocation>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantLocationControllerGetTenantLocationSuspense<TData = Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocation>>, TError = AxiosError<unknown>>(
 tenantId: string,
    tenantLocationId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocation>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantLocationControllerGetTenantLocationSuspense<TData = Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocation>>, TError = AxiosError<unknown>>(
 tenantId: string,
    tenantLocationId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantLocationControllerGetTenantLocation>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantLocationControllerGetTenantLocationSuspenseQueryOptions(tenantId,tenantLocationId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantLocationControllerUpdateTenantLocation = (
    tenantId: string,
    tenantLocationId: string,
    tenantLocationUpdateDto: TenantLocationUpdateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantLocationGetDto>> => {
    
    return axios.put(
      `/v1/${tenantId}/settings/location/${tenantLocationId}`,
      tenantLocationUpdateDto,options
    );
  }



export const getTenantLocationControllerUpdateTenantLocationMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantLocationControllerUpdateTenantLocation>>, TError,{tenantId: string;tenantLocationId: string;data: TenantLocationUpdateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantLocationControllerUpdateTenantLocation>>, TError,{tenantId: string;tenantLocationId: string;data: TenantLocationUpdateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantLocationControllerUpdateTenantLocation>>, {tenantId: string;tenantLocationId: string;data: TenantLocationUpdateDto}> = (props) => {
          const {tenantId,tenantLocationId,data} = props ?? {};

          return  tenantLocationControllerUpdateTenantLocation(tenantId,tenantLocationId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantLocationControllerUpdateTenantLocationMutationResult = NonNullable<Awaited<ReturnType<typeof tenantLocationControllerUpdateTenantLocation>>>
    export type TenantLocationControllerUpdateTenantLocationMutationBody = TenantLocationUpdateDto
    export type TenantLocationControllerUpdateTenantLocationMutationError = AxiosError<unknown>

    export const useTenantLocationControllerUpdateTenantLocation = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantLocationControllerUpdateTenantLocation>>, TError,{tenantId: string;tenantLocationId: string;data: TenantLocationUpdateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantLocationControllerUpdateTenantLocation>>,
        TError,
        {tenantId: string;tenantLocationId: string;data: TenantLocationUpdateDto},
        TContext
      > => {

      const mutationOptions = getTenantLocationControllerUpdateTenantLocationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantMatchDayTagLogicControllerGetMatchDayTagLogic = (
    tenantId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantMatchDayTagLogicGetDto>> => {
    
    return axios.get(
      `/v1/${tenantId}/settings/match-day-tag-logic`,options
    );
  }


export const getTenantMatchDayTagLogicControllerGetMatchDayTagLogicQueryKey = (tenantId: string,) => {
    return [`/v1/${tenantId}/settings/match-day-tag-logic`] as const;
    }

    
export const getTenantMatchDayTagLogicControllerGetMatchDayTagLogicQueryOptions = <TData = Awaited<ReturnType<typeof tenantMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError = AxiosError<unknown>>(tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantMatchDayTagLogicControllerGetMatchDayTagLogicQueryKey(tenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantMatchDayTagLogicControllerGetMatchDayTagLogic>>> = ({ signal }) => tenantMatchDayTagLogicControllerGetMatchDayTagLogic(tenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantMatchDayTagLogicControllerGetMatchDayTagLogicQueryResult = NonNullable<Awaited<ReturnType<typeof tenantMatchDayTagLogicControllerGetMatchDayTagLogic>>>
export type TenantMatchDayTagLogicControllerGetMatchDayTagLogicQueryError = AxiosError<unknown>


export function useTenantMatchDayTagLogicControllerGetMatchDayTagLogic<TData = Awaited<ReturnType<typeof tenantMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError = AxiosError<unknown>>(
 tenantId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantMatchDayTagLogicControllerGetMatchDayTagLogic>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantMatchDayTagLogicControllerGetMatchDayTagLogic<TData = Awaited<ReturnType<typeof tenantMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantMatchDayTagLogicControllerGetMatchDayTagLogic>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantMatchDayTagLogicControllerGetMatchDayTagLogic<TData = Awaited<ReturnType<typeof tenantMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantMatchDayTagLogicControllerGetMatchDayTagLogic<TData = Awaited<ReturnType<typeof tenantMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantMatchDayTagLogicControllerGetMatchDayTagLogicQueryOptions(tenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantMatchDayTagLogicControllerGetMatchDayTagLogicSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError = AxiosError<unknown>>(tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantMatchDayTagLogicControllerGetMatchDayTagLogicQueryKey(tenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantMatchDayTagLogicControllerGetMatchDayTagLogic>>> = ({ signal }) => tenantMatchDayTagLogicControllerGetMatchDayTagLogic(tenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantMatchDayTagLogicControllerGetMatchDayTagLogicSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantMatchDayTagLogicControllerGetMatchDayTagLogic>>>
export type TenantMatchDayTagLogicControllerGetMatchDayTagLogicSuspenseQueryError = AxiosError<unknown>


export function useTenantMatchDayTagLogicControllerGetMatchDayTagLogicSuspense<TData = Awaited<ReturnType<typeof tenantMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError = AxiosError<unknown>>(
 tenantId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantMatchDayTagLogicControllerGetMatchDayTagLogicSuspense<TData = Awaited<ReturnType<typeof tenantMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantMatchDayTagLogicControllerGetMatchDayTagLogicSuspense<TData = Awaited<ReturnType<typeof tenantMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantMatchDayTagLogicControllerGetMatchDayTagLogicSuspense<TData = Awaited<ReturnType<typeof tenantMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantMatchDayTagLogicControllerGetMatchDayTagLogic>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantMatchDayTagLogicControllerGetMatchDayTagLogicSuspenseQueryOptions(tenantId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantMatchDayTagLogicControllerUpdateMatchDayTagLogic = (
    tenantId: string,
    tenantMatchDayTagLogicPutDto: TenantMatchDayTagLogicPutDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantMatchDayTagLogicGetDto>> => {
    
    return axios.put(
      `/v1/${tenantId}/settings/match-day-tag-logic`,
      tenantMatchDayTagLogicPutDto,options
    );
  }



export const getTenantMatchDayTagLogicControllerUpdateMatchDayTagLogicMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantMatchDayTagLogicControllerUpdateMatchDayTagLogic>>, TError,{tenantId: string;data: TenantMatchDayTagLogicPutDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantMatchDayTagLogicControllerUpdateMatchDayTagLogic>>, TError,{tenantId: string;data: TenantMatchDayTagLogicPutDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantMatchDayTagLogicControllerUpdateMatchDayTagLogic>>, {tenantId: string;data: TenantMatchDayTagLogicPutDto}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  tenantMatchDayTagLogicControllerUpdateMatchDayTagLogic(tenantId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantMatchDayTagLogicControllerUpdateMatchDayTagLogicMutationResult = NonNullable<Awaited<ReturnType<typeof tenantMatchDayTagLogicControllerUpdateMatchDayTagLogic>>>
    export type TenantMatchDayTagLogicControllerUpdateMatchDayTagLogicMutationBody = TenantMatchDayTagLogicPutDto
    export type TenantMatchDayTagLogicControllerUpdateMatchDayTagLogicMutationError = AxiosError<unknown>

    export const useTenantMatchDayTagLogicControllerUpdateMatchDayTagLogic = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantMatchDayTagLogicControllerUpdateMatchDayTagLogic>>, TError,{tenantId: string;data: TenantMatchDayTagLogicPutDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantMatchDayTagLogicControllerUpdateMatchDayTagLogic>>,
        TError,
        {tenantId: string;data: TenantMatchDayTagLogicPutDto},
        TContext
      > => {

      const mutationOptions = getTenantMatchDayTagLogicControllerUpdateMatchDayTagLogicMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantSessionTagControllerGetAllSessionTags = (
    tenantId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantSessionTagGetDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/settings/session-tag`,options
    );
  }


export const getTenantSessionTagControllerGetAllSessionTagsQueryKey = (tenantId: string,) => {
    return [`/v1/${tenantId}/settings/session-tag`] as const;
    }

    
export const getTenantSessionTagControllerGetAllSessionTagsQueryOptions = <TData = Awaited<ReturnType<typeof tenantSessionTagControllerGetAllSessionTags>>, TError = AxiosError<unknown>>(tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantSessionTagControllerGetAllSessionTags>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantSessionTagControllerGetAllSessionTagsQueryKey(tenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantSessionTagControllerGetAllSessionTags>>> = ({ signal }) => tenantSessionTagControllerGetAllSessionTags(tenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantSessionTagControllerGetAllSessionTags>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantSessionTagControllerGetAllSessionTagsQueryResult = NonNullable<Awaited<ReturnType<typeof tenantSessionTagControllerGetAllSessionTags>>>
export type TenantSessionTagControllerGetAllSessionTagsQueryError = AxiosError<unknown>


export function useTenantSessionTagControllerGetAllSessionTags<TData = Awaited<ReturnType<typeof tenantSessionTagControllerGetAllSessionTags>>, TError = AxiosError<unknown>>(
 tenantId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantSessionTagControllerGetAllSessionTags>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantSessionTagControllerGetAllSessionTags>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantSessionTagControllerGetAllSessionTags<TData = Awaited<ReturnType<typeof tenantSessionTagControllerGetAllSessionTags>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantSessionTagControllerGetAllSessionTags>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantSessionTagControllerGetAllSessionTags>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantSessionTagControllerGetAllSessionTags<TData = Awaited<ReturnType<typeof tenantSessionTagControllerGetAllSessionTags>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantSessionTagControllerGetAllSessionTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantSessionTagControllerGetAllSessionTags<TData = Awaited<ReturnType<typeof tenantSessionTagControllerGetAllSessionTags>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantSessionTagControllerGetAllSessionTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantSessionTagControllerGetAllSessionTagsQueryOptions(tenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantSessionTagControllerGetAllSessionTagsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantSessionTagControllerGetAllSessionTags>>, TError = AxiosError<unknown>>(tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantSessionTagControllerGetAllSessionTags>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantSessionTagControllerGetAllSessionTagsQueryKey(tenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantSessionTagControllerGetAllSessionTags>>> = ({ signal }) => tenantSessionTagControllerGetAllSessionTags(tenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantSessionTagControllerGetAllSessionTags>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantSessionTagControllerGetAllSessionTagsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantSessionTagControllerGetAllSessionTags>>>
export type TenantSessionTagControllerGetAllSessionTagsSuspenseQueryError = AxiosError<unknown>


export function useTenantSessionTagControllerGetAllSessionTagsSuspense<TData = Awaited<ReturnType<typeof tenantSessionTagControllerGetAllSessionTags>>, TError = AxiosError<unknown>>(
 tenantId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantSessionTagControllerGetAllSessionTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantSessionTagControllerGetAllSessionTagsSuspense<TData = Awaited<ReturnType<typeof tenantSessionTagControllerGetAllSessionTags>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantSessionTagControllerGetAllSessionTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantSessionTagControllerGetAllSessionTagsSuspense<TData = Awaited<ReturnType<typeof tenantSessionTagControllerGetAllSessionTags>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantSessionTagControllerGetAllSessionTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantSessionTagControllerGetAllSessionTagsSuspense<TData = Awaited<ReturnType<typeof tenantSessionTagControllerGetAllSessionTags>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantSessionTagControllerGetAllSessionTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantSessionTagControllerGetAllSessionTagsSuspenseQueryOptions(tenantId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantSessionTagControllerCreateSessionTag = (
    tenantId: string,
    tenantSessionTagCreateDto: TenantSessionTagCreateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantSessionTagGetDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/settings/session-tag`,
      tenantSessionTagCreateDto,options
    );
  }



export const getTenantSessionTagControllerCreateSessionTagMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantSessionTagControllerCreateSessionTag>>, TError,{tenantId: string;data: TenantSessionTagCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantSessionTagControllerCreateSessionTag>>, TError,{tenantId: string;data: TenantSessionTagCreateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantSessionTagControllerCreateSessionTag>>, {tenantId: string;data: TenantSessionTagCreateDto}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  tenantSessionTagControllerCreateSessionTag(tenantId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantSessionTagControllerCreateSessionTagMutationResult = NonNullable<Awaited<ReturnType<typeof tenantSessionTagControllerCreateSessionTag>>>
    export type TenantSessionTagControllerCreateSessionTagMutationBody = TenantSessionTagCreateDto
    export type TenantSessionTagControllerCreateSessionTagMutationError = AxiosError<unknown>

    export const useTenantSessionTagControllerCreateSessionTag = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantSessionTagControllerCreateSessionTag>>, TError,{tenantId: string;data: TenantSessionTagCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantSessionTagControllerCreateSessionTag>>,
        TError,
        {tenantId: string;data: TenantSessionTagCreateDto},
        TContext
      > => {

      const mutationOptions = getTenantSessionTagControllerCreateSessionTagMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantSessionTagControllerGetSessionTag = (
    tenantId: string,
    sessionTagId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantSessionTagGetDto>> => {
    
    return axios.get(
      `/v1/${tenantId}/settings/session-tag/${sessionTagId}`,options
    );
  }


export const getTenantSessionTagControllerGetSessionTagQueryKey = (tenantId: string,
    sessionTagId: string,) => {
    return [`/v1/${tenantId}/settings/session-tag/${sessionTagId}`] as const;
    }

    
export const getTenantSessionTagControllerGetSessionTagQueryOptions = <TData = Awaited<ReturnType<typeof tenantSessionTagControllerGetSessionTag>>, TError = AxiosError<unknown>>(tenantId: string,
    sessionTagId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantSessionTagControllerGetSessionTag>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantSessionTagControllerGetSessionTagQueryKey(tenantId,sessionTagId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantSessionTagControllerGetSessionTag>>> = ({ signal }) => tenantSessionTagControllerGetSessionTag(tenantId,sessionTagId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && sessionTagId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantSessionTagControllerGetSessionTag>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantSessionTagControllerGetSessionTagQueryResult = NonNullable<Awaited<ReturnType<typeof tenantSessionTagControllerGetSessionTag>>>
export type TenantSessionTagControllerGetSessionTagQueryError = AxiosError<unknown>


export function useTenantSessionTagControllerGetSessionTag<TData = Awaited<ReturnType<typeof tenantSessionTagControllerGetSessionTag>>, TError = AxiosError<unknown>>(
 tenantId: string,
    sessionTagId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantSessionTagControllerGetSessionTag>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantSessionTagControllerGetSessionTag>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantSessionTagControllerGetSessionTag<TData = Awaited<ReturnType<typeof tenantSessionTagControllerGetSessionTag>>, TError = AxiosError<unknown>>(
 tenantId: string,
    sessionTagId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantSessionTagControllerGetSessionTag>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantSessionTagControllerGetSessionTag>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantSessionTagControllerGetSessionTag<TData = Awaited<ReturnType<typeof tenantSessionTagControllerGetSessionTag>>, TError = AxiosError<unknown>>(
 tenantId: string,
    sessionTagId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantSessionTagControllerGetSessionTag>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantSessionTagControllerGetSessionTag<TData = Awaited<ReturnType<typeof tenantSessionTagControllerGetSessionTag>>, TError = AxiosError<unknown>>(
 tenantId: string,
    sessionTagId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantSessionTagControllerGetSessionTag>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantSessionTagControllerGetSessionTagQueryOptions(tenantId,sessionTagId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantSessionTagControllerGetSessionTagSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantSessionTagControllerGetSessionTag>>, TError = AxiosError<unknown>>(tenantId: string,
    sessionTagId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantSessionTagControllerGetSessionTag>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantSessionTagControllerGetSessionTagQueryKey(tenantId,sessionTagId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantSessionTagControllerGetSessionTag>>> = ({ signal }) => tenantSessionTagControllerGetSessionTag(tenantId,sessionTagId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && sessionTagId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantSessionTagControllerGetSessionTag>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantSessionTagControllerGetSessionTagSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantSessionTagControllerGetSessionTag>>>
export type TenantSessionTagControllerGetSessionTagSuspenseQueryError = AxiosError<unknown>


export function useTenantSessionTagControllerGetSessionTagSuspense<TData = Awaited<ReturnType<typeof tenantSessionTagControllerGetSessionTag>>, TError = AxiosError<unknown>>(
 tenantId: string,
    sessionTagId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantSessionTagControllerGetSessionTag>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantSessionTagControllerGetSessionTagSuspense<TData = Awaited<ReturnType<typeof tenantSessionTagControllerGetSessionTag>>, TError = AxiosError<unknown>>(
 tenantId: string,
    sessionTagId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantSessionTagControllerGetSessionTag>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantSessionTagControllerGetSessionTagSuspense<TData = Awaited<ReturnType<typeof tenantSessionTagControllerGetSessionTag>>, TError = AxiosError<unknown>>(
 tenantId: string,
    sessionTagId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantSessionTagControllerGetSessionTag>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantSessionTagControllerGetSessionTagSuspense<TData = Awaited<ReturnType<typeof tenantSessionTagControllerGetSessionTag>>, TError = AxiosError<unknown>>(
 tenantId: string,
    sessionTagId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantSessionTagControllerGetSessionTag>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantSessionTagControllerGetSessionTagSuspenseQueryOptions(tenantId,sessionTagId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantSessionTagControllerUpdateSessionTag = (
    tenantId: string,
    sessionTagId: string,
    tenantSessionTagUpdateDto: TenantSessionTagUpdateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantSessionTagGetDto>> => {
    
    return axios.put(
      `/v1/${tenantId}/settings/session-tag/${sessionTagId}`,
      tenantSessionTagUpdateDto,options
    );
  }



export const getTenantSessionTagControllerUpdateSessionTagMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantSessionTagControllerUpdateSessionTag>>, TError,{tenantId: string;sessionTagId: string;data: TenantSessionTagUpdateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantSessionTagControllerUpdateSessionTag>>, TError,{tenantId: string;sessionTagId: string;data: TenantSessionTagUpdateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantSessionTagControllerUpdateSessionTag>>, {tenantId: string;sessionTagId: string;data: TenantSessionTagUpdateDto}> = (props) => {
          const {tenantId,sessionTagId,data} = props ?? {};

          return  tenantSessionTagControllerUpdateSessionTag(tenantId,sessionTagId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantSessionTagControllerUpdateSessionTagMutationResult = NonNullable<Awaited<ReturnType<typeof tenantSessionTagControllerUpdateSessionTag>>>
    export type TenantSessionTagControllerUpdateSessionTagMutationBody = TenantSessionTagUpdateDto
    export type TenantSessionTagControllerUpdateSessionTagMutationError = AxiosError<unknown>

    export const useTenantSessionTagControllerUpdateSessionTag = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantSessionTagControllerUpdateSessionTag>>, TError,{tenantId: string;sessionTagId: string;data: TenantSessionTagUpdateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantSessionTagControllerUpdateSessionTag>>,
        TError,
        {tenantId: string;sessionTagId: string;data: TenantSessionTagUpdateDto},
        TContext
      > => {

      const mutationOptions = getTenantSessionTagControllerUpdateSessionTagMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantSessionTagControllerDeleteSessionTag = (
    tenantId: string,
    sessionTagId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/v1/${tenantId}/settings/session-tag/${sessionTagId}`,options
    );
  }



export const getTenantSessionTagControllerDeleteSessionTagMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantSessionTagControllerDeleteSessionTag>>, TError,{tenantId: string;sessionTagId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantSessionTagControllerDeleteSessionTag>>, TError,{tenantId: string;sessionTagId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantSessionTagControllerDeleteSessionTag>>, {tenantId: string;sessionTagId: string}> = (props) => {
          const {tenantId,sessionTagId} = props ?? {};

          return  tenantSessionTagControllerDeleteSessionTag(tenantId,sessionTagId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantSessionTagControllerDeleteSessionTagMutationResult = NonNullable<Awaited<ReturnType<typeof tenantSessionTagControllerDeleteSessionTag>>>
    
    export type TenantSessionTagControllerDeleteSessionTagMutationError = AxiosError<unknown>

    export const useTenantSessionTagControllerDeleteSessionTag = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantSessionTagControllerDeleteSessionTag>>, TError,{tenantId: string;sessionTagId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantSessionTagControllerDeleteSessionTag>>,
        TError,
        {tenantId: string;sessionTagId: string},
        TContext
      > => {

      const mutationOptions = getTenantSessionTagControllerDeleteSessionTagMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantExerciseTagControllerGetAllExerciseTags = (
    tenantId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantExerciseTagGetDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/settings/exercise-tag`,options
    );
  }


export const getTenantExerciseTagControllerGetAllExerciseTagsQueryKey = (tenantId: string,) => {
    return [`/v1/${tenantId}/settings/exercise-tag`] as const;
    }

    
export const getTenantExerciseTagControllerGetAllExerciseTagsQueryOptions = <TData = Awaited<ReturnType<typeof tenantExerciseTagControllerGetAllExerciseTags>>, TError = AxiosError<unknown>>(tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantExerciseTagControllerGetAllExerciseTags>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantExerciseTagControllerGetAllExerciseTagsQueryKey(tenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantExerciseTagControllerGetAllExerciseTags>>> = ({ signal }) => tenantExerciseTagControllerGetAllExerciseTags(tenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantExerciseTagControllerGetAllExerciseTags>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantExerciseTagControllerGetAllExerciseTagsQueryResult = NonNullable<Awaited<ReturnType<typeof tenantExerciseTagControllerGetAllExerciseTags>>>
export type TenantExerciseTagControllerGetAllExerciseTagsQueryError = AxiosError<unknown>


export function useTenantExerciseTagControllerGetAllExerciseTags<TData = Awaited<ReturnType<typeof tenantExerciseTagControllerGetAllExerciseTags>>, TError = AxiosError<unknown>>(
 tenantId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantExerciseTagControllerGetAllExerciseTags>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantExerciseTagControllerGetAllExerciseTags>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantExerciseTagControllerGetAllExerciseTags<TData = Awaited<ReturnType<typeof tenantExerciseTagControllerGetAllExerciseTags>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantExerciseTagControllerGetAllExerciseTags>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantExerciseTagControllerGetAllExerciseTags>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantExerciseTagControllerGetAllExerciseTags<TData = Awaited<ReturnType<typeof tenantExerciseTagControllerGetAllExerciseTags>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantExerciseTagControllerGetAllExerciseTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantExerciseTagControllerGetAllExerciseTags<TData = Awaited<ReturnType<typeof tenantExerciseTagControllerGetAllExerciseTags>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantExerciseTagControllerGetAllExerciseTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantExerciseTagControllerGetAllExerciseTagsQueryOptions(tenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantExerciseTagControllerGetAllExerciseTagsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantExerciseTagControllerGetAllExerciseTags>>, TError = AxiosError<unknown>>(tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantExerciseTagControllerGetAllExerciseTags>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantExerciseTagControllerGetAllExerciseTagsQueryKey(tenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantExerciseTagControllerGetAllExerciseTags>>> = ({ signal }) => tenantExerciseTagControllerGetAllExerciseTags(tenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantExerciseTagControllerGetAllExerciseTags>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantExerciseTagControllerGetAllExerciseTagsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantExerciseTagControllerGetAllExerciseTags>>>
export type TenantExerciseTagControllerGetAllExerciseTagsSuspenseQueryError = AxiosError<unknown>


export function useTenantExerciseTagControllerGetAllExerciseTagsSuspense<TData = Awaited<ReturnType<typeof tenantExerciseTagControllerGetAllExerciseTags>>, TError = AxiosError<unknown>>(
 tenantId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantExerciseTagControllerGetAllExerciseTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantExerciseTagControllerGetAllExerciseTagsSuspense<TData = Awaited<ReturnType<typeof tenantExerciseTagControllerGetAllExerciseTags>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantExerciseTagControllerGetAllExerciseTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantExerciseTagControllerGetAllExerciseTagsSuspense<TData = Awaited<ReturnType<typeof tenantExerciseTagControllerGetAllExerciseTags>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantExerciseTagControllerGetAllExerciseTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantExerciseTagControllerGetAllExerciseTagsSuspense<TData = Awaited<ReturnType<typeof tenantExerciseTagControllerGetAllExerciseTags>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantExerciseTagControllerGetAllExerciseTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantExerciseTagControllerGetAllExerciseTagsSuspenseQueryOptions(tenantId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantExerciseTagControllerCreateExerciseTag = (
    tenantId: string,
    tenantExerciseTagCreateDto: TenantExerciseTagCreateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantExerciseTagGetDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/settings/exercise-tag`,
      tenantExerciseTagCreateDto,options
    );
  }



export const getTenantExerciseTagControllerCreateExerciseTagMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantExerciseTagControllerCreateExerciseTag>>, TError,{tenantId: string;data: TenantExerciseTagCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantExerciseTagControllerCreateExerciseTag>>, TError,{tenantId: string;data: TenantExerciseTagCreateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantExerciseTagControllerCreateExerciseTag>>, {tenantId: string;data: TenantExerciseTagCreateDto}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  tenantExerciseTagControllerCreateExerciseTag(tenantId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantExerciseTagControllerCreateExerciseTagMutationResult = NonNullable<Awaited<ReturnType<typeof tenantExerciseTagControllerCreateExerciseTag>>>
    export type TenantExerciseTagControllerCreateExerciseTagMutationBody = TenantExerciseTagCreateDto
    export type TenantExerciseTagControllerCreateExerciseTagMutationError = AxiosError<unknown>

    export const useTenantExerciseTagControllerCreateExerciseTag = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantExerciseTagControllerCreateExerciseTag>>, TError,{tenantId: string;data: TenantExerciseTagCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantExerciseTagControllerCreateExerciseTag>>,
        TError,
        {tenantId: string;data: TenantExerciseTagCreateDto},
        TContext
      > => {

      const mutationOptions = getTenantExerciseTagControllerCreateExerciseTagMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantExerciseTagControllerGetExerciseTag = (
    tenantId: string,
    exerciseTagId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantExerciseTagGetDto>> => {
    
    return axios.get(
      `/v1/${tenantId}/settings/exercise-tag/${exerciseTagId}`,options
    );
  }


export const getTenantExerciseTagControllerGetExerciseTagQueryKey = (tenantId: string,
    exerciseTagId: string,) => {
    return [`/v1/${tenantId}/settings/exercise-tag/${exerciseTagId}`] as const;
    }

    
export const getTenantExerciseTagControllerGetExerciseTagQueryOptions = <TData = Awaited<ReturnType<typeof tenantExerciseTagControllerGetExerciseTag>>, TError = AxiosError<unknown>>(tenantId: string,
    exerciseTagId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantExerciseTagControllerGetExerciseTag>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantExerciseTagControllerGetExerciseTagQueryKey(tenantId,exerciseTagId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantExerciseTagControllerGetExerciseTag>>> = ({ signal }) => tenantExerciseTagControllerGetExerciseTag(tenantId,exerciseTagId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && exerciseTagId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantExerciseTagControllerGetExerciseTag>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantExerciseTagControllerGetExerciseTagQueryResult = NonNullable<Awaited<ReturnType<typeof tenantExerciseTagControllerGetExerciseTag>>>
export type TenantExerciseTagControllerGetExerciseTagQueryError = AxiosError<unknown>


export function useTenantExerciseTagControllerGetExerciseTag<TData = Awaited<ReturnType<typeof tenantExerciseTagControllerGetExerciseTag>>, TError = AxiosError<unknown>>(
 tenantId: string,
    exerciseTagId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantExerciseTagControllerGetExerciseTag>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantExerciseTagControllerGetExerciseTag>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantExerciseTagControllerGetExerciseTag<TData = Awaited<ReturnType<typeof tenantExerciseTagControllerGetExerciseTag>>, TError = AxiosError<unknown>>(
 tenantId: string,
    exerciseTagId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantExerciseTagControllerGetExerciseTag>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantExerciseTagControllerGetExerciseTag>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantExerciseTagControllerGetExerciseTag<TData = Awaited<ReturnType<typeof tenantExerciseTagControllerGetExerciseTag>>, TError = AxiosError<unknown>>(
 tenantId: string,
    exerciseTagId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantExerciseTagControllerGetExerciseTag>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantExerciseTagControllerGetExerciseTag<TData = Awaited<ReturnType<typeof tenantExerciseTagControllerGetExerciseTag>>, TError = AxiosError<unknown>>(
 tenantId: string,
    exerciseTagId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantExerciseTagControllerGetExerciseTag>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantExerciseTagControllerGetExerciseTagQueryOptions(tenantId,exerciseTagId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantExerciseTagControllerGetExerciseTagSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantExerciseTagControllerGetExerciseTag>>, TError = AxiosError<unknown>>(tenantId: string,
    exerciseTagId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantExerciseTagControllerGetExerciseTag>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantExerciseTagControllerGetExerciseTagQueryKey(tenantId,exerciseTagId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantExerciseTagControllerGetExerciseTag>>> = ({ signal }) => tenantExerciseTagControllerGetExerciseTag(tenantId,exerciseTagId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && exerciseTagId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantExerciseTagControllerGetExerciseTag>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantExerciseTagControllerGetExerciseTagSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantExerciseTagControllerGetExerciseTag>>>
export type TenantExerciseTagControllerGetExerciseTagSuspenseQueryError = AxiosError<unknown>


export function useTenantExerciseTagControllerGetExerciseTagSuspense<TData = Awaited<ReturnType<typeof tenantExerciseTagControllerGetExerciseTag>>, TError = AxiosError<unknown>>(
 tenantId: string,
    exerciseTagId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantExerciseTagControllerGetExerciseTag>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantExerciseTagControllerGetExerciseTagSuspense<TData = Awaited<ReturnType<typeof tenantExerciseTagControllerGetExerciseTag>>, TError = AxiosError<unknown>>(
 tenantId: string,
    exerciseTagId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantExerciseTagControllerGetExerciseTag>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantExerciseTagControllerGetExerciseTagSuspense<TData = Awaited<ReturnType<typeof tenantExerciseTagControllerGetExerciseTag>>, TError = AxiosError<unknown>>(
 tenantId: string,
    exerciseTagId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantExerciseTagControllerGetExerciseTag>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantExerciseTagControllerGetExerciseTagSuspense<TData = Awaited<ReturnType<typeof tenantExerciseTagControllerGetExerciseTag>>, TError = AxiosError<unknown>>(
 tenantId: string,
    exerciseTagId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantExerciseTagControllerGetExerciseTag>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantExerciseTagControllerGetExerciseTagSuspenseQueryOptions(tenantId,exerciseTagId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantExerciseTagControllerUpdateExerciseTag = (
    tenantId: string,
    exerciseTagId: string,
    tenantExerciseTagUpdateDto: TenantExerciseTagUpdateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantExerciseTagGetDto>> => {
    
    return axios.put(
      `/v1/${tenantId}/settings/exercise-tag/${exerciseTagId}`,
      tenantExerciseTagUpdateDto,options
    );
  }



export const getTenantExerciseTagControllerUpdateExerciseTagMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantExerciseTagControllerUpdateExerciseTag>>, TError,{tenantId: string;exerciseTagId: string;data: TenantExerciseTagUpdateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantExerciseTagControllerUpdateExerciseTag>>, TError,{tenantId: string;exerciseTagId: string;data: TenantExerciseTagUpdateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantExerciseTagControllerUpdateExerciseTag>>, {tenantId: string;exerciseTagId: string;data: TenantExerciseTagUpdateDto}> = (props) => {
          const {tenantId,exerciseTagId,data} = props ?? {};

          return  tenantExerciseTagControllerUpdateExerciseTag(tenantId,exerciseTagId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantExerciseTagControllerUpdateExerciseTagMutationResult = NonNullable<Awaited<ReturnType<typeof tenantExerciseTagControllerUpdateExerciseTag>>>
    export type TenantExerciseTagControllerUpdateExerciseTagMutationBody = TenantExerciseTagUpdateDto
    export type TenantExerciseTagControllerUpdateExerciseTagMutationError = AxiosError<unknown>

    export const useTenantExerciseTagControllerUpdateExerciseTag = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantExerciseTagControllerUpdateExerciseTag>>, TError,{tenantId: string;exerciseTagId: string;data: TenantExerciseTagUpdateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantExerciseTagControllerUpdateExerciseTag>>,
        TError,
        {tenantId: string;exerciseTagId: string;data: TenantExerciseTagUpdateDto},
        TContext
      > => {

      const mutationOptions = getTenantExerciseTagControllerUpdateExerciseTagMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantExerciseTagControllerDeleteExerciseTag = (
    tenantId: string,
    exerciseTagId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/v1/${tenantId}/settings/exercise-tag/${exerciseTagId}`,options
    );
  }



export const getTenantExerciseTagControllerDeleteExerciseTagMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantExerciseTagControllerDeleteExerciseTag>>, TError,{tenantId: string;exerciseTagId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantExerciseTagControllerDeleteExerciseTag>>, TError,{tenantId: string;exerciseTagId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantExerciseTagControllerDeleteExerciseTag>>, {tenantId: string;exerciseTagId: string}> = (props) => {
          const {tenantId,exerciseTagId} = props ?? {};

          return  tenantExerciseTagControllerDeleteExerciseTag(tenantId,exerciseTagId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantExerciseTagControllerDeleteExerciseTagMutationResult = NonNullable<Awaited<ReturnType<typeof tenantExerciseTagControllerDeleteExerciseTag>>>
    
    export type TenantExerciseTagControllerDeleteExerciseTagMutationError = AxiosError<unknown>

    export const useTenantExerciseTagControllerDeleteExerciseTag = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantExerciseTagControllerDeleteExerciseTag>>, TError,{tenantId: string;exerciseTagId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantExerciseTagControllerDeleteExerciseTag>>,
        TError,
        {tenantId: string;exerciseTagId: string},
        TContext
      > => {

      const mutationOptions = getTenantExerciseTagControllerDeleteExerciseTagMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantDemoControllerGetTenantDemoSettings = (
    tenantId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantDemoSettingsDto>> => {
    
    return axios.get(
      `/v1/${tenantId}/settings/demo`,options
    );
  }


export const getTenantDemoControllerGetTenantDemoSettingsQueryKey = (tenantId: string,) => {
    return [`/v1/${tenantId}/settings/demo`] as const;
    }

    
export const getTenantDemoControllerGetTenantDemoSettingsQueryOptions = <TData = Awaited<ReturnType<typeof tenantDemoControllerGetTenantDemoSettings>>, TError = AxiosError<unknown>>(tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantDemoControllerGetTenantDemoSettings>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantDemoControllerGetTenantDemoSettingsQueryKey(tenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantDemoControllerGetTenantDemoSettings>>> = ({ signal }) => tenantDemoControllerGetTenantDemoSettings(tenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantDemoControllerGetTenantDemoSettings>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantDemoControllerGetTenantDemoSettingsQueryResult = NonNullable<Awaited<ReturnType<typeof tenantDemoControllerGetTenantDemoSettings>>>
export type TenantDemoControllerGetTenantDemoSettingsQueryError = AxiosError<unknown>


export function useTenantDemoControllerGetTenantDemoSettings<TData = Awaited<ReturnType<typeof tenantDemoControllerGetTenantDemoSettings>>, TError = AxiosError<unknown>>(
 tenantId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantDemoControllerGetTenantDemoSettings>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantDemoControllerGetTenantDemoSettings>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantDemoControllerGetTenantDemoSettings<TData = Awaited<ReturnType<typeof tenantDemoControllerGetTenantDemoSettings>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantDemoControllerGetTenantDemoSettings>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantDemoControllerGetTenantDemoSettings>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantDemoControllerGetTenantDemoSettings<TData = Awaited<ReturnType<typeof tenantDemoControllerGetTenantDemoSettings>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantDemoControllerGetTenantDemoSettings>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantDemoControllerGetTenantDemoSettings<TData = Awaited<ReturnType<typeof tenantDemoControllerGetTenantDemoSettings>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantDemoControllerGetTenantDemoSettings>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantDemoControllerGetTenantDemoSettingsQueryOptions(tenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantDemoControllerGetTenantDemoSettingsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantDemoControllerGetTenantDemoSettings>>, TError = AxiosError<unknown>>(tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantDemoControllerGetTenantDemoSettings>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantDemoControllerGetTenantDemoSettingsQueryKey(tenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantDemoControllerGetTenantDemoSettings>>> = ({ signal }) => tenantDemoControllerGetTenantDemoSettings(tenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantDemoControllerGetTenantDemoSettings>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantDemoControllerGetTenantDemoSettingsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantDemoControllerGetTenantDemoSettings>>>
export type TenantDemoControllerGetTenantDemoSettingsSuspenseQueryError = AxiosError<unknown>


export function useTenantDemoControllerGetTenantDemoSettingsSuspense<TData = Awaited<ReturnType<typeof tenantDemoControllerGetTenantDemoSettings>>, TError = AxiosError<unknown>>(
 tenantId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantDemoControllerGetTenantDemoSettings>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantDemoControllerGetTenantDemoSettingsSuspense<TData = Awaited<ReturnType<typeof tenantDemoControllerGetTenantDemoSettings>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantDemoControllerGetTenantDemoSettings>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantDemoControllerGetTenantDemoSettingsSuspense<TData = Awaited<ReturnType<typeof tenantDemoControllerGetTenantDemoSettings>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantDemoControllerGetTenantDemoSettings>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantDemoControllerGetTenantDemoSettingsSuspense<TData = Awaited<ReturnType<typeof tenantDemoControllerGetTenantDemoSettings>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantDemoControllerGetTenantDemoSettings>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantDemoControllerGetTenantDemoSettingsSuspenseQueryOptions(tenantId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantEmployeeQualificationControllerGetAllEmployeeQualifications = (
    tenantId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantEmployeeQualificationGetDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/settings/employee-qualification`,options
    );
  }


export const getTenantEmployeeQualificationControllerGetAllEmployeeQualificationsQueryKey = (tenantId: string,) => {
    return [`/v1/${tenantId}/settings/employee-qualification`] as const;
    }

    
export const getTenantEmployeeQualificationControllerGetAllEmployeeQualificationsQueryOptions = <TData = Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetAllEmployeeQualifications>>, TError = AxiosError<unknown>>(tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetAllEmployeeQualifications>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantEmployeeQualificationControllerGetAllEmployeeQualificationsQueryKey(tenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetAllEmployeeQualifications>>> = ({ signal }) => tenantEmployeeQualificationControllerGetAllEmployeeQualifications(tenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetAllEmployeeQualifications>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantEmployeeQualificationControllerGetAllEmployeeQualificationsQueryResult = NonNullable<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetAllEmployeeQualifications>>>
export type TenantEmployeeQualificationControllerGetAllEmployeeQualificationsQueryError = AxiosError<unknown>


export function useTenantEmployeeQualificationControllerGetAllEmployeeQualifications<TData = Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetAllEmployeeQualifications>>, TError = AxiosError<unknown>>(
 tenantId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetAllEmployeeQualifications>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetAllEmployeeQualifications>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEmployeeQualificationControllerGetAllEmployeeQualifications<TData = Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetAllEmployeeQualifications>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetAllEmployeeQualifications>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetAllEmployeeQualifications>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEmployeeQualificationControllerGetAllEmployeeQualifications<TData = Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetAllEmployeeQualifications>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetAllEmployeeQualifications>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantEmployeeQualificationControllerGetAllEmployeeQualifications<TData = Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetAllEmployeeQualifications>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetAllEmployeeQualifications>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantEmployeeQualificationControllerGetAllEmployeeQualificationsQueryOptions(tenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantEmployeeQualificationControllerGetAllEmployeeQualificationsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetAllEmployeeQualifications>>, TError = AxiosError<unknown>>(tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetAllEmployeeQualifications>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantEmployeeQualificationControllerGetAllEmployeeQualificationsQueryKey(tenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetAllEmployeeQualifications>>> = ({ signal }) => tenantEmployeeQualificationControllerGetAllEmployeeQualifications(tenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetAllEmployeeQualifications>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantEmployeeQualificationControllerGetAllEmployeeQualificationsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetAllEmployeeQualifications>>>
export type TenantEmployeeQualificationControllerGetAllEmployeeQualificationsSuspenseQueryError = AxiosError<unknown>


export function useTenantEmployeeQualificationControllerGetAllEmployeeQualificationsSuspense<TData = Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetAllEmployeeQualifications>>, TError = AxiosError<unknown>>(
 tenantId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetAllEmployeeQualifications>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEmployeeQualificationControllerGetAllEmployeeQualificationsSuspense<TData = Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetAllEmployeeQualifications>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetAllEmployeeQualifications>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEmployeeQualificationControllerGetAllEmployeeQualificationsSuspense<TData = Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetAllEmployeeQualifications>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetAllEmployeeQualifications>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantEmployeeQualificationControllerGetAllEmployeeQualificationsSuspense<TData = Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetAllEmployeeQualifications>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetAllEmployeeQualifications>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantEmployeeQualificationControllerGetAllEmployeeQualificationsSuspenseQueryOptions(tenantId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantEmployeeQualificationControllerCreateEmployeeQualification = (
    tenantId: string,
    tenantEmployeeQualificationCreateDto: TenantEmployeeQualificationCreateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantEmployeeQualificationGetDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/settings/employee-qualification`,
      tenantEmployeeQualificationCreateDto,options
    );
  }



export const getTenantEmployeeQualificationControllerCreateEmployeeQualificationMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerCreateEmployeeQualification>>, TError,{tenantId: string;data: TenantEmployeeQualificationCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerCreateEmployeeQualification>>, TError,{tenantId: string;data: TenantEmployeeQualificationCreateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerCreateEmployeeQualification>>, {tenantId: string;data: TenantEmployeeQualificationCreateDto}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  tenantEmployeeQualificationControllerCreateEmployeeQualification(tenantId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantEmployeeQualificationControllerCreateEmployeeQualificationMutationResult = NonNullable<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerCreateEmployeeQualification>>>
    export type TenantEmployeeQualificationControllerCreateEmployeeQualificationMutationBody = TenantEmployeeQualificationCreateDto
    export type TenantEmployeeQualificationControllerCreateEmployeeQualificationMutationError = AxiosError<unknown>

    export const useTenantEmployeeQualificationControllerCreateEmployeeQualification = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerCreateEmployeeQualification>>, TError,{tenantId: string;data: TenantEmployeeQualificationCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantEmployeeQualificationControllerCreateEmployeeQualification>>,
        TError,
        {tenantId: string;data: TenantEmployeeQualificationCreateDto},
        TContext
      > => {

      const mutationOptions = getTenantEmployeeQualificationControllerCreateEmployeeQualificationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantEmployeeQualificationControllerGetEmployeeQualification = (
    tenantId: string,
    employeeQualificationId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantEmployeeQualificationGetDto>> => {
    
    return axios.get(
      `/v1/${tenantId}/settings/employee-qualification/${employeeQualificationId}`,options
    );
  }


export const getTenantEmployeeQualificationControllerGetEmployeeQualificationQueryKey = (tenantId: string,
    employeeQualificationId: string,) => {
    return [`/v1/${tenantId}/settings/employee-qualification/${employeeQualificationId}`] as const;
    }

    
export const getTenantEmployeeQualificationControllerGetEmployeeQualificationQueryOptions = <TData = Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetEmployeeQualification>>, TError = AxiosError<unknown>>(tenantId: string,
    employeeQualificationId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetEmployeeQualification>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantEmployeeQualificationControllerGetEmployeeQualificationQueryKey(tenantId,employeeQualificationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetEmployeeQualification>>> = ({ signal }) => tenantEmployeeQualificationControllerGetEmployeeQualification(tenantId,employeeQualificationId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && employeeQualificationId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetEmployeeQualification>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantEmployeeQualificationControllerGetEmployeeQualificationQueryResult = NonNullable<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetEmployeeQualification>>>
export type TenantEmployeeQualificationControllerGetEmployeeQualificationQueryError = AxiosError<unknown>


export function useTenantEmployeeQualificationControllerGetEmployeeQualification<TData = Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetEmployeeQualification>>, TError = AxiosError<unknown>>(
 tenantId: string,
    employeeQualificationId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetEmployeeQualification>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetEmployeeQualification>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEmployeeQualificationControllerGetEmployeeQualification<TData = Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetEmployeeQualification>>, TError = AxiosError<unknown>>(
 tenantId: string,
    employeeQualificationId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetEmployeeQualification>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetEmployeeQualification>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEmployeeQualificationControllerGetEmployeeQualification<TData = Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetEmployeeQualification>>, TError = AxiosError<unknown>>(
 tenantId: string,
    employeeQualificationId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetEmployeeQualification>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantEmployeeQualificationControllerGetEmployeeQualification<TData = Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetEmployeeQualification>>, TError = AxiosError<unknown>>(
 tenantId: string,
    employeeQualificationId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetEmployeeQualification>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantEmployeeQualificationControllerGetEmployeeQualificationQueryOptions(tenantId,employeeQualificationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantEmployeeQualificationControllerGetEmployeeQualificationSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetEmployeeQualification>>, TError = AxiosError<unknown>>(tenantId: string,
    employeeQualificationId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetEmployeeQualification>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantEmployeeQualificationControllerGetEmployeeQualificationQueryKey(tenantId,employeeQualificationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetEmployeeQualification>>> = ({ signal }) => tenantEmployeeQualificationControllerGetEmployeeQualification(tenantId,employeeQualificationId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && employeeQualificationId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetEmployeeQualification>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantEmployeeQualificationControllerGetEmployeeQualificationSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetEmployeeQualification>>>
export type TenantEmployeeQualificationControllerGetEmployeeQualificationSuspenseQueryError = AxiosError<unknown>


export function useTenantEmployeeQualificationControllerGetEmployeeQualificationSuspense<TData = Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetEmployeeQualification>>, TError = AxiosError<unknown>>(
 tenantId: string,
    employeeQualificationId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetEmployeeQualification>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEmployeeQualificationControllerGetEmployeeQualificationSuspense<TData = Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetEmployeeQualification>>, TError = AxiosError<unknown>>(
 tenantId: string,
    employeeQualificationId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetEmployeeQualification>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEmployeeQualificationControllerGetEmployeeQualificationSuspense<TData = Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetEmployeeQualification>>, TError = AxiosError<unknown>>(
 tenantId: string,
    employeeQualificationId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetEmployeeQualification>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantEmployeeQualificationControllerGetEmployeeQualificationSuspense<TData = Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetEmployeeQualification>>, TError = AxiosError<unknown>>(
 tenantId: string,
    employeeQualificationId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerGetEmployeeQualification>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantEmployeeQualificationControllerGetEmployeeQualificationSuspenseQueryOptions(tenantId,employeeQualificationId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantEmployeeQualificationControllerUpdateEmployeeQualification = (
    tenantId: string,
    employeeQualificationId: string,
    tenantEmployeeQualificationPatchDto: TenantEmployeeQualificationPatchDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantEmployeeQualificationGetDto>> => {
    
    return axios.patch(
      `/v1/${tenantId}/settings/employee-qualification/${employeeQualificationId}`,
      tenantEmployeeQualificationPatchDto,options
    );
  }



export const getTenantEmployeeQualificationControllerUpdateEmployeeQualificationMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerUpdateEmployeeQualification>>, TError,{tenantId: string;employeeQualificationId: string;data: TenantEmployeeQualificationPatchDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerUpdateEmployeeQualification>>, TError,{tenantId: string;employeeQualificationId: string;data: TenantEmployeeQualificationPatchDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerUpdateEmployeeQualification>>, {tenantId: string;employeeQualificationId: string;data: TenantEmployeeQualificationPatchDto}> = (props) => {
          const {tenantId,employeeQualificationId,data} = props ?? {};

          return  tenantEmployeeQualificationControllerUpdateEmployeeQualification(tenantId,employeeQualificationId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantEmployeeQualificationControllerUpdateEmployeeQualificationMutationResult = NonNullable<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerUpdateEmployeeQualification>>>
    export type TenantEmployeeQualificationControllerUpdateEmployeeQualificationMutationBody = TenantEmployeeQualificationPatchDto
    export type TenantEmployeeQualificationControllerUpdateEmployeeQualificationMutationError = AxiosError<unknown>

    export const useTenantEmployeeQualificationControllerUpdateEmployeeQualification = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerUpdateEmployeeQualification>>, TError,{tenantId: string;employeeQualificationId: string;data: TenantEmployeeQualificationPatchDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantEmployeeQualificationControllerUpdateEmployeeQualification>>,
        TError,
        {tenantId: string;employeeQualificationId: string;data: TenantEmployeeQualificationPatchDto},
        TContext
      > => {

      const mutationOptions = getTenantEmployeeQualificationControllerUpdateEmployeeQualificationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantEmployeeQualificationControllerDeleteEmployeeQualification = (
    tenantId: string,
    employeeQualificationId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/v1/${tenantId}/settings/employee-qualification/${employeeQualificationId}`,options
    );
  }



export const getTenantEmployeeQualificationControllerDeleteEmployeeQualificationMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerDeleteEmployeeQualification>>, TError,{tenantId: string;employeeQualificationId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerDeleteEmployeeQualification>>, TError,{tenantId: string;employeeQualificationId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerDeleteEmployeeQualification>>, {tenantId: string;employeeQualificationId: string}> = (props) => {
          const {tenantId,employeeQualificationId} = props ?? {};

          return  tenantEmployeeQualificationControllerDeleteEmployeeQualification(tenantId,employeeQualificationId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantEmployeeQualificationControllerDeleteEmployeeQualificationMutationResult = NonNullable<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerDeleteEmployeeQualification>>>
    
    export type TenantEmployeeQualificationControllerDeleteEmployeeQualificationMutationError = AxiosError<unknown>

    export const useTenantEmployeeQualificationControllerDeleteEmployeeQualification = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeQualificationControllerDeleteEmployeeQualification>>, TError,{tenantId: string;employeeQualificationId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantEmployeeQualificationControllerDeleteEmployeeQualification>>,
        TError,
        {tenantId: string;employeeQualificationId: string},
        TContext
      > => {

      const mutationOptions = getTenantEmployeeQualificationControllerDeleteEmployeeQualificationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantEmployeeDepartmentControllerGetAllEmployeeDepartments = (
    tenantId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantEmployeeDepartmentGetDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/settings/employee-department`,options
    );
  }


export const getTenantEmployeeDepartmentControllerGetAllEmployeeDepartmentsQueryKey = (tenantId: string,) => {
    return [`/v1/${tenantId}/settings/employee-department`] as const;
    }

    
export const getTenantEmployeeDepartmentControllerGetAllEmployeeDepartmentsQueryOptions = <TData = Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetAllEmployeeDepartments>>, TError = AxiosError<unknown>>(tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetAllEmployeeDepartments>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantEmployeeDepartmentControllerGetAllEmployeeDepartmentsQueryKey(tenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetAllEmployeeDepartments>>> = ({ signal }) => tenantEmployeeDepartmentControllerGetAllEmployeeDepartments(tenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetAllEmployeeDepartments>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantEmployeeDepartmentControllerGetAllEmployeeDepartmentsQueryResult = NonNullable<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetAllEmployeeDepartments>>>
export type TenantEmployeeDepartmentControllerGetAllEmployeeDepartmentsQueryError = AxiosError<unknown>


export function useTenantEmployeeDepartmentControllerGetAllEmployeeDepartments<TData = Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetAllEmployeeDepartments>>, TError = AxiosError<unknown>>(
 tenantId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetAllEmployeeDepartments>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetAllEmployeeDepartments>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEmployeeDepartmentControllerGetAllEmployeeDepartments<TData = Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetAllEmployeeDepartments>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetAllEmployeeDepartments>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetAllEmployeeDepartments>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEmployeeDepartmentControllerGetAllEmployeeDepartments<TData = Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetAllEmployeeDepartments>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetAllEmployeeDepartments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantEmployeeDepartmentControllerGetAllEmployeeDepartments<TData = Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetAllEmployeeDepartments>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetAllEmployeeDepartments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantEmployeeDepartmentControllerGetAllEmployeeDepartmentsQueryOptions(tenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantEmployeeDepartmentControllerGetAllEmployeeDepartmentsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetAllEmployeeDepartments>>, TError = AxiosError<unknown>>(tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetAllEmployeeDepartments>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantEmployeeDepartmentControllerGetAllEmployeeDepartmentsQueryKey(tenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetAllEmployeeDepartments>>> = ({ signal }) => tenantEmployeeDepartmentControllerGetAllEmployeeDepartments(tenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetAllEmployeeDepartments>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantEmployeeDepartmentControllerGetAllEmployeeDepartmentsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetAllEmployeeDepartments>>>
export type TenantEmployeeDepartmentControllerGetAllEmployeeDepartmentsSuspenseQueryError = AxiosError<unknown>


export function useTenantEmployeeDepartmentControllerGetAllEmployeeDepartmentsSuspense<TData = Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetAllEmployeeDepartments>>, TError = AxiosError<unknown>>(
 tenantId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetAllEmployeeDepartments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEmployeeDepartmentControllerGetAllEmployeeDepartmentsSuspense<TData = Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetAllEmployeeDepartments>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetAllEmployeeDepartments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEmployeeDepartmentControllerGetAllEmployeeDepartmentsSuspense<TData = Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetAllEmployeeDepartments>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetAllEmployeeDepartments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantEmployeeDepartmentControllerGetAllEmployeeDepartmentsSuspense<TData = Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetAllEmployeeDepartments>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetAllEmployeeDepartments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantEmployeeDepartmentControllerGetAllEmployeeDepartmentsSuspenseQueryOptions(tenantId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantEmployeeDepartmentControllerCreateEmployeeDepartment = (
    tenantId: string,
    tenantEmployeeDepartmentCreateDto: TenantEmployeeDepartmentCreateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantEmployeeDepartmentGetDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/settings/employee-department`,
      tenantEmployeeDepartmentCreateDto,options
    );
  }



export const getTenantEmployeeDepartmentControllerCreateEmployeeDepartmentMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerCreateEmployeeDepartment>>, TError,{tenantId: string;data: TenantEmployeeDepartmentCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerCreateEmployeeDepartment>>, TError,{tenantId: string;data: TenantEmployeeDepartmentCreateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerCreateEmployeeDepartment>>, {tenantId: string;data: TenantEmployeeDepartmentCreateDto}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  tenantEmployeeDepartmentControllerCreateEmployeeDepartment(tenantId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantEmployeeDepartmentControllerCreateEmployeeDepartmentMutationResult = NonNullable<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerCreateEmployeeDepartment>>>
    export type TenantEmployeeDepartmentControllerCreateEmployeeDepartmentMutationBody = TenantEmployeeDepartmentCreateDto
    export type TenantEmployeeDepartmentControllerCreateEmployeeDepartmentMutationError = AxiosError<unknown>

    export const useTenantEmployeeDepartmentControllerCreateEmployeeDepartment = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerCreateEmployeeDepartment>>, TError,{tenantId: string;data: TenantEmployeeDepartmentCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerCreateEmployeeDepartment>>,
        TError,
        {tenantId: string;data: TenantEmployeeDepartmentCreateDto},
        TContext
      > => {

      const mutationOptions = getTenantEmployeeDepartmentControllerCreateEmployeeDepartmentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantEmployeeDepartmentControllerGetEmployeeDepartment = (
    tenantId: string,
    employeeDepartmentId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantEmployeeDepartmentGetDto>> => {
    
    return axios.get(
      `/v1/${tenantId}/settings/employee-department/${employeeDepartmentId}`,options
    );
  }


export const getTenantEmployeeDepartmentControllerGetEmployeeDepartmentQueryKey = (tenantId: string,
    employeeDepartmentId: string,) => {
    return [`/v1/${tenantId}/settings/employee-department/${employeeDepartmentId}`] as const;
    }

    
export const getTenantEmployeeDepartmentControllerGetEmployeeDepartmentQueryOptions = <TData = Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetEmployeeDepartment>>, TError = AxiosError<unknown>>(tenantId: string,
    employeeDepartmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetEmployeeDepartment>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantEmployeeDepartmentControllerGetEmployeeDepartmentQueryKey(tenantId,employeeDepartmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetEmployeeDepartment>>> = ({ signal }) => tenantEmployeeDepartmentControllerGetEmployeeDepartment(tenantId,employeeDepartmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && employeeDepartmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetEmployeeDepartment>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantEmployeeDepartmentControllerGetEmployeeDepartmentQueryResult = NonNullable<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetEmployeeDepartment>>>
export type TenantEmployeeDepartmentControllerGetEmployeeDepartmentQueryError = AxiosError<unknown>


export function useTenantEmployeeDepartmentControllerGetEmployeeDepartment<TData = Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetEmployeeDepartment>>, TError = AxiosError<unknown>>(
 tenantId: string,
    employeeDepartmentId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetEmployeeDepartment>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetEmployeeDepartment>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEmployeeDepartmentControllerGetEmployeeDepartment<TData = Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetEmployeeDepartment>>, TError = AxiosError<unknown>>(
 tenantId: string,
    employeeDepartmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetEmployeeDepartment>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetEmployeeDepartment>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEmployeeDepartmentControllerGetEmployeeDepartment<TData = Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetEmployeeDepartment>>, TError = AxiosError<unknown>>(
 tenantId: string,
    employeeDepartmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetEmployeeDepartment>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantEmployeeDepartmentControllerGetEmployeeDepartment<TData = Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetEmployeeDepartment>>, TError = AxiosError<unknown>>(
 tenantId: string,
    employeeDepartmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetEmployeeDepartment>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantEmployeeDepartmentControllerGetEmployeeDepartmentQueryOptions(tenantId,employeeDepartmentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantEmployeeDepartmentControllerGetEmployeeDepartmentSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetEmployeeDepartment>>, TError = AxiosError<unknown>>(tenantId: string,
    employeeDepartmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetEmployeeDepartment>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantEmployeeDepartmentControllerGetEmployeeDepartmentQueryKey(tenantId,employeeDepartmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetEmployeeDepartment>>> = ({ signal }) => tenantEmployeeDepartmentControllerGetEmployeeDepartment(tenantId,employeeDepartmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && employeeDepartmentId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetEmployeeDepartment>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantEmployeeDepartmentControllerGetEmployeeDepartmentSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetEmployeeDepartment>>>
export type TenantEmployeeDepartmentControllerGetEmployeeDepartmentSuspenseQueryError = AxiosError<unknown>


export function useTenantEmployeeDepartmentControllerGetEmployeeDepartmentSuspense<TData = Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetEmployeeDepartment>>, TError = AxiosError<unknown>>(
 tenantId: string,
    employeeDepartmentId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetEmployeeDepartment>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEmployeeDepartmentControllerGetEmployeeDepartmentSuspense<TData = Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetEmployeeDepartment>>, TError = AxiosError<unknown>>(
 tenantId: string,
    employeeDepartmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetEmployeeDepartment>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEmployeeDepartmentControllerGetEmployeeDepartmentSuspense<TData = Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetEmployeeDepartment>>, TError = AxiosError<unknown>>(
 tenantId: string,
    employeeDepartmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetEmployeeDepartment>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantEmployeeDepartmentControllerGetEmployeeDepartmentSuspense<TData = Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetEmployeeDepartment>>, TError = AxiosError<unknown>>(
 tenantId: string,
    employeeDepartmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerGetEmployeeDepartment>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantEmployeeDepartmentControllerGetEmployeeDepartmentSuspenseQueryOptions(tenantId,employeeDepartmentId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantEmployeeDepartmentControllerUpdateEmployeeDepartment = (
    tenantId: string,
    employeeDepartmentId: string,
    tenantEmployeeDepartmentPatchDto: TenantEmployeeDepartmentPatchDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantEmployeeDepartmentGetDto>> => {
    
    return axios.patch(
      `/v1/${tenantId}/settings/employee-department/${employeeDepartmentId}`,
      tenantEmployeeDepartmentPatchDto,options
    );
  }



export const getTenantEmployeeDepartmentControllerUpdateEmployeeDepartmentMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerUpdateEmployeeDepartment>>, TError,{tenantId: string;employeeDepartmentId: string;data: TenantEmployeeDepartmentPatchDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerUpdateEmployeeDepartment>>, TError,{tenantId: string;employeeDepartmentId: string;data: TenantEmployeeDepartmentPatchDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerUpdateEmployeeDepartment>>, {tenantId: string;employeeDepartmentId: string;data: TenantEmployeeDepartmentPatchDto}> = (props) => {
          const {tenantId,employeeDepartmentId,data} = props ?? {};

          return  tenantEmployeeDepartmentControllerUpdateEmployeeDepartment(tenantId,employeeDepartmentId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantEmployeeDepartmentControllerUpdateEmployeeDepartmentMutationResult = NonNullable<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerUpdateEmployeeDepartment>>>
    export type TenantEmployeeDepartmentControllerUpdateEmployeeDepartmentMutationBody = TenantEmployeeDepartmentPatchDto
    export type TenantEmployeeDepartmentControllerUpdateEmployeeDepartmentMutationError = AxiosError<unknown>

    export const useTenantEmployeeDepartmentControllerUpdateEmployeeDepartment = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerUpdateEmployeeDepartment>>, TError,{tenantId: string;employeeDepartmentId: string;data: TenantEmployeeDepartmentPatchDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerUpdateEmployeeDepartment>>,
        TError,
        {tenantId: string;employeeDepartmentId: string;data: TenantEmployeeDepartmentPatchDto},
        TContext
      > => {

      const mutationOptions = getTenantEmployeeDepartmentControllerUpdateEmployeeDepartmentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantEmployeeDepartmentControllerDeleteEmployeeDepartment = (
    tenantId: string,
    employeeDepartmentId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/v1/${tenantId}/settings/employee-department/${employeeDepartmentId}`,options
    );
  }



export const getTenantEmployeeDepartmentControllerDeleteEmployeeDepartmentMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerDeleteEmployeeDepartment>>, TError,{tenantId: string;employeeDepartmentId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerDeleteEmployeeDepartment>>, TError,{tenantId: string;employeeDepartmentId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerDeleteEmployeeDepartment>>, {tenantId: string;employeeDepartmentId: string}> = (props) => {
          const {tenantId,employeeDepartmentId} = props ?? {};

          return  tenantEmployeeDepartmentControllerDeleteEmployeeDepartment(tenantId,employeeDepartmentId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantEmployeeDepartmentControllerDeleteEmployeeDepartmentMutationResult = NonNullable<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerDeleteEmployeeDepartment>>>
    
    export type TenantEmployeeDepartmentControllerDeleteEmployeeDepartmentMutationError = AxiosError<unknown>

    export const useTenantEmployeeDepartmentControllerDeleteEmployeeDepartment = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerDeleteEmployeeDepartment>>, TError,{tenantId: string;employeeDepartmentId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantEmployeeDepartmentControllerDeleteEmployeeDepartment>>,
        TError,
        {tenantId: string;employeeDepartmentId: string},
        TContext
      > => {

      const mutationOptions = getTenantEmployeeDepartmentControllerDeleteEmployeeDepartmentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantDocumentCategoryControllerGetAllDocumentCategorys = (
    tenantId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantDocumentCategoryGetDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/settings/document-category`,options
    );
  }


export const getTenantDocumentCategoryControllerGetAllDocumentCategorysQueryKey = (tenantId: string,) => {
    return [`/v1/${tenantId}/settings/document-category`] as const;
    }

    
export const getTenantDocumentCategoryControllerGetAllDocumentCategorysQueryOptions = <TData = Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetAllDocumentCategorys>>, TError = AxiosError<unknown>>(tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetAllDocumentCategorys>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantDocumentCategoryControllerGetAllDocumentCategorysQueryKey(tenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetAllDocumentCategorys>>> = ({ signal }) => tenantDocumentCategoryControllerGetAllDocumentCategorys(tenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetAllDocumentCategorys>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantDocumentCategoryControllerGetAllDocumentCategorysQueryResult = NonNullable<Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetAllDocumentCategorys>>>
export type TenantDocumentCategoryControllerGetAllDocumentCategorysQueryError = AxiosError<unknown>


export function useTenantDocumentCategoryControllerGetAllDocumentCategorys<TData = Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetAllDocumentCategorys>>, TError = AxiosError<unknown>>(
 tenantId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetAllDocumentCategorys>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetAllDocumentCategorys>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantDocumentCategoryControllerGetAllDocumentCategorys<TData = Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetAllDocumentCategorys>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetAllDocumentCategorys>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetAllDocumentCategorys>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantDocumentCategoryControllerGetAllDocumentCategorys<TData = Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetAllDocumentCategorys>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetAllDocumentCategorys>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantDocumentCategoryControllerGetAllDocumentCategorys<TData = Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetAllDocumentCategorys>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetAllDocumentCategorys>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantDocumentCategoryControllerGetAllDocumentCategorysQueryOptions(tenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantDocumentCategoryControllerGetAllDocumentCategorysSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetAllDocumentCategorys>>, TError = AxiosError<unknown>>(tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetAllDocumentCategorys>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantDocumentCategoryControllerGetAllDocumentCategorysQueryKey(tenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetAllDocumentCategorys>>> = ({ signal }) => tenantDocumentCategoryControllerGetAllDocumentCategorys(tenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetAllDocumentCategorys>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantDocumentCategoryControllerGetAllDocumentCategorysSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetAllDocumentCategorys>>>
export type TenantDocumentCategoryControllerGetAllDocumentCategorysSuspenseQueryError = AxiosError<unknown>


export function useTenantDocumentCategoryControllerGetAllDocumentCategorysSuspense<TData = Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetAllDocumentCategorys>>, TError = AxiosError<unknown>>(
 tenantId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetAllDocumentCategorys>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantDocumentCategoryControllerGetAllDocumentCategorysSuspense<TData = Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetAllDocumentCategorys>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetAllDocumentCategorys>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantDocumentCategoryControllerGetAllDocumentCategorysSuspense<TData = Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetAllDocumentCategorys>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetAllDocumentCategorys>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantDocumentCategoryControllerGetAllDocumentCategorysSuspense<TData = Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetAllDocumentCategorys>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetAllDocumentCategorys>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantDocumentCategoryControllerGetAllDocumentCategorysSuspenseQueryOptions(tenantId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantDocumentCategoryControllerCreateDocumentCategory = (
    tenantId: string,
    tenantDocumentCategoryCreateDto: TenantDocumentCategoryCreateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantDocumentCategoryGetDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/settings/document-category`,
      tenantDocumentCategoryCreateDto,options
    );
  }



export const getTenantDocumentCategoryControllerCreateDocumentCategoryMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantDocumentCategoryControllerCreateDocumentCategory>>, TError,{tenantId: string;data: TenantDocumentCategoryCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantDocumentCategoryControllerCreateDocumentCategory>>, TError,{tenantId: string;data: TenantDocumentCategoryCreateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantDocumentCategoryControllerCreateDocumentCategory>>, {tenantId: string;data: TenantDocumentCategoryCreateDto}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  tenantDocumentCategoryControllerCreateDocumentCategory(tenantId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantDocumentCategoryControllerCreateDocumentCategoryMutationResult = NonNullable<Awaited<ReturnType<typeof tenantDocumentCategoryControllerCreateDocumentCategory>>>
    export type TenantDocumentCategoryControllerCreateDocumentCategoryMutationBody = TenantDocumentCategoryCreateDto
    export type TenantDocumentCategoryControllerCreateDocumentCategoryMutationError = AxiosError<unknown>

    export const useTenantDocumentCategoryControllerCreateDocumentCategory = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantDocumentCategoryControllerCreateDocumentCategory>>, TError,{tenantId: string;data: TenantDocumentCategoryCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantDocumentCategoryControllerCreateDocumentCategory>>,
        TError,
        {tenantId: string;data: TenantDocumentCategoryCreateDto},
        TContext
      > => {

      const mutationOptions = getTenantDocumentCategoryControllerCreateDocumentCategoryMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantDocumentCategoryControllerGetDocumentCategory = (
    tenantId: string,
    documentCategoryId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantDocumentCategoryGetDto>> => {
    
    return axios.get(
      `/v1/${tenantId}/settings/document-category/${documentCategoryId}`,options
    );
  }


export const getTenantDocumentCategoryControllerGetDocumentCategoryQueryKey = (tenantId: string,
    documentCategoryId: string,) => {
    return [`/v1/${tenantId}/settings/document-category/${documentCategoryId}`] as const;
    }

    
export const getTenantDocumentCategoryControllerGetDocumentCategoryQueryOptions = <TData = Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetDocumentCategory>>, TError = AxiosError<unknown>>(tenantId: string,
    documentCategoryId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetDocumentCategory>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantDocumentCategoryControllerGetDocumentCategoryQueryKey(tenantId,documentCategoryId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetDocumentCategory>>> = ({ signal }) => tenantDocumentCategoryControllerGetDocumentCategory(tenantId,documentCategoryId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && documentCategoryId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetDocumentCategory>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantDocumentCategoryControllerGetDocumentCategoryQueryResult = NonNullable<Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetDocumentCategory>>>
export type TenantDocumentCategoryControllerGetDocumentCategoryQueryError = AxiosError<unknown>


export function useTenantDocumentCategoryControllerGetDocumentCategory<TData = Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetDocumentCategory>>, TError = AxiosError<unknown>>(
 tenantId: string,
    documentCategoryId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetDocumentCategory>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetDocumentCategory>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantDocumentCategoryControllerGetDocumentCategory<TData = Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetDocumentCategory>>, TError = AxiosError<unknown>>(
 tenantId: string,
    documentCategoryId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetDocumentCategory>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetDocumentCategory>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantDocumentCategoryControllerGetDocumentCategory<TData = Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetDocumentCategory>>, TError = AxiosError<unknown>>(
 tenantId: string,
    documentCategoryId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetDocumentCategory>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantDocumentCategoryControllerGetDocumentCategory<TData = Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetDocumentCategory>>, TError = AxiosError<unknown>>(
 tenantId: string,
    documentCategoryId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetDocumentCategory>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantDocumentCategoryControllerGetDocumentCategoryQueryOptions(tenantId,documentCategoryId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantDocumentCategoryControllerGetDocumentCategorySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetDocumentCategory>>, TError = AxiosError<unknown>>(tenantId: string,
    documentCategoryId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetDocumentCategory>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantDocumentCategoryControllerGetDocumentCategoryQueryKey(tenantId,documentCategoryId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetDocumentCategory>>> = ({ signal }) => tenantDocumentCategoryControllerGetDocumentCategory(tenantId,documentCategoryId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && documentCategoryId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetDocumentCategory>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantDocumentCategoryControllerGetDocumentCategorySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetDocumentCategory>>>
export type TenantDocumentCategoryControllerGetDocumentCategorySuspenseQueryError = AxiosError<unknown>


export function useTenantDocumentCategoryControllerGetDocumentCategorySuspense<TData = Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetDocumentCategory>>, TError = AxiosError<unknown>>(
 tenantId: string,
    documentCategoryId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetDocumentCategory>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantDocumentCategoryControllerGetDocumentCategorySuspense<TData = Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetDocumentCategory>>, TError = AxiosError<unknown>>(
 tenantId: string,
    documentCategoryId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetDocumentCategory>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantDocumentCategoryControllerGetDocumentCategorySuspense<TData = Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetDocumentCategory>>, TError = AxiosError<unknown>>(
 tenantId: string,
    documentCategoryId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetDocumentCategory>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantDocumentCategoryControllerGetDocumentCategorySuspense<TData = Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetDocumentCategory>>, TError = AxiosError<unknown>>(
 tenantId: string,
    documentCategoryId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantDocumentCategoryControllerGetDocumentCategory>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantDocumentCategoryControllerGetDocumentCategorySuspenseQueryOptions(tenantId,documentCategoryId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantDocumentCategoryControllerUpdateDocumentCategory = (
    tenantId: string,
    documentCategoryId: string,
    tenantDocumentCategoryPatchDto: TenantDocumentCategoryPatchDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantDocumentCategoryGetDto>> => {
    
    return axios.patch(
      `/v1/${tenantId}/settings/document-category/${documentCategoryId}`,
      tenantDocumentCategoryPatchDto,options
    );
  }



export const getTenantDocumentCategoryControllerUpdateDocumentCategoryMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantDocumentCategoryControllerUpdateDocumentCategory>>, TError,{tenantId: string;documentCategoryId: string;data: TenantDocumentCategoryPatchDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantDocumentCategoryControllerUpdateDocumentCategory>>, TError,{tenantId: string;documentCategoryId: string;data: TenantDocumentCategoryPatchDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantDocumentCategoryControllerUpdateDocumentCategory>>, {tenantId: string;documentCategoryId: string;data: TenantDocumentCategoryPatchDto}> = (props) => {
          const {tenantId,documentCategoryId,data} = props ?? {};

          return  tenantDocumentCategoryControllerUpdateDocumentCategory(tenantId,documentCategoryId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantDocumentCategoryControllerUpdateDocumentCategoryMutationResult = NonNullable<Awaited<ReturnType<typeof tenantDocumentCategoryControllerUpdateDocumentCategory>>>
    export type TenantDocumentCategoryControllerUpdateDocumentCategoryMutationBody = TenantDocumentCategoryPatchDto
    export type TenantDocumentCategoryControllerUpdateDocumentCategoryMutationError = AxiosError<unknown>

    export const useTenantDocumentCategoryControllerUpdateDocumentCategory = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantDocumentCategoryControllerUpdateDocumentCategory>>, TError,{tenantId: string;documentCategoryId: string;data: TenantDocumentCategoryPatchDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantDocumentCategoryControllerUpdateDocumentCategory>>,
        TError,
        {tenantId: string;documentCategoryId: string;data: TenantDocumentCategoryPatchDto},
        TContext
      > => {

      const mutationOptions = getTenantDocumentCategoryControllerUpdateDocumentCategoryMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantDocumentCategoryControllerDeleteDocumentCategory = (
    tenantId: string,
    documentCategoryId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/v1/${tenantId}/settings/document-category/${documentCategoryId}`,options
    );
  }



export const getTenantDocumentCategoryControllerDeleteDocumentCategoryMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantDocumentCategoryControllerDeleteDocumentCategory>>, TError,{tenantId: string;documentCategoryId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantDocumentCategoryControllerDeleteDocumentCategory>>, TError,{tenantId: string;documentCategoryId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantDocumentCategoryControllerDeleteDocumentCategory>>, {tenantId: string;documentCategoryId: string}> = (props) => {
          const {tenantId,documentCategoryId} = props ?? {};

          return  tenantDocumentCategoryControllerDeleteDocumentCategory(tenantId,documentCategoryId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantDocumentCategoryControllerDeleteDocumentCategoryMutationResult = NonNullable<Awaited<ReturnType<typeof tenantDocumentCategoryControllerDeleteDocumentCategory>>>
    
    export type TenantDocumentCategoryControllerDeleteDocumentCategoryMutationError = AxiosError<unknown>

    export const useTenantDocumentCategoryControllerDeleteDocumentCategory = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantDocumentCategoryControllerDeleteDocumentCategory>>, TError,{tenantId: string;documentCategoryId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantDocumentCategoryControllerDeleteDocumentCategory>>,
        TError,
        {tenantId: string;documentCategoryId: string},
        TContext
      > => {

      const mutationOptions = getTenantDocumentCategoryControllerDeleteDocumentCategoryMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantSkillcornerAccessControllerGetTenantSkillcornerAccess = (
    tenantId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantSkillcornerAccessGetDto>> => {
    
    return axios.get(
      `/v1/${tenantId}/settings/skillcorner`,options
    );
  }


export const getTenantSkillcornerAccessControllerGetTenantSkillcornerAccessQueryKey = (tenantId: string,) => {
    return [`/v1/${tenantId}/settings/skillcorner`] as const;
    }

    
export const getTenantSkillcornerAccessControllerGetTenantSkillcornerAccessQueryOptions = <TData = Awaited<ReturnType<typeof tenantSkillcornerAccessControllerGetTenantSkillcornerAccess>>, TError = AxiosError<unknown>>(tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantSkillcornerAccessControllerGetTenantSkillcornerAccess>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantSkillcornerAccessControllerGetTenantSkillcornerAccessQueryKey(tenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantSkillcornerAccessControllerGetTenantSkillcornerAccess>>> = ({ signal }) => tenantSkillcornerAccessControllerGetTenantSkillcornerAccess(tenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantSkillcornerAccessControllerGetTenantSkillcornerAccess>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantSkillcornerAccessControllerGetTenantSkillcornerAccessQueryResult = NonNullable<Awaited<ReturnType<typeof tenantSkillcornerAccessControllerGetTenantSkillcornerAccess>>>
export type TenantSkillcornerAccessControllerGetTenantSkillcornerAccessQueryError = AxiosError<unknown>


export function useTenantSkillcornerAccessControllerGetTenantSkillcornerAccess<TData = Awaited<ReturnType<typeof tenantSkillcornerAccessControllerGetTenantSkillcornerAccess>>, TError = AxiosError<unknown>>(
 tenantId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantSkillcornerAccessControllerGetTenantSkillcornerAccess>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantSkillcornerAccessControllerGetTenantSkillcornerAccess>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantSkillcornerAccessControllerGetTenantSkillcornerAccess<TData = Awaited<ReturnType<typeof tenantSkillcornerAccessControllerGetTenantSkillcornerAccess>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantSkillcornerAccessControllerGetTenantSkillcornerAccess>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantSkillcornerAccessControllerGetTenantSkillcornerAccess>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantSkillcornerAccessControllerGetTenantSkillcornerAccess<TData = Awaited<ReturnType<typeof tenantSkillcornerAccessControllerGetTenantSkillcornerAccess>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantSkillcornerAccessControllerGetTenantSkillcornerAccess>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantSkillcornerAccessControllerGetTenantSkillcornerAccess<TData = Awaited<ReturnType<typeof tenantSkillcornerAccessControllerGetTenantSkillcornerAccess>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantSkillcornerAccessControllerGetTenantSkillcornerAccess>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantSkillcornerAccessControllerGetTenantSkillcornerAccessQueryOptions(tenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantSkillcornerAccessControllerGetTenantSkillcornerAccessSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantSkillcornerAccessControllerGetTenantSkillcornerAccess>>, TError = AxiosError<unknown>>(tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantSkillcornerAccessControllerGetTenantSkillcornerAccess>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantSkillcornerAccessControllerGetTenantSkillcornerAccessQueryKey(tenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantSkillcornerAccessControllerGetTenantSkillcornerAccess>>> = ({ signal }) => tenantSkillcornerAccessControllerGetTenantSkillcornerAccess(tenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantSkillcornerAccessControllerGetTenantSkillcornerAccess>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantSkillcornerAccessControllerGetTenantSkillcornerAccessSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantSkillcornerAccessControllerGetTenantSkillcornerAccess>>>
export type TenantSkillcornerAccessControllerGetTenantSkillcornerAccessSuspenseQueryError = AxiosError<unknown>


export function useTenantSkillcornerAccessControllerGetTenantSkillcornerAccessSuspense<TData = Awaited<ReturnType<typeof tenantSkillcornerAccessControllerGetTenantSkillcornerAccess>>, TError = AxiosError<unknown>>(
 tenantId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantSkillcornerAccessControllerGetTenantSkillcornerAccess>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantSkillcornerAccessControllerGetTenantSkillcornerAccessSuspense<TData = Awaited<ReturnType<typeof tenantSkillcornerAccessControllerGetTenantSkillcornerAccess>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantSkillcornerAccessControllerGetTenantSkillcornerAccess>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantSkillcornerAccessControllerGetTenantSkillcornerAccessSuspense<TData = Awaited<ReturnType<typeof tenantSkillcornerAccessControllerGetTenantSkillcornerAccess>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantSkillcornerAccessControllerGetTenantSkillcornerAccess>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantSkillcornerAccessControllerGetTenantSkillcornerAccessSuspense<TData = Awaited<ReturnType<typeof tenantSkillcornerAccessControllerGetTenantSkillcornerAccess>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantSkillcornerAccessControllerGetTenantSkillcornerAccess>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantSkillcornerAccessControllerGetTenantSkillcornerAccessSuspenseQueryOptions(tenantId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantScoutingUiControllerGet = (
    tenantId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantScoutingUiControllerGet200>> => {
    
    return axios.get(
      `/v1/${tenantId}/settings/scouting/ui`,options
    );
  }


export const getTenantScoutingUiControllerGetQueryKey = (tenantId: string,) => {
    return [`/v1/${tenantId}/settings/scouting/ui`] as const;
    }

    
export const getTenantScoutingUiControllerGetQueryOptions = <TData = Awaited<ReturnType<typeof tenantScoutingUiControllerGet>>, TError = AxiosError<unknown>>(tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantScoutingUiControllerGet>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantScoutingUiControllerGetQueryKey(tenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantScoutingUiControllerGet>>> = ({ signal }) => tenantScoutingUiControllerGet(tenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantScoutingUiControllerGet>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantScoutingUiControllerGetQueryResult = NonNullable<Awaited<ReturnType<typeof tenantScoutingUiControllerGet>>>
export type TenantScoutingUiControllerGetQueryError = AxiosError<unknown>


export function useTenantScoutingUiControllerGet<TData = Awaited<ReturnType<typeof tenantScoutingUiControllerGet>>, TError = AxiosError<unknown>>(
 tenantId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantScoutingUiControllerGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantScoutingUiControllerGet>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantScoutingUiControllerGet<TData = Awaited<ReturnType<typeof tenantScoutingUiControllerGet>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantScoutingUiControllerGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantScoutingUiControllerGet>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantScoutingUiControllerGet<TData = Awaited<ReturnType<typeof tenantScoutingUiControllerGet>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantScoutingUiControllerGet>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantScoutingUiControllerGet<TData = Awaited<ReturnType<typeof tenantScoutingUiControllerGet>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantScoutingUiControllerGet>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantScoutingUiControllerGetQueryOptions(tenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantScoutingUiControllerGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantScoutingUiControllerGet>>, TError = AxiosError<unknown>>(tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantScoutingUiControllerGet>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantScoutingUiControllerGetQueryKey(tenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantScoutingUiControllerGet>>> = ({ signal }) => tenantScoutingUiControllerGet(tenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantScoutingUiControllerGet>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantScoutingUiControllerGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantScoutingUiControllerGet>>>
export type TenantScoutingUiControllerGetSuspenseQueryError = AxiosError<unknown>


export function useTenantScoutingUiControllerGetSuspense<TData = Awaited<ReturnType<typeof tenantScoutingUiControllerGet>>, TError = AxiosError<unknown>>(
 tenantId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantScoutingUiControllerGet>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantScoutingUiControllerGetSuspense<TData = Awaited<ReturnType<typeof tenantScoutingUiControllerGet>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantScoutingUiControllerGet>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantScoutingUiControllerGetSuspense<TData = Awaited<ReturnType<typeof tenantScoutingUiControllerGet>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantScoutingUiControllerGet>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantScoutingUiControllerGetSuspense<TData = Awaited<ReturnType<typeof tenantScoutingUiControllerGet>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantScoutingUiControllerGet>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantScoutingUiControllerGetSuspenseQueryOptions(tenantId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantScoutingUiControllerUpdate = (
    tenantId: string,
    tenantScoutingUiPutDto: TenantScoutingUiPutDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantScoutingUiDto>> => {
    
    return axios.put(
      `/v1/${tenantId}/settings/scouting/ui`,
      tenantScoutingUiPutDto,options
    );
  }



export const getTenantScoutingUiControllerUpdateMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantScoutingUiControllerUpdate>>, TError,{tenantId: string;data: TenantScoutingUiPutDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantScoutingUiControllerUpdate>>, TError,{tenantId: string;data: TenantScoutingUiPutDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantScoutingUiControllerUpdate>>, {tenantId: string;data: TenantScoutingUiPutDto}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  tenantScoutingUiControllerUpdate(tenantId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantScoutingUiControllerUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof tenantScoutingUiControllerUpdate>>>
    export type TenantScoutingUiControllerUpdateMutationBody = TenantScoutingUiPutDto
    export type TenantScoutingUiControllerUpdateMutationError = AxiosError<unknown>

    export const useTenantScoutingUiControllerUpdate = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantScoutingUiControllerUpdate>>, TError,{tenantId: string;data: TenantScoutingUiPutDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantScoutingUiControllerUpdate>>,
        TError,
        {tenantId: string;data: TenantScoutingUiPutDto},
        TContext
      > => {

      const mutationOptions = getTenantScoutingUiControllerUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantWyscoutIndexControllerGetWyscoutIndices = (
    tenantId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantWyscoutIndexGetDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/settings/wyscout-index`,options
    );
  }


export const getTenantWyscoutIndexControllerGetWyscoutIndicesQueryKey = (tenantId: string,) => {
    return [`/v1/${tenantId}/settings/wyscout-index`] as const;
    }

    
export const getTenantWyscoutIndexControllerGetWyscoutIndicesQueryOptions = <TData = Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndices>>, TError = AxiosError<unknown>>(tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndices>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantWyscoutIndexControllerGetWyscoutIndicesQueryKey(tenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndices>>> = ({ signal }) => tenantWyscoutIndexControllerGetWyscoutIndices(tenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndices>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantWyscoutIndexControllerGetWyscoutIndicesQueryResult = NonNullable<Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndices>>>
export type TenantWyscoutIndexControllerGetWyscoutIndicesQueryError = AxiosError<unknown>


export function useTenantWyscoutIndexControllerGetWyscoutIndices<TData = Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndices>>, TError = AxiosError<unknown>>(
 tenantId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndices>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndices>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantWyscoutIndexControllerGetWyscoutIndices<TData = Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndices>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndices>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndices>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantWyscoutIndexControllerGetWyscoutIndices<TData = Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndices>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndices>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantWyscoutIndexControllerGetWyscoutIndices<TData = Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndices>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndices>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantWyscoutIndexControllerGetWyscoutIndicesQueryOptions(tenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantWyscoutIndexControllerGetWyscoutIndicesSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndices>>, TError = AxiosError<unknown>>(tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndices>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantWyscoutIndexControllerGetWyscoutIndicesQueryKey(tenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndices>>> = ({ signal }) => tenantWyscoutIndexControllerGetWyscoutIndices(tenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndices>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantWyscoutIndexControllerGetWyscoutIndicesSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndices>>>
export type TenantWyscoutIndexControllerGetWyscoutIndicesSuspenseQueryError = AxiosError<unknown>


export function useTenantWyscoutIndexControllerGetWyscoutIndicesSuspense<TData = Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndices>>, TError = AxiosError<unknown>>(
 tenantId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndices>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantWyscoutIndexControllerGetWyscoutIndicesSuspense<TData = Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndices>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndices>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantWyscoutIndexControllerGetWyscoutIndicesSuspense<TData = Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndices>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndices>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantWyscoutIndexControllerGetWyscoutIndicesSuspense<TData = Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndices>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndices>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantWyscoutIndexControllerGetWyscoutIndicesSuspenseQueryOptions(tenantId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantWyscoutIndexControllerCreateWyscoutIndex = (
    tenantId: string,
    tenantWyscoutIndexCreateDto: TenantWyscoutIndexCreateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantWyscoutIndexGetDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/settings/wyscout-index`,
      tenantWyscoutIndexCreateDto,options
    );
  }



export const getTenantWyscoutIndexControllerCreateWyscoutIndexMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantWyscoutIndexControllerCreateWyscoutIndex>>, TError,{tenantId: string;data: TenantWyscoutIndexCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantWyscoutIndexControllerCreateWyscoutIndex>>, TError,{tenantId: string;data: TenantWyscoutIndexCreateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantWyscoutIndexControllerCreateWyscoutIndex>>, {tenantId: string;data: TenantWyscoutIndexCreateDto}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  tenantWyscoutIndexControllerCreateWyscoutIndex(tenantId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantWyscoutIndexControllerCreateWyscoutIndexMutationResult = NonNullable<Awaited<ReturnType<typeof tenantWyscoutIndexControllerCreateWyscoutIndex>>>
    export type TenantWyscoutIndexControllerCreateWyscoutIndexMutationBody = TenantWyscoutIndexCreateDto
    export type TenantWyscoutIndexControllerCreateWyscoutIndexMutationError = AxiosError<unknown>

    export const useTenantWyscoutIndexControllerCreateWyscoutIndex = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantWyscoutIndexControllerCreateWyscoutIndex>>, TError,{tenantId: string;data: TenantWyscoutIndexCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantWyscoutIndexControllerCreateWyscoutIndex>>,
        TError,
        {tenantId: string;data: TenantWyscoutIndexCreateDto},
        TContext
      > => {

      const mutationOptions = getTenantWyscoutIndexControllerCreateWyscoutIndexMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantWyscoutIndexControllerGetWyscoutIndex = (
    tenantId: string,
    wyscoutIndexId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantWyscoutIndexGetDto>> => {
    
    return axios.get(
      `/v1/${tenantId}/settings/wyscout-index/${wyscoutIndexId}`,options
    );
  }


export const getTenantWyscoutIndexControllerGetWyscoutIndexQueryKey = (tenantId: string,
    wyscoutIndexId: string,) => {
    return [`/v1/${tenantId}/settings/wyscout-index/${wyscoutIndexId}`] as const;
    }

    
export const getTenantWyscoutIndexControllerGetWyscoutIndexQueryOptions = <TData = Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndex>>, TError = AxiosError<unknown>>(tenantId: string,
    wyscoutIndexId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndex>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantWyscoutIndexControllerGetWyscoutIndexQueryKey(tenantId,wyscoutIndexId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndex>>> = ({ signal }) => tenantWyscoutIndexControllerGetWyscoutIndex(tenantId,wyscoutIndexId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && wyscoutIndexId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndex>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantWyscoutIndexControllerGetWyscoutIndexQueryResult = NonNullable<Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndex>>>
export type TenantWyscoutIndexControllerGetWyscoutIndexQueryError = AxiosError<unknown>


export function useTenantWyscoutIndexControllerGetWyscoutIndex<TData = Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndex>>, TError = AxiosError<unknown>>(
 tenantId: string,
    wyscoutIndexId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndex>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndex>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantWyscoutIndexControllerGetWyscoutIndex<TData = Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndex>>, TError = AxiosError<unknown>>(
 tenantId: string,
    wyscoutIndexId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndex>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndex>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantWyscoutIndexControllerGetWyscoutIndex<TData = Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndex>>, TError = AxiosError<unknown>>(
 tenantId: string,
    wyscoutIndexId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndex>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantWyscoutIndexControllerGetWyscoutIndex<TData = Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndex>>, TError = AxiosError<unknown>>(
 tenantId: string,
    wyscoutIndexId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndex>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantWyscoutIndexControllerGetWyscoutIndexQueryOptions(tenantId,wyscoutIndexId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantWyscoutIndexControllerGetWyscoutIndexSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndex>>, TError = AxiosError<unknown>>(tenantId: string,
    wyscoutIndexId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndex>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantWyscoutIndexControllerGetWyscoutIndexQueryKey(tenantId,wyscoutIndexId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndex>>> = ({ signal }) => tenantWyscoutIndexControllerGetWyscoutIndex(tenantId,wyscoutIndexId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && wyscoutIndexId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndex>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantWyscoutIndexControllerGetWyscoutIndexSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndex>>>
export type TenantWyscoutIndexControllerGetWyscoutIndexSuspenseQueryError = AxiosError<unknown>


export function useTenantWyscoutIndexControllerGetWyscoutIndexSuspense<TData = Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndex>>, TError = AxiosError<unknown>>(
 tenantId: string,
    wyscoutIndexId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndex>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantWyscoutIndexControllerGetWyscoutIndexSuspense<TData = Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndex>>, TError = AxiosError<unknown>>(
 tenantId: string,
    wyscoutIndexId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndex>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantWyscoutIndexControllerGetWyscoutIndexSuspense<TData = Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndex>>, TError = AxiosError<unknown>>(
 tenantId: string,
    wyscoutIndexId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndex>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantWyscoutIndexControllerGetWyscoutIndexSuspense<TData = Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndex>>, TError = AxiosError<unknown>>(
 tenantId: string,
    wyscoutIndexId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantWyscoutIndexControllerGetWyscoutIndex>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantWyscoutIndexControllerGetWyscoutIndexSuspenseQueryOptions(tenantId,wyscoutIndexId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantWyscoutIndexControllerUpdateWyscoutIndex = (
    tenantId: string,
    wyscoutIndexId: string,
    tenantWyscoutIndexPatchDto: TenantWyscoutIndexPatchDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantWyscoutIndexGetDto>> => {
    
    return axios.patch(
      `/v1/${tenantId}/settings/wyscout-index/${wyscoutIndexId}`,
      tenantWyscoutIndexPatchDto,options
    );
  }



export const getTenantWyscoutIndexControllerUpdateWyscoutIndexMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantWyscoutIndexControllerUpdateWyscoutIndex>>, TError,{tenantId: string;wyscoutIndexId: string;data: TenantWyscoutIndexPatchDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantWyscoutIndexControllerUpdateWyscoutIndex>>, TError,{tenantId: string;wyscoutIndexId: string;data: TenantWyscoutIndexPatchDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantWyscoutIndexControllerUpdateWyscoutIndex>>, {tenantId: string;wyscoutIndexId: string;data: TenantWyscoutIndexPatchDto}> = (props) => {
          const {tenantId,wyscoutIndexId,data} = props ?? {};

          return  tenantWyscoutIndexControllerUpdateWyscoutIndex(tenantId,wyscoutIndexId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantWyscoutIndexControllerUpdateWyscoutIndexMutationResult = NonNullable<Awaited<ReturnType<typeof tenantWyscoutIndexControllerUpdateWyscoutIndex>>>
    export type TenantWyscoutIndexControllerUpdateWyscoutIndexMutationBody = TenantWyscoutIndexPatchDto
    export type TenantWyscoutIndexControllerUpdateWyscoutIndexMutationError = AxiosError<unknown>

    export const useTenantWyscoutIndexControllerUpdateWyscoutIndex = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantWyscoutIndexControllerUpdateWyscoutIndex>>, TError,{tenantId: string;wyscoutIndexId: string;data: TenantWyscoutIndexPatchDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantWyscoutIndexControllerUpdateWyscoutIndex>>,
        TError,
        {tenantId: string;wyscoutIndexId: string;data: TenantWyscoutIndexPatchDto},
        TContext
      > => {

      const mutationOptions = getTenantWyscoutIndexControllerUpdateWyscoutIndexMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantWyscoutIndexControllerDeleteWyscoutIndex = (
    tenantId: string,
    wyscoutIndexId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/v1/${tenantId}/settings/wyscout-index/${wyscoutIndexId}`,options
    );
  }



export const getTenantWyscoutIndexControllerDeleteWyscoutIndexMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantWyscoutIndexControllerDeleteWyscoutIndex>>, TError,{tenantId: string;wyscoutIndexId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantWyscoutIndexControllerDeleteWyscoutIndex>>, TError,{tenantId: string;wyscoutIndexId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantWyscoutIndexControllerDeleteWyscoutIndex>>, {tenantId: string;wyscoutIndexId: string}> = (props) => {
          const {tenantId,wyscoutIndexId} = props ?? {};

          return  tenantWyscoutIndexControllerDeleteWyscoutIndex(tenantId,wyscoutIndexId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantWyscoutIndexControllerDeleteWyscoutIndexMutationResult = NonNullable<Awaited<ReturnType<typeof tenantWyscoutIndexControllerDeleteWyscoutIndex>>>
    
    export type TenantWyscoutIndexControllerDeleteWyscoutIndexMutationError = AxiosError<unknown>

    export const useTenantWyscoutIndexControllerDeleteWyscoutIndex = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantWyscoutIndexControllerDeleteWyscoutIndex>>, TError,{tenantId: string;wyscoutIndexId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantWyscoutIndexControllerDeleteWyscoutIndex>>,
        TError,
        {tenantId: string;wyscoutIndexId: string},
        TContext
      > => {

      const mutationOptions = getTenantWyscoutIndexControllerDeleteWyscoutIndexMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const environmentPlayerControllerGetPlayers = (
    tenantId: string,
    environmentId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EnvironmentPlayerGetDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/player`,options
    );
  }


export const getEnvironmentPlayerControllerGetPlayersQueryKey = (tenantId: string,
    environmentId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/player`] as const;
    }

    
export const getEnvironmentPlayerControllerGetPlayersQueryOptions = <TData = Awaited<ReturnType<typeof environmentPlayerControllerGetPlayers>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentPlayerControllerGetPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getEnvironmentPlayerControllerGetPlayersQueryKey(tenantId,environmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof environmentPlayerControllerGetPlayers>>> = ({ signal }) => environmentPlayerControllerGetPlayers(tenantId,environmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof environmentPlayerControllerGetPlayers>>, TError, TData> & { queryKey: QueryKey }
}

export type EnvironmentPlayerControllerGetPlayersQueryResult = NonNullable<Awaited<ReturnType<typeof environmentPlayerControllerGetPlayers>>>
export type EnvironmentPlayerControllerGetPlayersQueryError = AxiosError<unknown>


export function useEnvironmentPlayerControllerGetPlayers<TData = Awaited<ReturnType<typeof environmentPlayerControllerGetPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentPlayerControllerGetPlayers>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof environmentPlayerControllerGetPlayers>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentPlayerControllerGetPlayers<TData = Awaited<ReturnType<typeof environmentPlayerControllerGetPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentPlayerControllerGetPlayers>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof environmentPlayerControllerGetPlayers>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentPlayerControllerGetPlayers<TData = Awaited<ReturnType<typeof environmentPlayerControllerGetPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentPlayerControllerGetPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useEnvironmentPlayerControllerGetPlayers<TData = Awaited<ReturnType<typeof environmentPlayerControllerGetPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof environmentPlayerControllerGetPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getEnvironmentPlayerControllerGetPlayersQueryOptions(tenantId,environmentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getEnvironmentPlayerControllerGetPlayersSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof environmentPlayerControllerGetPlayers>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentPlayerControllerGetPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getEnvironmentPlayerControllerGetPlayersQueryKey(tenantId,environmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof environmentPlayerControllerGetPlayers>>> = ({ signal }) => environmentPlayerControllerGetPlayers(tenantId,environmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentPlayerControllerGetPlayers>>, TError, TData> & { queryKey: QueryKey }
}

export type EnvironmentPlayerControllerGetPlayersSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof environmentPlayerControllerGetPlayers>>>
export type EnvironmentPlayerControllerGetPlayersSuspenseQueryError = AxiosError<unknown>


export function useEnvironmentPlayerControllerGetPlayersSuspense<TData = Awaited<ReturnType<typeof environmentPlayerControllerGetPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentPlayerControllerGetPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentPlayerControllerGetPlayersSuspense<TData = Awaited<ReturnType<typeof environmentPlayerControllerGetPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentPlayerControllerGetPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useEnvironmentPlayerControllerGetPlayersSuspense<TData = Awaited<ReturnType<typeof environmentPlayerControllerGetPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentPlayerControllerGetPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useEnvironmentPlayerControllerGetPlayersSuspense<TData = Awaited<ReturnType<typeof environmentPlayerControllerGetPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof environmentPlayerControllerGetPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getEnvironmentPlayerControllerGetPlayersSuspenseQueryOptions(tenantId,environmentId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const environmentPlayerControllerAddPlayer = (
    tenantId: string,
    environmentId: string,
    environmentPlayerPostDto: EnvironmentPlayerPostDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/v1/${tenantId}/${environmentId}/player`,
      environmentPlayerPostDto,options
    );
  }



export const getEnvironmentPlayerControllerAddPlayerMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentPlayerControllerAddPlayer>>, TError,{tenantId: string;environmentId: string;data: EnvironmentPlayerPostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof environmentPlayerControllerAddPlayer>>, TError,{tenantId: string;environmentId: string;data: EnvironmentPlayerPostDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof environmentPlayerControllerAddPlayer>>, {tenantId: string;environmentId: string;data: EnvironmentPlayerPostDto}> = (props) => {
          const {tenantId,environmentId,data} = props ?? {};

          return  environmentPlayerControllerAddPlayer(tenantId,environmentId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type EnvironmentPlayerControllerAddPlayerMutationResult = NonNullable<Awaited<ReturnType<typeof environmentPlayerControllerAddPlayer>>>
    export type EnvironmentPlayerControllerAddPlayerMutationBody = EnvironmentPlayerPostDto
    export type EnvironmentPlayerControllerAddPlayerMutationError = AxiosError<unknown>

    export const useEnvironmentPlayerControllerAddPlayer = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof environmentPlayerControllerAddPlayer>>, TError,{tenantId: string;environmentId: string;data: EnvironmentPlayerPostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof environmentPlayerControllerAddPlayer>>,
        TError,
        {tenantId: string;environmentId: string;data: EnvironmentPlayerPostDto},
        TContext
      > => {

      const mutationOptions = getEnvironmentPlayerControllerAddPlayerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantEventControllerGetTenantEvents = (
    tenantId: string,
    params: TenantEventControllerGetTenantEventsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantEventControllerGetTenantEvents200Item[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/event`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getTenantEventControllerGetTenantEventsQueryKey = (tenantId: string,
    params: TenantEventControllerGetTenantEventsParams,) => {
    return [`/v1/${tenantId}/event`, ...(params ? [params]: [])] as const;
    }

    
export const getTenantEventControllerGetTenantEventsQueryOptions = <TData = Awaited<ReturnType<typeof tenantEventControllerGetTenantEvents>>, TError = AxiosError<unknown>>(tenantId: string,
    params: TenantEventControllerGetTenantEventsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEventControllerGetTenantEvents>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantEventControllerGetTenantEventsQueryKey(tenantId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantEventControllerGetTenantEvents>>> = ({ signal }) => tenantEventControllerGetTenantEvents(tenantId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantEventControllerGetTenantEvents>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantEventControllerGetTenantEventsQueryResult = NonNullable<Awaited<ReturnType<typeof tenantEventControllerGetTenantEvents>>>
export type TenantEventControllerGetTenantEventsQueryError = AxiosError<unknown>


export function useTenantEventControllerGetTenantEvents<TData = Awaited<ReturnType<typeof tenantEventControllerGetTenantEvents>>, TError = AxiosError<unknown>>(
 tenantId: string,
    params: TenantEventControllerGetTenantEventsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEventControllerGetTenantEvents>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantEventControllerGetTenantEvents>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEventControllerGetTenantEvents<TData = Awaited<ReturnType<typeof tenantEventControllerGetTenantEvents>>, TError = AxiosError<unknown>>(
 tenantId: string,
    params: TenantEventControllerGetTenantEventsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEventControllerGetTenantEvents>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantEventControllerGetTenantEvents>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEventControllerGetTenantEvents<TData = Awaited<ReturnType<typeof tenantEventControllerGetTenantEvents>>, TError = AxiosError<unknown>>(
 tenantId: string,
    params: TenantEventControllerGetTenantEventsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEventControllerGetTenantEvents>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantEventControllerGetTenantEvents<TData = Awaited<ReturnType<typeof tenantEventControllerGetTenantEvents>>, TError = AxiosError<unknown>>(
 tenantId: string,
    params: TenantEventControllerGetTenantEventsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEventControllerGetTenantEvents>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantEventControllerGetTenantEventsQueryOptions(tenantId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantEventControllerGetTenantEventsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantEventControllerGetTenantEvents>>, TError = AxiosError<unknown>>(tenantId: string,
    params: TenantEventControllerGetTenantEventsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEventControllerGetTenantEvents>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantEventControllerGetTenantEventsQueryKey(tenantId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantEventControllerGetTenantEvents>>> = ({ signal }) => tenantEventControllerGetTenantEvents(tenantId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEventControllerGetTenantEvents>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantEventControllerGetTenantEventsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantEventControllerGetTenantEvents>>>
export type TenantEventControllerGetTenantEventsSuspenseQueryError = AxiosError<unknown>


export function useTenantEventControllerGetTenantEventsSuspense<TData = Awaited<ReturnType<typeof tenantEventControllerGetTenantEvents>>, TError = AxiosError<unknown>>(
 tenantId: string,
    params: TenantEventControllerGetTenantEventsParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEventControllerGetTenantEvents>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEventControllerGetTenantEventsSuspense<TData = Awaited<ReturnType<typeof tenantEventControllerGetTenantEvents>>, TError = AxiosError<unknown>>(
 tenantId: string,
    params: TenantEventControllerGetTenantEventsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEventControllerGetTenantEvents>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEventControllerGetTenantEventsSuspense<TData = Awaited<ReturnType<typeof tenantEventControllerGetTenantEvents>>, TError = AxiosError<unknown>>(
 tenantId: string,
    params: TenantEventControllerGetTenantEventsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEventControllerGetTenantEvents>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantEventControllerGetTenantEventsSuspense<TData = Awaited<ReturnType<typeof tenantEventControllerGetTenantEvents>>, TError = AxiosError<unknown>>(
 tenantId: string,
    params: TenantEventControllerGetTenantEventsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEventControllerGetTenantEvents>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantEventControllerGetTenantEventsSuspenseQueryOptions(tenantId,params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantEventControllerGetTenantEvent = (
    tenantId: string,
    eventId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantEventControllerGetTenantEvent200>> => {
    
    return axios.get(
      `/v1/${tenantId}/event/${eventId}`,options
    );
  }


export const getTenantEventControllerGetTenantEventQueryKey = (tenantId: string,
    eventId: string,) => {
    return [`/v1/${tenantId}/event/${eventId}`] as const;
    }

    
export const getTenantEventControllerGetTenantEventQueryOptions = <TData = Awaited<ReturnType<typeof tenantEventControllerGetTenantEvent>>, TError = AxiosError<unknown>>(tenantId: string,
    eventId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEventControllerGetTenantEvent>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantEventControllerGetTenantEventQueryKey(tenantId,eventId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantEventControllerGetTenantEvent>>> = ({ signal }) => tenantEventControllerGetTenantEvent(tenantId,eventId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && eventId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantEventControllerGetTenantEvent>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantEventControllerGetTenantEventQueryResult = NonNullable<Awaited<ReturnType<typeof tenantEventControllerGetTenantEvent>>>
export type TenantEventControllerGetTenantEventQueryError = AxiosError<unknown>


export function useTenantEventControllerGetTenantEvent<TData = Awaited<ReturnType<typeof tenantEventControllerGetTenantEvent>>, TError = AxiosError<unknown>>(
 tenantId: string,
    eventId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEventControllerGetTenantEvent>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantEventControllerGetTenantEvent>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEventControllerGetTenantEvent<TData = Awaited<ReturnType<typeof tenantEventControllerGetTenantEvent>>, TError = AxiosError<unknown>>(
 tenantId: string,
    eventId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEventControllerGetTenantEvent>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantEventControllerGetTenantEvent>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEventControllerGetTenantEvent<TData = Awaited<ReturnType<typeof tenantEventControllerGetTenantEvent>>, TError = AxiosError<unknown>>(
 tenantId: string,
    eventId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEventControllerGetTenantEvent>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantEventControllerGetTenantEvent<TData = Awaited<ReturnType<typeof tenantEventControllerGetTenantEvent>>, TError = AxiosError<unknown>>(
 tenantId: string,
    eventId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEventControllerGetTenantEvent>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantEventControllerGetTenantEventQueryOptions(tenantId,eventId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantEventControllerGetTenantEventSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantEventControllerGetTenantEvent>>, TError = AxiosError<unknown>>(tenantId: string,
    eventId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEventControllerGetTenantEvent>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantEventControllerGetTenantEventQueryKey(tenantId,eventId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantEventControllerGetTenantEvent>>> = ({ signal }) => tenantEventControllerGetTenantEvent(tenantId,eventId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && eventId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEventControllerGetTenantEvent>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantEventControllerGetTenantEventSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantEventControllerGetTenantEvent>>>
export type TenantEventControllerGetTenantEventSuspenseQueryError = AxiosError<unknown>


export function useTenantEventControllerGetTenantEventSuspense<TData = Awaited<ReturnType<typeof tenantEventControllerGetTenantEvent>>, TError = AxiosError<unknown>>(
 tenantId: string,
    eventId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEventControllerGetTenantEvent>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEventControllerGetTenantEventSuspense<TData = Awaited<ReturnType<typeof tenantEventControllerGetTenantEvent>>, TError = AxiosError<unknown>>(
 tenantId: string,
    eventId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEventControllerGetTenantEvent>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEventControllerGetTenantEventSuspense<TData = Awaited<ReturnType<typeof tenantEventControllerGetTenantEvent>>, TError = AxiosError<unknown>>(
 tenantId: string,
    eventId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEventControllerGetTenantEvent>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantEventControllerGetTenantEventSuspense<TData = Awaited<ReturnType<typeof tenantEventControllerGetTenantEvent>>, TError = AxiosError<unknown>>(
 tenantId: string,
    eventId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEventControllerGetTenantEvent>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantEventControllerGetTenantEventSuspenseQueryOptions(tenantId,eventId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantEventControllerDeleteEnvironmentEvent = (
    tenantId: string,
    eventId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/v1/${tenantId}/event/${eventId}`,options
    );
  }



export const getTenantEventControllerDeleteEnvironmentEventMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEventControllerDeleteEnvironmentEvent>>, TError,{tenantId: string;eventId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantEventControllerDeleteEnvironmentEvent>>, TError,{tenantId: string;eventId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantEventControllerDeleteEnvironmentEvent>>, {tenantId: string;eventId: string}> = (props) => {
          const {tenantId,eventId} = props ?? {};

          return  tenantEventControllerDeleteEnvironmentEvent(tenantId,eventId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantEventControllerDeleteEnvironmentEventMutationResult = NonNullable<Awaited<ReturnType<typeof tenantEventControllerDeleteEnvironmentEvent>>>
    
    export type TenantEventControllerDeleteEnvironmentEventMutationError = AxiosError<unknown>

    export const useTenantEventControllerDeleteEnvironmentEvent = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEventControllerDeleteEnvironmentEvent>>, TError,{tenantId: string;eventId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantEventControllerDeleteEnvironmentEvent>>,
        TError,
        {tenantId: string;eventId: string},
        TContext
      > => {

      const mutationOptions = getTenantEventControllerDeleteEnvironmentEventMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantEventControllerUpdateTenantEventMatch = (
    tenantId: string,
    eventId: string,
    tenantEventUpdateMatchDto: TenantEventUpdateMatchDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantEventMatchGetDto>> => {
    
    return axios.put(
      `/v1/${tenantId}/event/match/${eventId}`,
      tenantEventUpdateMatchDto,options
    );
  }



export const getTenantEventControllerUpdateTenantEventMatchMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEventControllerUpdateTenantEventMatch>>, TError,{tenantId: string;eventId: string;data: TenantEventUpdateMatchDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantEventControllerUpdateTenantEventMatch>>, TError,{tenantId: string;eventId: string;data: TenantEventUpdateMatchDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantEventControllerUpdateTenantEventMatch>>, {tenantId: string;eventId: string;data: TenantEventUpdateMatchDto}> = (props) => {
          const {tenantId,eventId,data} = props ?? {};

          return  tenantEventControllerUpdateTenantEventMatch(tenantId,eventId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantEventControllerUpdateTenantEventMatchMutationResult = NonNullable<Awaited<ReturnType<typeof tenantEventControllerUpdateTenantEventMatch>>>
    export type TenantEventControllerUpdateTenantEventMatchMutationBody = TenantEventUpdateMatchDto
    export type TenantEventControllerUpdateTenantEventMatchMutationError = AxiosError<unknown>

    export const useTenantEventControllerUpdateTenantEventMatch = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEventControllerUpdateTenantEventMatch>>, TError,{tenantId: string;eventId: string;data: TenantEventUpdateMatchDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantEventControllerUpdateTenantEventMatch>>,
        TError,
        {tenantId: string;eventId: string;data: TenantEventUpdateMatchDto},
        TContext
      > => {

      const mutationOptions = getTenantEventControllerUpdateTenantEventMatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantEventControllerUpdateTenantEventTraining = (
    tenantId: string,
    eventId: string,
    tenantEventUpdateTrainingDto: TenantEventUpdateTrainingDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantEventTrainingGetDto>> => {
    
    return axios.put(
      `/v1/${tenantId}/event/training/${eventId}`,
      tenantEventUpdateTrainingDto,options
    );
  }



export const getTenantEventControllerUpdateTenantEventTrainingMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEventControllerUpdateTenantEventTraining>>, TError,{tenantId: string;eventId: string;data: TenantEventUpdateTrainingDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantEventControllerUpdateTenantEventTraining>>, TError,{tenantId: string;eventId: string;data: TenantEventUpdateTrainingDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantEventControllerUpdateTenantEventTraining>>, {tenantId: string;eventId: string;data: TenantEventUpdateTrainingDto}> = (props) => {
          const {tenantId,eventId,data} = props ?? {};

          return  tenantEventControllerUpdateTenantEventTraining(tenantId,eventId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantEventControllerUpdateTenantEventTrainingMutationResult = NonNullable<Awaited<ReturnType<typeof tenantEventControllerUpdateTenantEventTraining>>>
    export type TenantEventControllerUpdateTenantEventTrainingMutationBody = TenantEventUpdateTrainingDto
    export type TenantEventControllerUpdateTenantEventTrainingMutationError = AxiosError<unknown>

    export const useTenantEventControllerUpdateTenantEventTraining = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEventControllerUpdateTenantEventTraining>>, TError,{tenantId: string;eventId: string;data: TenantEventUpdateTrainingDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantEventControllerUpdateTenantEventTraining>>,
        TError,
        {tenantId: string;eventId: string;data: TenantEventUpdateTrainingDto},
        TContext
      > => {

      const mutationOptions = getTenantEventControllerUpdateTenantEventTrainingMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantEventControllerUpdateTenantEventMedical = (
    tenantId: string,
    eventId: string,
    tenantEventUpdateMedicalDto: TenantEventUpdateMedicalDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantEventMedicalGetDto>> => {
    
    return axios.put(
      `/v1/${tenantId}/event/medical/${eventId}`,
      tenantEventUpdateMedicalDto,options
    );
  }



export const getTenantEventControllerUpdateTenantEventMedicalMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEventControllerUpdateTenantEventMedical>>, TError,{tenantId: string;eventId: string;data: TenantEventUpdateMedicalDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantEventControllerUpdateTenantEventMedical>>, TError,{tenantId: string;eventId: string;data: TenantEventUpdateMedicalDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantEventControllerUpdateTenantEventMedical>>, {tenantId: string;eventId: string;data: TenantEventUpdateMedicalDto}> = (props) => {
          const {tenantId,eventId,data} = props ?? {};

          return  tenantEventControllerUpdateTenantEventMedical(tenantId,eventId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantEventControllerUpdateTenantEventMedicalMutationResult = NonNullable<Awaited<ReturnType<typeof tenantEventControllerUpdateTenantEventMedical>>>
    export type TenantEventControllerUpdateTenantEventMedicalMutationBody = TenantEventUpdateMedicalDto
    export type TenantEventControllerUpdateTenantEventMedicalMutationError = AxiosError<unknown>

    export const useTenantEventControllerUpdateTenantEventMedical = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEventControllerUpdateTenantEventMedical>>, TError,{tenantId: string;eventId: string;data: TenantEventUpdateMedicalDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantEventControllerUpdateTenantEventMedical>>,
        TError,
        {tenantId: string;eventId: string;data: TenantEventUpdateMedicalDto},
        TContext
      > => {

      const mutationOptions = getTenantEventControllerUpdateTenantEventMedicalMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantEventControllerUpdateTenantEventSocial = (
    tenantId: string,
    eventId: string,
    tenantEventUpdateSocialDto: TenantEventUpdateSocialDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantEventSocialGetDto>> => {
    
    return axios.put(
      `/v1/${tenantId}/event/social/${eventId}`,
      tenantEventUpdateSocialDto,options
    );
  }



export const getTenantEventControllerUpdateTenantEventSocialMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEventControllerUpdateTenantEventSocial>>, TError,{tenantId: string;eventId: string;data: TenantEventUpdateSocialDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantEventControllerUpdateTenantEventSocial>>, TError,{tenantId: string;eventId: string;data: TenantEventUpdateSocialDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantEventControllerUpdateTenantEventSocial>>, {tenantId: string;eventId: string;data: TenantEventUpdateSocialDto}> = (props) => {
          const {tenantId,eventId,data} = props ?? {};

          return  tenantEventControllerUpdateTenantEventSocial(tenantId,eventId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantEventControllerUpdateTenantEventSocialMutationResult = NonNullable<Awaited<ReturnType<typeof tenantEventControllerUpdateTenantEventSocial>>>
    export type TenantEventControllerUpdateTenantEventSocialMutationBody = TenantEventUpdateSocialDto
    export type TenantEventControllerUpdateTenantEventSocialMutationError = AxiosError<unknown>

    export const useTenantEventControllerUpdateTenantEventSocial = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEventControllerUpdateTenantEventSocial>>, TError,{tenantId: string;eventId: string;data: TenantEventUpdateSocialDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantEventControllerUpdateTenantEventSocial>>,
        TError,
        {tenantId: string;eventId: string;data: TenantEventUpdateSocialDto},
        TContext
      > => {

      const mutationOptions = getTenantEventControllerUpdateTenantEventSocialMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantEventControllerCreateEnvironmentEventMatch = (
    tenantId: string,
    tenantEventCreateMatchDto: TenantEventCreateMatchDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantEventMatchGetDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/event/match`,
      tenantEventCreateMatchDto,options
    );
  }



export const getTenantEventControllerCreateEnvironmentEventMatchMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEventControllerCreateEnvironmentEventMatch>>, TError,{tenantId: string;data: TenantEventCreateMatchDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantEventControllerCreateEnvironmentEventMatch>>, TError,{tenantId: string;data: TenantEventCreateMatchDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantEventControllerCreateEnvironmentEventMatch>>, {tenantId: string;data: TenantEventCreateMatchDto}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  tenantEventControllerCreateEnvironmentEventMatch(tenantId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantEventControllerCreateEnvironmentEventMatchMutationResult = NonNullable<Awaited<ReturnType<typeof tenantEventControllerCreateEnvironmentEventMatch>>>
    export type TenantEventControllerCreateEnvironmentEventMatchMutationBody = TenantEventCreateMatchDto
    export type TenantEventControllerCreateEnvironmentEventMatchMutationError = AxiosError<unknown>

    export const useTenantEventControllerCreateEnvironmentEventMatch = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEventControllerCreateEnvironmentEventMatch>>, TError,{tenantId: string;data: TenantEventCreateMatchDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantEventControllerCreateEnvironmentEventMatch>>,
        TError,
        {tenantId: string;data: TenantEventCreateMatchDto},
        TContext
      > => {

      const mutationOptions = getTenantEventControllerCreateEnvironmentEventMatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantEventControllerCreateEnvironmentEventTraining = (
    tenantId: string,
    tenantEventCreateTrainingDto: TenantEventCreateTrainingDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantEventTrainingGetDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/event/training`,
      tenantEventCreateTrainingDto,options
    );
  }



export const getTenantEventControllerCreateEnvironmentEventTrainingMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEventControllerCreateEnvironmentEventTraining>>, TError,{tenantId: string;data: TenantEventCreateTrainingDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantEventControllerCreateEnvironmentEventTraining>>, TError,{tenantId: string;data: TenantEventCreateTrainingDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantEventControllerCreateEnvironmentEventTraining>>, {tenantId: string;data: TenantEventCreateTrainingDto}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  tenantEventControllerCreateEnvironmentEventTraining(tenantId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantEventControllerCreateEnvironmentEventTrainingMutationResult = NonNullable<Awaited<ReturnType<typeof tenantEventControllerCreateEnvironmentEventTraining>>>
    export type TenantEventControllerCreateEnvironmentEventTrainingMutationBody = TenantEventCreateTrainingDto
    export type TenantEventControllerCreateEnvironmentEventTrainingMutationError = AxiosError<unknown>

    export const useTenantEventControllerCreateEnvironmentEventTraining = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEventControllerCreateEnvironmentEventTraining>>, TError,{tenantId: string;data: TenantEventCreateTrainingDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantEventControllerCreateEnvironmentEventTraining>>,
        TError,
        {tenantId: string;data: TenantEventCreateTrainingDto},
        TContext
      > => {

      const mutationOptions = getTenantEventControllerCreateEnvironmentEventTrainingMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantEventControllerCreateEnvironmentEventMedical = (
    tenantId: string,
    tenantEventCreateMedicalDto: TenantEventCreateMedicalDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantEventMedicalGetDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/event/medical`,
      tenantEventCreateMedicalDto,options
    );
  }



export const getTenantEventControllerCreateEnvironmentEventMedicalMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEventControllerCreateEnvironmentEventMedical>>, TError,{tenantId: string;data: TenantEventCreateMedicalDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantEventControllerCreateEnvironmentEventMedical>>, TError,{tenantId: string;data: TenantEventCreateMedicalDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantEventControllerCreateEnvironmentEventMedical>>, {tenantId: string;data: TenantEventCreateMedicalDto}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  tenantEventControllerCreateEnvironmentEventMedical(tenantId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantEventControllerCreateEnvironmentEventMedicalMutationResult = NonNullable<Awaited<ReturnType<typeof tenantEventControllerCreateEnvironmentEventMedical>>>
    export type TenantEventControllerCreateEnvironmentEventMedicalMutationBody = TenantEventCreateMedicalDto
    export type TenantEventControllerCreateEnvironmentEventMedicalMutationError = AxiosError<unknown>

    export const useTenantEventControllerCreateEnvironmentEventMedical = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEventControllerCreateEnvironmentEventMedical>>, TError,{tenantId: string;data: TenantEventCreateMedicalDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantEventControllerCreateEnvironmentEventMedical>>,
        TError,
        {tenantId: string;data: TenantEventCreateMedicalDto},
        TContext
      > => {

      const mutationOptions = getTenantEventControllerCreateEnvironmentEventMedicalMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantEventControllerCreateEnvironmentEventSocial = (
    tenantId: string,
    tenantEventCreateSocialDto: TenantEventCreateSocialDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantEventSocialGetDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/event/social`,
      tenantEventCreateSocialDto,options
    );
  }



export const getTenantEventControllerCreateEnvironmentEventSocialMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEventControllerCreateEnvironmentEventSocial>>, TError,{tenantId: string;data: TenantEventCreateSocialDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantEventControllerCreateEnvironmentEventSocial>>, TError,{tenantId: string;data: TenantEventCreateSocialDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantEventControllerCreateEnvironmentEventSocial>>, {tenantId: string;data: TenantEventCreateSocialDto}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  tenantEventControllerCreateEnvironmentEventSocial(tenantId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantEventControllerCreateEnvironmentEventSocialMutationResult = NonNullable<Awaited<ReturnType<typeof tenantEventControllerCreateEnvironmentEventSocial>>>
    export type TenantEventControllerCreateEnvironmentEventSocialMutationBody = TenantEventCreateSocialDto
    export type TenantEventControllerCreateEnvironmentEventSocialMutationError = AxiosError<unknown>

    export const useTenantEventControllerCreateEnvironmentEventSocial = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEventControllerCreateEnvironmentEventSocial>>, TError,{tenantId: string;data: TenantEventCreateSocialDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantEventControllerCreateEnvironmentEventSocial>>,
        TError,
        {tenantId: string;data: TenantEventCreateSocialDto},
        TContext
      > => {

      const mutationOptions = getTenantEventControllerCreateEnvironmentEventSocialMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get "public" information about all the users in the current tenant
 */
export const tenantUserControllerGetAllTenantUsersPublic = (
    tenantId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<UserGetAllPublicDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/users/users/public`,options
    );
  }


export const getTenantUserControllerGetAllTenantUsersPublicQueryKey = (tenantId: string,) => {
    return [`/v1/${tenantId}/users/users/public`] as const;
    }

    
export const getTenantUserControllerGetAllTenantUsersPublicQueryOptions = <TData = Awaited<ReturnType<typeof tenantUserControllerGetAllTenantUsersPublic>>, TError = AxiosError<unknown>>(tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantUserControllerGetAllTenantUsersPublic>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantUserControllerGetAllTenantUsersPublicQueryKey(tenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantUserControllerGetAllTenantUsersPublic>>> = ({ signal }) => tenantUserControllerGetAllTenantUsersPublic(tenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantUserControllerGetAllTenantUsersPublic>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantUserControllerGetAllTenantUsersPublicQueryResult = NonNullable<Awaited<ReturnType<typeof tenantUserControllerGetAllTenantUsersPublic>>>
export type TenantUserControllerGetAllTenantUsersPublicQueryError = AxiosError<unknown>


export function useTenantUserControllerGetAllTenantUsersPublic<TData = Awaited<ReturnType<typeof tenantUserControllerGetAllTenantUsersPublic>>, TError = AxiosError<unknown>>(
 tenantId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantUserControllerGetAllTenantUsersPublic>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantUserControllerGetAllTenantUsersPublic>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantUserControllerGetAllTenantUsersPublic<TData = Awaited<ReturnType<typeof tenantUserControllerGetAllTenantUsersPublic>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantUserControllerGetAllTenantUsersPublic>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantUserControllerGetAllTenantUsersPublic>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantUserControllerGetAllTenantUsersPublic<TData = Awaited<ReturnType<typeof tenantUserControllerGetAllTenantUsersPublic>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantUserControllerGetAllTenantUsersPublic>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantUserControllerGetAllTenantUsersPublic<TData = Awaited<ReturnType<typeof tenantUserControllerGetAllTenantUsersPublic>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantUserControllerGetAllTenantUsersPublic>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantUserControllerGetAllTenantUsersPublicQueryOptions(tenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantUserControllerGetAllTenantUsersPublicSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantUserControllerGetAllTenantUsersPublic>>, TError = AxiosError<unknown>>(tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantUserControllerGetAllTenantUsersPublic>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantUserControllerGetAllTenantUsersPublicQueryKey(tenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantUserControllerGetAllTenantUsersPublic>>> = ({ signal }) => tenantUserControllerGetAllTenantUsersPublic(tenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantUserControllerGetAllTenantUsersPublic>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantUserControllerGetAllTenantUsersPublicSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantUserControllerGetAllTenantUsersPublic>>>
export type TenantUserControllerGetAllTenantUsersPublicSuspenseQueryError = AxiosError<unknown>


export function useTenantUserControllerGetAllTenantUsersPublicSuspense<TData = Awaited<ReturnType<typeof tenantUserControllerGetAllTenantUsersPublic>>, TError = AxiosError<unknown>>(
 tenantId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantUserControllerGetAllTenantUsersPublic>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantUserControllerGetAllTenantUsersPublicSuspense<TData = Awaited<ReturnType<typeof tenantUserControllerGetAllTenantUsersPublic>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantUserControllerGetAllTenantUsersPublic>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantUserControllerGetAllTenantUsersPublicSuspense<TData = Awaited<ReturnType<typeof tenantUserControllerGetAllTenantUsersPublic>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantUserControllerGetAllTenantUsersPublic>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantUserControllerGetAllTenantUsersPublicSuspense<TData = Awaited<ReturnType<typeof tenantUserControllerGetAllTenantUsersPublic>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantUserControllerGetAllTenantUsersPublic>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantUserControllerGetAllTenantUsersPublicSuspenseQueryOptions(tenantId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * List all Users for the current tenant
 */
export const tenantUserControllerGetAllUsers = (
    tenantId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantUserGetAllDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/users/users`,options
    );
  }


export const getTenantUserControllerGetAllUsersQueryKey = (tenantId: string,) => {
    return [`/v1/${tenantId}/users/users`] as const;
    }

    
export const getTenantUserControllerGetAllUsersQueryOptions = <TData = Awaited<ReturnType<typeof tenantUserControllerGetAllUsers>>, TError = AxiosError<unknown>>(tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantUserControllerGetAllUsers>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantUserControllerGetAllUsersQueryKey(tenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantUserControllerGetAllUsers>>> = ({ signal }) => tenantUserControllerGetAllUsers(tenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantUserControllerGetAllUsers>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantUserControllerGetAllUsersQueryResult = NonNullable<Awaited<ReturnType<typeof tenantUserControllerGetAllUsers>>>
export type TenantUserControllerGetAllUsersQueryError = AxiosError<unknown>


export function useTenantUserControllerGetAllUsers<TData = Awaited<ReturnType<typeof tenantUserControllerGetAllUsers>>, TError = AxiosError<unknown>>(
 tenantId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantUserControllerGetAllUsers>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantUserControllerGetAllUsers>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantUserControllerGetAllUsers<TData = Awaited<ReturnType<typeof tenantUserControllerGetAllUsers>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantUserControllerGetAllUsers>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantUserControllerGetAllUsers>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantUserControllerGetAllUsers<TData = Awaited<ReturnType<typeof tenantUserControllerGetAllUsers>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantUserControllerGetAllUsers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantUserControllerGetAllUsers<TData = Awaited<ReturnType<typeof tenantUserControllerGetAllUsers>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantUserControllerGetAllUsers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantUserControllerGetAllUsersQueryOptions(tenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantUserControllerGetAllUsersSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantUserControllerGetAllUsers>>, TError = AxiosError<unknown>>(tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantUserControllerGetAllUsers>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantUserControllerGetAllUsersQueryKey(tenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantUserControllerGetAllUsers>>> = ({ signal }) => tenantUserControllerGetAllUsers(tenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantUserControllerGetAllUsers>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantUserControllerGetAllUsersSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantUserControllerGetAllUsers>>>
export type TenantUserControllerGetAllUsersSuspenseQueryError = AxiosError<unknown>


export function useTenantUserControllerGetAllUsersSuspense<TData = Awaited<ReturnType<typeof tenantUserControllerGetAllUsers>>, TError = AxiosError<unknown>>(
 tenantId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantUserControllerGetAllUsers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantUserControllerGetAllUsersSuspense<TData = Awaited<ReturnType<typeof tenantUserControllerGetAllUsers>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantUserControllerGetAllUsers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantUserControllerGetAllUsersSuspense<TData = Awaited<ReturnType<typeof tenantUserControllerGetAllUsers>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantUserControllerGetAllUsers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantUserControllerGetAllUsersSuspense<TData = Awaited<ReturnType<typeof tenantUserControllerGetAllUsers>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantUserControllerGetAllUsers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantUserControllerGetAllUsersSuspenseQueryOptions(tenantId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantUserControllerCreateUser = (
    tenantId: string,
    tenantUserCreateDto: TenantUserCreateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantUserGetAllDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/users/users`,
      tenantUserCreateDto,options
    );
  }



export const getTenantUserControllerCreateUserMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantUserControllerCreateUser>>, TError,{tenantId: string;data: TenantUserCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantUserControllerCreateUser>>, TError,{tenantId: string;data: TenantUserCreateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantUserControllerCreateUser>>, {tenantId: string;data: TenantUserCreateDto}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  tenantUserControllerCreateUser(tenantId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantUserControllerCreateUserMutationResult = NonNullable<Awaited<ReturnType<typeof tenantUserControllerCreateUser>>>
    export type TenantUserControllerCreateUserMutationBody = TenantUserCreateDto
    export type TenantUserControllerCreateUserMutationError = AxiosError<unknown>

    export const useTenantUserControllerCreateUser = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantUserControllerCreateUser>>, TError,{tenantId: string;data: TenantUserCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantUserControllerCreateUser>>,
        TError,
        {tenantId: string;data: TenantUserCreateDto},
        TContext
      > => {

      const mutationOptions = getTenantUserControllerCreateUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantUserControllerUpdateUser = (
    tenantId: string,
    userId: string,
    tenantUserUpdateDto: TenantUserUpdateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantUserGetAllDto>> => {
    
    return axios.put(
      `/v1/${tenantId}/users/users/${userId}`,
      tenantUserUpdateDto,options
    );
  }



export const getTenantUserControllerUpdateUserMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantUserControllerUpdateUser>>, TError,{tenantId: string;userId: string;data: TenantUserUpdateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantUserControllerUpdateUser>>, TError,{tenantId: string;userId: string;data: TenantUserUpdateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantUserControllerUpdateUser>>, {tenantId: string;userId: string;data: TenantUserUpdateDto}> = (props) => {
          const {tenantId,userId,data} = props ?? {};

          return  tenantUserControllerUpdateUser(tenantId,userId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantUserControllerUpdateUserMutationResult = NonNullable<Awaited<ReturnType<typeof tenantUserControllerUpdateUser>>>
    export type TenantUserControllerUpdateUserMutationBody = TenantUserUpdateDto
    export type TenantUserControllerUpdateUserMutationError = AxiosError<unknown>

    export const useTenantUserControllerUpdateUser = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantUserControllerUpdateUser>>, TError,{tenantId: string;userId: string;data: TenantUserUpdateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantUserControllerUpdateUser>>,
        TError,
        {tenantId: string;userId: string;data: TenantUserUpdateDto},
        TContext
      > => {

      const mutationOptions = getTenantUserControllerUpdateUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const healthControllerPing = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.get(
      `/v1/health/ping`,options
    );
  }


export const getHealthControllerPingQueryKey = () => {
    return [`/v1/health/ping`] as const;
    }

    
export const getHealthControllerPingQueryOptions = <TData = Awaited<ReturnType<typeof healthControllerPing>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof healthControllerPing>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getHealthControllerPingQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof healthControllerPing>>> = ({ signal }) => healthControllerPing({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof healthControllerPing>>, TError, TData> & { queryKey: QueryKey }
}

export type HealthControllerPingQueryResult = NonNullable<Awaited<ReturnType<typeof healthControllerPing>>>
export type HealthControllerPingQueryError = AxiosError<unknown>


export function useHealthControllerPing<TData = Awaited<ReturnType<typeof healthControllerPing>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof healthControllerPing>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof healthControllerPing>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useHealthControllerPing<TData = Awaited<ReturnType<typeof healthControllerPing>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof healthControllerPing>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof healthControllerPing>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useHealthControllerPing<TData = Awaited<ReturnType<typeof healthControllerPing>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof healthControllerPing>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useHealthControllerPing<TData = Awaited<ReturnType<typeof healthControllerPing>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof healthControllerPing>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getHealthControllerPingQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getHealthControllerPingSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof healthControllerPing>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof healthControllerPing>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getHealthControllerPingQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof healthControllerPing>>> = ({ signal }) => healthControllerPing({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof healthControllerPing>>, TError, TData> & { queryKey: QueryKey }
}

export type HealthControllerPingSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof healthControllerPing>>>
export type HealthControllerPingSuspenseQueryError = AxiosError<unknown>


export function useHealthControllerPingSuspense<TData = Awaited<ReturnType<typeof healthControllerPing>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof healthControllerPing>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useHealthControllerPingSuspense<TData = Awaited<ReturnType<typeof healthControllerPing>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof healthControllerPing>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useHealthControllerPingSuspense<TData = Awaited<ReturnType<typeof healthControllerPing>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof healthControllerPing>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useHealthControllerPingSuspense<TData = Awaited<ReturnType<typeof healthControllerPing>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof healthControllerPing>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getHealthControllerPingSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const healthControllerPong = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.get(
      `/v1/health/point`,options
    );
  }


export const getHealthControllerPongQueryKey = () => {
    return [`/v1/health/point`] as const;
    }

    
export const getHealthControllerPongQueryOptions = <TData = Awaited<ReturnType<typeof healthControllerPong>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof healthControllerPong>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getHealthControllerPongQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof healthControllerPong>>> = ({ signal }) => healthControllerPong({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof healthControllerPong>>, TError, TData> & { queryKey: QueryKey }
}

export type HealthControllerPongQueryResult = NonNullable<Awaited<ReturnType<typeof healthControllerPong>>>
export type HealthControllerPongQueryError = AxiosError<unknown>


export function useHealthControllerPong<TData = Awaited<ReturnType<typeof healthControllerPong>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof healthControllerPong>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof healthControllerPong>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useHealthControllerPong<TData = Awaited<ReturnType<typeof healthControllerPong>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof healthControllerPong>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof healthControllerPong>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useHealthControllerPong<TData = Awaited<ReturnType<typeof healthControllerPong>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof healthControllerPong>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useHealthControllerPong<TData = Awaited<ReturnType<typeof healthControllerPong>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof healthControllerPong>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getHealthControllerPongQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getHealthControllerPongSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof healthControllerPong>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof healthControllerPong>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getHealthControllerPongQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof healthControllerPong>>> = ({ signal }) => healthControllerPong({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof healthControllerPong>>, TError, TData> & { queryKey: QueryKey }
}

export type HealthControllerPongSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof healthControllerPong>>>
export type HealthControllerPongSuspenseQueryError = AxiosError<unknown>


export function useHealthControllerPongSuspense<TData = Awaited<ReturnType<typeof healthControllerPong>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof healthControllerPong>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useHealthControllerPongSuspense<TData = Awaited<ReturnType<typeof healthControllerPong>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof healthControllerPong>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useHealthControllerPongSuspense<TData = Awaited<ReturnType<typeof healthControllerPong>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof healthControllerPong>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useHealthControllerPongSuspense<TData = Awaited<ReturnType<typeof healthControllerPong>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof healthControllerPong>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getHealthControllerPongSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const playerControllerGetPlayerProfile = (
    tenantId: string,
    environmentId: string,
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PlayerProfileDto>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/players/${id}`,options
    );
  }


export const getPlayerControllerGetPlayerProfileQueryKey = (tenantId: string,
    environmentId: string,
    id: string,) => {
    return [`/v1/${tenantId}/${environmentId}/players/${id}`] as const;
    }

    
export const getPlayerControllerGetPlayerProfileQueryOptions = <TData = Awaited<ReturnType<typeof playerControllerGetPlayerProfile>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerProfile>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPlayerControllerGetPlayerProfileQueryKey(tenantId,environmentId,id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof playerControllerGetPlayerProfile>>> = ({ signal }) => playerControllerGetPlayerProfile(tenantId,environmentId,id, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerProfile>>, TError, TData> & { queryKey: QueryKey }
}

export type PlayerControllerGetPlayerProfileQueryResult = NonNullable<Awaited<ReturnType<typeof playerControllerGetPlayerProfile>>>
export type PlayerControllerGetPlayerProfileQueryError = AxiosError<unknown>


export function usePlayerControllerGetPlayerProfile<TData = Awaited<ReturnType<typeof playerControllerGetPlayerProfile>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    id: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerProfile>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof playerControllerGetPlayerProfile>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerControllerGetPlayerProfile<TData = Awaited<ReturnType<typeof playerControllerGetPlayerProfile>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerProfile>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof playerControllerGetPlayerProfile>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerControllerGetPlayerProfile<TData = Awaited<ReturnType<typeof playerControllerGetPlayerProfile>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerProfile>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function usePlayerControllerGetPlayerProfile<TData = Awaited<ReturnType<typeof playerControllerGetPlayerProfile>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerProfile>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getPlayerControllerGetPlayerProfileQueryOptions(tenantId,environmentId,id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getPlayerControllerGetPlayerProfileSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof playerControllerGetPlayerProfile>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerProfile>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPlayerControllerGetPlayerProfileQueryKey(tenantId,environmentId,id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof playerControllerGetPlayerProfile>>> = ({ signal }) => playerControllerGetPlayerProfile(tenantId,environmentId,id, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && id), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerProfile>>, TError, TData> & { queryKey: QueryKey }
}

export type PlayerControllerGetPlayerProfileSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof playerControllerGetPlayerProfile>>>
export type PlayerControllerGetPlayerProfileSuspenseQueryError = AxiosError<unknown>


export function usePlayerControllerGetPlayerProfileSuspense<TData = Awaited<ReturnType<typeof playerControllerGetPlayerProfile>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    id: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerProfile>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerControllerGetPlayerProfileSuspense<TData = Awaited<ReturnType<typeof playerControllerGetPlayerProfile>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerProfile>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerControllerGetPlayerProfileSuspense<TData = Awaited<ReturnType<typeof playerControllerGetPlayerProfile>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerProfile>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function usePlayerControllerGetPlayerProfileSuspense<TData = Awaited<ReturnType<typeof playerControllerGetPlayerProfile>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerProfile>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getPlayerControllerGetPlayerProfileSuspenseQueryOptions(tenantId,environmentId,id,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const playerControllerGetPlayerScoutingProfile = (
    tenantId: string,
    environmentId: string,
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PlayerScoutingProfileDto>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/players/${id}/scouting`,options
    );
  }


export const getPlayerControllerGetPlayerScoutingProfileQueryKey = (tenantId: string,
    environmentId: string,
    id: string,) => {
    return [`/v1/${tenantId}/${environmentId}/players/${id}/scouting`] as const;
    }

    
export const getPlayerControllerGetPlayerScoutingProfileQueryOptions = <TData = Awaited<ReturnType<typeof playerControllerGetPlayerScoutingProfile>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerScoutingProfile>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPlayerControllerGetPlayerScoutingProfileQueryKey(tenantId,environmentId,id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof playerControllerGetPlayerScoutingProfile>>> = ({ signal }) => playerControllerGetPlayerScoutingProfile(tenantId,environmentId,id, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerScoutingProfile>>, TError, TData> & { queryKey: QueryKey }
}

export type PlayerControllerGetPlayerScoutingProfileQueryResult = NonNullable<Awaited<ReturnType<typeof playerControllerGetPlayerScoutingProfile>>>
export type PlayerControllerGetPlayerScoutingProfileQueryError = AxiosError<unknown>


export function usePlayerControllerGetPlayerScoutingProfile<TData = Awaited<ReturnType<typeof playerControllerGetPlayerScoutingProfile>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    id: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerScoutingProfile>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof playerControllerGetPlayerScoutingProfile>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerControllerGetPlayerScoutingProfile<TData = Awaited<ReturnType<typeof playerControllerGetPlayerScoutingProfile>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerScoutingProfile>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof playerControllerGetPlayerScoutingProfile>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerControllerGetPlayerScoutingProfile<TData = Awaited<ReturnType<typeof playerControllerGetPlayerScoutingProfile>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerScoutingProfile>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function usePlayerControllerGetPlayerScoutingProfile<TData = Awaited<ReturnType<typeof playerControllerGetPlayerScoutingProfile>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerScoutingProfile>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getPlayerControllerGetPlayerScoutingProfileQueryOptions(tenantId,environmentId,id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getPlayerControllerGetPlayerScoutingProfileSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof playerControllerGetPlayerScoutingProfile>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerScoutingProfile>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPlayerControllerGetPlayerScoutingProfileQueryKey(tenantId,environmentId,id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof playerControllerGetPlayerScoutingProfile>>> = ({ signal }) => playerControllerGetPlayerScoutingProfile(tenantId,environmentId,id, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && id), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerScoutingProfile>>, TError, TData> & { queryKey: QueryKey }
}

export type PlayerControllerGetPlayerScoutingProfileSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof playerControllerGetPlayerScoutingProfile>>>
export type PlayerControllerGetPlayerScoutingProfileSuspenseQueryError = AxiosError<unknown>


export function usePlayerControllerGetPlayerScoutingProfileSuspense<TData = Awaited<ReturnType<typeof playerControllerGetPlayerScoutingProfile>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    id: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerScoutingProfile>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerControllerGetPlayerScoutingProfileSuspense<TData = Awaited<ReturnType<typeof playerControllerGetPlayerScoutingProfile>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerScoutingProfile>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerControllerGetPlayerScoutingProfileSuspense<TData = Awaited<ReturnType<typeof playerControllerGetPlayerScoutingProfile>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerScoutingProfile>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function usePlayerControllerGetPlayerScoutingProfileSuspense<TData = Awaited<ReturnType<typeof playerControllerGetPlayerScoutingProfile>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerScoutingProfile>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getPlayerControllerGetPlayerScoutingProfileSuspenseQueryOptions(tenantId,environmentId,id,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const playerControllerGetPlayerCoreData = (
    tenantId: string,
    environmentId: string,
    playerId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PlayerCoredataGetDto>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/players/${playerId}/coredata`,options
    );
  }


export const getPlayerControllerGetPlayerCoreDataQueryKey = (tenantId: string,
    environmentId: string,
    playerId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/players/${playerId}/coredata`] as const;
    }

    
export const getPlayerControllerGetPlayerCoreDataQueryOptions = <TData = Awaited<ReturnType<typeof playerControllerGetPlayerCoreData>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerCoreData>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPlayerControllerGetPlayerCoreDataQueryKey(tenantId,environmentId,playerId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof playerControllerGetPlayerCoreData>>> = ({ signal }) => playerControllerGetPlayerCoreData(tenantId,environmentId,playerId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && playerId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerCoreData>>, TError, TData> & { queryKey: QueryKey }
}

export type PlayerControllerGetPlayerCoreDataQueryResult = NonNullable<Awaited<ReturnType<typeof playerControllerGetPlayerCoreData>>>
export type PlayerControllerGetPlayerCoreDataQueryError = AxiosError<unknown>


export function usePlayerControllerGetPlayerCoreData<TData = Awaited<ReturnType<typeof playerControllerGetPlayerCoreData>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerCoreData>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof playerControllerGetPlayerCoreData>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerControllerGetPlayerCoreData<TData = Awaited<ReturnType<typeof playerControllerGetPlayerCoreData>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerCoreData>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof playerControllerGetPlayerCoreData>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerControllerGetPlayerCoreData<TData = Awaited<ReturnType<typeof playerControllerGetPlayerCoreData>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerCoreData>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function usePlayerControllerGetPlayerCoreData<TData = Awaited<ReturnType<typeof playerControllerGetPlayerCoreData>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerCoreData>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getPlayerControllerGetPlayerCoreDataQueryOptions(tenantId,environmentId,playerId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getPlayerControllerGetPlayerCoreDataSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof playerControllerGetPlayerCoreData>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerCoreData>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPlayerControllerGetPlayerCoreDataQueryKey(tenantId,environmentId,playerId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof playerControllerGetPlayerCoreData>>> = ({ signal }) => playerControllerGetPlayerCoreData(tenantId,environmentId,playerId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && playerId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerCoreData>>, TError, TData> & { queryKey: QueryKey }
}

export type PlayerControllerGetPlayerCoreDataSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof playerControllerGetPlayerCoreData>>>
export type PlayerControllerGetPlayerCoreDataSuspenseQueryError = AxiosError<unknown>


export function usePlayerControllerGetPlayerCoreDataSuspense<TData = Awaited<ReturnType<typeof playerControllerGetPlayerCoreData>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerCoreData>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerControllerGetPlayerCoreDataSuspense<TData = Awaited<ReturnType<typeof playerControllerGetPlayerCoreData>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerCoreData>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerControllerGetPlayerCoreDataSuspense<TData = Awaited<ReturnType<typeof playerControllerGetPlayerCoreData>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerCoreData>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function usePlayerControllerGetPlayerCoreDataSuspense<TData = Awaited<ReturnType<typeof playerControllerGetPlayerCoreData>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerCoreData>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getPlayerControllerGetPlayerCoreDataSuspenseQueryOptions(tenantId,environmentId,playerId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const playerControllerGetPlayerAdditionalDataForEnvironment = (
    tenantId: string,
    environmentId: string,
    playerId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PlayerAdditionaldataGetDto>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/players/${playerId}/additionaldata/environment`,options
    );
  }


export const getPlayerControllerGetPlayerAdditionalDataForEnvironmentQueryKey = (tenantId: string,
    environmentId: string,
    playerId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/players/${playerId}/additionaldata/environment`] as const;
    }

    
export const getPlayerControllerGetPlayerAdditionalDataForEnvironmentQueryOptions = <TData = Awaited<ReturnType<typeof playerControllerGetPlayerAdditionalDataForEnvironment>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerAdditionalDataForEnvironment>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPlayerControllerGetPlayerAdditionalDataForEnvironmentQueryKey(tenantId,environmentId,playerId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof playerControllerGetPlayerAdditionalDataForEnvironment>>> = ({ signal }) => playerControllerGetPlayerAdditionalDataForEnvironment(tenantId,environmentId,playerId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && playerId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerAdditionalDataForEnvironment>>, TError, TData> & { queryKey: QueryKey }
}

export type PlayerControllerGetPlayerAdditionalDataForEnvironmentQueryResult = NonNullable<Awaited<ReturnType<typeof playerControllerGetPlayerAdditionalDataForEnvironment>>>
export type PlayerControllerGetPlayerAdditionalDataForEnvironmentQueryError = AxiosError<unknown>


export function usePlayerControllerGetPlayerAdditionalDataForEnvironment<TData = Awaited<ReturnType<typeof playerControllerGetPlayerAdditionalDataForEnvironment>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerAdditionalDataForEnvironment>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof playerControllerGetPlayerAdditionalDataForEnvironment>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerControllerGetPlayerAdditionalDataForEnvironment<TData = Awaited<ReturnType<typeof playerControllerGetPlayerAdditionalDataForEnvironment>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerAdditionalDataForEnvironment>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof playerControllerGetPlayerAdditionalDataForEnvironment>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerControllerGetPlayerAdditionalDataForEnvironment<TData = Awaited<ReturnType<typeof playerControllerGetPlayerAdditionalDataForEnvironment>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerAdditionalDataForEnvironment>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function usePlayerControllerGetPlayerAdditionalDataForEnvironment<TData = Awaited<ReturnType<typeof playerControllerGetPlayerAdditionalDataForEnvironment>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerAdditionalDataForEnvironment>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getPlayerControllerGetPlayerAdditionalDataForEnvironmentQueryOptions(tenantId,environmentId,playerId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getPlayerControllerGetPlayerAdditionalDataForEnvironmentSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof playerControllerGetPlayerAdditionalDataForEnvironment>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerAdditionalDataForEnvironment>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPlayerControllerGetPlayerAdditionalDataForEnvironmentQueryKey(tenantId,environmentId,playerId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof playerControllerGetPlayerAdditionalDataForEnvironment>>> = ({ signal }) => playerControllerGetPlayerAdditionalDataForEnvironment(tenantId,environmentId,playerId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && playerId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerAdditionalDataForEnvironment>>, TError, TData> & { queryKey: QueryKey }
}

export type PlayerControllerGetPlayerAdditionalDataForEnvironmentSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof playerControllerGetPlayerAdditionalDataForEnvironment>>>
export type PlayerControllerGetPlayerAdditionalDataForEnvironmentSuspenseQueryError = AxiosError<unknown>


export function usePlayerControllerGetPlayerAdditionalDataForEnvironmentSuspense<TData = Awaited<ReturnType<typeof playerControllerGetPlayerAdditionalDataForEnvironment>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerAdditionalDataForEnvironment>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerControllerGetPlayerAdditionalDataForEnvironmentSuspense<TData = Awaited<ReturnType<typeof playerControllerGetPlayerAdditionalDataForEnvironment>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerAdditionalDataForEnvironment>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerControllerGetPlayerAdditionalDataForEnvironmentSuspense<TData = Awaited<ReturnType<typeof playerControllerGetPlayerAdditionalDataForEnvironment>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerAdditionalDataForEnvironment>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function usePlayerControllerGetPlayerAdditionalDataForEnvironmentSuspense<TData = Awaited<ReturnType<typeof playerControllerGetPlayerAdditionalDataForEnvironment>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerControllerGetPlayerAdditionalDataForEnvironment>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getPlayerControllerGetPlayerAdditionalDataForEnvironmentSuspenseQueryOptions(tenantId,environmentId,playerId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const playerControllerUpdatePlayerAdditionalDataForEnvironment = (
    tenantId: string,
    environmentId: string,
    playerId: string,
    playerAdditionaldataPutDto: PlayerAdditionaldataPutDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `/v1/${tenantId}/${environmentId}/players/${playerId}/additionaldata/environment`,
      playerAdditionaldataPutDto,options
    );
  }



export const getPlayerControllerUpdatePlayerAdditionalDataForEnvironmentMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof playerControllerUpdatePlayerAdditionalDataForEnvironment>>, TError,{tenantId: string;environmentId: string;playerId: string;data: PlayerAdditionaldataPutDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof playerControllerUpdatePlayerAdditionalDataForEnvironment>>, TError,{tenantId: string;environmentId: string;playerId: string;data: PlayerAdditionaldataPutDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof playerControllerUpdatePlayerAdditionalDataForEnvironment>>, {tenantId: string;environmentId: string;playerId: string;data: PlayerAdditionaldataPutDto}> = (props) => {
          const {tenantId,environmentId,playerId,data} = props ?? {};

          return  playerControllerUpdatePlayerAdditionalDataForEnvironment(tenantId,environmentId,playerId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PlayerControllerUpdatePlayerAdditionalDataForEnvironmentMutationResult = NonNullable<Awaited<ReturnType<typeof playerControllerUpdatePlayerAdditionalDataForEnvironment>>>
    export type PlayerControllerUpdatePlayerAdditionalDataForEnvironmentMutationBody = PlayerAdditionaldataPutDto
    export type PlayerControllerUpdatePlayerAdditionalDataForEnvironmentMutationError = AxiosError<unknown>

    export const usePlayerControllerUpdatePlayerAdditionalDataForEnvironment = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof playerControllerUpdatePlayerAdditionalDataForEnvironment>>, TError,{tenantId: string;environmentId: string;playerId: string;data: PlayerAdditionaldataPutDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof playerControllerUpdatePlayerAdditionalDataForEnvironment>>,
        TError,
        {tenantId: string;environmentId: string;playerId: string;data: PlayerAdditionaldataPutDto},
        TContext
      > => {

      const mutationOptions = getPlayerControllerUpdatePlayerAdditionalDataForEnvironmentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const playerControllerUpdateExternalPlayerAdditionalDataForEnvironment = (
    tenantId: string,
    environmentId: string,
    playerId: string,
    playerAdditionaldataExternalPutDto: PlayerAdditionaldataExternalPutDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `/v1/${tenantId}/${environmentId}/players/${playerId}/additionaldataexternal/environment`,
      playerAdditionaldataExternalPutDto,options
    );
  }



export const getPlayerControllerUpdateExternalPlayerAdditionalDataForEnvironmentMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof playerControllerUpdateExternalPlayerAdditionalDataForEnvironment>>, TError,{tenantId: string;environmentId: string;playerId: string;data: PlayerAdditionaldataExternalPutDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof playerControllerUpdateExternalPlayerAdditionalDataForEnvironment>>, TError,{tenantId: string;environmentId: string;playerId: string;data: PlayerAdditionaldataExternalPutDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof playerControllerUpdateExternalPlayerAdditionalDataForEnvironment>>, {tenantId: string;environmentId: string;playerId: string;data: PlayerAdditionaldataExternalPutDto}> = (props) => {
          const {tenantId,environmentId,playerId,data} = props ?? {};

          return  playerControllerUpdateExternalPlayerAdditionalDataForEnvironment(tenantId,environmentId,playerId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PlayerControllerUpdateExternalPlayerAdditionalDataForEnvironmentMutationResult = NonNullable<Awaited<ReturnType<typeof playerControllerUpdateExternalPlayerAdditionalDataForEnvironment>>>
    export type PlayerControllerUpdateExternalPlayerAdditionalDataForEnvironmentMutationBody = PlayerAdditionaldataExternalPutDto
    export type PlayerControllerUpdateExternalPlayerAdditionalDataForEnvironmentMutationError = AxiosError<unknown>

    export const usePlayerControllerUpdateExternalPlayerAdditionalDataForEnvironment = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof playerControllerUpdateExternalPlayerAdditionalDataForEnvironment>>, TError,{tenantId: string;environmentId: string;playerId: string;data: PlayerAdditionaldataExternalPutDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof playerControllerUpdateExternalPlayerAdditionalDataForEnvironment>>,
        TError,
        {tenantId: string;environmentId: string;playerId: string;data: PlayerAdditionaldataExternalPutDto},
        TContext
      > => {

      const mutationOptions = getPlayerControllerUpdateExternalPlayerAdditionalDataForEnvironmentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const legacyscoutingControllerGetPlayerStatsConfig = (
    tenantId: string,
    environmentId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/legacyscouting/statsconfig`,options
    );
  }


export const getLegacyscoutingControllerGetPlayerStatsConfigQueryKey = (tenantId: string,
    environmentId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/legacyscouting/statsconfig`] as const;
    }

    
export const getLegacyscoutingControllerGetPlayerStatsConfigQueryOptions = <TData = Awaited<ReturnType<typeof legacyscoutingControllerGetPlayerStatsConfig>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetPlayerStatsConfig>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getLegacyscoutingControllerGetPlayerStatsConfigQueryKey(tenantId,environmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof legacyscoutingControllerGetPlayerStatsConfig>>> = ({ signal }) => legacyscoutingControllerGetPlayerStatsConfig(tenantId,environmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetPlayerStatsConfig>>, TError, TData> & { queryKey: QueryKey }
}

export type LegacyscoutingControllerGetPlayerStatsConfigQueryResult = NonNullable<Awaited<ReturnType<typeof legacyscoutingControllerGetPlayerStatsConfig>>>
export type LegacyscoutingControllerGetPlayerStatsConfigQueryError = AxiosError<unknown>


export function useLegacyscoutingControllerGetPlayerStatsConfig<TData = Awaited<ReturnType<typeof legacyscoutingControllerGetPlayerStatsConfig>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetPlayerStatsConfig>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof legacyscoutingControllerGetPlayerStatsConfig>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useLegacyscoutingControllerGetPlayerStatsConfig<TData = Awaited<ReturnType<typeof legacyscoutingControllerGetPlayerStatsConfig>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetPlayerStatsConfig>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof legacyscoutingControllerGetPlayerStatsConfig>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useLegacyscoutingControllerGetPlayerStatsConfig<TData = Awaited<ReturnType<typeof legacyscoutingControllerGetPlayerStatsConfig>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetPlayerStatsConfig>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useLegacyscoutingControllerGetPlayerStatsConfig<TData = Awaited<ReturnType<typeof legacyscoutingControllerGetPlayerStatsConfig>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetPlayerStatsConfig>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getLegacyscoutingControllerGetPlayerStatsConfigQueryOptions(tenantId,environmentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getLegacyscoutingControllerGetPlayerStatsConfigSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof legacyscoutingControllerGetPlayerStatsConfig>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetPlayerStatsConfig>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getLegacyscoutingControllerGetPlayerStatsConfigQueryKey(tenantId,environmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof legacyscoutingControllerGetPlayerStatsConfig>>> = ({ signal }) => legacyscoutingControllerGetPlayerStatsConfig(tenantId,environmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetPlayerStatsConfig>>, TError, TData> & { queryKey: QueryKey }
}

export type LegacyscoutingControllerGetPlayerStatsConfigSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof legacyscoutingControllerGetPlayerStatsConfig>>>
export type LegacyscoutingControllerGetPlayerStatsConfigSuspenseQueryError = AxiosError<unknown>


export function useLegacyscoutingControllerGetPlayerStatsConfigSuspense<TData = Awaited<ReturnType<typeof legacyscoutingControllerGetPlayerStatsConfig>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetPlayerStatsConfig>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useLegacyscoutingControllerGetPlayerStatsConfigSuspense<TData = Awaited<ReturnType<typeof legacyscoutingControllerGetPlayerStatsConfig>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetPlayerStatsConfig>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useLegacyscoutingControllerGetPlayerStatsConfigSuspense<TData = Awaited<ReturnType<typeof legacyscoutingControllerGetPlayerStatsConfig>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetPlayerStatsConfig>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useLegacyscoutingControllerGetPlayerStatsConfigSuspense<TData = Awaited<ReturnType<typeof legacyscoutingControllerGetPlayerStatsConfig>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetPlayerStatsConfig>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getLegacyscoutingControllerGetPlayerStatsConfigSuspenseQueryOptions(tenantId,environmentId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const legacyscoutingControllerGetPlayers = (
    tenantId: string,
    environmentId: string,
    anyApiBodyDto: AnyApiBodyDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/v1/${tenantId}/${environmentId}/legacyscouting/players`,
      anyApiBodyDto,options
    );
  }



export const getLegacyscoutingControllerGetPlayersMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetPlayers>>, TError,{tenantId: string;environmentId: string;data: AnyApiBodyDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetPlayers>>, TError,{tenantId: string;environmentId: string;data: AnyApiBodyDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof legacyscoutingControllerGetPlayers>>, {tenantId: string;environmentId: string;data: AnyApiBodyDto}> = (props) => {
          const {tenantId,environmentId,data} = props ?? {};

          return  legacyscoutingControllerGetPlayers(tenantId,environmentId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type LegacyscoutingControllerGetPlayersMutationResult = NonNullable<Awaited<ReturnType<typeof legacyscoutingControllerGetPlayers>>>
    export type LegacyscoutingControllerGetPlayersMutationBody = AnyApiBodyDto
    export type LegacyscoutingControllerGetPlayersMutationError = AxiosError<unknown>

    export const useLegacyscoutingControllerGetPlayers = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetPlayers>>, TError,{tenantId: string;environmentId: string;data: AnyApiBodyDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof legacyscoutingControllerGetPlayers>>,
        TError,
        {tenantId: string;environmentId: string;data: AnyApiBodyDto},
        TContext
      > => {

      const mutationOptions = getLegacyscoutingControllerGetPlayersMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const legacyscoutingControllerGetLayouts = (
    tenantId: string,
    environmentId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SavedLayoutGetDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/legacyscouting/layouts`,options
    );
  }


export const getLegacyscoutingControllerGetLayoutsQueryKey = (tenantId: string,
    environmentId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/legacyscouting/layouts`] as const;
    }

    
export const getLegacyscoutingControllerGetLayoutsQueryOptions = <TData = Awaited<ReturnType<typeof legacyscoutingControllerGetLayouts>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetLayouts>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getLegacyscoutingControllerGetLayoutsQueryKey(tenantId,environmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof legacyscoutingControllerGetLayouts>>> = ({ signal }) => legacyscoutingControllerGetLayouts(tenantId,environmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetLayouts>>, TError, TData> & { queryKey: QueryKey }
}

export type LegacyscoutingControllerGetLayoutsQueryResult = NonNullable<Awaited<ReturnType<typeof legacyscoutingControllerGetLayouts>>>
export type LegacyscoutingControllerGetLayoutsQueryError = AxiosError<unknown>


export function useLegacyscoutingControllerGetLayouts<TData = Awaited<ReturnType<typeof legacyscoutingControllerGetLayouts>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetLayouts>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof legacyscoutingControllerGetLayouts>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useLegacyscoutingControllerGetLayouts<TData = Awaited<ReturnType<typeof legacyscoutingControllerGetLayouts>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetLayouts>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof legacyscoutingControllerGetLayouts>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useLegacyscoutingControllerGetLayouts<TData = Awaited<ReturnType<typeof legacyscoutingControllerGetLayouts>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetLayouts>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useLegacyscoutingControllerGetLayouts<TData = Awaited<ReturnType<typeof legacyscoutingControllerGetLayouts>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetLayouts>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getLegacyscoutingControllerGetLayoutsQueryOptions(tenantId,environmentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getLegacyscoutingControllerGetLayoutsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof legacyscoutingControllerGetLayouts>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetLayouts>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getLegacyscoutingControllerGetLayoutsQueryKey(tenantId,environmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof legacyscoutingControllerGetLayouts>>> = ({ signal }) => legacyscoutingControllerGetLayouts(tenantId,environmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetLayouts>>, TError, TData> & { queryKey: QueryKey }
}

export type LegacyscoutingControllerGetLayoutsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof legacyscoutingControllerGetLayouts>>>
export type LegacyscoutingControllerGetLayoutsSuspenseQueryError = AxiosError<unknown>


export function useLegacyscoutingControllerGetLayoutsSuspense<TData = Awaited<ReturnType<typeof legacyscoutingControllerGetLayouts>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetLayouts>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useLegacyscoutingControllerGetLayoutsSuspense<TData = Awaited<ReturnType<typeof legacyscoutingControllerGetLayouts>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetLayouts>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useLegacyscoutingControllerGetLayoutsSuspense<TData = Awaited<ReturnType<typeof legacyscoutingControllerGetLayouts>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetLayouts>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useLegacyscoutingControllerGetLayoutsSuspense<TData = Awaited<ReturnType<typeof legacyscoutingControllerGetLayouts>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetLayouts>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getLegacyscoutingControllerGetLayoutsSuspenseQueryOptions(tenantId,environmentId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const legacyscoutingControllerSaveLayout = (
    tenantId: string,
    environmentId: string,
    savedLayoutPostDto: SavedLayoutPostDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/v1/${tenantId}/${environmentId}/legacyscouting/layouts`,
      savedLayoutPostDto,options
    );
  }



export const getLegacyscoutingControllerSaveLayoutMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof legacyscoutingControllerSaveLayout>>, TError,{tenantId: string;environmentId: string;data: SavedLayoutPostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof legacyscoutingControllerSaveLayout>>, TError,{tenantId: string;environmentId: string;data: SavedLayoutPostDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof legacyscoutingControllerSaveLayout>>, {tenantId: string;environmentId: string;data: SavedLayoutPostDto}> = (props) => {
          const {tenantId,environmentId,data} = props ?? {};

          return  legacyscoutingControllerSaveLayout(tenantId,environmentId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type LegacyscoutingControllerSaveLayoutMutationResult = NonNullable<Awaited<ReturnType<typeof legacyscoutingControllerSaveLayout>>>
    export type LegacyscoutingControllerSaveLayoutMutationBody = SavedLayoutPostDto
    export type LegacyscoutingControllerSaveLayoutMutationError = AxiosError<unknown>

    export const useLegacyscoutingControllerSaveLayout = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof legacyscoutingControllerSaveLayout>>, TError,{tenantId: string;environmentId: string;data: SavedLayoutPostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof legacyscoutingControllerSaveLayout>>,
        TError,
        {tenantId: string;environmentId: string;data: SavedLayoutPostDto},
        TContext
      > => {

      const mutationOptions = getLegacyscoutingControllerSaveLayoutMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const legacyscoutingControllerUpdateLayout = (
    tenantId: string,
    environmentId: string,
    layoutId: string,
    savedLayoutPutDto: SavedLayoutPutDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `/v1/${tenantId}/${environmentId}/legacyscouting/layouts/${layoutId}`,
      savedLayoutPutDto,options
    );
  }



export const getLegacyscoutingControllerUpdateLayoutMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof legacyscoutingControllerUpdateLayout>>, TError,{tenantId: string;environmentId: string;layoutId: string;data: SavedLayoutPutDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof legacyscoutingControllerUpdateLayout>>, TError,{tenantId: string;environmentId: string;layoutId: string;data: SavedLayoutPutDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof legacyscoutingControllerUpdateLayout>>, {tenantId: string;environmentId: string;layoutId: string;data: SavedLayoutPutDto}> = (props) => {
          const {tenantId,environmentId,layoutId,data} = props ?? {};

          return  legacyscoutingControllerUpdateLayout(tenantId,environmentId,layoutId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type LegacyscoutingControllerUpdateLayoutMutationResult = NonNullable<Awaited<ReturnType<typeof legacyscoutingControllerUpdateLayout>>>
    export type LegacyscoutingControllerUpdateLayoutMutationBody = SavedLayoutPutDto
    export type LegacyscoutingControllerUpdateLayoutMutationError = AxiosError<unknown>

    export const useLegacyscoutingControllerUpdateLayout = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof legacyscoutingControllerUpdateLayout>>, TError,{tenantId: string;environmentId: string;layoutId: string;data: SavedLayoutPutDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof legacyscoutingControllerUpdateLayout>>,
        TError,
        {tenantId: string;environmentId: string;layoutId: string;data: SavedLayoutPutDto},
        TContext
      > => {

      const mutationOptions = getLegacyscoutingControllerUpdateLayoutMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const legacyscoutingControllerDeleteLayout = (
    tenantId: string,
    environmentId: string,
    layoutId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/v1/${tenantId}/${environmentId}/legacyscouting/layouts/${layoutId}`,options
    );
  }



export const getLegacyscoutingControllerDeleteLayoutMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof legacyscoutingControllerDeleteLayout>>, TError,{tenantId: string;environmentId: string;layoutId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof legacyscoutingControllerDeleteLayout>>, TError,{tenantId: string;environmentId: string;layoutId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof legacyscoutingControllerDeleteLayout>>, {tenantId: string;environmentId: string;layoutId: string}> = (props) => {
          const {tenantId,environmentId,layoutId} = props ?? {};

          return  legacyscoutingControllerDeleteLayout(tenantId,environmentId,layoutId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type LegacyscoutingControllerDeleteLayoutMutationResult = NonNullable<Awaited<ReturnType<typeof legacyscoutingControllerDeleteLayout>>>
    
    export type LegacyscoutingControllerDeleteLayoutMutationError = AxiosError<unknown>

    export const useLegacyscoutingControllerDeleteLayout = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof legacyscoutingControllerDeleteLayout>>, TError,{tenantId: string;environmentId: string;layoutId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof legacyscoutingControllerDeleteLayout>>,
        TError,
        {tenantId: string;environmentId: string;layoutId: string},
        TContext
      > => {

      const mutationOptions = getLegacyscoutingControllerDeleteLayoutMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const legacyscoutingControllerGetFilters = (
    tenantId: string,
    environmentId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SavedFilterGetDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/legacyscouting/filters`,options
    );
  }


export const getLegacyscoutingControllerGetFiltersQueryKey = (tenantId: string,
    environmentId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/legacyscouting/filters`] as const;
    }

    
export const getLegacyscoutingControllerGetFiltersQueryOptions = <TData = Awaited<ReturnType<typeof legacyscoutingControllerGetFilters>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetFilters>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getLegacyscoutingControllerGetFiltersQueryKey(tenantId,environmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof legacyscoutingControllerGetFilters>>> = ({ signal }) => legacyscoutingControllerGetFilters(tenantId,environmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetFilters>>, TError, TData> & { queryKey: QueryKey }
}

export type LegacyscoutingControllerGetFiltersQueryResult = NonNullable<Awaited<ReturnType<typeof legacyscoutingControllerGetFilters>>>
export type LegacyscoutingControllerGetFiltersQueryError = AxiosError<unknown>


export function useLegacyscoutingControllerGetFilters<TData = Awaited<ReturnType<typeof legacyscoutingControllerGetFilters>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetFilters>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof legacyscoutingControllerGetFilters>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useLegacyscoutingControllerGetFilters<TData = Awaited<ReturnType<typeof legacyscoutingControllerGetFilters>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetFilters>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof legacyscoutingControllerGetFilters>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useLegacyscoutingControllerGetFilters<TData = Awaited<ReturnType<typeof legacyscoutingControllerGetFilters>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetFilters>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useLegacyscoutingControllerGetFilters<TData = Awaited<ReturnType<typeof legacyscoutingControllerGetFilters>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetFilters>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getLegacyscoutingControllerGetFiltersQueryOptions(tenantId,environmentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getLegacyscoutingControllerGetFiltersSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof legacyscoutingControllerGetFilters>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetFilters>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getLegacyscoutingControllerGetFiltersQueryKey(tenantId,environmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof legacyscoutingControllerGetFilters>>> = ({ signal }) => legacyscoutingControllerGetFilters(tenantId,environmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetFilters>>, TError, TData> & { queryKey: QueryKey }
}

export type LegacyscoutingControllerGetFiltersSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof legacyscoutingControllerGetFilters>>>
export type LegacyscoutingControllerGetFiltersSuspenseQueryError = AxiosError<unknown>


export function useLegacyscoutingControllerGetFiltersSuspense<TData = Awaited<ReturnType<typeof legacyscoutingControllerGetFilters>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetFilters>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useLegacyscoutingControllerGetFiltersSuspense<TData = Awaited<ReturnType<typeof legacyscoutingControllerGetFilters>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetFilters>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useLegacyscoutingControllerGetFiltersSuspense<TData = Awaited<ReturnType<typeof legacyscoutingControllerGetFilters>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetFilters>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useLegacyscoutingControllerGetFiltersSuspense<TData = Awaited<ReturnType<typeof legacyscoutingControllerGetFilters>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof legacyscoutingControllerGetFilters>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getLegacyscoutingControllerGetFiltersSuspenseQueryOptions(tenantId,environmentId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const legacyscoutingControllerSaveFilter = (
    tenantId: string,
    environmentId: string,
    savedFilterPostDto: SavedFilterPostDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/v1/${tenantId}/${environmentId}/legacyscouting/filters`,
      savedFilterPostDto,options
    );
  }



export const getLegacyscoutingControllerSaveFilterMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof legacyscoutingControllerSaveFilter>>, TError,{tenantId: string;environmentId: string;data: SavedFilterPostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof legacyscoutingControllerSaveFilter>>, TError,{tenantId: string;environmentId: string;data: SavedFilterPostDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof legacyscoutingControllerSaveFilter>>, {tenantId: string;environmentId: string;data: SavedFilterPostDto}> = (props) => {
          const {tenantId,environmentId,data} = props ?? {};

          return  legacyscoutingControllerSaveFilter(tenantId,environmentId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type LegacyscoutingControllerSaveFilterMutationResult = NonNullable<Awaited<ReturnType<typeof legacyscoutingControllerSaveFilter>>>
    export type LegacyscoutingControllerSaveFilterMutationBody = SavedFilterPostDto
    export type LegacyscoutingControllerSaveFilterMutationError = AxiosError<unknown>

    export const useLegacyscoutingControllerSaveFilter = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof legacyscoutingControllerSaveFilter>>, TError,{tenantId: string;environmentId: string;data: SavedFilterPostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof legacyscoutingControllerSaveFilter>>,
        TError,
        {tenantId: string;environmentId: string;data: SavedFilterPostDto},
        TContext
      > => {

      const mutationOptions = getLegacyscoutingControllerSaveFilterMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const legacyscoutingControllerUpdateFilter = (
    tenantId: string,
    environmentId: string,
    filterId: string,
    savedFilterPutDto: SavedFilterPutDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `/v1/${tenantId}/${environmentId}/legacyscouting/filters/${filterId}`,
      savedFilterPutDto,options
    );
  }



export const getLegacyscoutingControllerUpdateFilterMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof legacyscoutingControllerUpdateFilter>>, TError,{tenantId: string;environmentId: string;filterId: string;data: SavedFilterPutDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof legacyscoutingControllerUpdateFilter>>, TError,{tenantId: string;environmentId: string;filterId: string;data: SavedFilterPutDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof legacyscoutingControllerUpdateFilter>>, {tenantId: string;environmentId: string;filterId: string;data: SavedFilterPutDto}> = (props) => {
          const {tenantId,environmentId,filterId,data} = props ?? {};

          return  legacyscoutingControllerUpdateFilter(tenantId,environmentId,filterId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type LegacyscoutingControllerUpdateFilterMutationResult = NonNullable<Awaited<ReturnType<typeof legacyscoutingControllerUpdateFilter>>>
    export type LegacyscoutingControllerUpdateFilterMutationBody = SavedFilterPutDto
    export type LegacyscoutingControllerUpdateFilterMutationError = AxiosError<unknown>

    export const useLegacyscoutingControllerUpdateFilter = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof legacyscoutingControllerUpdateFilter>>, TError,{tenantId: string;environmentId: string;filterId: string;data: SavedFilterPutDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof legacyscoutingControllerUpdateFilter>>,
        TError,
        {tenantId: string;environmentId: string;filterId: string;data: SavedFilterPutDto},
        TContext
      > => {

      const mutationOptions = getLegacyscoutingControllerUpdateFilterMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const legacyscoutingControllerDeleteFilter = (
    tenantId: string,
    environmentId: string,
    filterId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/v1/${tenantId}/${environmentId}/legacyscouting/filters/${filterId}`,options
    );
  }



export const getLegacyscoutingControllerDeleteFilterMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof legacyscoutingControllerDeleteFilter>>, TError,{tenantId: string;environmentId: string;filterId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof legacyscoutingControllerDeleteFilter>>, TError,{tenantId: string;environmentId: string;filterId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof legacyscoutingControllerDeleteFilter>>, {tenantId: string;environmentId: string;filterId: string}> = (props) => {
          const {tenantId,environmentId,filterId} = props ?? {};

          return  legacyscoutingControllerDeleteFilter(tenantId,environmentId,filterId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type LegacyscoutingControllerDeleteFilterMutationResult = NonNullable<Awaited<ReturnType<typeof legacyscoutingControllerDeleteFilter>>>
    
    export type LegacyscoutingControllerDeleteFilterMutationError = AxiosError<unknown>

    export const useLegacyscoutingControllerDeleteFilter = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof legacyscoutingControllerDeleteFilter>>, TError,{tenantId: string;environmentId: string;filterId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof legacyscoutingControllerDeleteFilter>>,
        TError,
        {tenantId: string;environmentId: string;filterId: string},
        TContext
      > => {

      const mutationOptions = getLegacyscoutingControllerDeleteFilterMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const scoutingMatchControllerCreate = (
    tenantId: string,
    environmentId: string,
    scoutingMatchPostDto: ScoutingMatchPostDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ScoutingMatchDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/${environmentId}/scouting/match`,
      scoutingMatchPostDto,options
    );
  }



export const getScoutingMatchControllerCreateMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingMatchControllerCreate>>, TError,{tenantId: string;environmentId: string;data: ScoutingMatchPostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof scoutingMatchControllerCreate>>, TError,{tenantId: string;environmentId: string;data: ScoutingMatchPostDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof scoutingMatchControllerCreate>>, {tenantId: string;environmentId: string;data: ScoutingMatchPostDto}> = (props) => {
          const {tenantId,environmentId,data} = props ?? {};

          return  scoutingMatchControllerCreate(tenantId,environmentId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ScoutingMatchControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof scoutingMatchControllerCreate>>>
    export type ScoutingMatchControllerCreateMutationBody = ScoutingMatchPostDto
    export type ScoutingMatchControllerCreateMutationError = AxiosError<unknown>

    export const useScoutingMatchControllerCreate = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingMatchControllerCreate>>, TError,{tenantId: string;environmentId: string;data: ScoutingMatchPostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof scoutingMatchControllerCreate>>,
        TError,
        {tenantId: string;environmentId: string;data: ScoutingMatchPostDto},
        TContext
      > => {

      const mutationOptions = getScoutingMatchControllerCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const scoutingMatchControllerFindAll = (
    tenantId: string,
    environmentId: string,
    params?: ScoutingMatchControllerFindAllParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ScoutingMatchDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/scouting/match`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getScoutingMatchControllerFindAllQueryKey = (tenantId: string,
    environmentId: string,
    params?: ScoutingMatchControllerFindAllParams,) => {
    return [`/v1/${tenantId}/${environmentId}/scouting/match`, ...(params ? [params]: [])] as const;
    }

    
export const getScoutingMatchControllerFindAllQueryOptions = <TData = Awaited<ReturnType<typeof scoutingMatchControllerFindAll>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    params?: ScoutingMatchControllerFindAllParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingMatchControllerFindAll>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getScoutingMatchControllerFindAllQueryKey(tenantId,environmentId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof scoutingMatchControllerFindAll>>> = ({ signal }) => scoutingMatchControllerFindAll(tenantId,environmentId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof scoutingMatchControllerFindAll>>, TError, TData> & { queryKey: QueryKey }
}

export type ScoutingMatchControllerFindAllQueryResult = NonNullable<Awaited<ReturnType<typeof scoutingMatchControllerFindAll>>>
export type ScoutingMatchControllerFindAllQueryError = AxiosError<unknown>


export function useScoutingMatchControllerFindAll<TData = Awaited<ReturnType<typeof scoutingMatchControllerFindAll>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: undefined |  ScoutingMatchControllerFindAllParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingMatchControllerFindAll>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof scoutingMatchControllerFindAll>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingMatchControllerFindAll<TData = Awaited<ReturnType<typeof scoutingMatchControllerFindAll>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params?: ScoutingMatchControllerFindAllParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingMatchControllerFindAll>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof scoutingMatchControllerFindAll>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingMatchControllerFindAll<TData = Awaited<ReturnType<typeof scoutingMatchControllerFindAll>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params?: ScoutingMatchControllerFindAllParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingMatchControllerFindAll>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useScoutingMatchControllerFindAll<TData = Awaited<ReturnType<typeof scoutingMatchControllerFindAll>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params?: ScoutingMatchControllerFindAllParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingMatchControllerFindAll>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getScoutingMatchControllerFindAllQueryOptions(tenantId,environmentId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getScoutingMatchControllerFindAllSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof scoutingMatchControllerFindAll>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    params?: ScoutingMatchControllerFindAllParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingMatchControllerFindAll>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getScoutingMatchControllerFindAllQueryKey(tenantId,environmentId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof scoutingMatchControllerFindAll>>> = ({ signal }) => scoutingMatchControllerFindAll(tenantId,environmentId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingMatchControllerFindAll>>, TError, TData> & { queryKey: QueryKey }
}

export type ScoutingMatchControllerFindAllSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof scoutingMatchControllerFindAll>>>
export type ScoutingMatchControllerFindAllSuspenseQueryError = AxiosError<unknown>


export function useScoutingMatchControllerFindAllSuspense<TData = Awaited<ReturnType<typeof scoutingMatchControllerFindAll>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: undefined |  ScoutingMatchControllerFindAllParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingMatchControllerFindAll>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingMatchControllerFindAllSuspense<TData = Awaited<ReturnType<typeof scoutingMatchControllerFindAll>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params?: ScoutingMatchControllerFindAllParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingMatchControllerFindAll>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingMatchControllerFindAllSuspense<TData = Awaited<ReturnType<typeof scoutingMatchControllerFindAll>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params?: ScoutingMatchControllerFindAllParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingMatchControllerFindAll>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useScoutingMatchControllerFindAllSuspense<TData = Awaited<ReturnType<typeof scoutingMatchControllerFindAll>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params?: ScoutingMatchControllerFindAllParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingMatchControllerFindAll>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getScoutingMatchControllerFindAllSuspenseQueryOptions(tenantId,environmentId,params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const scoutingMatchPlayerNoteControllerCreate = (
    tenantId: string,
    environmentId: string,
    scoutingMatchId: string,
    scoutingMatchPlayerNotePostDto: ScoutingMatchPlayerNotePostDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ScoutingMatchPlayerNoteDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/${environmentId}/scouting/match/${scoutingMatchId}/note`,
      scoutingMatchPlayerNotePostDto,options
    );
  }



export const getScoutingMatchPlayerNoteControllerCreateMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerCreate>>, TError,{tenantId: string;environmentId: string;scoutingMatchId: string;data: ScoutingMatchPlayerNotePostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerCreate>>, TError,{tenantId: string;environmentId: string;scoutingMatchId: string;data: ScoutingMatchPlayerNotePostDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerCreate>>, {tenantId: string;environmentId: string;scoutingMatchId: string;data: ScoutingMatchPlayerNotePostDto}> = (props) => {
          const {tenantId,environmentId,scoutingMatchId,data} = props ?? {};

          return  scoutingMatchPlayerNoteControllerCreate(tenantId,environmentId,scoutingMatchId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ScoutingMatchPlayerNoteControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerCreate>>>
    export type ScoutingMatchPlayerNoteControllerCreateMutationBody = ScoutingMatchPlayerNotePostDto
    export type ScoutingMatchPlayerNoteControllerCreateMutationError = AxiosError<unknown>

    export const useScoutingMatchPlayerNoteControllerCreate = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerCreate>>, TError,{tenantId: string;environmentId: string;scoutingMatchId: string;data: ScoutingMatchPlayerNotePostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerCreate>>,
        TError,
        {tenantId: string;environmentId: string;scoutingMatchId: string;data: ScoutingMatchPlayerNotePostDto},
        TContext
      > => {

      const mutationOptions = getScoutingMatchPlayerNoteControllerCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const scoutingMatchPlayerNoteControllerFindAll = (
    tenantId: string,
    environmentId: string,
    scoutingMatchId: string,
    params?: ScoutingMatchPlayerNoteControllerFindAllParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ScoutingMatchPlayerNoteDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/scouting/match/${scoutingMatchId}/note`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getScoutingMatchPlayerNoteControllerFindAllQueryKey = (tenantId: string,
    environmentId: string,
    scoutingMatchId: string,
    params?: ScoutingMatchPlayerNoteControllerFindAllParams,) => {
    return [`/v1/${tenantId}/${environmentId}/scouting/match/${scoutingMatchId}/note`, ...(params ? [params]: [])] as const;
    }

    
export const getScoutingMatchPlayerNoteControllerFindAllQueryOptions = <TData = Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerFindAll>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    scoutingMatchId: string,
    params?: ScoutingMatchPlayerNoteControllerFindAllParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerFindAll>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getScoutingMatchPlayerNoteControllerFindAllQueryKey(tenantId,environmentId,scoutingMatchId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerFindAll>>> = ({ signal }) => scoutingMatchPlayerNoteControllerFindAll(tenantId,environmentId,scoutingMatchId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && scoutingMatchId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerFindAll>>, TError, TData> & { queryKey: QueryKey }
}

export type ScoutingMatchPlayerNoteControllerFindAllQueryResult = NonNullable<Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerFindAll>>>
export type ScoutingMatchPlayerNoteControllerFindAllQueryError = AxiosError<unknown>


export function useScoutingMatchPlayerNoteControllerFindAll<TData = Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerFindAll>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    scoutingMatchId: string,
    params: undefined |  ScoutingMatchPlayerNoteControllerFindAllParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerFindAll>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerFindAll>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingMatchPlayerNoteControllerFindAll<TData = Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerFindAll>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    scoutingMatchId: string,
    params?: ScoutingMatchPlayerNoteControllerFindAllParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerFindAll>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerFindAll>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingMatchPlayerNoteControllerFindAll<TData = Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerFindAll>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    scoutingMatchId: string,
    params?: ScoutingMatchPlayerNoteControllerFindAllParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerFindAll>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useScoutingMatchPlayerNoteControllerFindAll<TData = Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerFindAll>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    scoutingMatchId: string,
    params?: ScoutingMatchPlayerNoteControllerFindAllParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerFindAll>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getScoutingMatchPlayerNoteControllerFindAllQueryOptions(tenantId,environmentId,scoutingMatchId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getScoutingMatchPlayerNoteControllerFindAllSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerFindAll>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    scoutingMatchId: string,
    params?: ScoutingMatchPlayerNoteControllerFindAllParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerFindAll>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getScoutingMatchPlayerNoteControllerFindAllQueryKey(tenantId,environmentId,scoutingMatchId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerFindAll>>> = ({ signal }) => scoutingMatchPlayerNoteControllerFindAll(tenantId,environmentId,scoutingMatchId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && scoutingMatchId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerFindAll>>, TError, TData> & { queryKey: QueryKey }
}

export type ScoutingMatchPlayerNoteControllerFindAllSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerFindAll>>>
export type ScoutingMatchPlayerNoteControllerFindAllSuspenseQueryError = AxiosError<unknown>


export function useScoutingMatchPlayerNoteControllerFindAllSuspense<TData = Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerFindAll>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    scoutingMatchId: string,
    params: undefined |  ScoutingMatchPlayerNoteControllerFindAllParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerFindAll>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingMatchPlayerNoteControllerFindAllSuspense<TData = Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerFindAll>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    scoutingMatchId: string,
    params?: ScoutingMatchPlayerNoteControllerFindAllParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerFindAll>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingMatchPlayerNoteControllerFindAllSuspense<TData = Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerFindAll>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    scoutingMatchId: string,
    params?: ScoutingMatchPlayerNoteControllerFindAllParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerFindAll>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useScoutingMatchPlayerNoteControllerFindAllSuspense<TData = Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerFindAll>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    scoutingMatchId: string,
    params?: ScoutingMatchPlayerNoteControllerFindAllParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerFindAll>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getScoutingMatchPlayerNoteControllerFindAllSuspenseQueryOptions(tenantId,environmentId,scoutingMatchId,params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const scoutingMatchPlayerNoteControllerRemove = (
    tenantId: string,
    environmentId: string,
    scoutingMatchId: string,
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ScoutingMatchPlayerNoteDto>> => {
    
    return axios.delete(
      `/v1/${tenantId}/${environmentId}/scouting/match/${scoutingMatchId}/note/${id}`,options
    );
  }



export const getScoutingMatchPlayerNoteControllerRemoveMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerRemove>>, TError,{tenantId: string;environmentId: string;scoutingMatchId: string;id: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerRemove>>, TError,{tenantId: string;environmentId: string;scoutingMatchId: string;id: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerRemove>>, {tenantId: string;environmentId: string;scoutingMatchId: string;id: string}> = (props) => {
          const {tenantId,environmentId,scoutingMatchId,id} = props ?? {};

          return  scoutingMatchPlayerNoteControllerRemove(tenantId,environmentId,scoutingMatchId,id,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ScoutingMatchPlayerNoteControllerRemoveMutationResult = NonNullable<Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerRemove>>>
    
    export type ScoutingMatchPlayerNoteControllerRemoveMutationError = AxiosError<unknown>

    export const useScoutingMatchPlayerNoteControllerRemove = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerRemove>>, TError,{tenantId: string;environmentId: string;scoutingMatchId: string;id: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof scoutingMatchPlayerNoteControllerRemove>>,
        TError,
        {tenantId: string;environmentId: string;scoutingMatchId: string;id: string},
        TContext
      > => {

      const mutationOptions = getScoutingMatchPlayerNoteControllerRemoveMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const scoutingWorkflowControllerGetScoutingWorkflowStateTemplates = (
    tenantId: string,
    environmentId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ScoutingWorkflowTemplatesGetDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/scoutingworkflow/workflowtemplates`,options
    );
  }


export const getScoutingWorkflowControllerGetScoutingWorkflowStateTemplatesQueryKey = (tenantId: string,
    environmentId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/scoutingworkflow/workflowtemplates`] as const;
    }

    
export const getScoutingWorkflowControllerGetScoutingWorkflowStateTemplatesQueryOptions = <TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetScoutingWorkflowStateTemplates>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetScoutingWorkflowStateTemplates>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getScoutingWorkflowControllerGetScoutingWorkflowStateTemplatesQueryKey(tenantId,environmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof scoutingWorkflowControllerGetScoutingWorkflowStateTemplates>>> = ({ signal }) => scoutingWorkflowControllerGetScoutingWorkflowStateTemplates(tenantId,environmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetScoutingWorkflowStateTemplates>>, TError, TData> & { queryKey: QueryKey }
}

export type ScoutingWorkflowControllerGetScoutingWorkflowStateTemplatesQueryResult = NonNullable<Awaited<ReturnType<typeof scoutingWorkflowControllerGetScoutingWorkflowStateTemplates>>>
export type ScoutingWorkflowControllerGetScoutingWorkflowStateTemplatesQueryError = AxiosError<unknown>


export function useScoutingWorkflowControllerGetScoutingWorkflowStateTemplates<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetScoutingWorkflowStateTemplates>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetScoutingWorkflowStateTemplates>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof scoutingWorkflowControllerGetScoutingWorkflowStateTemplates>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingWorkflowControllerGetScoutingWorkflowStateTemplates<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetScoutingWorkflowStateTemplates>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetScoutingWorkflowStateTemplates>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof scoutingWorkflowControllerGetScoutingWorkflowStateTemplates>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingWorkflowControllerGetScoutingWorkflowStateTemplates<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetScoutingWorkflowStateTemplates>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetScoutingWorkflowStateTemplates>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useScoutingWorkflowControllerGetScoutingWorkflowStateTemplates<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetScoutingWorkflowStateTemplates>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetScoutingWorkflowStateTemplates>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getScoutingWorkflowControllerGetScoutingWorkflowStateTemplatesQueryOptions(tenantId,environmentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getScoutingWorkflowControllerGetScoutingWorkflowStateTemplatesSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetScoutingWorkflowStateTemplates>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetScoutingWorkflowStateTemplates>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getScoutingWorkflowControllerGetScoutingWorkflowStateTemplatesQueryKey(tenantId,environmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof scoutingWorkflowControllerGetScoutingWorkflowStateTemplates>>> = ({ signal }) => scoutingWorkflowControllerGetScoutingWorkflowStateTemplates(tenantId,environmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetScoutingWorkflowStateTemplates>>, TError, TData> & { queryKey: QueryKey }
}

export type ScoutingWorkflowControllerGetScoutingWorkflowStateTemplatesSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof scoutingWorkflowControllerGetScoutingWorkflowStateTemplates>>>
export type ScoutingWorkflowControllerGetScoutingWorkflowStateTemplatesSuspenseQueryError = AxiosError<unknown>


export function useScoutingWorkflowControllerGetScoutingWorkflowStateTemplatesSuspense<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetScoutingWorkflowStateTemplates>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetScoutingWorkflowStateTemplates>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingWorkflowControllerGetScoutingWorkflowStateTemplatesSuspense<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetScoutingWorkflowStateTemplates>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetScoutingWorkflowStateTemplates>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingWorkflowControllerGetScoutingWorkflowStateTemplatesSuspense<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetScoutingWorkflowStateTemplates>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetScoutingWorkflowStateTemplates>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useScoutingWorkflowControllerGetScoutingWorkflowStateTemplatesSuspense<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetScoutingWorkflowStateTemplates>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetScoutingWorkflowStateTemplates>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getScoutingWorkflowControllerGetScoutingWorkflowStateTemplatesSuspenseQueryOptions(tenantId,environmentId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const scoutingWorkflowControllerUpdateScoutingWorkflowStateTemplates = (
    tenantId: string,
    environmentId: string,
    scoutingWorkflowTemplatesUpdateDto: ScoutingWorkflowTemplatesUpdateDto[], options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `/v1/${tenantId}/${environmentId}/scoutingworkflow/workflowtemplates`,
      scoutingWorkflowTemplatesUpdateDto,options
    );
  }



export const getScoutingWorkflowControllerUpdateScoutingWorkflowStateTemplatesMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerUpdateScoutingWorkflowStateTemplates>>, TError,{tenantId: string;environmentId: string;data: ScoutingWorkflowTemplatesUpdateDto[]}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerUpdateScoutingWorkflowStateTemplates>>, TError,{tenantId: string;environmentId: string;data: ScoutingWorkflowTemplatesUpdateDto[]}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof scoutingWorkflowControllerUpdateScoutingWorkflowStateTemplates>>, {tenantId: string;environmentId: string;data: ScoutingWorkflowTemplatesUpdateDto[]}> = (props) => {
          const {tenantId,environmentId,data} = props ?? {};

          return  scoutingWorkflowControllerUpdateScoutingWorkflowStateTemplates(tenantId,environmentId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ScoutingWorkflowControllerUpdateScoutingWorkflowStateTemplatesMutationResult = NonNullable<Awaited<ReturnType<typeof scoutingWorkflowControllerUpdateScoutingWorkflowStateTemplates>>>
    export type ScoutingWorkflowControllerUpdateScoutingWorkflowStateTemplatesMutationBody = ScoutingWorkflowTemplatesUpdateDto[]
    export type ScoutingWorkflowControllerUpdateScoutingWorkflowStateTemplatesMutationError = AxiosError<unknown>

    export const useScoutingWorkflowControllerUpdateScoutingWorkflowStateTemplates = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerUpdateScoutingWorkflowStateTemplates>>, TError,{tenantId: string;environmentId: string;data: ScoutingWorkflowTemplatesUpdateDto[]}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof scoutingWorkflowControllerUpdateScoutingWorkflowStateTemplates>>,
        TError,
        {tenantId: string;environmentId: string;data: ScoutingWorkflowTemplatesUpdateDto[]},
        TContext
      > => {

      const mutationOptions = getScoutingWorkflowControllerUpdateScoutingWorkflowStateTemplatesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const scoutingWorkflowControllerGetLatestScoutingWorkflowPlayers = (
    tenantId: string,
    environmentId: string,
    params?: ScoutingWorkflowControllerGetLatestScoutingWorkflowPlayersParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ScoutingWorkflowPlayersGetLatestDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/scoutingworkflow/workflows/latest`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getScoutingWorkflowControllerGetLatestScoutingWorkflowPlayersQueryKey = (tenantId: string,
    environmentId: string,
    params?: ScoutingWorkflowControllerGetLatestScoutingWorkflowPlayersParams,) => {
    return [`/v1/${tenantId}/${environmentId}/scoutingworkflow/workflows/latest`, ...(params ? [params]: [])] as const;
    }

    
export const getScoutingWorkflowControllerGetLatestScoutingWorkflowPlayersQueryOptions = <TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetLatestScoutingWorkflowPlayers>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    params?: ScoutingWorkflowControllerGetLatestScoutingWorkflowPlayersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetLatestScoutingWorkflowPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getScoutingWorkflowControllerGetLatestScoutingWorkflowPlayersQueryKey(tenantId,environmentId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof scoutingWorkflowControllerGetLatestScoutingWorkflowPlayers>>> = ({ signal }) => scoutingWorkflowControllerGetLatestScoutingWorkflowPlayers(tenantId,environmentId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetLatestScoutingWorkflowPlayers>>, TError, TData> & { queryKey: QueryKey }
}

export type ScoutingWorkflowControllerGetLatestScoutingWorkflowPlayersQueryResult = NonNullable<Awaited<ReturnType<typeof scoutingWorkflowControllerGetLatestScoutingWorkflowPlayers>>>
export type ScoutingWorkflowControllerGetLatestScoutingWorkflowPlayersQueryError = AxiosError<unknown>


export function useScoutingWorkflowControllerGetLatestScoutingWorkflowPlayers<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetLatestScoutingWorkflowPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: undefined |  ScoutingWorkflowControllerGetLatestScoutingWorkflowPlayersParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetLatestScoutingWorkflowPlayers>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof scoutingWorkflowControllerGetLatestScoutingWorkflowPlayers>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingWorkflowControllerGetLatestScoutingWorkflowPlayers<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetLatestScoutingWorkflowPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params?: ScoutingWorkflowControllerGetLatestScoutingWorkflowPlayersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetLatestScoutingWorkflowPlayers>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof scoutingWorkflowControllerGetLatestScoutingWorkflowPlayers>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingWorkflowControllerGetLatestScoutingWorkflowPlayers<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetLatestScoutingWorkflowPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params?: ScoutingWorkflowControllerGetLatestScoutingWorkflowPlayersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetLatestScoutingWorkflowPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useScoutingWorkflowControllerGetLatestScoutingWorkflowPlayers<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetLatestScoutingWorkflowPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params?: ScoutingWorkflowControllerGetLatestScoutingWorkflowPlayersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetLatestScoutingWorkflowPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getScoutingWorkflowControllerGetLatestScoutingWorkflowPlayersQueryOptions(tenantId,environmentId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getScoutingWorkflowControllerGetLatestScoutingWorkflowPlayersSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetLatestScoutingWorkflowPlayers>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    params?: ScoutingWorkflowControllerGetLatestScoutingWorkflowPlayersParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetLatestScoutingWorkflowPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getScoutingWorkflowControllerGetLatestScoutingWorkflowPlayersQueryKey(tenantId,environmentId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof scoutingWorkflowControllerGetLatestScoutingWorkflowPlayers>>> = ({ signal }) => scoutingWorkflowControllerGetLatestScoutingWorkflowPlayers(tenantId,environmentId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetLatestScoutingWorkflowPlayers>>, TError, TData> & { queryKey: QueryKey }
}

export type ScoutingWorkflowControllerGetLatestScoutingWorkflowPlayersSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof scoutingWorkflowControllerGetLatestScoutingWorkflowPlayers>>>
export type ScoutingWorkflowControllerGetLatestScoutingWorkflowPlayersSuspenseQueryError = AxiosError<unknown>


export function useScoutingWorkflowControllerGetLatestScoutingWorkflowPlayersSuspense<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetLatestScoutingWorkflowPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: undefined |  ScoutingWorkflowControllerGetLatestScoutingWorkflowPlayersParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetLatestScoutingWorkflowPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingWorkflowControllerGetLatestScoutingWorkflowPlayersSuspense<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetLatestScoutingWorkflowPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params?: ScoutingWorkflowControllerGetLatestScoutingWorkflowPlayersParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetLatestScoutingWorkflowPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingWorkflowControllerGetLatestScoutingWorkflowPlayersSuspense<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetLatestScoutingWorkflowPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params?: ScoutingWorkflowControllerGetLatestScoutingWorkflowPlayersParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetLatestScoutingWorkflowPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useScoutingWorkflowControllerGetLatestScoutingWorkflowPlayersSuspense<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetLatestScoutingWorkflowPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params?: ScoutingWorkflowControllerGetLatestScoutingWorkflowPlayersParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetLatestScoutingWorkflowPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getScoutingWorkflowControllerGetLatestScoutingWorkflowPlayersSuspenseQueryOptions(tenantId,environmentId,params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const scoutingWorkflowControllerAssignUserToScoutingWorkflowState = (
    tenantId: string,
    environmentId: string,
    workflowStateId: string,
    scoutingWorkflowAssignPutBodyDto: ScoutingWorkflowAssignPutBodyDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `/v1/${tenantId}/${environmentId}/scoutingworkflow/workflows/${workflowStateId}/assignee`,
      scoutingWorkflowAssignPutBodyDto,options
    );
  }



export const getScoutingWorkflowControllerAssignUserToScoutingWorkflowStateMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerAssignUserToScoutingWorkflowState>>, TError,{tenantId: string;environmentId: string;workflowStateId: string;data: ScoutingWorkflowAssignPutBodyDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerAssignUserToScoutingWorkflowState>>, TError,{tenantId: string;environmentId: string;workflowStateId: string;data: ScoutingWorkflowAssignPutBodyDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof scoutingWorkflowControllerAssignUserToScoutingWorkflowState>>, {tenantId: string;environmentId: string;workflowStateId: string;data: ScoutingWorkflowAssignPutBodyDto}> = (props) => {
          const {tenantId,environmentId,workflowStateId,data} = props ?? {};

          return  scoutingWorkflowControllerAssignUserToScoutingWorkflowState(tenantId,environmentId,workflowStateId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ScoutingWorkflowControllerAssignUserToScoutingWorkflowStateMutationResult = NonNullable<Awaited<ReturnType<typeof scoutingWorkflowControllerAssignUserToScoutingWorkflowState>>>
    export type ScoutingWorkflowControllerAssignUserToScoutingWorkflowStateMutationBody = ScoutingWorkflowAssignPutBodyDto
    export type ScoutingWorkflowControllerAssignUserToScoutingWorkflowStateMutationError = AxiosError<unknown>

    export const useScoutingWorkflowControllerAssignUserToScoutingWorkflowState = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerAssignUserToScoutingWorkflowState>>, TError,{tenantId: string;environmentId: string;workflowStateId: string;data: ScoutingWorkflowAssignPutBodyDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof scoutingWorkflowControllerAssignUserToScoutingWorkflowState>>,
        TError,
        {tenantId: string;environmentId: string;workflowStateId: string;data: ScoutingWorkflowAssignPutBodyDto},
        TContext
      > => {

      const mutationOptions = getScoutingWorkflowControllerAssignUserToScoutingWorkflowStateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const scoutingWorkflowControllerSuggestScoutingForPlayer = (
    tenantId: string,
    environmentId: string,
    scoutingWorkflowSuggestPostBodyDto: ScoutingWorkflowSuggestPostBodyDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/v1/${tenantId}/${environmentId}/scoutingworkflow/workflows/suggestions`,
      scoutingWorkflowSuggestPostBodyDto,options
    );
  }



export const getScoutingWorkflowControllerSuggestScoutingForPlayerMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerSuggestScoutingForPlayer>>, TError,{tenantId: string;environmentId: string;data: ScoutingWorkflowSuggestPostBodyDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerSuggestScoutingForPlayer>>, TError,{tenantId: string;environmentId: string;data: ScoutingWorkflowSuggestPostBodyDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof scoutingWorkflowControllerSuggestScoutingForPlayer>>, {tenantId: string;environmentId: string;data: ScoutingWorkflowSuggestPostBodyDto}> = (props) => {
          const {tenantId,environmentId,data} = props ?? {};

          return  scoutingWorkflowControllerSuggestScoutingForPlayer(tenantId,environmentId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ScoutingWorkflowControllerSuggestScoutingForPlayerMutationResult = NonNullable<Awaited<ReturnType<typeof scoutingWorkflowControllerSuggestScoutingForPlayer>>>
    export type ScoutingWorkflowControllerSuggestScoutingForPlayerMutationBody = ScoutingWorkflowSuggestPostBodyDto
    export type ScoutingWorkflowControllerSuggestScoutingForPlayerMutationError = AxiosError<unknown>

    export const useScoutingWorkflowControllerSuggestScoutingForPlayer = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerSuggestScoutingForPlayer>>, TError,{tenantId: string;environmentId: string;data: ScoutingWorkflowSuggestPostBodyDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof scoutingWorkflowControllerSuggestScoutingForPlayer>>,
        TError,
        {tenantId: string;environmentId: string;data: ScoutingWorkflowSuggestPostBodyDto},
        TContext
      > => {

      const mutationOptions = getScoutingWorkflowControllerSuggestScoutingForPlayerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const scoutingWorkflowControllerSuggestScoutingForWyscoutPlayer = (
    tenantId: string,
    environmentId: string,
    scoutingWorkflowSuggestwyscoutPostBodyDto: ScoutingWorkflowSuggestwyscoutPostBodyDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/v1/${tenantId}/${environmentId}/scoutingworkflow/workflows/suggestions/wyscout`,
      scoutingWorkflowSuggestwyscoutPostBodyDto,options
    );
  }



export const getScoutingWorkflowControllerSuggestScoutingForWyscoutPlayerMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerSuggestScoutingForWyscoutPlayer>>, TError,{tenantId: string;environmentId: string;data: ScoutingWorkflowSuggestwyscoutPostBodyDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerSuggestScoutingForWyscoutPlayer>>, TError,{tenantId: string;environmentId: string;data: ScoutingWorkflowSuggestwyscoutPostBodyDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof scoutingWorkflowControllerSuggestScoutingForWyscoutPlayer>>, {tenantId: string;environmentId: string;data: ScoutingWorkflowSuggestwyscoutPostBodyDto}> = (props) => {
          const {tenantId,environmentId,data} = props ?? {};

          return  scoutingWorkflowControllerSuggestScoutingForWyscoutPlayer(tenantId,environmentId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ScoutingWorkflowControllerSuggestScoutingForWyscoutPlayerMutationResult = NonNullable<Awaited<ReturnType<typeof scoutingWorkflowControllerSuggestScoutingForWyscoutPlayer>>>
    export type ScoutingWorkflowControllerSuggestScoutingForWyscoutPlayerMutationBody = ScoutingWorkflowSuggestwyscoutPostBodyDto
    export type ScoutingWorkflowControllerSuggestScoutingForWyscoutPlayerMutationError = AxiosError<unknown>

    export const useScoutingWorkflowControllerSuggestScoutingForWyscoutPlayer = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerSuggestScoutingForWyscoutPlayer>>, TError,{tenantId: string;environmentId: string;data: ScoutingWorkflowSuggestwyscoutPostBodyDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof scoutingWorkflowControllerSuggestScoutingForWyscoutPlayer>>,
        TError,
        {tenantId: string;environmentId: string;data: ScoutingWorkflowSuggestwyscoutPostBodyDto},
        TContext
      > => {

      const mutationOptions = getScoutingWorkflowControllerSuggestScoutingForWyscoutPlayerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const scoutingWorkflowControllerCancelScoutingForPlayer = (
    tenantId: string,
    environmentId: string,
    workflowStateId: string,
    scoutingWorkflowCancelPutBodyDto: ScoutingWorkflowCancelPutBodyDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `/v1/${tenantId}/${environmentId}/scoutingworkflow/workflows/${workflowStateId}/cancel`,
      scoutingWorkflowCancelPutBodyDto,options
    );
  }



export const getScoutingWorkflowControllerCancelScoutingForPlayerMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerCancelScoutingForPlayer>>, TError,{tenantId: string;environmentId: string;workflowStateId: string;data: ScoutingWorkflowCancelPutBodyDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerCancelScoutingForPlayer>>, TError,{tenantId: string;environmentId: string;workflowStateId: string;data: ScoutingWorkflowCancelPutBodyDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof scoutingWorkflowControllerCancelScoutingForPlayer>>, {tenantId: string;environmentId: string;workflowStateId: string;data: ScoutingWorkflowCancelPutBodyDto}> = (props) => {
          const {tenantId,environmentId,workflowStateId,data} = props ?? {};

          return  scoutingWorkflowControllerCancelScoutingForPlayer(tenantId,environmentId,workflowStateId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ScoutingWorkflowControllerCancelScoutingForPlayerMutationResult = NonNullable<Awaited<ReturnType<typeof scoutingWorkflowControllerCancelScoutingForPlayer>>>
    export type ScoutingWorkflowControllerCancelScoutingForPlayerMutationBody = ScoutingWorkflowCancelPutBodyDto
    export type ScoutingWorkflowControllerCancelScoutingForPlayerMutationError = AxiosError<unknown>

    export const useScoutingWorkflowControllerCancelScoutingForPlayer = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerCancelScoutingForPlayer>>, TError,{tenantId: string;environmentId: string;workflowStateId: string;data: ScoutingWorkflowCancelPutBodyDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof scoutingWorkflowControllerCancelScoutingForPlayer>>,
        TError,
        {tenantId: string;environmentId: string;workflowStateId: string;data: ScoutingWorkflowCancelPutBodyDto},
        TContext
      > => {

      const mutationOptions = getScoutingWorkflowControllerCancelScoutingForPlayerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const scoutingWorkflowControllerArchivePlayer = (
    tenantId: string,
    environmentId: string,
    workflowStateId: string,
    scoutingWorkflowArchivePutBodyDto: ScoutingWorkflowArchivePutBodyDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `/v1/${tenantId}/${environmentId}/scoutingworkflow/workflows/${workflowStateId}/archive`,
      scoutingWorkflowArchivePutBodyDto,options
    );
  }



export const getScoutingWorkflowControllerArchivePlayerMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerArchivePlayer>>, TError,{tenantId: string;environmentId: string;workflowStateId: string;data: ScoutingWorkflowArchivePutBodyDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerArchivePlayer>>, TError,{tenantId: string;environmentId: string;workflowStateId: string;data: ScoutingWorkflowArchivePutBodyDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof scoutingWorkflowControllerArchivePlayer>>, {tenantId: string;environmentId: string;workflowStateId: string;data: ScoutingWorkflowArchivePutBodyDto}> = (props) => {
          const {tenantId,environmentId,workflowStateId,data} = props ?? {};

          return  scoutingWorkflowControllerArchivePlayer(tenantId,environmentId,workflowStateId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ScoutingWorkflowControllerArchivePlayerMutationResult = NonNullable<Awaited<ReturnType<typeof scoutingWorkflowControllerArchivePlayer>>>
    export type ScoutingWorkflowControllerArchivePlayerMutationBody = ScoutingWorkflowArchivePutBodyDto
    export type ScoutingWorkflowControllerArchivePlayerMutationError = AxiosError<unknown>

    export const useScoutingWorkflowControllerArchivePlayer = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerArchivePlayer>>, TError,{tenantId: string;environmentId: string;workflowStateId: string;data: ScoutingWorkflowArchivePutBodyDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof scoutingWorkflowControllerArchivePlayer>>,
        TError,
        {tenantId: string;environmentId: string;workflowStateId: string;data: ScoutingWorkflowArchivePutBodyDto},
        TContext
      > => {

      const mutationOptions = getScoutingWorkflowControllerArchivePlayerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const scoutingWorkflowControllerApproveWorkflowState = (
    tenantId: string,
    environmentId: string,
    workflowStateId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `/v1/${tenantId}/${environmentId}/scoutingworkflow/workflows/${workflowStateId}/approve`,undefined,options
    );
  }



export const getScoutingWorkflowControllerApproveWorkflowStateMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerApproveWorkflowState>>, TError,{tenantId: string;environmentId: string;workflowStateId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerApproveWorkflowState>>, TError,{tenantId: string;environmentId: string;workflowStateId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof scoutingWorkflowControllerApproveWorkflowState>>, {tenantId: string;environmentId: string;workflowStateId: string}> = (props) => {
          const {tenantId,environmentId,workflowStateId} = props ?? {};

          return  scoutingWorkflowControllerApproveWorkflowState(tenantId,environmentId,workflowStateId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ScoutingWorkflowControllerApproveWorkflowStateMutationResult = NonNullable<Awaited<ReturnType<typeof scoutingWorkflowControllerApproveWorkflowState>>>
    
    export type ScoutingWorkflowControllerApproveWorkflowStateMutationError = AxiosError<unknown>

    export const useScoutingWorkflowControllerApproveWorkflowState = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerApproveWorkflowState>>, TError,{tenantId: string;environmentId: string;workflowStateId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof scoutingWorkflowControllerApproveWorkflowState>>,
        TError,
        {tenantId: string;environmentId: string;workflowStateId: string},
        TContext
      > => {

      const mutationOptions = getScoutingWorkflowControllerApproveWorkflowStateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const scoutingWorkflowControllerRequestWorkflowState = (
    tenantId: string,
    environmentId: string,
    workflowStateId: string,
    scoutingWorkflowRequestPutBodyDto: ScoutingWorkflowRequestPutBodyDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `/v1/${tenantId}/${environmentId}/scoutingworkflow/workflows/${workflowStateId}/request`,
      scoutingWorkflowRequestPutBodyDto,options
    );
  }



export const getScoutingWorkflowControllerRequestWorkflowStateMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerRequestWorkflowState>>, TError,{tenantId: string;environmentId: string;workflowStateId: string;data: ScoutingWorkflowRequestPutBodyDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerRequestWorkflowState>>, TError,{tenantId: string;environmentId: string;workflowStateId: string;data: ScoutingWorkflowRequestPutBodyDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof scoutingWorkflowControllerRequestWorkflowState>>, {tenantId: string;environmentId: string;workflowStateId: string;data: ScoutingWorkflowRequestPutBodyDto}> = (props) => {
          const {tenantId,environmentId,workflowStateId,data} = props ?? {};

          return  scoutingWorkflowControllerRequestWorkflowState(tenantId,environmentId,workflowStateId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ScoutingWorkflowControllerRequestWorkflowStateMutationResult = NonNullable<Awaited<ReturnType<typeof scoutingWorkflowControllerRequestWorkflowState>>>
    export type ScoutingWorkflowControllerRequestWorkflowStateMutationBody = ScoutingWorkflowRequestPutBodyDto
    export type ScoutingWorkflowControllerRequestWorkflowStateMutationError = AxiosError<unknown>

    export const useScoutingWorkflowControllerRequestWorkflowState = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerRequestWorkflowState>>, TError,{tenantId: string;environmentId: string;workflowStateId: string;data: ScoutingWorkflowRequestPutBodyDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof scoutingWorkflowControllerRequestWorkflowState>>,
        TError,
        {tenantId: string;environmentId: string;workflowStateId: string;data: ScoutingWorkflowRequestPutBodyDto},
        TContext
      > => {

      const mutationOptions = getScoutingWorkflowControllerRequestWorkflowStateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const scoutingWorkflowControllerGetWorkflowStatesByPlayer = (
    tenantId: string,
    environmentId: string,
    playerId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ScoutingWorkflowByPlayerGetDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/scoutingworkflow/workflows/byPlayer/${playerId}`,options
    );
  }


export const getScoutingWorkflowControllerGetWorkflowStatesByPlayerQueryKey = (tenantId: string,
    environmentId: string,
    playerId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/scoutingworkflow/workflows/byPlayer/${playerId}`] as const;
    }

    
export const getScoutingWorkflowControllerGetWorkflowStatesByPlayerQueryOptions = <TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowStatesByPlayer>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowStatesByPlayer>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getScoutingWorkflowControllerGetWorkflowStatesByPlayerQueryKey(tenantId,environmentId,playerId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowStatesByPlayer>>> = ({ signal }) => scoutingWorkflowControllerGetWorkflowStatesByPlayer(tenantId,environmentId,playerId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && playerId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowStatesByPlayer>>, TError, TData> & { queryKey: QueryKey }
}

export type ScoutingWorkflowControllerGetWorkflowStatesByPlayerQueryResult = NonNullable<Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowStatesByPlayer>>>
export type ScoutingWorkflowControllerGetWorkflowStatesByPlayerQueryError = AxiosError<unknown>


export function useScoutingWorkflowControllerGetWorkflowStatesByPlayer<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowStatesByPlayer>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowStatesByPlayer>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowStatesByPlayer>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingWorkflowControllerGetWorkflowStatesByPlayer<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowStatesByPlayer>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowStatesByPlayer>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowStatesByPlayer>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingWorkflowControllerGetWorkflowStatesByPlayer<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowStatesByPlayer>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowStatesByPlayer>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useScoutingWorkflowControllerGetWorkflowStatesByPlayer<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowStatesByPlayer>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowStatesByPlayer>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getScoutingWorkflowControllerGetWorkflowStatesByPlayerQueryOptions(tenantId,environmentId,playerId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getScoutingWorkflowControllerGetWorkflowStatesByPlayerSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowStatesByPlayer>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowStatesByPlayer>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getScoutingWorkflowControllerGetWorkflowStatesByPlayerQueryKey(tenantId,environmentId,playerId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowStatesByPlayer>>> = ({ signal }) => scoutingWorkflowControllerGetWorkflowStatesByPlayer(tenantId,environmentId,playerId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && playerId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowStatesByPlayer>>, TError, TData> & { queryKey: QueryKey }
}

export type ScoutingWorkflowControllerGetWorkflowStatesByPlayerSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowStatesByPlayer>>>
export type ScoutingWorkflowControllerGetWorkflowStatesByPlayerSuspenseQueryError = AxiosError<unknown>


export function useScoutingWorkflowControllerGetWorkflowStatesByPlayerSuspense<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowStatesByPlayer>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowStatesByPlayer>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingWorkflowControllerGetWorkflowStatesByPlayerSuspense<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowStatesByPlayer>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowStatesByPlayer>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingWorkflowControllerGetWorkflowStatesByPlayerSuspense<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowStatesByPlayer>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowStatesByPlayer>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useScoutingWorkflowControllerGetWorkflowStatesByPlayerSuspense<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowStatesByPlayer>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowStatesByPlayer>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getScoutingWorkflowControllerGetWorkflowStatesByPlayerSuspenseQueryOptions(tenantId,environmentId,playerId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const scoutingWorkflowControllerGetWorkflowPlayerFileList = (
    tenantId: string,
    environmentId: string,
    playerId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ScoutingWorkflowFileDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/scoutingworkflow/workflow/byPlayer/${playerId}/files`,options
    );
  }


export const getScoutingWorkflowControllerGetWorkflowPlayerFileListQueryKey = (tenantId: string,
    environmentId: string,
    playerId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/scoutingworkflow/workflow/byPlayer/${playerId}/files`] as const;
    }

    
export const getScoutingWorkflowControllerGetWorkflowPlayerFileListQueryOptions = <TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowPlayerFileList>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowPlayerFileList>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getScoutingWorkflowControllerGetWorkflowPlayerFileListQueryKey(tenantId,environmentId,playerId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowPlayerFileList>>> = ({ signal }) => scoutingWorkflowControllerGetWorkflowPlayerFileList(tenantId,environmentId,playerId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && playerId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowPlayerFileList>>, TError, TData> & { queryKey: QueryKey }
}

export type ScoutingWorkflowControllerGetWorkflowPlayerFileListQueryResult = NonNullable<Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowPlayerFileList>>>
export type ScoutingWorkflowControllerGetWorkflowPlayerFileListQueryError = AxiosError<unknown>


export function useScoutingWorkflowControllerGetWorkflowPlayerFileList<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowPlayerFileList>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowPlayerFileList>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowPlayerFileList>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingWorkflowControllerGetWorkflowPlayerFileList<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowPlayerFileList>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowPlayerFileList>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowPlayerFileList>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingWorkflowControllerGetWorkflowPlayerFileList<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowPlayerFileList>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowPlayerFileList>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useScoutingWorkflowControllerGetWorkflowPlayerFileList<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowPlayerFileList>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowPlayerFileList>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getScoutingWorkflowControllerGetWorkflowPlayerFileListQueryOptions(tenantId,environmentId,playerId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getScoutingWorkflowControllerGetWorkflowPlayerFileListSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowPlayerFileList>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowPlayerFileList>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getScoutingWorkflowControllerGetWorkflowPlayerFileListQueryKey(tenantId,environmentId,playerId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowPlayerFileList>>> = ({ signal }) => scoutingWorkflowControllerGetWorkflowPlayerFileList(tenantId,environmentId,playerId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && playerId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowPlayerFileList>>, TError, TData> & { queryKey: QueryKey }
}

export type ScoutingWorkflowControllerGetWorkflowPlayerFileListSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowPlayerFileList>>>
export type ScoutingWorkflowControllerGetWorkflowPlayerFileListSuspenseQueryError = AxiosError<unknown>


export function useScoutingWorkflowControllerGetWorkflowPlayerFileListSuspense<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowPlayerFileList>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowPlayerFileList>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingWorkflowControllerGetWorkflowPlayerFileListSuspense<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowPlayerFileList>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowPlayerFileList>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingWorkflowControllerGetWorkflowPlayerFileListSuspense<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowPlayerFileList>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowPlayerFileList>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useScoutingWorkflowControllerGetWorkflowPlayerFileListSuspense<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowPlayerFileList>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowPlayerFileList>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getScoutingWorkflowControllerGetWorkflowPlayerFileListSuspenseQueryOptions(tenantId,environmentId,playerId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const scoutingWorkflowControllerGetWorkflowPlayerFileUploadUrl = (
    tenantId: string,
    environmentId: string,
    playerId: string,
    scoutingWorkflowFileuploadPostBodyDto: ScoutingWorkflowFileuploadPostBodyDto[], options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ScoutingWorkflowFileuploadPostReturnValueDto[]>> => {
    
    return axios.post(
      `/v1/${tenantId}/${environmentId}/scoutingworkflow/workflow/byPlayer/${playerId}/files/uploadrequest`,
      scoutingWorkflowFileuploadPostBodyDto,options
    );
  }



export const getScoutingWorkflowControllerGetWorkflowPlayerFileUploadUrlMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowPlayerFileUploadUrl>>, TError,{tenantId: string;environmentId: string;playerId: string;data: ScoutingWorkflowFileuploadPostBodyDto[]}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowPlayerFileUploadUrl>>, TError,{tenantId: string;environmentId: string;playerId: string;data: ScoutingWorkflowFileuploadPostBodyDto[]}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowPlayerFileUploadUrl>>, {tenantId: string;environmentId: string;playerId: string;data: ScoutingWorkflowFileuploadPostBodyDto[]}> = (props) => {
          const {tenantId,environmentId,playerId,data} = props ?? {};

          return  scoutingWorkflowControllerGetWorkflowPlayerFileUploadUrl(tenantId,environmentId,playerId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ScoutingWorkflowControllerGetWorkflowPlayerFileUploadUrlMutationResult = NonNullable<Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowPlayerFileUploadUrl>>>
    export type ScoutingWorkflowControllerGetWorkflowPlayerFileUploadUrlMutationBody = ScoutingWorkflowFileuploadPostBodyDto[]
    export type ScoutingWorkflowControllerGetWorkflowPlayerFileUploadUrlMutationError = AxiosError<unknown>

    export const useScoutingWorkflowControllerGetWorkflowPlayerFileUploadUrl = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowPlayerFileUploadUrl>>, TError,{tenantId: string;environmentId: string;playerId: string;data: ScoutingWorkflowFileuploadPostBodyDto[]}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof scoutingWorkflowControllerGetWorkflowPlayerFileUploadUrl>>,
        TError,
        {tenantId: string;environmentId: string;playerId: string;data: ScoutingWorkflowFileuploadPostBodyDto[]},
        TContext
      > => {

      const mutationOptions = getScoutingWorkflowControllerGetWorkflowPlayerFileUploadUrlMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const scoutingWorkflowControllerDeleteWorkflowPlayerFile = (
    tenantId: string,
    environmentId: string,
    playerId: string,
    fileId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/v1/${tenantId}/${environmentId}/scoutingworkflow/workflow/byPlayer/${playerId}/files/${fileId}`,options
    );
  }



export const getScoutingWorkflowControllerDeleteWorkflowPlayerFileMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerDeleteWorkflowPlayerFile>>, TError,{tenantId: string;environmentId: string;playerId: string;fileId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerDeleteWorkflowPlayerFile>>, TError,{tenantId: string;environmentId: string;playerId: string;fileId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof scoutingWorkflowControllerDeleteWorkflowPlayerFile>>, {tenantId: string;environmentId: string;playerId: string;fileId: string}> = (props) => {
          const {tenantId,environmentId,playerId,fileId} = props ?? {};

          return  scoutingWorkflowControllerDeleteWorkflowPlayerFile(tenantId,environmentId,playerId,fileId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ScoutingWorkflowControllerDeleteWorkflowPlayerFileMutationResult = NonNullable<Awaited<ReturnType<typeof scoutingWorkflowControllerDeleteWorkflowPlayerFile>>>
    
    export type ScoutingWorkflowControllerDeleteWorkflowPlayerFileMutationError = AxiosError<unknown>

    export const useScoutingWorkflowControllerDeleteWorkflowPlayerFile = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerDeleteWorkflowPlayerFile>>, TError,{tenantId: string;environmentId: string;playerId: string;fileId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof scoutingWorkflowControllerDeleteWorkflowPlayerFile>>,
        TError,
        {tenantId: string;environmentId: string;playerId: string;fileId: string},
        TContext
      > => {

      const mutationOptions = getScoutingWorkflowControllerDeleteWorkflowPlayerFileMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const scoutingWorkflowControllerGetNotesForPlayer = (
    tenantId: string,
    environmentId: string,
    playerId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ScoutingWorkflowNotesGetReturnValueDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/scoutingworkflow/workflow/byPlayer/${playerId}/notes`,options
    );
  }


export const getScoutingWorkflowControllerGetNotesForPlayerQueryKey = (tenantId: string,
    environmentId: string,
    playerId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/scoutingworkflow/workflow/byPlayer/${playerId}/notes`] as const;
    }

    
export const getScoutingWorkflowControllerGetNotesForPlayerQueryOptions = <TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetNotesForPlayer>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetNotesForPlayer>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getScoutingWorkflowControllerGetNotesForPlayerQueryKey(tenantId,environmentId,playerId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof scoutingWorkflowControllerGetNotesForPlayer>>> = ({ signal }) => scoutingWorkflowControllerGetNotesForPlayer(tenantId,environmentId,playerId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && playerId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetNotesForPlayer>>, TError, TData> & { queryKey: QueryKey }
}

export type ScoutingWorkflowControllerGetNotesForPlayerQueryResult = NonNullable<Awaited<ReturnType<typeof scoutingWorkflowControllerGetNotesForPlayer>>>
export type ScoutingWorkflowControllerGetNotesForPlayerQueryError = AxiosError<unknown>


export function useScoutingWorkflowControllerGetNotesForPlayer<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetNotesForPlayer>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetNotesForPlayer>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof scoutingWorkflowControllerGetNotesForPlayer>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingWorkflowControllerGetNotesForPlayer<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetNotesForPlayer>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetNotesForPlayer>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof scoutingWorkflowControllerGetNotesForPlayer>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingWorkflowControllerGetNotesForPlayer<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetNotesForPlayer>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetNotesForPlayer>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useScoutingWorkflowControllerGetNotesForPlayer<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetNotesForPlayer>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetNotesForPlayer>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getScoutingWorkflowControllerGetNotesForPlayerQueryOptions(tenantId,environmentId,playerId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getScoutingWorkflowControllerGetNotesForPlayerSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetNotesForPlayer>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetNotesForPlayer>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getScoutingWorkflowControllerGetNotesForPlayerQueryKey(tenantId,environmentId,playerId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof scoutingWorkflowControllerGetNotesForPlayer>>> = ({ signal }) => scoutingWorkflowControllerGetNotesForPlayer(tenantId,environmentId,playerId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && playerId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetNotesForPlayer>>, TError, TData> & { queryKey: QueryKey }
}

export type ScoutingWorkflowControllerGetNotesForPlayerSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof scoutingWorkflowControllerGetNotesForPlayer>>>
export type ScoutingWorkflowControllerGetNotesForPlayerSuspenseQueryError = AxiosError<unknown>


export function useScoutingWorkflowControllerGetNotesForPlayerSuspense<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetNotesForPlayer>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetNotesForPlayer>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingWorkflowControllerGetNotesForPlayerSuspense<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetNotesForPlayer>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetNotesForPlayer>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingWorkflowControllerGetNotesForPlayerSuspense<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetNotesForPlayer>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetNotesForPlayer>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useScoutingWorkflowControllerGetNotesForPlayerSuspense<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetNotesForPlayer>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    playerId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetNotesForPlayer>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getScoutingWorkflowControllerGetNotesForPlayerSuspenseQueryOptions(tenantId,environmentId,playerId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const scoutingWorkflowControllerAddNoteForPlayer = (
    tenantId: string,
    environmentId: string,
    playerId: string,
    scoutingWorkflowNotesPostBodyDto: ScoutingWorkflowNotesPostBodyDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/v1/${tenantId}/${environmentId}/scoutingworkflow/workflow/byPlayer/${playerId}/notes`,
      scoutingWorkflowNotesPostBodyDto,options
    );
  }



export const getScoutingWorkflowControllerAddNoteForPlayerMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerAddNoteForPlayer>>, TError,{tenantId: string;environmentId: string;playerId: string;data: ScoutingWorkflowNotesPostBodyDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerAddNoteForPlayer>>, TError,{tenantId: string;environmentId: string;playerId: string;data: ScoutingWorkflowNotesPostBodyDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof scoutingWorkflowControllerAddNoteForPlayer>>, {tenantId: string;environmentId: string;playerId: string;data: ScoutingWorkflowNotesPostBodyDto}> = (props) => {
          const {tenantId,environmentId,playerId,data} = props ?? {};

          return  scoutingWorkflowControllerAddNoteForPlayer(tenantId,environmentId,playerId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ScoutingWorkflowControllerAddNoteForPlayerMutationResult = NonNullable<Awaited<ReturnType<typeof scoutingWorkflowControllerAddNoteForPlayer>>>
    export type ScoutingWorkflowControllerAddNoteForPlayerMutationBody = ScoutingWorkflowNotesPostBodyDto
    export type ScoutingWorkflowControllerAddNoteForPlayerMutationError = AxiosError<unknown>

    export const useScoutingWorkflowControllerAddNoteForPlayer = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerAddNoteForPlayer>>, TError,{tenantId: string;environmentId: string;playerId: string;data: ScoutingWorkflowNotesPostBodyDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof scoutingWorkflowControllerAddNoteForPlayer>>,
        TError,
        {tenantId: string;environmentId: string;playerId: string;data: ScoutingWorkflowNotesPostBodyDto},
        TContext
      > => {

      const mutationOptions = getScoutingWorkflowControllerAddNoteForPlayerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const scoutingWorkflowControllerUpdateNoteForPlayer = (
    tenantId: string,
    environmentId: string,
    playerId: string,
    noteId: string,
    scoutingWorkflowNotesPutBodyDto: ScoutingWorkflowNotesPutBodyDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `/v1/${tenantId}/${environmentId}/scoutingworkflow/workflow/byPlayer/${playerId}/notes/${noteId}`,
      scoutingWorkflowNotesPutBodyDto,options
    );
  }



export const getScoutingWorkflowControllerUpdateNoteForPlayerMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerUpdateNoteForPlayer>>, TError,{tenantId: string;environmentId: string;playerId: string;noteId: string;data: ScoutingWorkflowNotesPutBodyDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerUpdateNoteForPlayer>>, TError,{tenantId: string;environmentId: string;playerId: string;noteId: string;data: ScoutingWorkflowNotesPutBodyDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof scoutingWorkflowControllerUpdateNoteForPlayer>>, {tenantId: string;environmentId: string;playerId: string;noteId: string;data: ScoutingWorkflowNotesPutBodyDto}> = (props) => {
          const {tenantId,environmentId,playerId,noteId,data} = props ?? {};

          return  scoutingWorkflowControllerUpdateNoteForPlayer(tenantId,environmentId,playerId,noteId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ScoutingWorkflowControllerUpdateNoteForPlayerMutationResult = NonNullable<Awaited<ReturnType<typeof scoutingWorkflowControllerUpdateNoteForPlayer>>>
    export type ScoutingWorkflowControllerUpdateNoteForPlayerMutationBody = ScoutingWorkflowNotesPutBodyDto
    export type ScoutingWorkflowControllerUpdateNoteForPlayerMutationError = AxiosError<unknown>

    export const useScoutingWorkflowControllerUpdateNoteForPlayer = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerUpdateNoteForPlayer>>, TError,{tenantId: string;environmentId: string;playerId: string;noteId: string;data: ScoutingWorkflowNotesPutBodyDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof scoutingWorkflowControllerUpdateNoteForPlayer>>,
        TError,
        {tenantId: string;environmentId: string;playerId: string;noteId: string;data: ScoutingWorkflowNotesPutBodyDto},
        TContext
      > => {

      const mutationOptions = getScoutingWorkflowControllerUpdateNoteForPlayerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const scoutingWorkflowControllerDeleteNoteForPlayer = (
    tenantId: string,
    environmentId: string,
    playerId: string,
    noteId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/v1/${tenantId}/${environmentId}/scoutingworkflow/workflow/byPlayer/${playerId}/notes/${noteId}`,options
    );
  }



export const getScoutingWorkflowControllerDeleteNoteForPlayerMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerDeleteNoteForPlayer>>, TError,{tenantId: string;environmentId: string;playerId: string;noteId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerDeleteNoteForPlayer>>, TError,{tenantId: string;environmentId: string;playerId: string;noteId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof scoutingWorkflowControllerDeleteNoteForPlayer>>, {tenantId: string;environmentId: string;playerId: string;noteId: string}> = (props) => {
          const {tenantId,environmentId,playerId,noteId} = props ?? {};

          return  scoutingWorkflowControllerDeleteNoteForPlayer(tenantId,environmentId,playerId,noteId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ScoutingWorkflowControllerDeleteNoteForPlayerMutationResult = NonNullable<Awaited<ReturnType<typeof scoutingWorkflowControllerDeleteNoteForPlayer>>>
    
    export type ScoutingWorkflowControllerDeleteNoteForPlayerMutationError = AxiosError<unknown>

    export const useScoutingWorkflowControllerDeleteNoteForPlayer = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerDeleteNoteForPlayer>>, TError,{tenantId: string;environmentId: string;playerId: string;noteId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof scoutingWorkflowControllerDeleteNoteForPlayer>>,
        TError,
        {tenantId: string;environmentId: string;playerId: string;noteId: string},
        TContext
      > => {

      const mutationOptions = getScoutingWorkflowControllerDeleteNoteForPlayerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const scoutingWorkflowControllerGetTenantWorkflowTags = (
    tenantId: string,
    environmentId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ScoutingTenantWorkflowTagDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/scoutingworkflow/workflow/tags`,options
    );
  }


export const getScoutingWorkflowControllerGetTenantWorkflowTagsQueryKey = (tenantId: string,
    environmentId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/scoutingworkflow/workflow/tags`] as const;
    }

    
export const getScoutingWorkflowControllerGetTenantWorkflowTagsQueryOptions = <TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetTenantWorkflowTags>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetTenantWorkflowTags>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getScoutingWorkflowControllerGetTenantWorkflowTagsQueryKey(tenantId,environmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof scoutingWorkflowControllerGetTenantWorkflowTags>>> = ({ signal }) => scoutingWorkflowControllerGetTenantWorkflowTags(tenantId,environmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetTenantWorkflowTags>>, TError, TData> & { queryKey: QueryKey }
}

export type ScoutingWorkflowControllerGetTenantWorkflowTagsQueryResult = NonNullable<Awaited<ReturnType<typeof scoutingWorkflowControllerGetTenantWorkflowTags>>>
export type ScoutingWorkflowControllerGetTenantWorkflowTagsQueryError = AxiosError<unknown>


export function useScoutingWorkflowControllerGetTenantWorkflowTags<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetTenantWorkflowTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetTenantWorkflowTags>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof scoutingWorkflowControllerGetTenantWorkflowTags>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingWorkflowControllerGetTenantWorkflowTags<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetTenantWorkflowTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetTenantWorkflowTags>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof scoutingWorkflowControllerGetTenantWorkflowTags>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingWorkflowControllerGetTenantWorkflowTags<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetTenantWorkflowTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetTenantWorkflowTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useScoutingWorkflowControllerGetTenantWorkflowTags<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetTenantWorkflowTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetTenantWorkflowTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getScoutingWorkflowControllerGetTenantWorkflowTagsQueryOptions(tenantId,environmentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getScoutingWorkflowControllerGetTenantWorkflowTagsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetTenantWorkflowTags>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetTenantWorkflowTags>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getScoutingWorkflowControllerGetTenantWorkflowTagsQueryKey(tenantId,environmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof scoutingWorkflowControllerGetTenantWorkflowTags>>> = ({ signal }) => scoutingWorkflowControllerGetTenantWorkflowTags(tenantId,environmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetTenantWorkflowTags>>, TError, TData> & { queryKey: QueryKey }
}

export type ScoutingWorkflowControllerGetTenantWorkflowTagsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof scoutingWorkflowControllerGetTenantWorkflowTags>>>
export type ScoutingWorkflowControllerGetTenantWorkflowTagsSuspenseQueryError = AxiosError<unknown>


export function useScoutingWorkflowControllerGetTenantWorkflowTagsSuspense<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetTenantWorkflowTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetTenantWorkflowTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingWorkflowControllerGetTenantWorkflowTagsSuspense<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetTenantWorkflowTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetTenantWorkflowTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingWorkflowControllerGetTenantWorkflowTagsSuspense<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetTenantWorkflowTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetTenantWorkflowTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useScoutingWorkflowControllerGetTenantWorkflowTagsSuspense<TData = Awaited<ReturnType<typeof scoutingWorkflowControllerGetTenantWorkflowTags>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerGetTenantWorkflowTags>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getScoutingWorkflowControllerGetTenantWorkflowTagsSuspenseQueryOptions(tenantId,environmentId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const scoutingWorkflowControllerCreateTenantWorkflowTag = (
    tenantId: string,
    environmentId: string,
    scoutingTenantWorkflowTagPostDto: ScoutingTenantWorkflowTagPostDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/v1/${tenantId}/${environmentId}/scoutingworkflow/workflow/tags`,
      scoutingTenantWorkflowTagPostDto,options
    );
  }



export const getScoutingWorkflowControllerCreateTenantWorkflowTagMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerCreateTenantWorkflowTag>>, TError,{tenantId: string;environmentId: string;data: ScoutingTenantWorkflowTagPostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerCreateTenantWorkflowTag>>, TError,{tenantId: string;environmentId: string;data: ScoutingTenantWorkflowTagPostDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof scoutingWorkflowControllerCreateTenantWorkflowTag>>, {tenantId: string;environmentId: string;data: ScoutingTenantWorkflowTagPostDto}> = (props) => {
          const {tenantId,environmentId,data} = props ?? {};

          return  scoutingWorkflowControllerCreateTenantWorkflowTag(tenantId,environmentId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ScoutingWorkflowControllerCreateTenantWorkflowTagMutationResult = NonNullable<Awaited<ReturnType<typeof scoutingWorkflowControllerCreateTenantWorkflowTag>>>
    export type ScoutingWorkflowControllerCreateTenantWorkflowTagMutationBody = ScoutingTenantWorkflowTagPostDto
    export type ScoutingWorkflowControllerCreateTenantWorkflowTagMutationError = AxiosError<unknown>

    export const useScoutingWorkflowControllerCreateTenantWorkflowTag = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerCreateTenantWorkflowTag>>, TError,{tenantId: string;environmentId: string;data: ScoutingTenantWorkflowTagPostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof scoutingWorkflowControllerCreateTenantWorkflowTag>>,
        TError,
        {tenantId: string;environmentId: string;data: ScoutingTenantWorkflowTagPostDto},
        TContext
      > => {

      const mutationOptions = getScoutingWorkflowControllerCreateTenantWorkflowTagMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const scoutingWorkflowControllerUpdateTenantWorkflowTag = (
    tenantId: string,
    environmentId: string,
    tagId: string,
    scoutingTenantWorkflowTagPutDto: ScoutingTenantWorkflowTagPutDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `/v1/${tenantId}/${environmentId}/scoutingworkflow/workflow/tags/${tagId}`,
      scoutingTenantWorkflowTagPutDto,options
    );
  }



export const getScoutingWorkflowControllerUpdateTenantWorkflowTagMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerUpdateTenantWorkflowTag>>, TError,{tenantId: string;environmentId: string;tagId: string;data: ScoutingTenantWorkflowTagPutDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerUpdateTenantWorkflowTag>>, TError,{tenantId: string;environmentId: string;tagId: string;data: ScoutingTenantWorkflowTagPutDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof scoutingWorkflowControllerUpdateTenantWorkflowTag>>, {tenantId: string;environmentId: string;tagId: string;data: ScoutingTenantWorkflowTagPutDto}> = (props) => {
          const {tenantId,environmentId,tagId,data} = props ?? {};

          return  scoutingWorkflowControllerUpdateTenantWorkflowTag(tenantId,environmentId,tagId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ScoutingWorkflowControllerUpdateTenantWorkflowTagMutationResult = NonNullable<Awaited<ReturnType<typeof scoutingWorkflowControllerUpdateTenantWorkflowTag>>>
    export type ScoutingWorkflowControllerUpdateTenantWorkflowTagMutationBody = ScoutingTenantWorkflowTagPutDto
    export type ScoutingWorkflowControllerUpdateTenantWorkflowTagMutationError = AxiosError<unknown>

    export const useScoutingWorkflowControllerUpdateTenantWorkflowTag = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerUpdateTenantWorkflowTag>>, TError,{tenantId: string;environmentId: string;tagId: string;data: ScoutingTenantWorkflowTagPutDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof scoutingWorkflowControllerUpdateTenantWorkflowTag>>,
        TError,
        {tenantId: string;environmentId: string;tagId: string;data: ScoutingTenantWorkflowTagPutDto},
        TContext
      > => {

      const mutationOptions = getScoutingWorkflowControllerUpdateTenantWorkflowTagMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const scoutingWorkflowControllerDeleteTenantWorkflowTag = (
    tenantId: string,
    environmentId: string,
    tagId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/v1/${tenantId}/${environmentId}/scoutingworkflow/workflow/tags/${tagId}`,options
    );
  }



export const getScoutingWorkflowControllerDeleteTenantWorkflowTagMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerDeleteTenantWorkflowTag>>, TError,{tenantId: string;environmentId: string;tagId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerDeleteTenantWorkflowTag>>, TError,{tenantId: string;environmentId: string;tagId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof scoutingWorkflowControllerDeleteTenantWorkflowTag>>, {tenantId: string;environmentId: string;tagId: string}> = (props) => {
          const {tenantId,environmentId,tagId} = props ?? {};

          return  scoutingWorkflowControllerDeleteTenantWorkflowTag(tenantId,environmentId,tagId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ScoutingWorkflowControllerDeleteTenantWorkflowTagMutationResult = NonNullable<Awaited<ReturnType<typeof scoutingWorkflowControllerDeleteTenantWorkflowTag>>>
    
    export type ScoutingWorkflowControllerDeleteTenantWorkflowTagMutationError = AxiosError<unknown>

    export const useScoutingWorkflowControllerDeleteTenantWorkflowTag = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerDeleteTenantWorkflowTag>>, TError,{tenantId: string;environmentId: string;tagId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof scoutingWorkflowControllerDeleteTenantWorkflowTag>>,
        TError,
        {tenantId: string;environmentId: string;tagId: string},
        TContext
      > => {

      const mutationOptions = getScoutingWorkflowControllerDeleteTenantWorkflowTagMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const scoutingWorkflowControllerUpdateWorkflowStateTag = (
    tenantId: string,
    environmentId: string,
    workflowStateId: string,
    scoutingWorkflowTagPutDto: ScoutingWorkflowTagPutDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `/v1/${tenantId}/${environmentId}/scoutingworkflow/workflows/${workflowStateId}/tag`,
      scoutingWorkflowTagPutDto,options
    );
  }



export const getScoutingWorkflowControllerUpdateWorkflowStateTagMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerUpdateWorkflowStateTag>>, TError,{tenantId: string;environmentId: string;workflowStateId: string;data: ScoutingWorkflowTagPutDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerUpdateWorkflowStateTag>>, TError,{tenantId: string;environmentId: string;workflowStateId: string;data: ScoutingWorkflowTagPutDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof scoutingWorkflowControllerUpdateWorkflowStateTag>>, {tenantId: string;environmentId: string;workflowStateId: string;data: ScoutingWorkflowTagPutDto}> = (props) => {
          const {tenantId,environmentId,workflowStateId,data} = props ?? {};

          return  scoutingWorkflowControllerUpdateWorkflowStateTag(tenantId,environmentId,workflowStateId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ScoutingWorkflowControllerUpdateWorkflowStateTagMutationResult = NonNullable<Awaited<ReturnType<typeof scoutingWorkflowControllerUpdateWorkflowStateTag>>>
    export type ScoutingWorkflowControllerUpdateWorkflowStateTagMutationBody = ScoutingWorkflowTagPutDto
    export type ScoutingWorkflowControllerUpdateWorkflowStateTagMutationError = AxiosError<unknown>

    export const useScoutingWorkflowControllerUpdateWorkflowStateTag = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerUpdateWorkflowStateTag>>, TError,{tenantId: string;environmentId: string;workflowStateId: string;data: ScoutingWorkflowTagPutDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof scoutingWorkflowControllerUpdateWorkflowStateTag>>,
        TError,
        {tenantId: string;environmentId: string;workflowStateId: string;data: ScoutingWorkflowTagPutDto},
        TContext
      > => {

      const mutationOptions = getScoutingWorkflowControllerUpdateWorkflowStateTagMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const scoutingWorkflowControllerUpdateWorkflowStateTags = (
    tenantId: string,
    environmentId: string,
    workflowStateId: string,
    scoutingWorkflowTagsPutDto: ScoutingWorkflowTagsPutDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `/v1/${tenantId}/${environmentId}/scoutingworkflow/workflows/${workflowStateId}/tags`,
      scoutingWorkflowTagsPutDto,options
    );
  }



export const getScoutingWorkflowControllerUpdateWorkflowStateTagsMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerUpdateWorkflowStateTags>>, TError,{tenantId: string;environmentId: string;workflowStateId: string;data: ScoutingWorkflowTagsPutDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerUpdateWorkflowStateTags>>, TError,{tenantId: string;environmentId: string;workflowStateId: string;data: ScoutingWorkflowTagsPutDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof scoutingWorkflowControllerUpdateWorkflowStateTags>>, {tenantId: string;environmentId: string;workflowStateId: string;data: ScoutingWorkflowTagsPutDto}> = (props) => {
          const {tenantId,environmentId,workflowStateId,data} = props ?? {};

          return  scoutingWorkflowControllerUpdateWorkflowStateTags(tenantId,environmentId,workflowStateId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ScoutingWorkflowControllerUpdateWorkflowStateTagsMutationResult = NonNullable<Awaited<ReturnType<typeof scoutingWorkflowControllerUpdateWorkflowStateTags>>>
    export type ScoutingWorkflowControllerUpdateWorkflowStateTagsMutationBody = ScoutingWorkflowTagsPutDto
    export type ScoutingWorkflowControllerUpdateWorkflowStateTagsMutationError = AxiosError<unknown>

    export const useScoutingWorkflowControllerUpdateWorkflowStateTags = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingWorkflowControllerUpdateWorkflowStateTags>>, TError,{tenantId: string;environmentId: string;workflowStateId: string;data: ScoutingWorkflowTagsPutDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof scoutingWorkflowControllerUpdateWorkflowStateTags>>,
        TError,
        {tenantId: string;environmentId: string;workflowStateId: string;data: ScoutingWorkflowTagsPutDto},
        TContext
      > => {

      const mutationOptions = getScoutingWorkflowControllerUpdateWorkflowStateTagsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const filesControllerCompleteUpload = (
    completeFileUploadPutDto: CompleteFileUploadPutDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `/v1/files/completeupload`,
      completeFileUploadPutDto,options
    );
  }



export const getFilesControllerCompleteUploadMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof filesControllerCompleteUpload>>, TError,{data: CompleteFileUploadPutDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof filesControllerCompleteUpload>>, TError,{data: CompleteFileUploadPutDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof filesControllerCompleteUpload>>, {data: CompleteFileUploadPutDto}> = (props) => {
          const {data} = props ?? {};

          return  filesControllerCompleteUpload(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type FilesControllerCompleteUploadMutationResult = NonNullable<Awaited<ReturnType<typeof filesControllerCompleteUpload>>>
    export type FilesControllerCompleteUploadMutationBody = CompleteFileUploadPutDto
    export type FilesControllerCompleteUploadMutationError = AxiosError<unknown>

    export const useFilesControllerCompleteUpload = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof filesControllerCompleteUpload>>, TError,{data: CompleteFileUploadPutDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof filesControllerCompleteUpload>>,
        TError,
        {data: CompleteFileUploadPutDto},
        TContext
      > => {

      const mutationOptions = getFilesControllerCompleteUploadMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const scoutingSquadsControllerGetSquads = (
    tenantId: string,
    environmentId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ScoutingSquadsListGetReturnValueDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/scoutingsquads`,options
    );
  }


export const getScoutingSquadsControllerGetSquadsQueryKey = (tenantId: string,
    environmentId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/scoutingsquads`] as const;
    }

    
export const getScoutingSquadsControllerGetSquadsQueryOptions = <TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquads>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquads>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getScoutingSquadsControllerGetSquadsQueryKey(tenantId,environmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquads>>> = ({ signal }) => scoutingSquadsControllerGetSquads(tenantId,environmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquads>>, TError, TData> & { queryKey: QueryKey }
}

export type ScoutingSquadsControllerGetSquadsQueryResult = NonNullable<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquads>>>
export type ScoutingSquadsControllerGetSquadsQueryError = AxiosError<unknown>


export function useScoutingSquadsControllerGetSquads<TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquads>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquads>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof scoutingSquadsControllerGetSquads>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingSquadsControllerGetSquads<TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquads>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquads>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof scoutingSquadsControllerGetSquads>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingSquadsControllerGetSquads<TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquads>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquads>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useScoutingSquadsControllerGetSquads<TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquads>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquads>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getScoutingSquadsControllerGetSquadsQueryOptions(tenantId,environmentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getScoutingSquadsControllerGetSquadsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquads>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquads>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getScoutingSquadsControllerGetSquadsQueryKey(tenantId,environmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquads>>> = ({ signal }) => scoutingSquadsControllerGetSquads(tenantId,environmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquads>>, TError, TData> & { queryKey: QueryKey }
}

export type ScoutingSquadsControllerGetSquadsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquads>>>
export type ScoutingSquadsControllerGetSquadsSuspenseQueryError = AxiosError<unknown>


export function useScoutingSquadsControllerGetSquadsSuspense<TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquads>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquads>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingSquadsControllerGetSquadsSuspense<TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquads>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquads>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingSquadsControllerGetSquadsSuspense<TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquads>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquads>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useScoutingSquadsControllerGetSquadsSuspense<TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquads>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquads>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getScoutingSquadsControllerGetSquadsSuspenseQueryOptions(tenantId,environmentId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const scoutingSquadsControllerCreateSquad = (
    tenantId: string,
    environmentId: string,
    scoutingSquadsCreatePostBodyDto: ScoutingSquadsCreatePostBodyDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/v1/${tenantId}/${environmentId}/scoutingsquads`,
      scoutingSquadsCreatePostBodyDto,options
    );
  }



export const getScoutingSquadsControllerCreateSquadMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingSquadsControllerCreateSquad>>, TError,{tenantId: string;environmentId: string;data: ScoutingSquadsCreatePostBodyDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof scoutingSquadsControllerCreateSquad>>, TError,{tenantId: string;environmentId: string;data: ScoutingSquadsCreatePostBodyDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof scoutingSquadsControllerCreateSquad>>, {tenantId: string;environmentId: string;data: ScoutingSquadsCreatePostBodyDto}> = (props) => {
          const {tenantId,environmentId,data} = props ?? {};

          return  scoutingSquadsControllerCreateSquad(tenantId,environmentId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ScoutingSquadsControllerCreateSquadMutationResult = NonNullable<Awaited<ReturnType<typeof scoutingSquadsControllerCreateSquad>>>
    export type ScoutingSquadsControllerCreateSquadMutationBody = ScoutingSquadsCreatePostBodyDto
    export type ScoutingSquadsControllerCreateSquadMutationError = AxiosError<unknown>

    export const useScoutingSquadsControllerCreateSquad = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingSquadsControllerCreateSquad>>, TError,{tenantId: string;environmentId: string;data: ScoutingSquadsCreatePostBodyDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof scoutingSquadsControllerCreateSquad>>,
        TError,
        {tenantId: string;environmentId: string;data: ScoutingSquadsCreatePostBodyDto},
        TContext
      > => {

      const mutationOptions = getScoutingSquadsControllerCreateSquadMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const scoutingSquadsControllerGetSquad = (
    tenantId: string,
    environmentId: string,
    squadId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ScoutingSquadsGetGetReturnValueDto>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/scoutingsquads/${squadId}`,options
    );
  }


export const getScoutingSquadsControllerGetSquadQueryKey = (tenantId: string,
    environmentId: string,
    squadId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/scoutingsquads/${squadId}`] as const;
    }

    
export const getScoutingSquadsControllerGetSquadQueryOptions = <TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquad>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    squadId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquad>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getScoutingSquadsControllerGetSquadQueryKey(tenantId,environmentId,squadId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquad>>> = ({ signal }) => scoutingSquadsControllerGetSquad(tenantId,environmentId,squadId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && squadId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquad>>, TError, TData> & { queryKey: QueryKey }
}

export type ScoutingSquadsControllerGetSquadQueryResult = NonNullable<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquad>>>
export type ScoutingSquadsControllerGetSquadQueryError = AxiosError<unknown>


export function useScoutingSquadsControllerGetSquad<TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquad>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    squadId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquad>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof scoutingSquadsControllerGetSquad>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingSquadsControllerGetSquad<TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquad>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    squadId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquad>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof scoutingSquadsControllerGetSquad>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingSquadsControllerGetSquad<TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquad>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    squadId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquad>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useScoutingSquadsControllerGetSquad<TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquad>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    squadId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquad>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getScoutingSquadsControllerGetSquadQueryOptions(tenantId,environmentId,squadId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getScoutingSquadsControllerGetSquadSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquad>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    squadId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquad>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getScoutingSquadsControllerGetSquadQueryKey(tenantId,environmentId,squadId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquad>>> = ({ signal }) => scoutingSquadsControllerGetSquad(tenantId,environmentId,squadId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && squadId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquad>>, TError, TData> & { queryKey: QueryKey }
}

export type ScoutingSquadsControllerGetSquadSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquad>>>
export type ScoutingSquadsControllerGetSquadSuspenseQueryError = AxiosError<unknown>


export function useScoutingSquadsControllerGetSquadSuspense<TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquad>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    squadId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquad>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingSquadsControllerGetSquadSuspense<TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquad>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    squadId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquad>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingSquadsControllerGetSquadSuspense<TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquad>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    squadId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquad>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useScoutingSquadsControllerGetSquadSuspense<TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquad>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    squadId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquad>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getScoutingSquadsControllerGetSquadSuspenseQueryOptions(tenantId,environmentId,squadId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const scoutingSquadsControllerUpdateSquad = (
    tenantId: string,
    environmentId: string,
    squadId: string,
    scoutingSquadsUpdatePutBodyDto: ScoutingSquadsUpdatePutBodyDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `/v1/${tenantId}/${environmentId}/scoutingsquads/${squadId}`,
      scoutingSquadsUpdatePutBodyDto,options
    );
  }



export const getScoutingSquadsControllerUpdateSquadMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingSquadsControllerUpdateSquad>>, TError,{tenantId: string;environmentId: string;squadId: string;data: ScoutingSquadsUpdatePutBodyDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof scoutingSquadsControllerUpdateSquad>>, TError,{tenantId: string;environmentId: string;squadId: string;data: ScoutingSquadsUpdatePutBodyDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof scoutingSquadsControllerUpdateSquad>>, {tenantId: string;environmentId: string;squadId: string;data: ScoutingSquadsUpdatePutBodyDto}> = (props) => {
          const {tenantId,environmentId,squadId,data} = props ?? {};

          return  scoutingSquadsControllerUpdateSquad(tenantId,environmentId,squadId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ScoutingSquadsControllerUpdateSquadMutationResult = NonNullable<Awaited<ReturnType<typeof scoutingSquadsControllerUpdateSquad>>>
    export type ScoutingSquadsControllerUpdateSquadMutationBody = ScoutingSquadsUpdatePutBodyDto
    export type ScoutingSquadsControllerUpdateSquadMutationError = AxiosError<unknown>

    export const useScoutingSquadsControllerUpdateSquad = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingSquadsControllerUpdateSquad>>, TError,{tenantId: string;environmentId: string;squadId: string;data: ScoutingSquadsUpdatePutBodyDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof scoutingSquadsControllerUpdateSquad>>,
        TError,
        {tenantId: string;environmentId: string;squadId: string;data: ScoutingSquadsUpdatePutBodyDto},
        TContext
      > => {

      const mutationOptions = getScoutingSquadsControllerUpdateSquadMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const scoutingSquadsControllerDeleteSquad = (
    tenantId: string,
    environmentId: string,
    squadId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/v1/${tenantId}/${environmentId}/scoutingsquads/${squadId}`,options
    );
  }



export const getScoutingSquadsControllerDeleteSquadMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingSquadsControllerDeleteSquad>>, TError,{tenantId: string;environmentId: string;squadId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof scoutingSquadsControllerDeleteSquad>>, TError,{tenantId: string;environmentId: string;squadId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof scoutingSquadsControllerDeleteSquad>>, {tenantId: string;environmentId: string;squadId: string}> = (props) => {
          const {tenantId,environmentId,squadId} = props ?? {};

          return  scoutingSquadsControllerDeleteSquad(tenantId,environmentId,squadId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ScoutingSquadsControllerDeleteSquadMutationResult = NonNullable<Awaited<ReturnType<typeof scoutingSquadsControllerDeleteSquad>>>
    
    export type ScoutingSquadsControllerDeleteSquadMutationError = AxiosError<unknown>

    export const useScoutingSquadsControllerDeleteSquad = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingSquadsControllerDeleteSquad>>, TError,{tenantId: string;environmentId: string;squadId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof scoutingSquadsControllerDeleteSquad>>,
        TError,
        {tenantId: string;environmentId: string;squadId: string},
        TContext
      > => {

      const mutationOptions = getScoutingSquadsControllerDeleteSquadMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const scoutingSquadsControllerGetSquadWorkflowPlayers = (
    tenantId: string,
    environmentId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ScoutingSquadsPlayersworkflowGetReturnValueDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/scoutingsquads/players/workflow`,options
    );
  }


export const getScoutingSquadsControllerGetSquadWorkflowPlayersQueryKey = (tenantId: string,
    environmentId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/scoutingsquads/players/workflow`] as const;
    }

    
export const getScoutingSquadsControllerGetSquadWorkflowPlayersQueryOptions = <TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadWorkflowPlayers>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadWorkflowPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getScoutingSquadsControllerGetSquadWorkflowPlayersQueryKey(tenantId,environmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadWorkflowPlayers>>> = ({ signal }) => scoutingSquadsControllerGetSquadWorkflowPlayers(tenantId,environmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadWorkflowPlayers>>, TError, TData> & { queryKey: QueryKey }
}

export type ScoutingSquadsControllerGetSquadWorkflowPlayersQueryResult = NonNullable<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadWorkflowPlayers>>>
export type ScoutingSquadsControllerGetSquadWorkflowPlayersQueryError = AxiosError<unknown>


export function useScoutingSquadsControllerGetSquadWorkflowPlayers<TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadWorkflowPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadWorkflowPlayers>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadWorkflowPlayers>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingSquadsControllerGetSquadWorkflowPlayers<TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadWorkflowPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadWorkflowPlayers>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadWorkflowPlayers>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingSquadsControllerGetSquadWorkflowPlayers<TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadWorkflowPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadWorkflowPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useScoutingSquadsControllerGetSquadWorkflowPlayers<TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadWorkflowPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadWorkflowPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getScoutingSquadsControllerGetSquadWorkflowPlayersQueryOptions(tenantId,environmentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getScoutingSquadsControllerGetSquadWorkflowPlayersSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadWorkflowPlayers>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadWorkflowPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getScoutingSquadsControllerGetSquadWorkflowPlayersQueryKey(tenantId,environmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadWorkflowPlayers>>> = ({ signal }) => scoutingSquadsControllerGetSquadWorkflowPlayers(tenantId,environmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadWorkflowPlayers>>, TError, TData> & { queryKey: QueryKey }
}

export type ScoutingSquadsControllerGetSquadWorkflowPlayersSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadWorkflowPlayers>>>
export type ScoutingSquadsControllerGetSquadWorkflowPlayersSuspenseQueryError = AxiosError<unknown>


export function useScoutingSquadsControllerGetSquadWorkflowPlayersSuspense<TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadWorkflowPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadWorkflowPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingSquadsControllerGetSquadWorkflowPlayersSuspense<TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadWorkflowPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadWorkflowPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingSquadsControllerGetSquadWorkflowPlayersSuspense<TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadWorkflowPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadWorkflowPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useScoutingSquadsControllerGetSquadWorkflowPlayersSuspense<TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadWorkflowPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadWorkflowPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getScoutingSquadsControllerGetSquadWorkflowPlayersSuspenseQueryOptions(tenantId,environmentId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const scoutingSquadsControllerGetSquadOwnPlayers = (
    tenantId: string,
    environmentId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ScoutingSquadsPlayersownGetReturnValueDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/scoutingsquads/players/own`,options
    );
  }


export const getScoutingSquadsControllerGetSquadOwnPlayersQueryKey = (tenantId: string,
    environmentId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/scoutingsquads/players/own`] as const;
    }

    
export const getScoutingSquadsControllerGetSquadOwnPlayersQueryOptions = <TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadOwnPlayers>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadOwnPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getScoutingSquadsControllerGetSquadOwnPlayersQueryKey(tenantId,environmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadOwnPlayers>>> = ({ signal }) => scoutingSquadsControllerGetSquadOwnPlayers(tenantId,environmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadOwnPlayers>>, TError, TData> & { queryKey: QueryKey }
}

export type ScoutingSquadsControllerGetSquadOwnPlayersQueryResult = NonNullable<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadOwnPlayers>>>
export type ScoutingSquadsControllerGetSquadOwnPlayersQueryError = AxiosError<unknown>


export function useScoutingSquadsControllerGetSquadOwnPlayers<TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadOwnPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadOwnPlayers>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadOwnPlayers>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingSquadsControllerGetSquadOwnPlayers<TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadOwnPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadOwnPlayers>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadOwnPlayers>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingSquadsControllerGetSquadOwnPlayers<TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadOwnPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadOwnPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useScoutingSquadsControllerGetSquadOwnPlayers<TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadOwnPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadOwnPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getScoutingSquadsControllerGetSquadOwnPlayersQueryOptions(tenantId,environmentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getScoutingSquadsControllerGetSquadOwnPlayersSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadOwnPlayers>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadOwnPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getScoutingSquadsControllerGetSquadOwnPlayersQueryKey(tenantId,environmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadOwnPlayers>>> = ({ signal }) => scoutingSquadsControllerGetSquadOwnPlayers(tenantId,environmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadOwnPlayers>>, TError, TData> & { queryKey: QueryKey }
}

export type ScoutingSquadsControllerGetSquadOwnPlayersSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadOwnPlayers>>>
export type ScoutingSquadsControllerGetSquadOwnPlayersSuspenseQueryError = AxiosError<unknown>


export function useScoutingSquadsControllerGetSquadOwnPlayersSuspense<TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadOwnPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadOwnPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingSquadsControllerGetSquadOwnPlayersSuspense<TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadOwnPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadOwnPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingSquadsControllerGetSquadOwnPlayersSuspense<TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadOwnPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadOwnPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useScoutingSquadsControllerGetSquadOwnPlayersSuspense<TData = Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadOwnPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadsControllerGetSquadOwnPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getScoutingSquadsControllerGetSquadOwnPlayersSuspenseQueryOptions(tenantId,environmentId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const wyscoutControllerGetProposedSkillcornerTeamMatching = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WyscoutTeamMatcherGetDto>> => {
    
    return axios.get(
      `/v1/wyscout/matching/skillcorner/team`,options
    );
  }


export const getWyscoutControllerGetProposedSkillcornerTeamMatchingQueryKey = () => {
    return [`/v1/wyscout/matching/skillcorner/team`] as const;
    }

    
export const getWyscoutControllerGetProposedSkillcornerTeamMatchingQueryOptions = <TData = Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerTeamMatching>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerTeamMatching>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWyscoutControllerGetProposedSkillcornerTeamMatchingQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerTeamMatching>>> = ({ signal }) => wyscoutControllerGetProposedSkillcornerTeamMatching({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerTeamMatching>>, TError, TData> & { queryKey: QueryKey }
}

export type WyscoutControllerGetProposedSkillcornerTeamMatchingQueryResult = NonNullable<Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerTeamMatching>>>
export type WyscoutControllerGetProposedSkillcornerTeamMatchingQueryError = AxiosError<unknown>


export function useWyscoutControllerGetProposedSkillcornerTeamMatching<TData = Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerTeamMatching>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerTeamMatching>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerTeamMatching>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerGetProposedSkillcornerTeamMatching<TData = Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerTeamMatching>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerTeamMatching>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerTeamMatching>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerGetProposedSkillcornerTeamMatching<TData = Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerTeamMatching>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerTeamMatching>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useWyscoutControllerGetProposedSkillcornerTeamMatching<TData = Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerTeamMatching>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerTeamMatching>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getWyscoutControllerGetProposedSkillcornerTeamMatchingQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getWyscoutControllerGetProposedSkillcornerTeamMatchingSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerTeamMatching>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerTeamMatching>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWyscoutControllerGetProposedSkillcornerTeamMatchingQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerTeamMatching>>> = ({ signal }) => wyscoutControllerGetProposedSkillcornerTeamMatching({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerTeamMatching>>, TError, TData> & { queryKey: QueryKey }
}

export type WyscoutControllerGetProposedSkillcornerTeamMatchingSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerTeamMatching>>>
export type WyscoutControllerGetProposedSkillcornerTeamMatchingSuspenseQueryError = AxiosError<unknown>


export function useWyscoutControllerGetProposedSkillcornerTeamMatchingSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerTeamMatching>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerTeamMatching>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerGetProposedSkillcornerTeamMatchingSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerTeamMatching>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerTeamMatching>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerGetProposedSkillcornerTeamMatchingSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerTeamMatching>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerTeamMatching>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useWyscoutControllerGetProposedSkillcornerTeamMatchingSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerTeamMatching>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerTeamMatching>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getWyscoutControllerGetProposedSkillcornerTeamMatchingSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const wyscoutControllerApproveProposedSkillcornerTeamMatching = (
    wyscoutTeamMatcherApprovePostDto: WyscoutTeamMatcherApprovePostDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/v1/wyscout/matching/skillcorner/team/approve`,
      wyscoutTeamMatcherApprovePostDto,options
    );
  }



export const getWyscoutControllerApproveProposedSkillcornerTeamMatchingMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof wyscoutControllerApproveProposedSkillcornerTeamMatching>>, TError,{data: WyscoutTeamMatcherApprovePostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof wyscoutControllerApproveProposedSkillcornerTeamMatching>>, TError,{data: WyscoutTeamMatcherApprovePostDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof wyscoutControllerApproveProposedSkillcornerTeamMatching>>, {data: WyscoutTeamMatcherApprovePostDto}> = (props) => {
          const {data} = props ?? {};

          return  wyscoutControllerApproveProposedSkillcornerTeamMatching(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type WyscoutControllerApproveProposedSkillcornerTeamMatchingMutationResult = NonNullable<Awaited<ReturnType<typeof wyscoutControllerApproveProposedSkillcornerTeamMatching>>>
    export type WyscoutControllerApproveProposedSkillcornerTeamMatchingMutationBody = WyscoutTeamMatcherApprovePostDto
    export type WyscoutControllerApproveProposedSkillcornerTeamMatchingMutationError = AxiosError<unknown>

    export const useWyscoutControllerApproveProposedSkillcornerTeamMatching = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof wyscoutControllerApproveProposedSkillcornerTeamMatching>>, TError,{data: WyscoutTeamMatcherApprovePostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof wyscoutControllerApproveProposedSkillcornerTeamMatching>>,
        TError,
        {data: WyscoutTeamMatcherApprovePostDto},
        TContext
      > => {

      const mutationOptions = getWyscoutControllerApproveProposedSkillcornerTeamMatchingMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const wyscoutControllerDeclineProposedSkillcornerTeamMatching = (
    wyscoutTeamMatcherApprovePostDto: WyscoutTeamMatcherApprovePostDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/v1/wyscout/matching/skillcorner/team/decline`,
      wyscoutTeamMatcherApprovePostDto,options
    );
  }



export const getWyscoutControllerDeclineProposedSkillcornerTeamMatchingMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof wyscoutControllerDeclineProposedSkillcornerTeamMatching>>, TError,{data: WyscoutTeamMatcherApprovePostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof wyscoutControllerDeclineProposedSkillcornerTeamMatching>>, TError,{data: WyscoutTeamMatcherApprovePostDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof wyscoutControllerDeclineProposedSkillcornerTeamMatching>>, {data: WyscoutTeamMatcherApprovePostDto}> = (props) => {
          const {data} = props ?? {};

          return  wyscoutControllerDeclineProposedSkillcornerTeamMatching(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type WyscoutControllerDeclineProposedSkillcornerTeamMatchingMutationResult = NonNullable<Awaited<ReturnType<typeof wyscoutControllerDeclineProposedSkillcornerTeamMatching>>>
    export type WyscoutControllerDeclineProposedSkillcornerTeamMatchingMutationBody = WyscoutTeamMatcherApprovePostDto
    export type WyscoutControllerDeclineProposedSkillcornerTeamMatchingMutationError = AxiosError<unknown>

    export const useWyscoutControllerDeclineProposedSkillcornerTeamMatching = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof wyscoutControllerDeclineProposedSkillcornerTeamMatching>>, TError,{data: WyscoutTeamMatcherApprovePostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof wyscoutControllerDeclineProposedSkillcornerTeamMatching>>,
        TError,
        {data: WyscoutTeamMatcherApprovePostDto},
        TContext
      > => {

      const mutationOptions = getWyscoutControllerDeclineProposedSkillcornerTeamMatchingMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const wyscoutControllerGetProposedSkillcornerPlayerMatching = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WyscoutPlayerMatcherGetDto>> => {
    
    return axios.get(
      `/v1/wyscout/matching/skillcorner/player`,options
    );
  }


export const getWyscoutControllerGetProposedSkillcornerPlayerMatchingQueryKey = () => {
    return [`/v1/wyscout/matching/skillcorner/player`] as const;
    }

    
export const getWyscoutControllerGetProposedSkillcornerPlayerMatchingQueryOptions = <TData = Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerPlayerMatching>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerPlayerMatching>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWyscoutControllerGetProposedSkillcornerPlayerMatchingQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerPlayerMatching>>> = ({ signal }) => wyscoutControllerGetProposedSkillcornerPlayerMatching({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerPlayerMatching>>, TError, TData> & { queryKey: QueryKey }
}

export type WyscoutControllerGetProposedSkillcornerPlayerMatchingQueryResult = NonNullable<Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerPlayerMatching>>>
export type WyscoutControllerGetProposedSkillcornerPlayerMatchingQueryError = AxiosError<unknown>


export function useWyscoutControllerGetProposedSkillcornerPlayerMatching<TData = Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerPlayerMatching>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerPlayerMatching>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerPlayerMatching>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerGetProposedSkillcornerPlayerMatching<TData = Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerPlayerMatching>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerPlayerMatching>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerPlayerMatching>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerGetProposedSkillcornerPlayerMatching<TData = Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerPlayerMatching>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerPlayerMatching>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useWyscoutControllerGetProposedSkillcornerPlayerMatching<TData = Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerPlayerMatching>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerPlayerMatching>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getWyscoutControllerGetProposedSkillcornerPlayerMatchingQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getWyscoutControllerGetProposedSkillcornerPlayerMatchingSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerPlayerMatching>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerPlayerMatching>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWyscoutControllerGetProposedSkillcornerPlayerMatchingQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerPlayerMatching>>> = ({ signal }) => wyscoutControllerGetProposedSkillcornerPlayerMatching({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerPlayerMatching>>, TError, TData> & { queryKey: QueryKey }
}

export type WyscoutControllerGetProposedSkillcornerPlayerMatchingSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerPlayerMatching>>>
export type WyscoutControllerGetProposedSkillcornerPlayerMatchingSuspenseQueryError = AxiosError<unknown>


export function useWyscoutControllerGetProposedSkillcornerPlayerMatchingSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerPlayerMatching>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerPlayerMatching>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerGetProposedSkillcornerPlayerMatchingSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerPlayerMatching>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerPlayerMatching>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerGetProposedSkillcornerPlayerMatchingSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerPlayerMatching>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerPlayerMatching>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useWyscoutControllerGetProposedSkillcornerPlayerMatchingSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerPlayerMatching>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetProposedSkillcornerPlayerMatching>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getWyscoutControllerGetProposedSkillcornerPlayerMatchingSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const wyscoutControllerApproveProposedSkillcornerPlayerMatching = (
    wyscoutPlayerMatcherApprovePostDto: WyscoutPlayerMatcherApprovePostDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/v1/wyscout/matching/skillcorner/player/approve`,
      wyscoutPlayerMatcherApprovePostDto,options
    );
  }



export const getWyscoutControllerApproveProposedSkillcornerPlayerMatchingMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof wyscoutControllerApproveProposedSkillcornerPlayerMatching>>, TError,{data: WyscoutPlayerMatcherApprovePostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof wyscoutControllerApproveProposedSkillcornerPlayerMatching>>, TError,{data: WyscoutPlayerMatcherApprovePostDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof wyscoutControllerApproveProposedSkillcornerPlayerMatching>>, {data: WyscoutPlayerMatcherApprovePostDto}> = (props) => {
          const {data} = props ?? {};

          return  wyscoutControllerApproveProposedSkillcornerPlayerMatching(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type WyscoutControllerApproveProposedSkillcornerPlayerMatchingMutationResult = NonNullable<Awaited<ReturnType<typeof wyscoutControllerApproveProposedSkillcornerPlayerMatching>>>
    export type WyscoutControllerApproveProposedSkillcornerPlayerMatchingMutationBody = WyscoutPlayerMatcherApprovePostDto
    export type WyscoutControllerApproveProposedSkillcornerPlayerMatchingMutationError = AxiosError<unknown>

    export const useWyscoutControllerApproveProposedSkillcornerPlayerMatching = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof wyscoutControllerApproveProposedSkillcornerPlayerMatching>>, TError,{data: WyscoutPlayerMatcherApprovePostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof wyscoutControllerApproveProposedSkillcornerPlayerMatching>>,
        TError,
        {data: WyscoutPlayerMatcherApprovePostDto},
        TContext
      > => {

      const mutationOptions = getWyscoutControllerApproveProposedSkillcornerPlayerMatchingMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const wyscoutControllerDeclineProposedSkillcornerPlayerMatching = (
    wyscoutPlayerMatcherApprovePostDto: WyscoutPlayerMatcherApprovePostDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/v1/wyscout/matching/skillcorner/player/decline`,
      wyscoutPlayerMatcherApprovePostDto,options
    );
  }



export const getWyscoutControllerDeclineProposedSkillcornerPlayerMatchingMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof wyscoutControllerDeclineProposedSkillcornerPlayerMatching>>, TError,{data: WyscoutPlayerMatcherApprovePostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof wyscoutControllerDeclineProposedSkillcornerPlayerMatching>>, TError,{data: WyscoutPlayerMatcherApprovePostDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof wyscoutControllerDeclineProposedSkillcornerPlayerMatching>>, {data: WyscoutPlayerMatcherApprovePostDto}> = (props) => {
          const {data} = props ?? {};

          return  wyscoutControllerDeclineProposedSkillcornerPlayerMatching(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type WyscoutControllerDeclineProposedSkillcornerPlayerMatchingMutationResult = NonNullable<Awaited<ReturnType<typeof wyscoutControllerDeclineProposedSkillcornerPlayerMatching>>>
    export type WyscoutControllerDeclineProposedSkillcornerPlayerMatchingMutationBody = WyscoutPlayerMatcherApprovePostDto
    export type WyscoutControllerDeclineProposedSkillcornerPlayerMatchingMutationError = AxiosError<unknown>

    export const useWyscoutControllerDeclineProposedSkillcornerPlayerMatching = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof wyscoutControllerDeclineProposedSkillcornerPlayerMatching>>, TError,{data: WyscoutPlayerMatcherApprovePostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof wyscoutControllerDeclineProposedSkillcornerPlayerMatching>>,
        TError,
        {data: WyscoutPlayerMatcherApprovePostDto},
        TContext
      > => {

      const mutationOptions = getWyscoutControllerDeclineProposedSkillcornerPlayerMatchingMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const wyscoutControllerGetAreas = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WyscoutAreasGetDto[]>> => {
    
    return axios.get(
      `/v1/wyscout/areas`,options
    );
  }


export const getWyscoutControllerGetAreasQueryKey = () => {
    return [`/v1/wyscout/areas`] as const;
    }

    
export const getWyscoutControllerGetAreasQueryOptions = <TData = Awaited<ReturnType<typeof wyscoutControllerGetAreas>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetAreas>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWyscoutControllerGetAreasQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof wyscoutControllerGetAreas>>> = ({ signal }) => wyscoutControllerGetAreas({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetAreas>>, TError, TData> & { queryKey: QueryKey }
}

export type WyscoutControllerGetAreasQueryResult = NonNullable<Awaited<ReturnType<typeof wyscoutControllerGetAreas>>>
export type WyscoutControllerGetAreasQueryError = AxiosError<unknown>


export function useWyscoutControllerGetAreas<TData = Awaited<ReturnType<typeof wyscoutControllerGetAreas>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetAreas>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof wyscoutControllerGetAreas>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerGetAreas<TData = Awaited<ReturnType<typeof wyscoutControllerGetAreas>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetAreas>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof wyscoutControllerGetAreas>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerGetAreas<TData = Awaited<ReturnType<typeof wyscoutControllerGetAreas>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetAreas>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useWyscoutControllerGetAreas<TData = Awaited<ReturnType<typeof wyscoutControllerGetAreas>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetAreas>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getWyscoutControllerGetAreasQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getWyscoutControllerGetAreasSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof wyscoutControllerGetAreas>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetAreas>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWyscoutControllerGetAreasQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof wyscoutControllerGetAreas>>> = ({ signal }) => wyscoutControllerGetAreas({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetAreas>>, TError, TData> & { queryKey: QueryKey }
}

export type WyscoutControllerGetAreasSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof wyscoutControllerGetAreas>>>
export type WyscoutControllerGetAreasSuspenseQueryError = AxiosError<unknown>


export function useWyscoutControllerGetAreasSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerGetAreas>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetAreas>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerGetAreasSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerGetAreas>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetAreas>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerGetAreasSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerGetAreas>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetAreas>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useWyscoutControllerGetAreasSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerGetAreas>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetAreas>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getWyscoutControllerGetAreasSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const wyscoutControllerGetPlayerSeasons = (
    strykerlabsPlayerId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WyscoutPlayerSeasonsGetDto[]>> => {
    
    return axios.get(
      `/v1/wyscout/player/${strykerlabsPlayerId}/seasons`,options
    );
  }


export const getWyscoutControllerGetPlayerSeasonsQueryKey = (strykerlabsPlayerId: string,) => {
    return [`/v1/wyscout/player/${strykerlabsPlayerId}/seasons`] as const;
    }

    
export const getWyscoutControllerGetPlayerSeasonsQueryOptions = <TData = Awaited<ReturnType<typeof wyscoutControllerGetPlayerSeasons>>, TError = AxiosError<unknown>>(strykerlabsPlayerId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetPlayerSeasons>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWyscoutControllerGetPlayerSeasonsQueryKey(strykerlabsPlayerId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof wyscoutControllerGetPlayerSeasons>>> = ({ signal }) => wyscoutControllerGetPlayerSeasons(strykerlabsPlayerId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(strykerlabsPlayerId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetPlayerSeasons>>, TError, TData> & { queryKey: QueryKey }
}

export type WyscoutControllerGetPlayerSeasonsQueryResult = NonNullable<Awaited<ReturnType<typeof wyscoutControllerGetPlayerSeasons>>>
export type WyscoutControllerGetPlayerSeasonsQueryError = AxiosError<unknown>


export function useWyscoutControllerGetPlayerSeasons<TData = Awaited<ReturnType<typeof wyscoutControllerGetPlayerSeasons>>, TError = AxiosError<unknown>>(
 strykerlabsPlayerId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetPlayerSeasons>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof wyscoutControllerGetPlayerSeasons>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerGetPlayerSeasons<TData = Awaited<ReturnType<typeof wyscoutControllerGetPlayerSeasons>>, TError = AxiosError<unknown>>(
 strykerlabsPlayerId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetPlayerSeasons>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof wyscoutControllerGetPlayerSeasons>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerGetPlayerSeasons<TData = Awaited<ReturnType<typeof wyscoutControllerGetPlayerSeasons>>, TError = AxiosError<unknown>>(
 strykerlabsPlayerId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetPlayerSeasons>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useWyscoutControllerGetPlayerSeasons<TData = Awaited<ReturnType<typeof wyscoutControllerGetPlayerSeasons>>, TError = AxiosError<unknown>>(
 strykerlabsPlayerId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetPlayerSeasons>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getWyscoutControllerGetPlayerSeasonsQueryOptions(strykerlabsPlayerId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getWyscoutControllerGetPlayerSeasonsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof wyscoutControllerGetPlayerSeasons>>, TError = AxiosError<unknown>>(strykerlabsPlayerId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetPlayerSeasons>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWyscoutControllerGetPlayerSeasonsQueryKey(strykerlabsPlayerId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof wyscoutControllerGetPlayerSeasons>>> = ({ signal }) => wyscoutControllerGetPlayerSeasons(strykerlabsPlayerId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(strykerlabsPlayerId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetPlayerSeasons>>, TError, TData> & { queryKey: QueryKey }
}

export type WyscoutControllerGetPlayerSeasonsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof wyscoutControllerGetPlayerSeasons>>>
export type WyscoutControllerGetPlayerSeasonsSuspenseQueryError = AxiosError<unknown>


export function useWyscoutControllerGetPlayerSeasonsSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerGetPlayerSeasons>>, TError = AxiosError<unknown>>(
 strykerlabsPlayerId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetPlayerSeasons>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerGetPlayerSeasonsSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerGetPlayerSeasons>>, TError = AxiosError<unknown>>(
 strykerlabsPlayerId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetPlayerSeasons>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerGetPlayerSeasonsSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerGetPlayerSeasons>>, TError = AxiosError<unknown>>(
 strykerlabsPlayerId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetPlayerSeasons>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useWyscoutControllerGetPlayerSeasonsSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerGetPlayerSeasons>>, TError = AxiosError<unknown>>(
 strykerlabsPlayerId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetPlayerSeasons>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getWyscoutControllerGetPlayerSeasonsSuspenseQueryOptions(strykerlabsPlayerId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const wyscoutControllerSearchPlayers = (
    params?: WyscoutControllerSearchPlayersParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PlayerProfileDto[]>> => {
    
    return axios.get(
      `/v1/wyscout/search/players`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getWyscoutControllerSearchPlayersQueryKey = (params?: WyscoutControllerSearchPlayersParams,) => {
    return [`/v1/wyscout/search/players`, ...(params ? [params]: [])] as const;
    }

    
export const getWyscoutControllerSearchPlayersQueryOptions = <TData = Awaited<ReturnType<typeof wyscoutControllerSearchPlayers>>, TError = AxiosError<unknown>>(params?: WyscoutControllerSearchPlayersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWyscoutControllerSearchPlayersQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof wyscoutControllerSearchPlayers>>> = ({ signal }) => wyscoutControllerSearchPlayers(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchPlayers>>, TError, TData> & { queryKey: QueryKey }
}

export type WyscoutControllerSearchPlayersQueryResult = NonNullable<Awaited<ReturnType<typeof wyscoutControllerSearchPlayers>>>
export type WyscoutControllerSearchPlayersQueryError = AxiosError<unknown>


export function useWyscoutControllerSearchPlayers<TData = Awaited<ReturnType<typeof wyscoutControllerSearchPlayers>>, TError = AxiosError<unknown>>(
 params: undefined |  WyscoutControllerSearchPlayersParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchPlayers>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof wyscoutControllerSearchPlayers>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerSearchPlayers<TData = Awaited<ReturnType<typeof wyscoutControllerSearchPlayers>>, TError = AxiosError<unknown>>(
 params?: WyscoutControllerSearchPlayersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchPlayers>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof wyscoutControllerSearchPlayers>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerSearchPlayers<TData = Awaited<ReturnType<typeof wyscoutControllerSearchPlayers>>, TError = AxiosError<unknown>>(
 params?: WyscoutControllerSearchPlayersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useWyscoutControllerSearchPlayers<TData = Awaited<ReturnType<typeof wyscoutControllerSearchPlayers>>, TError = AxiosError<unknown>>(
 params?: WyscoutControllerSearchPlayersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getWyscoutControllerSearchPlayersQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getWyscoutControllerSearchPlayersSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof wyscoutControllerSearchPlayers>>, TError = AxiosError<unknown>>(params?: WyscoutControllerSearchPlayersParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWyscoutControllerSearchPlayersQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof wyscoutControllerSearchPlayers>>> = ({ signal }) => wyscoutControllerSearchPlayers(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchPlayers>>, TError, TData> & { queryKey: QueryKey }
}

export type WyscoutControllerSearchPlayersSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof wyscoutControllerSearchPlayers>>>
export type WyscoutControllerSearchPlayersSuspenseQueryError = AxiosError<unknown>


export function useWyscoutControllerSearchPlayersSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerSearchPlayers>>, TError = AxiosError<unknown>>(
 params: undefined |  WyscoutControllerSearchPlayersParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerSearchPlayersSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerSearchPlayers>>, TError = AxiosError<unknown>>(
 params?: WyscoutControllerSearchPlayersParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerSearchPlayersSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerSearchPlayers>>, TError = AxiosError<unknown>>(
 params?: WyscoutControllerSearchPlayersParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useWyscoutControllerSearchPlayersSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerSearchPlayers>>, TError = AxiosError<unknown>>(
 params?: WyscoutControllerSearchPlayersParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getWyscoutControllerSearchPlayersSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const wyscoutControllerSearchCompetitions = (
    params?: WyscoutControllerSearchCompetitionsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WyscoutControllerSearchCompetitions200>> => {
    
    return axios.get(
      `/v1/wyscout/search/competitions`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getWyscoutControllerSearchCompetitionsQueryKey = (params?: WyscoutControllerSearchCompetitionsParams,) => {
    return [`/v1/wyscout/search/competitions`, ...(params ? [params]: [])] as const;
    }

    
export const getWyscoutControllerSearchCompetitionsQueryOptions = <TData = Awaited<ReturnType<typeof wyscoutControllerSearchCompetitions>>, TError = AxiosError<unknown>>(params?: WyscoutControllerSearchCompetitionsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchCompetitions>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWyscoutControllerSearchCompetitionsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof wyscoutControllerSearchCompetitions>>> = ({ signal }) => wyscoutControllerSearchCompetitions(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchCompetitions>>, TError, TData> & { queryKey: QueryKey }
}

export type WyscoutControllerSearchCompetitionsQueryResult = NonNullable<Awaited<ReturnType<typeof wyscoutControllerSearchCompetitions>>>
export type WyscoutControllerSearchCompetitionsQueryError = AxiosError<unknown>


export function useWyscoutControllerSearchCompetitions<TData = Awaited<ReturnType<typeof wyscoutControllerSearchCompetitions>>, TError = AxiosError<unknown>>(
 params: undefined |  WyscoutControllerSearchCompetitionsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchCompetitions>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof wyscoutControllerSearchCompetitions>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerSearchCompetitions<TData = Awaited<ReturnType<typeof wyscoutControllerSearchCompetitions>>, TError = AxiosError<unknown>>(
 params?: WyscoutControllerSearchCompetitionsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchCompetitions>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof wyscoutControllerSearchCompetitions>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerSearchCompetitions<TData = Awaited<ReturnType<typeof wyscoutControllerSearchCompetitions>>, TError = AxiosError<unknown>>(
 params?: WyscoutControllerSearchCompetitionsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchCompetitions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useWyscoutControllerSearchCompetitions<TData = Awaited<ReturnType<typeof wyscoutControllerSearchCompetitions>>, TError = AxiosError<unknown>>(
 params?: WyscoutControllerSearchCompetitionsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchCompetitions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getWyscoutControllerSearchCompetitionsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getWyscoutControllerSearchCompetitionsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof wyscoutControllerSearchCompetitions>>, TError = AxiosError<unknown>>(params?: WyscoutControllerSearchCompetitionsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchCompetitions>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWyscoutControllerSearchCompetitionsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof wyscoutControllerSearchCompetitions>>> = ({ signal }) => wyscoutControllerSearchCompetitions(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchCompetitions>>, TError, TData> & { queryKey: QueryKey }
}

export type WyscoutControllerSearchCompetitionsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof wyscoutControllerSearchCompetitions>>>
export type WyscoutControllerSearchCompetitionsSuspenseQueryError = AxiosError<unknown>


export function useWyscoutControllerSearchCompetitionsSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerSearchCompetitions>>, TError = AxiosError<unknown>>(
 params: undefined |  WyscoutControllerSearchCompetitionsParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchCompetitions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerSearchCompetitionsSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerSearchCompetitions>>, TError = AxiosError<unknown>>(
 params?: WyscoutControllerSearchCompetitionsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchCompetitions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerSearchCompetitionsSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerSearchCompetitions>>, TError = AxiosError<unknown>>(
 params?: WyscoutControllerSearchCompetitionsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchCompetitions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useWyscoutControllerSearchCompetitionsSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerSearchCompetitions>>, TError = AxiosError<unknown>>(
 params?: WyscoutControllerSearchCompetitionsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchCompetitions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getWyscoutControllerSearchCompetitionsSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const wyscoutControllerSearchTeams = (
    params?: WyscoutControllerSearchTeamsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WyscoutControllerSearchTeams200>> => {
    
    return axios.get(
      `/v1/wyscout/search/teams`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getWyscoutControllerSearchTeamsQueryKey = (params?: WyscoutControllerSearchTeamsParams,) => {
    return [`/v1/wyscout/search/teams`, ...(params ? [params]: [])] as const;
    }

    
export const getWyscoutControllerSearchTeamsQueryOptions = <TData = Awaited<ReturnType<typeof wyscoutControllerSearchTeams>>, TError = AxiosError<unknown>>(params?: WyscoutControllerSearchTeamsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchTeams>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWyscoutControllerSearchTeamsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof wyscoutControllerSearchTeams>>> = ({ signal }) => wyscoutControllerSearchTeams(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchTeams>>, TError, TData> & { queryKey: QueryKey }
}

export type WyscoutControllerSearchTeamsQueryResult = NonNullable<Awaited<ReturnType<typeof wyscoutControllerSearchTeams>>>
export type WyscoutControllerSearchTeamsQueryError = AxiosError<unknown>


export function useWyscoutControllerSearchTeams<TData = Awaited<ReturnType<typeof wyscoutControllerSearchTeams>>, TError = AxiosError<unknown>>(
 params: undefined |  WyscoutControllerSearchTeamsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchTeams>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof wyscoutControllerSearchTeams>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerSearchTeams<TData = Awaited<ReturnType<typeof wyscoutControllerSearchTeams>>, TError = AxiosError<unknown>>(
 params?: WyscoutControllerSearchTeamsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchTeams>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof wyscoutControllerSearchTeams>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerSearchTeams<TData = Awaited<ReturnType<typeof wyscoutControllerSearchTeams>>, TError = AxiosError<unknown>>(
 params?: WyscoutControllerSearchTeamsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchTeams>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useWyscoutControllerSearchTeams<TData = Awaited<ReturnType<typeof wyscoutControllerSearchTeams>>, TError = AxiosError<unknown>>(
 params?: WyscoutControllerSearchTeamsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchTeams>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getWyscoutControllerSearchTeamsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getWyscoutControllerSearchTeamsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof wyscoutControllerSearchTeams>>, TError = AxiosError<unknown>>(params?: WyscoutControllerSearchTeamsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchTeams>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWyscoutControllerSearchTeamsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof wyscoutControllerSearchTeams>>> = ({ signal }) => wyscoutControllerSearchTeams(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchTeams>>, TError, TData> & { queryKey: QueryKey }
}

export type WyscoutControllerSearchTeamsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof wyscoutControllerSearchTeams>>>
export type WyscoutControllerSearchTeamsSuspenseQueryError = AxiosError<unknown>


export function useWyscoutControllerSearchTeamsSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerSearchTeams>>, TError = AxiosError<unknown>>(
 params: undefined |  WyscoutControllerSearchTeamsParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchTeams>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerSearchTeamsSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerSearchTeams>>, TError = AxiosError<unknown>>(
 params?: WyscoutControllerSearchTeamsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchTeams>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerSearchTeamsSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerSearchTeams>>, TError = AxiosError<unknown>>(
 params?: WyscoutControllerSearchTeamsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchTeams>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useWyscoutControllerSearchTeamsSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerSearchTeams>>, TError = AxiosError<unknown>>(
 params?: WyscoutControllerSearchTeamsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchTeams>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getWyscoutControllerSearchTeamsSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const wyscoutControllerGetNextGamesForPlayer = (
    playerId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WyscoutPlayerNextGamesGetDto[]>> => {
    
    return axios.get(
      `/v1/wyscout/player/${playerId}/nextGames`,options
    );
  }


export const getWyscoutControllerGetNextGamesForPlayerQueryKey = (playerId: string,) => {
    return [`/v1/wyscout/player/${playerId}/nextGames`] as const;
    }

    
export const getWyscoutControllerGetNextGamesForPlayerQueryOptions = <TData = Awaited<ReturnType<typeof wyscoutControllerGetNextGamesForPlayer>>, TError = AxiosError<unknown>>(playerId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetNextGamesForPlayer>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWyscoutControllerGetNextGamesForPlayerQueryKey(playerId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof wyscoutControllerGetNextGamesForPlayer>>> = ({ signal }) => wyscoutControllerGetNextGamesForPlayer(playerId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(playerId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetNextGamesForPlayer>>, TError, TData> & { queryKey: QueryKey }
}

export type WyscoutControllerGetNextGamesForPlayerQueryResult = NonNullable<Awaited<ReturnType<typeof wyscoutControllerGetNextGamesForPlayer>>>
export type WyscoutControllerGetNextGamesForPlayerQueryError = AxiosError<unknown>


export function useWyscoutControllerGetNextGamesForPlayer<TData = Awaited<ReturnType<typeof wyscoutControllerGetNextGamesForPlayer>>, TError = AxiosError<unknown>>(
 playerId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetNextGamesForPlayer>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof wyscoutControllerGetNextGamesForPlayer>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerGetNextGamesForPlayer<TData = Awaited<ReturnType<typeof wyscoutControllerGetNextGamesForPlayer>>, TError = AxiosError<unknown>>(
 playerId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetNextGamesForPlayer>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof wyscoutControllerGetNextGamesForPlayer>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerGetNextGamesForPlayer<TData = Awaited<ReturnType<typeof wyscoutControllerGetNextGamesForPlayer>>, TError = AxiosError<unknown>>(
 playerId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetNextGamesForPlayer>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useWyscoutControllerGetNextGamesForPlayer<TData = Awaited<ReturnType<typeof wyscoutControllerGetNextGamesForPlayer>>, TError = AxiosError<unknown>>(
 playerId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetNextGamesForPlayer>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getWyscoutControllerGetNextGamesForPlayerQueryOptions(playerId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getWyscoutControllerGetNextGamesForPlayerSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof wyscoutControllerGetNextGamesForPlayer>>, TError = AxiosError<unknown>>(playerId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetNextGamesForPlayer>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWyscoutControllerGetNextGamesForPlayerQueryKey(playerId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof wyscoutControllerGetNextGamesForPlayer>>> = ({ signal }) => wyscoutControllerGetNextGamesForPlayer(playerId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(playerId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetNextGamesForPlayer>>, TError, TData> & { queryKey: QueryKey }
}

export type WyscoutControllerGetNextGamesForPlayerSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof wyscoutControllerGetNextGamesForPlayer>>>
export type WyscoutControllerGetNextGamesForPlayerSuspenseQueryError = AxiosError<unknown>


export function useWyscoutControllerGetNextGamesForPlayerSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerGetNextGamesForPlayer>>, TError = AxiosError<unknown>>(
 playerId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetNextGamesForPlayer>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerGetNextGamesForPlayerSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerGetNextGamesForPlayer>>, TError = AxiosError<unknown>>(
 playerId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetNextGamesForPlayer>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerGetNextGamesForPlayerSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerGetNextGamesForPlayer>>, TError = AxiosError<unknown>>(
 playerId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetNextGamesForPlayer>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useWyscoutControllerGetNextGamesForPlayerSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerGetNextGamesForPlayer>>, TError = AxiosError<unknown>>(
 playerId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetNextGamesForPlayer>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getWyscoutControllerGetNextGamesForPlayerSuspenseQueryOptions(playerId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const wyscoutControllerGetSeasonsForCompetition = (
    wyscoutCompetitionId: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WyscoutCompetitionSeasonsGetReturnValueDto[]>> => {
    
    return axios.get(
      `/v1/wyscout/competition/${wyscoutCompetitionId}/seasons`,options
    );
  }


export const getWyscoutControllerGetSeasonsForCompetitionQueryKey = (wyscoutCompetitionId: number,) => {
    return [`/v1/wyscout/competition/${wyscoutCompetitionId}/seasons`] as const;
    }

    
export const getWyscoutControllerGetSeasonsForCompetitionQueryOptions = <TData = Awaited<ReturnType<typeof wyscoutControllerGetSeasonsForCompetition>>, TError = AxiosError<unknown>>(wyscoutCompetitionId: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetSeasonsForCompetition>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWyscoutControllerGetSeasonsForCompetitionQueryKey(wyscoutCompetitionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof wyscoutControllerGetSeasonsForCompetition>>> = ({ signal }) => wyscoutControllerGetSeasonsForCompetition(wyscoutCompetitionId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(wyscoutCompetitionId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetSeasonsForCompetition>>, TError, TData> & { queryKey: QueryKey }
}

export type WyscoutControllerGetSeasonsForCompetitionQueryResult = NonNullable<Awaited<ReturnType<typeof wyscoutControllerGetSeasonsForCompetition>>>
export type WyscoutControllerGetSeasonsForCompetitionQueryError = AxiosError<unknown>


export function useWyscoutControllerGetSeasonsForCompetition<TData = Awaited<ReturnType<typeof wyscoutControllerGetSeasonsForCompetition>>, TError = AxiosError<unknown>>(
 wyscoutCompetitionId: number, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetSeasonsForCompetition>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof wyscoutControllerGetSeasonsForCompetition>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerGetSeasonsForCompetition<TData = Awaited<ReturnType<typeof wyscoutControllerGetSeasonsForCompetition>>, TError = AxiosError<unknown>>(
 wyscoutCompetitionId: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetSeasonsForCompetition>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof wyscoutControllerGetSeasonsForCompetition>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerGetSeasonsForCompetition<TData = Awaited<ReturnType<typeof wyscoutControllerGetSeasonsForCompetition>>, TError = AxiosError<unknown>>(
 wyscoutCompetitionId: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetSeasonsForCompetition>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useWyscoutControllerGetSeasonsForCompetition<TData = Awaited<ReturnType<typeof wyscoutControllerGetSeasonsForCompetition>>, TError = AxiosError<unknown>>(
 wyscoutCompetitionId: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetSeasonsForCompetition>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getWyscoutControllerGetSeasonsForCompetitionQueryOptions(wyscoutCompetitionId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getWyscoutControllerGetSeasonsForCompetitionSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof wyscoutControllerGetSeasonsForCompetition>>, TError = AxiosError<unknown>>(wyscoutCompetitionId: number, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetSeasonsForCompetition>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWyscoutControllerGetSeasonsForCompetitionQueryKey(wyscoutCompetitionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof wyscoutControllerGetSeasonsForCompetition>>> = ({ signal }) => wyscoutControllerGetSeasonsForCompetition(wyscoutCompetitionId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(wyscoutCompetitionId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetSeasonsForCompetition>>, TError, TData> & { queryKey: QueryKey }
}

export type WyscoutControllerGetSeasonsForCompetitionSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof wyscoutControllerGetSeasonsForCompetition>>>
export type WyscoutControllerGetSeasonsForCompetitionSuspenseQueryError = AxiosError<unknown>


export function useWyscoutControllerGetSeasonsForCompetitionSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerGetSeasonsForCompetition>>, TError = AxiosError<unknown>>(
 wyscoutCompetitionId: number, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetSeasonsForCompetition>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerGetSeasonsForCompetitionSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerGetSeasonsForCompetition>>, TError = AxiosError<unknown>>(
 wyscoutCompetitionId: number, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetSeasonsForCompetition>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerGetSeasonsForCompetitionSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerGetSeasonsForCompetition>>, TError = AxiosError<unknown>>(
 wyscoutCompetitionId: number, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetSeasonsForCompetition>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useWyscoutControllerGetSeasonsForCompetitionSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerGetSeasonsForCompetition>>, TError = AxiosError<unknown>>(
 wyscoutCompetitionId: number, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetSeasonsForCompetition>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getWyscoutControllerGetSeasonsForCompetitionSuspenseQueryOptions(wyscoutCompetitionId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const wyscoutControllerGetCompetitionById = (
    wyscoutCompetitionId: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WyscoutSearchCompetitionsGetDto>> => {
    
    return axios.get(
      `/v1/wyscout/competition/${wyscoutCompetitionId}`,options
    );
  }


export const getWyscoutControllerGetCompetitionByIdQueryKey = (wyscoutCompetitionId: number,) => {
    return [`/v1/wyscout/competition/${wyscoutCompetitionId}`] as const;
    }

    
export const getWyscoutControllerGetCompetitionByIdQueryOptions = <TData = Awaited<ReturnType<typeof wyscoutControllerGetCompetitionById>>, TError = AxiosError<unknown>>(wyscoutCompetitionId: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetCompetitionById>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWyscoutControllerGetCompetitionByIdQueryKey(wyscoutCompetitionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof wyscoutControllerGetCompetitionById>>> = ({ signal }) => wyscoutControllerGetCompetitionById(wyscoutCompetitionId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(wyscoutCompetitionId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetCompetitionById>>, TError, TData> & { queryKey: QueryKey }
}

export type WyscoutControllerGetCompetitionByIdQueryResult = NonNullable<Awaited<ReturnType<typeof wyscoutControllerGetCompetitionById>>>
export type WyscoutControllerGetCompetitionByIdQueryError = AxiosError<unknown>


export function useWyscoutControllerGetCompetitionById<TData = Awaited<ReturnType<typeof wyscoutControllerGetCompetitionById>>, TError = AxiosError<unknown>>(
 wyscoutCompetitionId: number, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetCompetitionById>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof wyscoutControllerGetCompetitionById>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerGetCompetitionById<TData = Awaited<ReturnType<typeof wyscoutControllerGetCompetitionById>>, TError = AxiosError<unknown>>(
 wyscoutCompetitionId: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetCompetitionById>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof wyscoutControllerGetCompetitionById>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerGetCompetitionById<TData = Awaited<ReturnType<typeof wyscoutControllerGetCompetitionById>>, TError = AxiosError<unknown>>(
 wyscoutCompetitionId: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetCompetitionById>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useWyscoutControllerGetCompetitionById<TData = Awaited<ReturnType<typeof wyscoutControllerGetCompetitionById>>, TError = AxiosError<unknown>>(
 wyscoutCompetitionId: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetCompetitionById>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getWyscoutControllerGetCompetitionByIdQueryOptions(wyscoutCompetitionId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getWyscoutControllerGetCompetitionByIdSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof wyscoutControllerGetCompetitionById>>, TError = AxiosError<unknown>>(wyscoutCompetitionId: number, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetCompetitionById>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWyscoutControllerGetCompetitionByIdQueryKey(wyscoutCompetitionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof wyscoutControllerGetCompetitionById>>> = ({ signal }) => wyscoutControllerGetCompetitionById(wyscoutCompetitionId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(wyscoutCompetitionId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetCompetitionById>>, TError, TData> & { queryKey: QueryKey }
}

export type WyscoutControllerGetCompetitionByIdSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof wyscoutControllerGetCompetitionById>>>
export type WyscoutControllerGetCompetitionByIdSuspenseQueryError = AxiosError<unknown>


export function useWyscoutControllerGetCompetitionByIdSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerGetCompetitionById>>, TError = AxiosError<unknown>>(
 wyscoutCompetitionId: number, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetCompetitionById>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerGetCompetitionByIdSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerGetCompetitionById>>, TError = AxiosError<unknown>>(
 wyscoutCompetitionId: number, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetCompetitionById>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerGetCompetitionByIdSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerGetCompetitionById>>, TError = AxiosError<unknown>>(
 wyscoutCompetitionId: number, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetCompetitionById>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useWyscoutControllerGetCompetitionByIdSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerGetCompetitionById>>, TError = AxiosError<unknown>>(
 wyscoutCompetitionId: number, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetCompetitionById>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getWyscoutControllerGetCompetitionByIdSuspenseQueryOptions(wyscoutCompetitionId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const wyscoutControllerSearchMatches = (
    params?: WyscoutControllerSearchMatchesParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WyscoutMatchDto[]>> => {
    
    return axios.get(
      `/v1/wyscout/match`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getWyscoutControllerSearchMatchesQueryKey = (params?: WyscoutControllerSearchMatchesParams,) => {
    return [`/v1/wyscout/match`, ...(params ? [params]: [])] as const;
    }

    
export const getWyscoutControllerSearchMatchesQueryOptions = <TData = Awaited<ReturnType<typeof wyscoutControllerSearchMatches>>, TError = AxiosError<unknown>>(params?: WyscoutControllerSearchMatchesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchMatches>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWyscoutControllerSearchMatchesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof wyscoutControllerSearchMatches>>> = ({ signal }) => wyscoutControllerSearchMatches(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchMatches>>, TError, TData> & { queryKey: QueryKey }
}

export type WyscoutControllerSearchMatchesQueryResult = NonNullable<Awaited<ReturnType<typeof wyscoutControllerSearchMatches>>>
export type WyscoutControllerSearchMatchesQueryError = AxiosError<unknown>


export function useWyscoutControllerSearchMatches<TData = Awaited<ReturnType<typeof wyscoutControllerSearchMatches>>, TError = AxiosError<unknown>>(
 params: undefined |  WyscoutControllerSearchMatchesParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchMatches>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof wyscoutControllerSearchMatches>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerSearchMatches<TData = Awaited<ReturnType<typeof wyscoutControllerSearchMatches>>, TError = AxiosError<unknown>>(
 params?: WyscoutControllerSearchMatchesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchMatches>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof wyscoutControllerSearchMatches>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerSearchMatches<TData = Awaited<ReturnType<typeof wyscoutControllerSearchMatches>>, TError = AxiosError<unknown>>(
 params?: WyscoutControllerSearchMatchesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchMatches>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useWyscoutControllerSearchMatches<TData = Awaited<ReturnType<typeof wyscoutControllerSearchMatches>>, TError = AxiosError<unknown>>(
 params?: WyscoutControllerSearchMatchesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchMatches>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getWyscoutControllerSearchMatchesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getWyscoutControllerSearchMatchesSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof wyscoutControllerSearchMatches>>, TError = AxiosError<unknown>>(params?: WyscoutControllerSearchMatchesParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchMatches>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWyscoutControllerSearchMatchesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof wyscoutControllerSearchMatches>>> = ({ signal }) => wyscoutControllerSearchMatches(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchMatches>>, TError, TData> & { queryKey: QueryKey }
}

export type WyscoutControllerSearchMatchesSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof wyscoutControllerSearchMatches>>>
export type WyscoutControllerSearchMatchesSuspenseQueryError = AxiosError<unknown>


export function useWyscoutControllerSearchMatchesSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerSearchMatches>>, TError = AxiosError<unknown>>(
 params: undefined |  WyscoutControllerSearchMatchesParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchMatches>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerSearchMatchesSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerSearchMatches>>, TError = AxiosError<unknown>>(
 params?: WyscoutControllerSearchMatchesParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchMatches>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerSearchMatchesSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerSearchMatches>>, TError = AxiosError<unknown>>(
 params?: WyscoutControllerSearchMatchesParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchMatches>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useWyscoutControllerSearchMatchesSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerSearchMatches>>, TError = AxiosError<unknown>>(
 params?: WyscoutControllerSearchMatchesParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerSearchMatches>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getWyscoutControllerSearchMatchesSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const wyscoutControllerGetMatchPlayers = (
    id: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WyscoutMatchPlayerAppearance[]>> => {
    
    return axios.get(
      `/v1/wyscout/match/${id}/players`,options
    );
  }


export const getWyscoutControllerGetMatchPlayersQueryKey = (id: number,) => {
    return [`/v1/wyscout/match/${id}/players`] as const;
    }

    
export const getWyscoutControllerGetMatchPlayersQueryOptions = <TData = Awaited<ReturnType<typeof wyscoutControllerGetMatchPlayers>>, TError = AxiosError<unknown>>(id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetMatchPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWyscoutControllerGetMatchPlayersQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof wyscoutControllerGetMatchPlayers>>> = ({ signal }) => wyscoutControllerGetMatchPlayers(id, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetMatchPlayers>>, TError, TData> & { queryKey: QueryKey }
}

export type WyscoutControllerGetMatchPlayersQueryResult = NonNullable<Awaited<ReturnType<typeof wyscoutControllerGetMatchPlayers>>>
export type WyscoutControllerGetMatchPlayersQueryError = AxiosError<unknown>


export function useWyscoutControllerGetMatchPlayers<TData = Awaited<ReturnType<typeof wyscoutControllerGetMatchPlayers>>, TError = AxiosError<unknown>>(
 id: number, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetMatchPlayers>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof wyscoutControllerGetMatchPlayers>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerGetMatchPlayers<TData = Awaited<ReturnType<typeof wyscoutControllerGetMatchPlayers>>, TError = AxiosError<unknown>>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetMatchPlayers>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof wyscoutControllerGetMatchPlayers>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerGetMatchPlayers<TData = Awaited<ReturnType<typeof wyscoutControllerGetMatchPlayers>>, TError = AxiosError<unknown>>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetMatchPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useWyscoutControllerGetMatchPlayers<TData = Awaited<ReturnType<typeof wyscoutControllerGetMatchPlayers>>, TError = AxiosError<unknown>>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetMatchPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getWyscoutControllerGetMatchPlayersQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getWyscoutControllerGetMatchPlayersSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof wyscoutControllerGetMatchPlayers>>, TError = AxiosError<unknown>>(id: number, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetMatchPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWyscoutControllerGetMatchPlayersQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof wyscoutControllerGetMatchPlayers>>> = ({ signal }) => wyscoutControllerGetMatchPlayers(id, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetMatchPlayers>>, TError, TData> & { queryKey: QueryKey }
}

export type WyscoutControllerGetMatchPlayersSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof wyscoutControllerGetMatchPlayers>>>
export type WyscoutControllerGetMatchPlayersSuspenseQueryError = AxiosError<unknown>


export function useWyscoutControllerGetMatchPlayersSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerGetMatchPlayers>>, TError = AxiosError<unknown>>(
 id: number, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetMatchPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerGetMatchPlayersSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerGetMatchPlayers>>, TError = AxiosError<unknown>>(
 id: number, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetMatchPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useWyscoutControllerGetMatchPlayersSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerGetMatchPlayers>>, TError = AxiosError<unknown>>(
 id: number, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetMatchPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useWyscoutControllerGetMatchPlayersSuspense<TData = Awaited<ReturnType<typeof wyscoutControllerGetMatchPlayers>>, TError = AxiosError<unknown>>(
 id: number, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof wyscoutControllerGetMatchPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getWyscoutControllerGetMatchPlayersSuspenseQueryOptions(id,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantPlayerControllerGetPlayers = (
    tenantId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantPlayerGetDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/player`,options
    );
  }


export const getTenantPlayerControllerGetPlayersQueryKey = (tenantId: string,) => {
    return [`/v1/${tenantId}/player`] as const;
    }

    
export const getTenantPlayerControllerGetPlayersQueryOptions = <TData = Awaited<ReturnType<typeof tenantPlayerControllerGetPlayers>>, TError = AxiosError<unknown>>(tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerControllerGetPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantPlayerControllerGetPlayersQueryKey(tenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantPlayerControllerGetPlayers>>> = ({ signal }) => tenantPlayerControllerGetPlayers(tenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerControllerGetPlayers>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantPlayerControllerGetPlayersQueryResult = NonNullable<Awaited<ReturnType<typeof tenantPlayerControllerGetPlayers>>>
export type TenantPlayerControllerGetPlayersQueryError = AxiosError<unknown>


export function useTenantPlayerControllerGetPlayers<TData = Awaited<ReturnType<typeof tenantPlayerControllerGetPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerControllerGetPlayers>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantPlayerControllerGetPlayers>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPlayerControllerGetPlayers<TData = Awaited<ReturnType<typeof tenantPlayerControllerGetPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerControllerGetPlayers>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantPlayerControllerGetPlayers>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPlayerControllerGetPlayers<TData = Awaited<ReturnType<typeof tenantPlayerControllerGetPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerControllerGetPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantPlayerControllerGetPlayers<TData = Awaited<ReturnType<typeof tenantPlayerControllerGetPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerControllerGetPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantPlayerControllerGetPlayersQueryOptions(tenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantPlayerControllerGetPlayersSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantPlayerControllerGetPlayers>>, TError = AxiosError<unknown>>(tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerControllerGetPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantPlayerControllerGetPlayersQueryKey(tenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantPlayerControllerGetPlayers>>> = ({ signal }) => tenantPlayerControllerGetPlayers(tenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerControllerGetPlayers>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantPlayerControllerGetPlayersSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantPlayerControllerGetPlayers>>>
export type TenantPlayerControllerGetPlayersSuspenseQueryError = AxiosError<unknown>


export function useTenantPlayerControllerGetPlayersSuspense<TData = Awaited<ReturnType<typeof tenantPlayerControllerGetPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerControllerGetPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPlayerControllerGetPlayersSuspense<TData = Awaited<ReturnType<typeof tenantPlayerControllerGetPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerControllerGetPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPlayerControllerGetPlayersSuspense<TData = Awaited<ReturnType<typeof tenantPlayerControllerGetPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerControllerGetPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantPlayerControllerGetPlayersSuspense<TData = Awaited<ReturnType<typeof tenantPlayerControllerGetPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerControllerGetPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantPlayerControllerGetPlayersSuspenseQueryOptions(tenantId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantPlayerControllerCreatePlayer = (
    tenantId: string,
    tenantPlayerCreateDto: TenantPlayerCreateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/v1/${tenantId}/player`,
      tenantPlayerCreateDto,options
    );
  }



export const getTenantPlayerControllerCreatePlayerMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerControllerCreatePlayer>>, TError,{tenantId: string;data: TenantPlayerCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerControllerCreatePlayer>>, TError,{tenantId: string;data: TenantPlayerCreateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantPlayerControllerCreatePlayer>>, {tenantId: string;data: TenantPlayerCreateDto}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  tenantPlayerControllerCreatePlayer(tenantId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantPlayerControllerCreatePlayerMutationResult = NonNullable<Awaited<ReturnType<typeof tenantPlayerControllerCreatePlayer>>>
    export type TenantPlayerControllerCreatePlayerMutationBody = TenantPlayerCreateDto
    export type TenantPlayerControllerCreatePlayerMutationError = AxiosError<unknown>

    export const useTenantPlayerControllerCreatePlayer = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerControllerCreatePlayer>>, TError,{tenantId: string;data: TenantPlayerCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantPlayerControllerCreatePlayer>>,
        TError,
        {tenantId: string;data: TenantPlayerCreateDto},
        TContext
      > => {

      const mutationOptions = getTenantPlayerControllerCreatePlayerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantPlayerControllerGetPlayer = (
    tenantId: string,
    playerOnTenantId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantPlayerGetDto>> => {
    
    return axios.get(
      `/v1/${tenantId}/player/${playerOnTenantId}`,options
    );
  }


export const getTenantPlayerControllerGetPlayerQueryKey = (tenantId: string,
    playerOnTenantId: string,) => {
    return [`/v1/${tenantId}/player/${playerOnTenantId}`] as const;
    }

    
export const getTenantPlayerControllerGetPlayerQueryOptions = <TData = Awaited<ReturnType<typeof tenantPlayerControllerGetPlayer>>, TError = AxiosError<unknown>>(tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerControllerGetPlayer>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantPlayerControllerGetPlayerQueryKey(tenantId,playerOnTenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantPlayerControllerGetPlayer>>> = ({ signal }) => tenantPlayerControllerGetPlayer(tenantId,playerOnTenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && playerOnTenantId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerControllerGetPlayer>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantPlayerControllerGetPlayerQueryResult = NonNullable<Awaited<ReturnType<typeof tenantPlayerControllerGetPlayer>>>
export type TenantPlayerControllerGetPlayerQueryError = AxiosError<unknown>


export function useTenantPlayerControllerGetPlayer<TData = Awaited<ReturnType<typeof tenantPlayerControllerGetPlayer>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerControllerGetPlayer>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantPlayerControllerGetPlayer>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPlayerControllerGetPlayer<TData = Awaited<ReturnType<typeof tenantPlayerControllerGetPlayer>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerControllerGetPlayer>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantPlayerControllerGetPlayer>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPlayerControllerGetPlayer<TData = Awaited<ReturnType<typeof tenantPlayerControllerGetPlayer>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerControllerGetPlayer>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantPlayerControllerGetPlayer<TData = Awaited<ReturnType<typeof tenantPlayerControllerGetPlayer>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerControllerGetPlayer>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantPlayerControllerGetPlayerQueryOptions(tenantId,playerOnTenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantPlayerControllerGetPlayerSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantPlayerControllerGetPlayer>>, TError = AxiosError<unknown>>(tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerControllerGetPlayer>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantPlayerControllerGetPlayerQueryKey(tenantId,playerOnTenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantPlayerControllerGetPlayer>>> = ({ signal }) => tenantPlayerControllerGetPlayer(tenantId,playerOnTenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && playerOnTenantId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerControllerGetPlayer>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantPlayerControllerGetPlayerSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantPlayerControllerGetPlayer>>>
export type TenantPlayerControllerGetPlayerSuspenseQueryError = AxiosError<unknown>


export function useTenantPlayerControllerGetPlayerSuspense<TData = Awaited<ReturnType<typeof tenantPlayerControllerGetPlayer>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerControllerGetPlayer>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPlayerControllerGetPlayerSuspense<TData = Awaited<ReturnType<typeof tenantPlayerControllerGetPlayer>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerControllerGetPlayer>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPlayerControllerGetPlayerSuspense<TData = Awaited<ReturnType<typeof tenantPlayerControllerGetPlayer>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerControllerGetPlayer>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantPlayerControllerGetPlayerSuspense<TData = Awaited<ReturnType<typeof tenantPlayerControllerGetPlayer>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerControllerGetPlayer>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantPlayerControllerGetPlayerSuspenseQueryOptions(tenantId,playerOnTenantId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantPlayerControllerUpdatePlayer = (
    tenantId: string,
    playerOnTenantId: string,
    tenantPlayerUpdateDto: TenantPlayerUpdateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `/v1/${tenantId}/player/${playerOnTenantId}`,
      tenantPlayerUpdateDto,options
    );
  }



export const getTenantPlayerControllerUpdatePlayerMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerControllerUpdatePlayer>>, TError,{tenantId: string;playerOnTenantId: string;data: TenantPlayerUpdateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerControllerUpdatePlayer>>, TError,{tenantId: string;playerOnTenantId: string;data: TenantPlayerUpdateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantPlayerControllerUpdatePlayer>>, {tenantId: string;playerOnTenantId: string;data: TenantPlayerUpdateDto}> = (props) => {
          const {tenantId,playerOnTenantId,data} = props ?? {};

          return  tenantPlayerControllerUpdatePlayer(tenantId,playerOnTenantId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantPlayerControllerUpdatePlayerMutationResult = NonNullable<Awaited<ReturnType<typeof tenantPlayerControllerUpdatePlayer>>>
    export type TenantPlayerControllerUpdatePlayerMutationBody = TenantPlayerUpdateDto
    export type TenantPlayerControllerUpdatePlayerMutationError = AxiosError<unknown>

    export const useTenantPlayerControllerUpdatePlayer = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerControllerUpdatePlayer>>, TError,{tenantId: string;playerOnTenantId: string;data: TenantPlayerUpdateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantPlayerControllerUpdatePlayer>>,
        TError,
        {tenantId: string;playerOnTenantId: string;data: TenantPlayerUpdateDto},
        TContext
      > => {

      const mutationOptions = getTenantPlayerControllerUpdatePlayerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantPlayerControllerPatchPlayer = (
    tenantId: string,
    playerOnTenantId: string,
    tenantPlayerUpdateDto: TenantPlayerUpdateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantPlayerGetDto>> => {
    
    return axios.patch(
      `/v1/${tenantId}/player/${playerOnTenantId}`,
      tenantPlayerUpdateDto,options
    );
  }



export const getTenantPlayerControllerPatchPlayerMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerControllerPatchPlayer>>, TError,{tenantId: string;playerOnTenantId: string;data: TenantPlayerUpdateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerControllerPatchPlayer>>, TError,{tenantId: string;playerOnTenantId: string;data: TenantPlayerUpdateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantPlayerControllerPatchPlayer>>, {tenantId: string;playerOnTenantId: string;data: TenantPlayerUpdateDto}> = (props) => {
          const {tenantId,playerOnTenantId,data} = props ?? {};

          return  tenantPlayerControllerPatchPlayer(tenantId,playerOnTenantId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantPlayerControllerPatchPlayerMutationResult = NonNullable<Awaited<ReturnType<typeof tenantPlayerControllerPatchPlayer>>>
    export type TenantPlayerControllerPatchPlayerMutationBody = TenantPlayerUpdateDto
    export type TenantPlayerControllerPatchPlayerMutationError = AxiosError<unknown>

    export const useTenantPlayerControllerPatchPlayer = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerControllerPatchPlayer>>, TError,{tenantId: string;playerOnTenantId: string;data: TenantPlayerUpdateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantPlayerControllerPatchPlayer>>,
        TError,
        {tenantId: string;playerOnTenantId: string;data: TenantPlayerUpdateDto},
        TContext
      > => {

      const mutationOptions = getTenantPlayerControllerPatchPlayerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantPlayerControllerDeletePlayer = (
    tenantId: string,
    playerOnTenantId: string,
    tenantPlayerDeleteDto: TenantPlayerDeleteDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/v1/${tenantId}/player/${playerOnTenantId}`,{data:
      tenantPlayerDeleteDto, ...options}
    );
  }



export const getTenantPlayerControllerDeletePlayerMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerControllerDeletePlayer>>, TError,{tenantId: string;playerOnTenantId: string;data: TenantPlayerDeleteDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerControllerDeletePlayer>>, TError,{tenantId: string;playerOnTenantId: string;data: TenantPlayerDeleteDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantPlayerControllerDeletePlayer>>, {tenantId: string;playerOnTenantId: string;data: TenantPlayerDeleteDto}> = (props) => {
          const {tenantId,playerOnTenantId,data} = props ?? {};

          return  tenantPlayerControllerDeletePlayer(tenantId,playerOnTenantId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantPlayerControllerDeletePlayerMutationResult = NonNullable<Awaited<ReturnType<typeof tenantPlayerControllerDeletePlayer>>>
    export type TenantPlayerControllerDeletePlayerMutationBody = TenantPlayerDeleteDto
    export type TenantPlayerControllerDeletePlayerMutationError = AxiosError<unknown>

    export const useTenantPlayerControllerDeletePlayer = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerControllerDeletePlayer>>, TError,{tenantId: string;playerOnTenantId: string;data: TenantPlayerDeleteDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantPlayerControllerDeletePlayer>>,
        TError,
        {tenantId: string;playerOnTenantId: string;data: TenantPlayerDeleteDto},
        TContext
      > => {

      const mutationOptions = getTenantPlayerControllerDeletePlayerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantPlayerPerformanceTestControllerGetPerformanceTests = (
    tenantId: string,
    playerOnTenantId: string,
    params?: TenantPlayerPerformanceTestControllerGetPerformanceTestsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantPlayerPerformanceTestControllerGetPerformanceTests200>> => {
    
    return axios.get(
      `/v1/${tenantId}/player/${playerOnTenantId}/performance-test`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getTenantPlayerPerformanceTestControllerGetPerformanceTestsQueryKey = (tenantId: string,
    playerOnTenantId: string,
    params?: TenantPlayerPerformanceTestControllerGetPerformanceTestsParams,) => {
    return [`/v1/${tenantId}/player/${playerOnTenantId}/performance-test`, ...(params ? [params]: [])] as const;
    }

    
export const getTenantPlayerPerformanceTestControllerGetPerformanceTestsQueryOptions = <TData = Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTests>>, TError = AxiosError<unknown>>(tenantId: string,
    playerOnTenantId: string,
    params?: TenantPlayerPerformanceTestControllerGetPerformanceTestsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTests>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantPlayerPerformanceTestControllerGetPerformanceTestsQueryKey(tenantId,playerOnTenantId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTests>>> = ({ signal }) => tenantPlayerPerformanceTestControllerGetPerformanceTests(tenantId,playerOnTenantId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && playerOnTenantId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTests>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantPlayerPerformanceTestControllerGetPerformanceTestsQueryResult = NonNullable<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTests>>>
export type TenantPlayerPerformanceTestControllerGetPerformanceTestsQueryError = AxiosError<unknown>


export function useTenantPlayerPerformanceTestControllerGetPerformanceTests<TData = Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTests>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    params: undefined |  TenantPlayerPerformanceTestControllerGetPerformanceTestsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTests>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTests>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPlayerPerformanceTestControllerGetPerformanceTests<TData = Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTests>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    params?: TenantPlayerPerformanceTestControllerGetPerformanceTestsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTests>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTests>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPlayerPerformanceTestControllerGetPerformanceTests<TData = Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTests>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    params?: TenantPlayerPerformanceTestControllerGetPerformanceTestsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTests>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantPlayerPerformanceTestControllerGetPerformanceTests<TData = Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTests>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    params?: TenantPlayerPerformanceTestControllerGetPerformanceTestsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTests>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantPlayerPerformanceTestControllerGetPerformanceTestsQueryOptions(tenantId,playerOnTenantId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantPlayerPerformanceTestControllerGetPerformanceTestsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTests>>, TError = AxiosError<unknown>>(tenantId: string,
    playerOnTenantId: string,
    params?: TenantPlayerPerformanceTestControllerGetPerformanceTestsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTests>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantPlayerPerformanceTestControllerGetPerformanceTestsQueryKey(tenantId,playerOnTenantId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTests>>> = ({ signal }) => tenantPlayerPerformanceTestControllerGetPerformanceTests(tenantId,playerOnTenantId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && playerOnTenantId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTests>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantPlayerPerformanceTestControllerGetPerformanceTestsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTests>>>
export type TenantPlayerPerformanceTestControllerGetPerformanceTestsSuspenseQueryError = AxiosError<unknown>


export function useTenantPlayerPerformanceTestControllerGetPerformanceTestsSuspense<TData = Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTests>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    params: undefined |  TenantPlayerPerformanceTestControllerGetPerformanceTestsParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTests>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPlayerPerformanceTestControllerGetPerformanceTestsSuspense<TData = Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTests>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    params?: TenantPlayerPerformanceTestControllerGetPerformanceTestsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTests>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPlayerPerformanceTestControllerGetPerformanceTestsSuspense<TData = Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTests>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    params?: TenantPlayerPerformanceTestControllerGetPerformanceTestsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTests>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantPlayerPerformanceTestControllerGetPerformanceTestsSuspense<TData = Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTests>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    params?: TenantPlayerPerformanceTestControllerGetPerformanceTestsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTests>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantPlayerPerformanceTestControllerGetPerformanceTestsSuspenseQueryOptions(tenantId,playerOnTenantId,params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantPlayerPerformanceTestControllerCreatePerformanceTest = (
    tenantId: string,
    playerOnTenantId: string,
    tenantPlayerPerformanceTestCreateDto: TenantPlayerPerformanceTestCreateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantPlayerPerformanceTestResultGetDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/player/${playerOnTenantId}/performance-test`,
      tenantPlayerPerformanceTestCreateDto,options
    );
  }



export const getTenantPlayerPerformanceTestControllerCreatePerformanceTestMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerCreatePerformanceTest>>, TError,{tenantId: string;playerOnTenantId: string;data: TenantPlayerPerformanceTestCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerCreatePerformanceTest>>, TError,{tenantId: string;playerOnTenantId: string;data: TenantPlayerPerformanceTestCreateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerCreatePerformanceTest>>, {tenantId: string;playerOnTenantId: string;data: TenantPlayerPerformanceTestCreateDto}> = (props) => {
          const {tenantId,playerOnTenantId,data} = props ?? {};

          return  tenantPlayerPerformanceTestControllerCreatePerformanceTest(tenantId,playerOnTenantId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantPlayerPerformanceTestControllerCreatePerformanceTestMutationResult = NonNullable<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerCreatePerformanceTest>>>
    export type TenantPlayerPerformanceTestControllerCreatePerformanceTestMutationBody = TenantPlayerPerformanceTestCreateDto
    export type TenantPlayerPerformanceTestControllerCreatePerformanceTestMutationError = AxiosError<unknown>

    export const useTenantPlayerPerformanceTestControllerCreatePerformanceTest = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerCreatePerformanceTest>>, TError,{tenantId: string;playerOnTenantId: string;data: TenantPlayerPerformanceTestCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerCreatePerformanceTest>>,
        TError,
        {tenantId: string;playerOnTenantId: string;data: TenantPlayerPerformanceTestCreateDto},
        TContext
      > => {

      const mutationOptions = getTenantPlayerPerformanceTestControllerCreatePerformanceTestMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantPlayerPerformanceTestControllerGetLatestPerformanceTests = (
    tenantId: string,
    playerOnTenantId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantPlayerPerformanceTestResultGetDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/player/${playerOnTenantId}/performance-test/latest`,options
    );
  }


export const getTenantPlayerPerformanceTestControllerGetLatestPerformanceTestsQueryKey = (tenantId: string,
    playerOnTenantId: string,) => {
    return [`/v1/${tenantId}/player/${playerOnTenantId}/performance-test/latest`] as const;
    }

    
export const getTenantPlayerPerformanceTestControllerGetLatestPerformanceTestsQueryOptions = <TData = Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetLatestPerformanceTests>>, TError = AxiosError<unknown>>(tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetLatestPerformanceTests>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantPlayerPerformanceTestControllerGetLatestPerformanceTestsQueryKey(tenantId,playerOnTenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetLatestPerformanceTests>>> = ({ signal }) => tenantPlayerPerformanceTestControllerGetLatestPerformanceTests(tenantId,playerOnTenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && playerOnTenantId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetLatestPerformanceTests>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantPlayerPerformanceTestControllerGetLatestPerformanceTestsQueryResult = NonNullable<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetLatestPerformanceTests>>>
export type TenantPlayerPerformanceTestControllerGetLatestPerformanceTestsQueryError = AxiosError<unknown>


export function useTenantPlayerPerformanceTestControllerGetLatestPerformanceTests<TData = Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetLatestPerformanceTests>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetLatestPerformanceTests>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetLatestPerformanceTests>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPlayerPerformanceTestControllerGetLatestPerformanceTests<TData = Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetLatestPerformanceTests>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetLatestPerformanceTests>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetLatestPerformanceTests>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPlayerPerformanceTestControllerGetLatestPerformanceTests<TData = Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetLatestPerformanceTests>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetLatestPerformanceTests>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantPlayerPerformanceTestControllerGetLatestPerformanceTests<TData = Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetLatestPerformanceTests>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetLatestPerformanceTests>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantPlayerPerformanceTestControllerGetLatestPerformanceTestsQueryOptions(tenantId,playerOnTenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantPlayerPerformanceTestControllerGetLatestPerformanceTestsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetLatestPerformanceTests>>, TError = AxiosError<unknown>>(tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetLatestPerformanceTests>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantPlayerPerformanceTestControllerGetLatestPerformanceTestsQueryKey(tenantId,playerOnTenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetLatestPerformanceTests>>> = ({ signal }) => tenantPlayerPerformanceTestControllerGetLatestPerformanceTests(tenantId,playerOnTenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && playerOnTenantId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetLatestPerformanceTests>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantPlayerPerformanceTestControllerGetLatestPerformanceTestsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetLatestPerformanceTests>>>
export type TenantPlayerPerformanceTestControllerGetLatestPerformanceTestsSuspenseQueryError = AxiosError<unknown>


export function useTenantPlayerPerformanceTestControllerGetLatestPerformanceTestsSuspense<TData = Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetLatestPerformanceTests>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetLatestPerformanceTests>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPlayerPerformanceTestControllerGetLatestPerformanceTestsSuspense<TData = Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetLatestPerformanceTests>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetLatestPerformanceTests>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPlayerPerformanceTestControllerGetLatestPerformanceTestsSuspense<TData = Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetLatestPerformanceTests>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetLatestPerformanceTests>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantPlayerPerformanceTestControllerGetLatestPerformanceTestsSuspense<TData = Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetLatestPerformanceTests>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetLatestPerformanceTests>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantPlayerPerformanceTestControllerGetLatestPerformanceTestsSuspenseQueryOptions(tenantId,playerOnTenantId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantPlayerPerformanceTestControllerGetPerformanceTest = (
    tenantId: string,
    playerOnTenantId: string,
    performanceTestId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantPlayerPerformanceTestResultGetDto>> => {
    
    return axios.get(
      `/v1/${tenantId}/player/${playerOnTenantId}/performance-test/${performanceTestId}`,options
    );
  }


export const getTenantPlayerPerformanceTestControllerGetPerformanceTestQueryKey = (tenantId: string,
    playerOnTenantId: string,
    performanceTestId: string,) => {
    return [`/v1/${tenantId}/player/${playerOnTenantId}/performance-test/${performanceTestId}`] as const;
    }

    
export const getTenantPlayerPerformanceTestControllerGetPerformanceTestQueryOptions = <TData = Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTest>>, TError = AxiosError<unknown>>(tenantId: string,
    playerOnTenantId: string,
    performanceTestId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTest>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantPlayerPerformanceTestControllerGetPerformanceTestQueryKey(tenantId,playerOnTenantId,performanceTestId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTest>>> = ({ signal }) => tenantPlayerPerformanceTestControllerGetPerformanceTest(tenantId,playerOnTenantId,performanceTestId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && playerOnTenantId && performanceTestId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTest>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantPlayerPerformanceTestControllerGetPerformanceTestQueryResult = NonNullable<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTest>>>
export type TenantPlayerPerformanceTestControllerGetPerformanceTestQueryError = AxiosError<unknown>


export function useTenantPlayerPerformanceTestControllerGetPerformanceTest<TData = Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTest>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    performanceTestId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTest>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTest>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPlayerPerformanceTestControllerGetPerformanceTest<TData = Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTest>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    performanceTestId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTest>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTest>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPlayerPerformanceTestControllerGetPerformanceTest<TData = Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTest>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    performanceTestId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTest>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantPlayerPerformanceTestControllerGetPerformanceTest<TData = Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTest>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    performanceTestId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTest>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantPlayerPerformanceTestControllerGetPerformanceTestQueryOptions(tenantId,playerOnTenantId,performanceTestId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantPlayerPerformanceTestControllerGetPerformanceTestSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTest>>, TError = AxiosError<unknown>>(tenantId: string,
    playerOnTenantId: string,
    performanceTestId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTest>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantPlayerPerformanceTestControllerGetPerformanceTestQueryKey(tenantId,playerOnTenantId,performanceTestId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTest>>> = ({ signal }) => tenantPlayerPerformanceTestControllerGetPerformanceTest(tenantId,playerOnTenantId,performanceTestId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && playerOnTenantId && performanceTestId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTest>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantPlayerPerformanceTestControllerGetPerformanceTestSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTest>>>
export type TenantPlayerPerformanceTestControllerGetPerformanceTestSuspenseQueryError = AxiosError<unknown>


export function useTenantPlayerPerformanceTestControllerGetPerformanceTestSuspense<TData = Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTest>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    performanceTestId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTest>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPlayerPerformanceTestControllerGetPerformanceTestSuspense<TData = Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTest>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    performanceTestId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTest>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPlayerPerformanceTestControllerGetPerformanceTestSuspense<TData = Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTest>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    performanceTestId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTest>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantPlayerPerformanceTestControllerGetPerformanceTestSuspense<TData = Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTest>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    performanceTestId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerGetPerformanceTest>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantPlayerPerformanceTestControllerGetPerformanceTestSuspenseQueryOptions(tenantId,playerOnTenantId,performanceTestId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantPlayerPerformanceTestControllerUpdatePerformanceTest = (
    tenantId: string,
    playerOnTenantId: string,
    performanceTestId: string,
    tenantPlayerPerformanceTestPatchDto: TenantPlayerPerformanceTestPatchDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantPlayerPerformanceTestResultGetDto>> => {
    
    return axios.patch(
      `/v1/${tenantId}/player/${playerOnTenantId}/performance-test/${performanceTestId}`,
      tenantPlayerPerformanceTestPatchDto,options
    );
  }



export const getTenantPlayerPerformanceTestControllerUpdatePerformanceTestMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerUpdatePerformanceTest>>, TError,{tenantId: string;playerOnTenantId: string;performanceTestId: string;data: TenantPlayerPerformanceTestPatchDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerUpdatePerformanceTest>>, TError,{tenantId: string;playerOnTenantId: string;performanceTestId: string;data: TenantPlayerPerformanceTestPatchDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerUpdatePerformanceTest>>, {tenantId: string;playerOnTenantId: string;performanceTestId: string;data: TenantPlayerPerformanceTestPatchDto}> = (props) => {
          const {tenantId,playerOnTenantId,performanceTestId,data} = props ?? {};

          return  tenantPlayerPerformanceTestControllerUpdatePerformanceTest(tenantId,playerOnTenantId,performanceTestId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantPlayerPerformanceTestControllerUpdatePerformanceTestMutationResult = NonNullable<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerUpdatePerformanceTest>>>
    export type TenantPlayerPerformanceTestControllerUpdatePerformanceTestMutationBody = TenantPlayerPerformanceTestPatchDto
    export type TenantPlayerPerformanceTestControllerUpdatePerformanceTestMutationError = AxiosError<unknown>

    export const useTenantPlayerPerformanceTestControllerUpdatePerformanceTest = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerUpdatePerformanceTest>>, TError,{tenantId: string;playerOnTenantId: string;performanceTestId: string;data: TenantPlayerPerformanceTestPatchDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerUpdatePerformanceTest>>,
        TError,
        {tenantId: string;playerOnTenantId: string;performanceTestId: string;data: TenantPlayerPerformanceTestPatchDto},
        TContext
      > => {

      const mutationOptions = getTenantPlayerPerformanceTestControllerUpdatePerformanceTestMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantPlayerPerformanceTestControllerDeletePerformanceTest = (
    tenantId: string,
    playerOnTenantId: string,
    performanceTestId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/v1/${tenantId}/player/${playerOnTenantId}/performance-test/${performanceTestId}`,options
    );
  }



export const getTenantPlayerPerformanceTestControllerDeletePerformanceTestMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerDeletePerformanceTest>>, TError,{tenantId: string;playerOnTenantId: string;performanceTestId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerDeletePerformanceTest>>, TError,{tenantId: string;playerOnTenantId: string;performanceTestId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerDeletePerformanceTest>>, {tenantId: string;playerOnTenantId: string;performanceTestId: string}> = (props) => {
          const {tenantId,playerOnTenantId,performanceTestId} = props ?? {};

          return  tenantPlayerPerformanceTestControllerDeletePerformanceTest(tenantId,playerOnTenantId,performanceTestId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantPlayerPerformanceTestControllerDeletePerformanceTestMutationResult = NonNullable<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerDeletePerformanceTest>>>
    
    export type TenantPlayerPerformanceTestControllerDeletePerformanceTestMutationError = AxiosError<unknown>

    export const useTenantPlayerPerformanceTestControllerDeletePerformanceTest = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerDeletePerformanceTest>>, TError,{tenantId: string;playerOnTenantId: string;performanceTestId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantPlayerPerformanceTestControllerDeletePerformanceTest>>,
        TError,
        {tenantId: string;playerOnTenantId: string;performanceTestId: string},
        TContext
      > => {

      const mutationOptions = getTenantPlayerPerformanceTestControllerDeletePerformanceTestMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantPlayerMedicalInformationControllerGetMedicalInformation = (
    tenantId: string,
    playerOnTenantId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantPlayerMedicalInformationGetDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/player/${playerOnTenantId}/medical-information`,options
    );
  }


export const getTenantPlayerMedicalInformationControllerGetMedicalInformationQueryKey = (tenantId: string,
    playerOnTenantId: string,) => {
    return [`/v1/${tenantId}/player/${playerOnTenantId}/medical-information`] as const;
    }

    
export const getTenantPlayerMedicalInformationControllerGetMedicalInformationQueryOptions = <TData = Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetMedicalInformation>>, TError = AxiosError<unknown>>(tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetMedicalInformation>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantPlayerMedicalInformationControllerGetMedicalInformationQueryKey(tenantId,playerOnTenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetMedicalInformation>>> = ({ signal }) => tenantPlayerMedicalInformationControllerGetMedicalInformation(tenantId,playerOnTenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && playerOnTenantId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetMedicalInformation>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantPlayerMedicalInformationControllerGetMedicalInformationQueryResult = NonNullable<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetMedicalInformation>>>
export type TenantPlayerMedicalInformationControllerGetMedicalInformationQueryError = AxiosError<unknown>


export function useTenantPlayerMedicalInformationControllerGetMedicalInformation<TData = Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetMedicalInformation>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetMedicalInformation>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetMedicalInformation>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPlayerMedicalInformationControllerGetMedicalInformation<TData = Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetMedicalInformation>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetMedicalInformation>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetMedicalInformation>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPlayerMedicalInformationControllerGetMedicalInformation<TData = Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetMedicalInformation>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetMedicalInformation>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantPlayerMedicalInformationControllerGetMedicalInformation<TData = Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetMedicalInformation>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetMedicalInformation>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantPlayerMedicalInformationControllerGetMedicalInformationQueryOptions(tenantId,playerOnTenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantPlayerMedicalInformationControllerGetMedicalInformationSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetMedicalInformation>>, TError = AxiosError<unknown>>(tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetMedicalInformation>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantPlayerMedicalInformationControllerGetMedicalInformationQueryKey(tenantId,playerOnTenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetMedicalInformation>>> = ({ signal }) => tenantPlayerMedicalInformationControllerGetMedicalInformation(tenantId,playerOnTenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && playerOnTenantId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetMedicalInformation>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantPlayerMedicalInformationControllerGetMedicalInformationSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetMedicalInformation>>>
export type TenantPlayerMedicalInformationControllerGetMedicalInformationSuspenseQueryError = AxiosError<unknown>


export function useTenantPlayerMedicalInformationControllerGetMedicalInformationSuspense<TData = Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetMedicalInformation>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetMedicalInformation>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPlayerMedicalInformationControllerGetMedicalInformationSuspense<TData = Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetMedicalInformation>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetMedicalInformation>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPlayerMedicalInformationControllerGetMedicalInformationSuspense<TData = Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetMedicalInformation>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetMedicalInformation>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantPlayerMedicalInformationControllerGetMedicalInformationSuspense<TData = Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetMedicalInformation>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetMedicalInformation>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantPlayerMedicalInformationControllerGetMedicalInformationSuspenseQueryOptions(tenantId,playerOnTenantId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantPlayerMedicalInformationControllerCreateMedicalInformation = (
    tenantId: string,
    playerOnTenantId: string,
    tenantPlayerMedicalInformationCreateDto: TenantPlayerMedicalInformationCreateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantPlayerMedicalInformationGetDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/player/${playerOnTenantId}/medical-information`,
      tenantPlayerMedicalInformationCreateDto,options
    );
  }



export const getTenantPlayerMedicalInformationControllerCreateMedicalInformationMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerCreateMedicalInformation>>, TError,{tenantId: string;playerOnTenantId: string;data: TenantPlayerMedicalInformationCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerCreateMedicalInformation>>, TError,{tenantId: string;playerOnTenantId: string;data: TenantPlayerMedicalInformationCreateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerCreateMedicalInformation>>, {tenantId: string;playerOnTenantId: string;data: TenantPlayerMedicalInformationCreateDto}> = (props) => {
          const {tenantId,playerOnTenantId,data} = props ?? {};

          return  tenantPlayerMedicalInformationControllerCreateMedicalInformation(tenantId,playerOnTenantId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantPlayerMedicalInformationControllerCreateMedicalInformationMutationResult = NonNullable<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerCreateMedicalInformation>>>
    export type TenantPlayerMedicalInformationControllerCreateMedicalInformationMutationBody = TenantPlayerMedicalInformationCreateDto
    export type TenantPlayerMedicalInformationControllerCreateMedicalInformationMutationError = AxiosError<unknown>

    export const useTenantPlayerMedicalInformationControllerCreateMedicalInformation = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerCreateMedicalInformation>>, TError,{tenantId: string;playerOnTenantId: string;data: TenantPlayerMedicalInformationCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerCreateMedicalInformation>>,
        TError,
        {tenantId: string;playerOnTenantId: string;data: TenantPlayerMedicalInformationCreateDto},
        TContext
      > => {

      const mutationOptions = getTenantPlayerMedicalInformationControllerCreateMedicalInformationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantPlayerMedicalInformationControllerGetSingleMedicalInformation = (
    tenantId: string,
    playerOnTenantId: string,
    medicalInformationId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantPlayerMedicalInformationGetDto>> => {
    
    return axios.get(
      `/v1/${tenantId}/player/${playerOnTenantId}/medical-information/${medicalInformationId}`,options
    );
  }


export const getTenantPlayerMedicalInformationControllerGetSingleMedicalInformationQueryKey = (tenantId: string,
    playerOnTenantId: string,
    medicalInformationId: string,) => {
    return [`/v1/${tenantId}/player/${playerOnTenantId}/medical-information/${medicalInformationId}`] as const;
    }

    
export const getTenantPlayerMedicalInformationControllerGetSingleMedicalInformationQueryOptions = <TData = Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetSingleMedicalInformation>>, TError = AxiosError<unknown>>(tenantId: string,
    playerOnTenantId: string,
    medicalInformationId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetSingleMedicalInformation>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantPlayerMedicalInformationControllerGetSingleMedicalInformationQueryKey(tenantId,playerOnTenantId,medicalInformationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetSingleMedicalInformation>>> = ({ signal }) => tenantPlayerMedicalInformationControllerGetSingleMedicalInformation(tenantId,playerOnTenantId,medicalInformationId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && playerOnTenantId && medicalInformationId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetSingleMedicalInformation>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantPlayerMedicalInformationControllerGetSingleMedicalInformationQueryResult = NonNullable<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetSingleMedicalInformation>>>
export type TenantPlayerMedicalInformationControllerGetSingleMedicalInformationQueryError = AxiosError<unknown>


export function useTenantPlayerMedicalInformationControllerGetSingleMedicalInformation<TData = Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetSingleMedicalInformation>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    medicalInformationId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetSingleMedicalInformation>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetSingleMedicalInformation>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPlayerMedicalInformationControllerGetSingleMedicalInformation<TData = Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetSingleMedicalInformation>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    medicalInformationId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetSingleMedicalInformation>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetSingleMedicalInformation>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPlayerMedicalInformationControllerGetSingleMedicalInformation<TData = Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetSingleMedicalInformation>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    medicalInformationId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetSingleMedicalInformation>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantPlayerMedicalInformationControllerGetSingleMedicalInformation<TData = Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetSingleMedicalInformation>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    medicalInformationId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetSingleMedicalInformation>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantPlayerMedicalInformationControllerGetSingleMedicalInformationQueryOptions(tenantId,playerOnTenantId,medicalInformationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantPlayerMedicalInformationControllerGetSingleMedicalInformationSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetSingleMedicalInformation>>, TError = AxiosError<unknown>>(tenantId: string,
    playerOnTenantId: string,
    medicalInformationId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetSingleMedicalInformation>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantPlayerMedicalInformationControllerGetSingleMedicalInformationQueryKey(tenantId,playerOnTenantId,medicalInformationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetSingleMedicalInformation>>> = ({ signal }) => tenantPlayerMedicalInformationControllerGetSingleMedicalInformation(tenantId,playerOnTenantId,medicalInformationId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && playerOnTenantId && medicalInformationId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetSingleMedicalInformation>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantPlayerMedicalInformationControllerGetSingleMedicalInformationSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetSingleMedicalInformation>>>
export type TenantPlayerMedicalInformationControllerGetSingleMedicalInformationSuspenseQueryError = AxiosError<unknown>


export function useTenantPlayerMedicalInformationControllerGetSingleMedicalInformationSuspense<TData = Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetSingleMedicalInformation>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    medicalInformationId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetSingleMedicalInformation>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPlayerMedicalInformationControllerGetSingleMedicalInformationSuspense<TData = Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetSingleMedicalInformation>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    medicalInformationId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetSingleMedicalInformation>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPlayerMedicalInformationControllerGetSingleMedicalInformationSuspense<TData = Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetSingleMedicalInformation>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    medicalInformationId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetSingleMedicalInformation>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantPlayerMedicalInformationControllerGetSingleMedicalInformationSuspense<TData = Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetSingleMedicalInformation>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    medicalInformationId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerGetSingleMedicalInformation>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantPlayerMedicalInformationControllerGetSingleMedicalInformationSuspenseQueryOptions(tenantId,playerOnTenantId,medicalInformationId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantPlayerMedicalInformationControllerUpdateMedicalInformation = (
    tenantId: string,
    playerOnTenantId: string,
    medicalInformationId: string,
    tenantPlayerMedicalInformationPatchDto: TenantPlayerMedicalInformationPatchDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantPlayerMedicalInformationGetDto>> => {
    
    return axios.patch(
      `/v1/${tenantId}/player/${playerOnTenantId}/medical-information/${medicalInformationId}`,
      tenantPlayerMedicalInformationPatchDto,options
    );
  }



export const getTenantPlayerMedicalInformationControllerUpdateMedicalInformationMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerUpdateMedicalInformation>>, TError,{tenantId: string;playerOnTenantId: string;medicalInformationId: string;data: TenantPlayerMedicalInformationPatchDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerUpdateMedicalInformation>>, TError,{tenantId: string;playerOnTenantId: string;medicalInformationId: string;data: TenantPlayerMedicalInformationPatchDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerUpdateMedicalInformation>>, {tenantId: string;playerOnTenantId: string;medicalInformationId: string;data: TenantPlayerMedicalInformationPatchDto}> = (props) => {
          const {tenantId,playerOnTenantId,medicalInformationId,data} = props ?? {};

          return  tenantPlayerMedicalInformationControllerUpdateMedicalInformation(tenantId,playerOnTenantId,medicalInformationId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantPlayerMedicalInformationControllerUpdateMedicalInformationMutationResult = NonNullable<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerUpdateMedicalInformation>>>
    export type TenantPlayerMedicalInformationControllerUpdateMedicalInformationMutationBody = TenantPlayerMedicalInformationPatchDto
    export type TenantPlayerMedicalInformationControllerUpdateMedicalInformationMutationError = AxiosError<unknown>

    export const useTenantPlayerMedicalInformationControllerUpdateMedicalInformation = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerUpdateMedicalInformation>>, TError,{tenantId: string;playerOnTenantId: string;medicalInformationId: string;data: TenantPlayerMedicalInformationPatchDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerUpdateMedicalInformation>>,
        TError,
        {tenantId: string;playerOnTenantId: string;medicalInformationId: string;data: TenantPlayerMedicalInformationPatchDto},
        TContext
      > => {

      const mutationOptions = getTenantPlayerMedicalInformationControllerUpdateMedicalInformationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantPlayerMedicalInformationControllerDeleteMedicalInformation = (
    tenantId: string,
    playerOnTenantId: string,
    medicalInformationId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/v1/${tenantId}/player/${playerOnTenantId}/medical-information/${medicalInformationId}`,options
    );
  }



export const getTenantPlayerMedicalInformationControllerDeleteMedicalInformationMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerDeleteMedicalInformation>>, TError,{tenantId: string;playerOnTenantId: string;medicalInformationId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerDeleteMedicalInformation>>, TError,{tenantId: string;playerOnTenantId: string;medicalInformationId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerDeleteMedicalInformation>>, {tenantId: string;playerOnTenantId: string;medicalInformationId: string}> = (props) => {
          const {tenantId,playerOnTenantId,medicalInformationId} = props ?? {};

          return  tenantPlayerMedicalInformationControllerDeleteMedicalInformation(tenantId,playerOnTenantId,medicalInformationId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantPlayerMedicalInformationControllerDeleteMedicalInformationMutationResult = NonNullable<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerDeleteMedicalInformation>>>
    
    export type TenantPlayerMedicalInformationControllerDeleteMedicalInformationMutationError = AxiosError<unknown>

    export const useTenantPlayerMedicalInformationControllerDeleteMedicalInformation = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerDeleteMedicalInformation>>, TError,{tenantId: string;playerOnTenantId: string;medicalInformationId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerDeleteMedicalInformation>>,
        TError,
        {tenantId: string;playerOnTenantId: string;medicalInformationId: string},
        TContext
      > => {

      const mutationOptions = getTenantPlayerMedicalInformationControllerDeleteMedicalInformationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantPlayerMedicalInformationControllerCreateMedicalInformationBulk = (
    tenantId: string,
    playerOnTenantId: string,
    tenantPlayerMedicalInformationCreateDto: TenantPlayerMedicalInformationCreateDto[], options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantPlayerMedicalInformationGetDto[]>> => {
    
    return axios.post(
      `/v1/${tenantId}/player/${playerOnTenantId}/medical-information/bulk`,
      tenantPlayerMedicalInformationCreateDto,options
    );
  }



export const getTenantPlayerMedicalInformationControllerCreateMedicalInformationBulkMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerCreateMedicalInformationBulk>>, TError,{tenantId: string;playerOnTenantId: string;data: TenantPlayerMedicalInformationCreateDto[]}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerCreateMedicalInformationBulk>>, TError,{tenantId: string;playerOnTenantId: string;data: TenantPlayerMedicalInformationCreateDto[]}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerCreateMedicalInformationBulk>>, {tenantId: string;playerOnTenantId: string;data: TenantPlayerMedicalInformationCreateDto[]}> = (props) => {
          const {tenantId,playerOnTenantId,data} = props ?? {};

          return  tenantPlayerMedicalInformationControllerCreateMedicalInformationBulk(tenantId,playerOnTenantId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantPlayerMedicalInformationControllerCreateMedicalInformationBulkMutationResult = NonNullable<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerCreateMedicalInformationBulk>>>
    export type TenantPlayerMedicalInformationControllerCreateMedicalInformationBulkMutationBody = TenantPlayerMedicalInformationCreateDto[]
    export type TenantPlayerMedicalInformationControllerCreateMedicalInformationBulkMutationError = AxiosError<unknown>

    export const useTenantPlayerMedicalInformationControllerCreateMedicalInformationBulk = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerCreateMedicalInformationBulk>>, TError,{tenantId: string;playerOnTenantId: string;data: TenantPlayerMedicalInformationCreateDto[]}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerCreateMedicalInformationBulk>>,
        TError,
        {tenantId: string;playerOnTenantId: string;data: TenantPlayerMedicalInformationCreateDto[]},
        TContext
      > => {

      const mutationOptions = getTenantPlayerMedicalInformationControllerCreateMedicalInformationBulkMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantPlayerMedicalInformationControllerUpdateMedicalInformationBulk = (
    tenantId: string,
    playerOnTenantId: string,
    tenantPlayerMedicalInformationPutDto: TenantPlayerMedicalInformationPutDto[], options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantPlayerMedicalInformationGetDto[]>> => {
    
    return axios.put(
      `/v1/${tenantId}/player/${playerOnTenantId}/medical-information/bulk`,
      tenantPlayerMedicalInformationPutDto,options
    );
  }



export const getTenantPlayerMedicalInformationControllerUpdateMedicalInformationBulkMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerUpdateMedicalInformationBulk>>, TError,{tenantId: string;playerOnTenantId: string;data: TenantPlayerMedicalInformationPutDto[]}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerUpdateMedicalInformationBulk>>, TError,{tenantId: string;playerOnTenantId: string;data: TenantPlayerMedicalInformationPutDto[]}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerUpdateMedicalInformationBulk>>, {tenantId: string;playerOnTenantId: string;data: TenantPlayerMedicalInformationPutDto[]}> = (props) => {
          const {tenantId,playerOnTenantId,data} = props ?? {};

          return  tenantPlayerMedicalInformationControllerUpdateMedicalInformationBulk(tenantId,playerOnTenantId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantPlayerMedicalInformationControllerUpdateMedicalInformationBulkMutationResult = NonNullable<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerUpdateMedicalInformationBulk>>>
    export type TenantPlayerMedicalInformationControllerUpdateMedicalInformationBulkMutationBody = TenantPlayerMedicalInformationPutDto[]
    export type TenantPlayerMedicalInformationControllerUpdateMedicalInformationBulkMutationError = AxiosError<unknown>

    export const useTenantPlayerMedicalInformationControllerUpdateMedicalInformationBulk = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerUpdateMedicalInformationBulk>>, TError,{tenantId: string;playerOnTenantId: string;data: TenantPlayerMedicalInformationPutDto[]}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerUpdateMedicalInformationBulk>>,
        TError,
        {tenantId: string;playerOnTenantId: string;data: TenantPlayerMedicalInformationPutDto[]},
        TContext
      > => {

      const mutationOptions = getTenantPlayerMedicalInformationControllerUpdateMedicalInformationBulkMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantPlayerMedicalInformationControllerDeleteMedicalInformationBulk = (
    tenantId: string,
    playerOnTenantId: string,
    tenantPlayerMedicalInformationControllerDeleteMedicalInformationBulkBody: string[], options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/v1/${tenantId}/player/${playerOnTenantId}/medical-information/bulk`,{data:
      tenantPlayerMedicalInformationControllerDeleteMedicalInformationBulkBody, ...options}
    );
  }



export const getTenantPlayerMedicalInformationControllerDeleteMedicalInformationBulkMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerDeleteMedicalInformationBulk>>, TError,{tenantId: string;playerOnTenantId: string;data: string[]}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerDeleteMedicalInformationBulk>>, TError,{tenantId: string;playerOnTenantId: string;data: string[]}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerDeleteMedicalInformationBulk>>, {tenantId: string;playerOnTenantId: string;data: string[]}> = (props) => {
          const {tenantId,playerOnTenantId,data} = props ?? {};

          return  tenantPlayerMedicalInformationControllerDeleteMedicalInformationBulk(tenantId,playerOnTenantId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantPlayerMedicalInformationControllerDeleteMedicalInformationBulkMutationResult = NonNullable<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerDeleteMedicalInformationBulk>>>
    export type TenantPlayerMedicalInformationControllerDeleteMedicalInformationBulkMutationBody = string[]
    export type TenantPlayerMedicalInformationControllerDeleteMedicalInformationBulkMutationError = AxiosError<unknown>

    export const useTenantPlayerMedicalInformationControllerDeleteMedicalInformationBulk = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerDeleteMedicalInformationBulk>>, TError,{tenantId: string;playerOnTenantId: string;data: string[]}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantPlayerMedicalInformationControllerDeleteMedicalInformationBulk>>,
        TError,
        {tenantId: string;playerOnTenantId: string;data: string[]},
        TContext
      > => {

      const mutationOptions = getTenantPlayerMedicalInformationControllerDeleteMedicalInformationBulkMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantPlayerFileControllerGetDocuments = (
    tenantId: string,
    playerOnTenantId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantPlayerFileGetDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/player/${playerOnTenantId}/file`,options
    );
  }


export const getTenantPlayerFileControllerGetDocumentsQueryKey = (tenantId: string,
    playerOnTenantId: string,) => {
    return [`/v1/${tenantId}/player/${playerOnTenantId}/file`] as const;
    }

    
export const getTenantPlayerFileControllerGetDocumentsQueryOptions = <TData = Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocuments>>, TError = AxiosError<unknown>>(tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocuments>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantPlayerFileControllerGetDocumentsQueryKey(tenantId,playerOnTenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocuments>>> = ({ signal }) => tenantPlayerFileControllerGetDocuments(tenantId,playerOnTenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && playerOnTenantId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocuments>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantPlayerFileControllerGetDocumentsQueryResult = NonNullable<Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocuments>>>
export type TenantPlayerFileControllerGetDocumentsQueryError = AxiosError<unknown>


export function useTenantPlayerFileControllerGetDocuments<TData = Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocuments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocuments>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocuments>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPlayerFileControllerGetDocuments<TData = Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocuments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocuments>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocuments>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPlayerFileControllerGetDocuments<TData = Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocuments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocuments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantPlayerFileControllerGetDocuments<TData = Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocuments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocuments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantPlayerFileControllerGetDocumentsQueryOptions(tenantId,playerOnTenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantPlayerFileControllerGetDocumentsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocuments>>, TError = AxiosError<unknown>>(tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocuments>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantPlayerFileControllerGetDocumentsQueryKey(tenantId,playerOnTenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocuments>>> = ({ signal }) => tenantPlayerFileControllerGetDocuments(tenantId,playerOnTenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && playerOnTenantId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocuments>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantPlayerFileControllerGetDocumentsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocuments>>>
export type TenantPlayerFileControllerGetDocumentsSuspenseQueryError = AxiosError<unknown>


export function useTenantPlayerFileControllerGetDocumentsSuspense<TData = Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocuments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocuments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPlayerFileControllerGetDocumentsSuspense<TData = Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocuments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocuments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPlayerFileControllerGetDocumentsSuspense<TData = Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocuments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocuments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantPlayerFileControllerGetDocumentsSuspense<TData = Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocuments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocuments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantPlayerFileControllerGetDocumentsSuspenseQueryOptions(tenantId,playerOnTenantId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantPlayerFileControllerGetDocumentDownloadLink = (
    tenantId: string,
    playerOnTenantId: string,
    tenantPlayerFileId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantPlayerFileDownloadUrlDto>> => {
    
    return axios.get(
      `/v1/${tenantId}/player/${playerOnTenantId}/file/${tenantPlayerFileId}/downloadrequest`,options
    );
  }


export const getTenantPlayerFileControllerGetDocumentDownloadLinkQueryKey = (tenantId: string,
    playerOnTenantId: string,
    tenantPlayerFileId: string,) => {
    return [`/v1/${tenantId}/player/${playerOnTenantId}/file/${tenantPlayerFileId}/downloadrequest`] as const;
    }

    
export const getTenantPlayerFileControllerGetDocumentDownloadLinkQueryOptions = <TData = Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocumentDownloadLink>>, TError = AxiosError<unknown>>(tenantId: string,
    playerOnTenantId: string,
    tenantPlayerFileId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocumentDownloadLink>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantPlayerFileControllerGetDocumentDownloadLinkQueryKey(tenantId,playerOnTenantId,tenantPlayerFileId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocumentDownloadLink>>> = ({ signal }) => tenantPlayerFileControllerGetDocumentDownloadLink(tenantId,playerOnTenantId,tenantPlayerFileId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && playerOnTenantId && tenantPlayerFileId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocumentDownloadLink>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantPlayerFileControllerGetDocumentDownloadLinkQueryResult = NonNullable<Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocumentDownloadLink>>>
export type TenantPlayerFileControllerGetDocumentDownloadLinkQueryError = AxiosError<unknown>


export function useTenantPlayerFileControllerGetDocumentDownloadLink<TData = Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocumentDownloadLink>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    tenantPlayerFileId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocumentDownloadLink>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocumentDownloadLink>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPlayerFileControllerGetDocumentDownloadLink<TData = Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocumentDownloadLink>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    tenantPlayerFileId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocumentDownloadLink>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocumentDownloadLink>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPlayerFileControllerGetDocumentDownloadLink<TData = Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocumentDownloadLink>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    tenantPlayerFileId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocumentDownloadLink>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantPlayerFileControllerGetDocumentDownloadLink<TData = Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocumentDownloadLink>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    tenantPlayerFileId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocumentDownloadLink>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantPlayerFileControllerGetDocumentDownloadLinkQueryOptions(tenantId,playerOnTenantId,tenantPlayerFileId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantPlayerFileControllerGetDocumentDownloadLinkSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocumentDownloadLink>>, TError = AxiosError<unknown>>(tenantId: string,
    playerOnTenantId: string,
    tenantPlayerFileId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocumentDownloadLink>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantPlayerFileControllerGetDocumentDownloadLinkQueryKey(tenantId,playerOnTenantId,tenantPlayerFileId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocumentDownloadLink>>> = ({ signal }) => tenantPlayerFileControllerGetDocumentDownloadLink(tenantId,playerOnTenantId,tenantPlayerFileId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && playerOnTenantId && tenantPlayerFileId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocumentDownloadLink>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantPlayerFileControllerGetDocumentDownloadLinkSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocumentDownloadLink>>>
export type TenantPlayerFileControllerGetDocumentDownloadLinkSuspenseQueryError = AxiosError<unknown>


export function useTenantPlayerFileControllerGetDocumentDownloadLinkSuspense<TData = Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocumentDownloadLink>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    tenantPlayerFileId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocumentDownloadLink>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPlayerFileControllerGetDocumentDownloadLinkSuspense<TData = Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocumentDownloadLink>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    tenantPlayerFileId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocumentDownloadLink>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPlayerFileControllerGetDocumentDownloadLinkSuspense<TData = Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocumentDownloadLink>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    tenantPlayerFileId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocumentDownloadLink>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantPlayerFileControllerGetDocumentDownloadLinkSuspense<TData = Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocumentDownloadLink>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    tenantPlayerFileId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPlayerFileControllerGetDocumentDownloadLink>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantPlayerFileControllerGetDocumentDownloadLinkSuspenseQueryOptions(tenantId,playerOnTenantId,tenantPlayerFileId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantPlayerFileControllerCreateProfileImageUploadRequest = (
    tenantId: string,
    playerOnTenantId: string,
    tenantPlayerFileUploadPostDto: TenantPlayerFileUploadPostDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantPlayerFileUploadReturnDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/player/${playerOnTenantId}/file/uploadrequest`,
      tenantPlayerFileUploadPostDto,options
    );
  }



export const getTenantPlayerFileControllerCreateProfileImageUploadRequestMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerFileControllerCreateProfileImageUploadRequest>>, TError,{tenantId: string;playerOnTenantId: string;data: TenantPlayerFileUploadPostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerFileControllerCreateProfileImageUploadRequest>>, TError,{tenantId: string;playerOnTenantId: string;data: TenantPlayerFileUploadPostDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantPlayerFileControllerCreateProfileImageUploadRequest>>, {tenantId: string;playerOnTenantId: string;data: TenantPlayerFileUploadPostDto}> = (props) => {
          const {tenantId,playerOnTenantId,data} = props ?? {};

          return  tenantPlayerFileControllerCreateProfileImageUploadRequest(tenantId,playerOnTenantId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantPlayerFileControllerCreateProfileImageUploadRequestMutationResult = NonNullable<Awaited<ReturnType<typeof tenantPlayerFileControllerCreateProfileImageUploadRequest>>>
    export type TenantPlayerFileControllerCreateProfileImageUploadRequestMutationBody = TenantPlayerFileUploadPostDto
    export type TenantPlayerFileControllerCreateProfileImageUploadRequestMutationError = AxiosError<unknown>

    export const useTenantPlayerFileControllerCreateProfileImageUploadRequest = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerFileControllerCreateProfileImageUploadRequest>>, TError,{tenantId: string;playerOnTenantId: string;data: TenantPlayerFileUploadPostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantPlayerFileControllerCreateProfileImageUploadRequest>>,
        TError,
        {tenantId: string;playerOnTenantId: string;data: TenantPlayerFileUploadPostDto},
        TContext
      > => {

      const mutationOptions = getTenantPlayerFileControllerCreateProfileImageUploadRequestMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantPlayerFileControllerDeleteDocument = (
    tenantId: string,
    playerOnTenantId: string,
    tenantPlayerFileId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/v1/${tenantId}/player/${playerOnTenantId}/file/${tenantPlayerFileId}`,options
    );
  }



export const getTenantPlayerFileControllerDeleteDocumentMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerFileControllerDeleteDocument>>, TError,{tenantId: string;playerOnTenantId: string;tenantPlayerFileId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerFileControllerDeleteDocument>>, TError,{tenantId: string;playerOnTenantId: string;tenantPlayerFileId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantPlayerFileControllerDeleteDocument>>, {tenantId: string;playerOnTenantId: string;tenantPlayerFileId: string}> = (props) => {
          const {tenantId,playerOnTenantId,tenantPlayerFileId} = props ?? {};

          return  tenantPlayerFileControllerDeleteDocument(tenantId,playerOnTenantId,tenantPlayerFileId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantPlayerFileControllerDeleteDocumentMutationResult = NonNullable<Awaited<ReturnType<typeof tenantPlayerFileControllerDeleteDocument>>>
    
    export type TenantPlayerFileControllerDeleteDocumentMutationError = AxiosError<unknown>

    export const useTenantPlayerFileControllerDeleteDocument = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPlayerFileControllerDeleteDocument>>, TError,{tenantId: string;playerOnTenantId: string;tenantPlayerFileId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantPlayerFileControllerDeleteDocument>>,
        TError,
        {tenantId: string;playerOnTenantId: string;tenantPlayerFileId: string},
        TContext
      > => {

      const mutationOptions = getTenantPlayerFileControllerDeleteDocumentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const playerComparisonControllerGetCompetitionReferenceData = (
    wyscoutSeasonId: number,
    wyscoutCompetitionId: number,
    position: 'GK' | 'CB' | 'WB' | 'CM' | 'AM' | 'WF' | 'CF' | 'N/A', options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GetCompetitionReferenceDto>> => {
    
    return axios.get(
      `/v1/statistics/playercomparison/season/${wyscoutSeasonId}/competition/${wyscoutCompetitionId}/${position}`,options
    );
  }


export const getPlayerComparisonControllerGetCompetitionReferenceDataQueryKey = (wyscoutSeasonId: number,
    wyscoutCompetitionId: number,
    position: 'GK' | 'CB' | 'WB' | 'CM' | 'AM' | 'WF' | 'CF' | 'N/A',) => {
    return [`/v1/statistics/playercomparison/season/${wyscoutSeasonId}/competition/${wyscoutCompetitionId}/${position}`] as const;
    }

    
export const getPlayerComparisonControllerGetCompetitionReferenceDataQueryOptions = <TData = Awaited<ReturnType<typeof playerComparisonControllerGetCompetitionReferenceData>>, TError = AxiosError<unknown>>(wyscoutSeasonId: number,
    wyscoutCompetitionId: number,
    position: 'GK' | 'CB' | 'WB' | 'CM' | 'AM' | 'WF' | 'CF' | 'N/A', options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerComparisonControllerGetCompetitionReferenceData>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPlayerComparisonControllerGetCompetitionReferenceDataQueryKey(wyscoutSeasonId,wyscoutCompetitionId,position);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof playerComparisonControllerGetCompetitionReferenceData>>> = ({ signal }) => playerComparisonControllerGetCompetitionReferenceData(wyscoutSeasonId,wyscoutCompetitionId,position, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(wyscoutSeasonId && wyscoutCompetitionId && position), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof playerComparisonControllerGetCompetitionReferenceData>>, TError, TData> & { queryKey: QueryKey }
}

export type PlayerComparisonControllerGetCompetitionReferenceDataQueryResult = NonNullable<Awaited<ReturnType<typeof playerComparisonControllerGetCompetitionReferenceData>>>
export type PlayerComparisonControllerGetCompetitionReferenceDataQueryError = AxiosError<unknown>


export function usePlayerComparisonControllerGetCompetitionReferenceData<TData = Awaited<ReturnType<typeof playerComparisonControllerGetCompetitionReferenceData>>, TError = AxiosError<unknown>>(
 wyscoutSeasonId: number,
    wyscoutCompetitionId: number,
    position: 'GK' | 'CB' | 'WB' | 'CM' | 'AM' | 'WF' | 'CF' | 'N/A', options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerComparisonControllerGetCompetitionReferenceData>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof playerComparisonControllerGetCompetitionReferenceData>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerComparisonControllerGetCompetitionReferenceData<TData = Awaited<ReturnType<typeof playerComparisonControllerGetCompetitionReferenceData>>, TError = AxiosError<unknown>>(
 wyscoutSeasonId: number,
    wyscoutCompetitionId: number,
    position: 'GK' | 'CB' | 'WB' | 'CM' | 'AM' | 'WF' | 'CF' | 'N/A', options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerComparisonControllerGetCompetitionReferenceData>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof playerComparisonControllerGetCompetitionReferenceData>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerComparisonControllerGetCompetitionReferenceData<TData = Awaited<ReturnType<typeof playerComparisonControllerGetCompetitionReferenceData>>, TError = AxiosError<unknown>>(
 wyscoutSeasonId: number,
    wyscoutCompetitionId: number,
    position: 'GK' | 'CB' | 'WB' | 'CM' | 'AM' | 'WF' | 'CF' | 'N/A', options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerComparisonControllerGetCompetitionReferenceData>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function usePlayerComparisonControllerGetCompetitionReferenceData<TData = Awaited<ReturnType<typeof playerComparisonControllerGetCompetitionReferenceData>>, TError = AxiosError<unknown>>(
 wyscoutSeasonId: number,
    wyscoutCompetitionId: number,
    position: 'GK' | 'CB' | 'WB' | 'CM' | 'AM' | 'WF' | 'CF' | 'N/A', options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerComparisonControllerGetCompetitionReferenceData>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getPlayerComparisonControllerGetCompetitionReferenceDataQueryOptions(wyscoutSeasonId,wyscoutCompetitionId,position,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getPlayerComparisonControllerGetCompetitionReferenceDataSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof playerComparisonControllerGetCompetitionReferenceData>>, TError = AxiosError<unknown>>(wyscoutSeasonId: number,
    wyscoutCompetitionId: number,
    position: 'GK' | 'CB' | 'WB' | 'CM' | 'AM' | 'WF' | 'CF' | 'N/A', options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerComparisonControllerGetCompetitionReferenceData>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPlayerComparisonControllerGetCompetitionReferenceDataQueryKey(wyscoutSeasonId,wyscoutCompetitionId,position);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof playerComparisonControllerGetCompetitionReferenceData>>> = ({ signal }) => playerComparisonControllerGetCompetitionReferenceData(wyscoutSeasonId,wyscoutCompetitionId,position, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(wyscoutSeasonId && wyscoutCompetitionId && position), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerComparisonControllerGetCompetitionReferenceData>>, TError, TData> & { queryKey: QueryKey }
}

export type PlayerComparisonControllerGetCompetitionReferenceDataSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof playerComparisonControllerGetCompetitionReferenceData>>>
export type PlayerComparisonControllerGetCompetitionReferenceDataSuspenseQueryError = AxiosError<unknown>


export function usePlayerComparisonControllerGetCompetitionReferenceDataSuspense<TData = Awaited<ReturnType<typeof playerComparisonControllerGetCompetitionReferenceData>>, TError = AxiosError<unknown>>(
 wyscoutSeasonId: number,
    wyscoutCompetitionId: number,
    position: 'GK' | 'CB' | 'WB' | 'CM' | 'AM' | 'WF' | 'CF' | 'N/A', options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerComparisonControllerGetCompetitionReferenceData>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerComparisonControllerGetCompetitionReferenceDataSuspense<TData = Awaited<ReturnType<typeof playerComparisonControllerGetCompetitionReferenceData>>, TError = AxiosError<unknown>>(
 wyscoutSeasonId: number,
    wyscoutCompetitionId: number,
    position: 'GK' | 'CB' | 'WB' | 'CM' | 'AM' | 'WF' | 'CF' | 'N/A', options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerComparisonControllerGetCompetitionReferenceData>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerComparisonControllerGetCompetitionReferenceDataSuspense<TData = Awaited<ReturnType<typeof playerComparisonControllerGetCompetitionReferenceData>>, TError = AxiosError<unknown>>(
 wyscoutSeasonId: number,
    wyscoutCompetitionId: number,
    position: 'GK' | 'CB' | 'WB' | 'CM' | 'AM' | 'WF' | 'CF' | 'N/A', options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerComparisonControllerGetCompetitionReferenceData>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function usePlayerComparisonControllerGetCompetitionReferenceDataSuspense<TData = Awaited<ReturnType<typeof playerComparisonControllerGetCompetitionReferenceData>>, TError = AxiosError<unknown>>(
 wyscoutSeasonId: number,
    wyscoutCompetitionId: number,
    position: 'GK' | 'CB' | 'WB' | 'CM' | 'AM' | 'WF' | 'CF' | 'N/A', options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerComparisonControllerGetCompetitionReferenceData>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getPlayerComparisonControllerGetCompetitionReferenceDataSuspenseQueryOptions(wyscoutSeasonId,wyscoutCompetitionId,position,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const playerComparisonControllerGetPlayerComparison = (
    wyscoutSeasonId: number,
    wyscoutPlayerId: number,
    position: 'GK' | 'CB' | 'WB' | 'CM' | 'AM' | 'WF' | 'CF' | 'N/A', options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GetPlayerComparisonDto>> => {
    
    return axios.get(
      `/v1/statistics/playercomparison/season/${wyscoutSeasonId}/player/${wyscoutPlayerId}/${position}`,options
    );
  }


export const getPlayerComparisonControllerGetPlayerComparisonQueryKey = (wyscoutSeasonId: number,
    wyscoutPlayerId: number,
    position: 'GK' | 'CB' | 'WB' | 'CM' | 'AM' | 'WF' | 'CF' | 'N/A',) => {
    return [`/v1/statistics/playercomparison/season/${wyscoutSeasonId}/player/${wyscoutPlayerId}/${position}`] as const;
    }

    
export const getPlayerComparisonControllerGetPlayerComparisonQueryOptions = <TData = Awaited<ReturnType<typeof playerComparisonControllerGetPlayerComparison>>, TError = AxiosError<unknown>>(wyscoutSeasonId: number,
    wyscoutPlayerId: number,
    position: 'GK' | 'CB' | 'WB' | 'CM' | 'AM' | 'WF' | 'CF' | 'N/A', options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerComparisonControllerGetPlayerComparison>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPlayerComparisonControllerGetPlayerComparisonQueryKey(wyscoutSeasonId,wyscoutPlayerId,position);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof playerComparisonControllerGetPlayerComparison>>> = ({ signal }) => playerComparisonControllerGetPlayerComparison(wyscoutSeasonId,wyscoutPlayerId,position, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(wyscoutSeasonId && wyscoutPlayerId && position), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof playerComparisonControllerGetPlayerComparison>>, TError, TData> & { queryKey: QueryKey }
}

export type PlayerComparisonControllerGetPlayerComparisonQueryResult = NonNullable<Awaited<ReturnType<typeof playerComparisonControllerGetPlayerComparison>>>
export type PlayerComparisonControllerGetPlayerComparisonQueryError = AxiosError<unknown>


export function usePlayerComparisonControllerGetPlayerComparison<TData = Awaited<ReturnType<typeof playerComparisonControllerGetPlayerComparison>>, TError = AxiosError<unknown>>(
 wyscoutSeasonId: number,
    wyscoutPlayerId: number,
    position: 'GK' | 'CB' | 'WB' | 'CM' | 'AM' | 'WF' | 'CF' | 'N/A', options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerComparisonControllerGetPlayerComparison>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof playerComparisonControllerGetPlayerComparison>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerComparisonControllerGetPlayerComparison<TData = Awaited<ReturnType<typeof playerComparisonControllerGetPlayerComparison>>, TError = AxiosError<unknown>>(
 wyscoutSeasonId: number,
    wyscoutPlayerId: number,
    position: 'GK' | 'CB' | 'WB' | 'CM' | 'AM' | 'WF' | 'CF' | 'N/A', options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerComparisonControllerGetPlayerComparison>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof playerComparisonControllerGetPlayerComparison>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerComparisonControllerGetPlayerComparison<TData = Awaited<ReturnType<typeof playerComparisonControllerGetPlayerComparison>>, TError = AxiosError<unknown>>(
 wyscoutSeasonId: number,
    wyscoutPlayerId: number,
    position: 'GK' | 'CB' | 'WB' | 'CM' | 'AM' | 'WF' | 'CF' | 'N/A', options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerComparisonControllerGetPlayerComparison>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function usePlayerComparisonControllerGetPlayerComparison<TData = Awaited<ReturnType<typeof playerComparisonControllerGetPlayerComparison>>, TError = AxiosError<unknown>>(
 wyscoutSeasonId: number,
    wyscoutPlayerId: number,
    position: 'GK' | 'CB' | 'WB' | 'CM' | 'AM' | 'WF' | 'CF' | 'N/A', options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerComparisonControllerGetPlayerComparison>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getPlayerComparisonControllerGetPlayerComparisonQueryOptions(wyscoutSeasonId,wyscoutPlayerId,position,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getPlayerComparisonControllerGetPlayerComparisonSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof playerComparisonControllerGetPlayerComparison>>, TError = AxiosError<unknown>>(wyscoutSeasonId: number,
    wyscoutPlayerId: number,
    position: 'GK' | 'CB' | 'WB' | 'CM' | 'AM' | 'WF' | 'CF' | 'N/A', options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerComparisonControllerGetPlayerComparison>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPlayerComparisonControllerGetPlayerComparisonQueryKey(wyscoutSeasonId,wyscoutPlayerId,position);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof playerComparisonControllerGetPlayerComparison>>> = ({ signal }) => playerComparisonControllerGetPlayerComparison(wyscoutSeasonId,wyscoutPlayerId,position, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(wyscoutSeasonId && wyscoutPlayerId && position), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerComparisonControllerGetPlayerComparison>>, TError, TData> & { queryKey: QueryKey }
}

export type PlayerComparisonControllerGetPlayerComparisonSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof playerComparisonControllerGetPlayerComparison>>>
export type PlayerComparisonControllerGetPlayerComparisonSuspenseQueryError = AxiosError<unknown>


export function usePlayerComparisonControllerGetPlayerComparisonSuspense<TData = Awaited<ReturnType<typeof playerComparisonControllerGetPlayerComparison>>, TError = AxiosError<unknown>>(
 wyscoutSeasonId: number,
    wyscoutPlayerId: number,
    position: 'GK' | 'CB' | 'WB' | 'CM' | 'AM' | 'WF' | 'CF' | 'N/A', options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerComparisonControllerGetPlayerComparison>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerComparisonControllerGetPlayerComparisonSuspense<TData = Awaited<ReturnType<typeof playerComparisonControllerGetPlayerComparison>>, TError = AxiosError<unknown>>(
 wyscoutSeasonId: number,
    wyscoutPlayerId: number,
    position: 'GK' | 'CB' | 'WB' | 'CM' | 'AM' | 'WF' | 'CF' | 'N/A', options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerComparisonControllerGetPlayerComparison>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerComparisonControllerGetPlayerComparisonSuspense<TData = Awaited<ReturnType<typeof playerComparisonControllerGetPlayerComparison>>, TError = AxiosError<unknown>>(
 wyscoutSeasonId: number,
    wyscoutPlayerId: number,
    position: 'GK' | 'CB' | 'WB' | 'CM' | 'AM' | 'WF' | 'CF' | 'N/A', options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerComparisonControllerGetPlayerComparison>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function usePlayerComparisonControllerGetPlayerComparisonSuspense<TData = Awaited<ReturnType<typeof playerComparisonControllerGetPlayerComparison>>, TError = AxiosError<unknown>>(
 wyscoutSeasonId: number,
    wyscoutPlayerId: number,
    position: 'GK' | 'CB' | 'WB' | 'CM' | 'AM' | 'WF' | 'CF' | 'N/A', options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerComparisonControllerGetPlayerComparison>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getPlayerComparisonControllerGetPlayerComparisonSuspenseQueryOptions(wyscoutSeasonId,wyscoutPlayerId,position,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const scoutingPlayerTableControllerGetPlayerOverview = (
    tenantId: string,
    environmentId: string,
    scoutingPlayerTablePostBodyParamsDto: ScoutingPlayerTablePostBodyParamsDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ScoutingPlayerTablePostReturnValueDto[]>> => {
    
    return axios.post(
      `/v1/${tenantId}/${environmentId}/scoutingplayertable`,
      scoutingPlayerTablePostBodyParamsDto,options
    );
  }



export const getScoutingPlayerTableControllerGetPlayerOverviewMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerOverview>>, TError,{tenantId: string;environmentId: string;data: ScoutingPlayerTablePostBodyParamsDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerOverview>>, TError,{tenantId: string;environmentId: string;data: ScoutingPlayerTablePostBodyParamsDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerOverview>>, {tenantId: string;environmentId: string;data: ScoutingPlayerTablePostBodyParamsDto}> = (props) => {
          const {tenantId,environmentId,data} = props ?? {};

          return  scoutingPlayerTableControllerGetPlayerOverview(tenantId,environmentId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ScoutingPlayerTableControllerGetPlayerOverviewMutationResult = NonNullable<Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerOverview>>>
    export type ScoutingPlayerTableControllerGetPlayerOverviewMutationBody = ScoutingPlayerTablePostBodyParamsDto
    export type ScoutingPlayerTableControllerGetPlayerOverviewMutationError = AxiosError<unknown>

    export const useScoutingPlayerTableControllerGetPlayerOverview = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerOverview>>, TError,{tenantId: string;environmentId: string;data: ScoutingPlayerTablePostBodyParamsDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerOverview>>,
        TError,
        {tenantId: string;environmentId: string;data: ScoutingPlayerTablePostBodyParamsDto},
        TContext
      > => {

      const mutationOptions = getScoutingPlayerTableControllerGetPlayerOverviewMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const scoutingPlayerTableControllerGetPlayerSeasonsByYear = (
    tenantId: string,
    environmentId: string,
    params: ScoutingPlayerTableControllerGetPlayerSeasonsByYearParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ScoutingPlayerTableGetResponseDto>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/scoutingplayertable`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getScoutingPlayerTableControllerGetPlayerSeasonsByYearQueryKey = (tenantId: string,
    environmentId: string,
    params: ScoutingPlayerTableControllerGetPlayerSeasonsByYearParams,) => {
    return [`/v1/${tenantId}/${environmentId}/scoutingplayertable`, ...(params ? [params]: [])] as const;
    }

    
export const getScoutingPlayerTableControllerGetPlayerSeasonsByYearQueryOptions = <TData = Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerSeasonsByYear>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    params: ScoutingPlayerTableControllerGetPlayerSeasonsByYearParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerSeasonsByYear>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getScoutingPlayerTableControllerGetPlayerSeasonsByYearQueryKey(tenantId,environmentId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerSeasonsByYear>>> = ({ signal }) => scoutingPlayerTableControllerGetPlayerSeasonsByYear(tenantId,environmentId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerSeasonsByYear>>, TError, TData> & { queryKey: QueryKey }
}

export type ScoutingPlayerTableControllerGetPlayerSeasonsByYearQueryResult = NonNullable<Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerSeasonsByYear>>>
export type ScoutingPlayerTableControllerGetPlayerSeasonsByYearQueryError = AxiosError<unknown>


export function useScoutingPlayerTableControllerGetPlayerSeasonsByYear<TData = Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerSeasonsByYear>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: ScoutingPlayerTableControllerGetPlayerSeasonsByYearParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerSeasonsByYear>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerSeasonsByYear>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingPlayerTableControllerGetPlayerSeasonsByYear<TData = Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerSeasonsByYear>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: ScoutingPlayerTableControllerGetPlayerSeasonsByYearParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerSeasonsByYear>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerSeasonsByYear>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingPlayerTableControllerGetPlayerSeasonsByYear<TData = Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerSeasonsByYear>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: ScoutingPlayerTableControllerGetPlayerSeasonsByYearParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerSeasonsByYear>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useScoutingPlayerTableControllerGetPlayerSeasonsByYear<TData = Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerSeasonsByYear>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: ScoutingPlayerTableControllerGetPlayerSeasonsByYearParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerSeasonsByYear>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getScoutingPlayerTableControllerGetPlayerSeasonsByYearQueryOptions(tenantId,environmentId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getScoutingPlayerTableControllerGetPlayerSeasonsByYearSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerSeasonsByYear>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    params: ScoutingPlayerTableControllerGetPlayerSeasonsByYearParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerSeasonsByYear>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getScoutingPlayerTableControllerGetPlayerSeasonsByYearQueryKey(tenantId,environmentId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerSeasonsByYear>>> = ({ signal }) => scoutingPlayerTableControllerGetPlayerSeasonsByYear(tenantId,environmentId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerSeasonsByYear>>, TError, TData> & { queryKey: QueryKey }
}

export type ScoutingPlayerTableControllerGetPlayerSeasonsByYearSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerSeasonsByYear>>>
export type ScoutingPlayerTableControllerGetPlayerSeasonsByYearSuspenseQueryError = AxiosError<unknown>


export function useScoutingPlayerTableControllerGetPlayerSeasonsByYearSuspense<TData = Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerSeasonsByYear>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: ScoutingPlayerTableControllerGetPlayerSeasonsByYearParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerSeasonsByYear>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingPlayerTableControllerGetPlayerSeasonsByYearSuspense<TData = Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerSeasonsByYear>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: ScoutingPlayerTableControllerGetPlayerSeasonsByYearParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerSeasonsByYear>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingPlayerTableControllerGetPlayerSeasonsByYearSuspense<TData = Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerSeasonsByYear>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: ScoutingPlayerTableControllerGetPlayerSeasonsByYearParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerSeasonsByYear>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useScoutingPlayerTableControllerGetPlayerSeasonsByYearSuspense<TData = Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerSeasonsByYear>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: ScoutingPlayerTableControllerGetPlayerSeasonsByYearParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerSeasonsByYear>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getScoutingPlayerTableControllerGetPlayerSeasonsByYearSuspenseQueryOptions(tenantId,environmentId,params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const scoutingPlayerTableControllerGetPlayerSeasonByIds = (
    tenantId: string,
    environmentId: string,
    scoutingPlayerTableGetByIdsDto: ScoutingPlayerTableGetByIdsDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ScoutingPlayerTablePostReturnValueDto[]>> => {
    
    return axios.post(
      `/v1/${tenantId}/${environmentId}/scoutingplayertable/pinned`,
      scoutingPlayerTableGetByIdsDto,options
    );
  }



export const getScoutingPlayerTableControllerGetPlayerSeasonByIdsMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerSeasonByIds>>, TError,{tenantId: string;environmentId: string;data: ScoutingPlayerTableGetByIdsDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerSeasonByIds>>, TError,{tenantId: string;environmentId: string;data: ScoutingPlayerTableGetByIdsDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerSeasonByIds>>, {tenantId: string;environmentId: string;data: ScoutingPlayerTableGetByIdsDto}> = (props) => {
          const {tenantId,environmentId,data} = props ?? {};

          return  scoutingPlayerTableControllerGetPlayerSeasonByIds(tenantId,environmentId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ScoutingPlayerTableControllerGetPlayerSeasonByIdsMutationResult = NonNullable<Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerSeasonByIds>>>
    export type ScoutingPlayerTableControllerGetPlayerSeasonByIdsMutationBody = ScoutingPlayerTableGetByIdsDto
    export type ScoutingPlayerTableControllerGetPlayerSeasonByIdsMutationError = AxiosError<unknown>

    export const useScoutingPlayerTableControllerGetPlayerSeasonByIds = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerSeasonByIds>>, TError,{tenantId: string;environmentId: string;data: ScoutingPlayerTableGetByIdsDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof scoutingPlayerTableControllerGetPlayerSeasonByIds>>,
        TError,
        {tenantId: string;environmentId: string;data: ScoutingPlayerTableGetByIdsDto},
        TContext
      > => {

      const mutationOptions = getScoutingPlayerTableControllerGetPlayerSeasonByIdsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const scoutingPlayerTableControllerCreateLayoutConfig = (
    tenantId: string,
    environmentId: string,
    createScoutingPlayerTableConfigLayoutDto: CreateScoutingPlayerTableConfigLayoutDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ScoutingPlayerTableConfigLayoutDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/${environmentId}/scoutingplayertable/config/layouts`,
      createScoutingPlayerTableConfigLayoutDto,options
    );
  }



export const getScoutingPlayerTableControllerCreateLayoutConfigMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerCreateLayoutConfig>>, TError,{tenantId: string;environmentId: string;data: CreateScoutingPlayerTableConfigLayoutDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerCreateLayoutConfig>>, TError,{tenantId: string;environmentId: string;data: CreateScoutingPlayerTableConfigLayoutDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof scoutingPlayerTableControllerCreateLayoutConfig>>, {tenantId: string;environmentId: string;data: CreateScoutingPlayerTableConfigLayoutDto}> = (props) => {
          const {tenantId,environmentId,data} = props ?? {};

          return  scoutingPlayerTableControllerCreateLayoutConfig(tenantId,environmentId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ScoutingPlayerTableControllerCreateLayoutConfigMutationResult = NonNullable<Awaited<ReturnType<typeof scoutingPlayerTableControllerCreateLayoutConfig>>>
    export type ScoutingPlayerTableControllerCreateLayoutConfigMutationBody = CreateScoutingPlayerTableConfigLayoutDto
    export type ScoutingPlayerTableControllerCreateLayoutConfigMutationError = AxiosError<unknown>

    export const useScoutingPlayerTableControllerCreateLayoutConfig = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerCreateLayoutConfig>>, TError,{tenantId: string;environmentId: string;data: CreateScoutingPlayerTableConfigLayoutDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof scoutingPlayerTableControllerCreateLayoutConfig>>,
        TError,
        {tenantId: string;environmentId: string;data: CreateScoutingPlayerTableConfigLayoutDto},
        TContext
      > => {

      const mutationOptions = getScoutingPlayerTableControllerCreateLayoutConfigMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const scoutingPlayerTableControllerFindAllLayoutConfigs = (
    tenantId: string,
    environmentId: string,
    params: ScoutingPlayerTableControllerFindAllLayoutConfigsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ScoutingPlayerTableConfigLayoutDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/scoutingplayertable/config/layouts`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getScoutingPlayerTableControllerFindAllLayoutConfigsQueryKey = (tenantId: string,
    environmentId: string,
    params: ScoutingPlayerTableControllerFindAllLayoutConfigsParams,) => {
    return [`/v1/${tenantId}/${environmentId}/scoutingplayertable/config/layouts`, ...(params ? [params]: [])] as const;
    }

    
export const getScoutingPlayerTableControllerFindAllLayoutConfigsQueryOptions = <TData = Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllLayoutConfigs>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    params: ScoutingPlayerTableControllerFindAllLayoutConfigsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllLayoutConfigs>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getScoutingPlayerTableControllerFindAllLayoutConfigsQueryKey(tenantId,environmentId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllLayoutConfigs>>> = ({ signal }) => scoutingPlayerTableControllerFindAllLayoutConfigs(tenantId,environmentId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllLayoutConfigs>>, TError, TData> & { queryKey: QueryKey }
}

export type ScoutingPlayerTableControllerFindAllLayoutConfigsQueryResult = NonNullable<Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllLayoutConfigs>>>
export type ScoutingPlayerTableControllerFindAllLayoutConfigsQueryError = AxiosError<unknown>


export function useScoutingPlayerTableControllerFindAllLayoutConfigs<TData = Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllLayoutConfigs>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: ScoutingPlayerTableControllerFindAllLayoutConfigsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllLayoutConfigs>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllLayoutConfigs>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingPlayerTableControllerFindAllLayoutConfigs<TData = Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllLayoutConfigs>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: ScoutingPlayerTableControllerFindAllLayoutConfigsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllLayoutConfigs>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllLayoutConfigs>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingPlayerTableControllerFindAllLayoutConfigs<TData = Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllLayoutConfigs>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: ScoutingPlayerTableControllerFindAllLayoutConfigsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllLayoutConfigs>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useScoutingPlayerTableControllerFindAllLayoutConfigs<TData = Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllLayoutConfigs>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: ScoutingPlayerTableControllerFindAllLayoutConfigsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllLayoutConfigs>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getScoutingPlayerTableControllerFindAllLayoutConfigsQueryOptions(tenantId,environmentId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getScoutingPlayerTableControllerFindAllLayoutConfigsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllLayoutConfigs>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    params: ScoutingPlayerTableControllerFindAllLayoutConfigsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllLayoutConfigs>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getScoutingPlayerTableControllerFindAllLayoutConfigsQueryKey(tenantId,environmentId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllLayoutConfigs>>> = ({ signal }) => scoutingPlayerTableControllerFindAllLayoutConfigs(tenantId,environmentId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllLayoutConfigs>>, TError, TData> & { queryKey: QueryKey }
}

export type ScoutingPlayerTableControllerFindAllLayoutConfigsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllLayoutConfigs>>>
export type ScoutingPlayerTableControllerFindAllLayoutConfigsSuspenseQueryError = AxiosError<unknown>


export function useScoutingPlayerTableControllerFindAllLayoutConfigsSuspense<TData = Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllLayoutConfigs>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: ScoutingPlayerTableControllerFindAllLayoutConfigsParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllLayoutConfigs>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingPlayerTableControllerFindAllLayoutConfigsSuspense<TData = Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllLayoutConfigs>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: ScoutingPlayerTableControllerFindAllLayoutConfigsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllLayoutConfigs>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingPlayerTableControllerFindAllLayoutConfigsSuspense<TData = Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllLayoutConfigs>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: ScoutingPlayerTableControllerFindAllLayoutConfigsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllLayoutConfigs>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useScoutingPlayerTableControllerFindAllLayoutConfigsSuspense<TData = Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllLayoutConfigs>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: ScoutingPlayerTableControllerFindAllLayoutConfigsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllLayoutConfigs>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getScoutingPlayerTableControllerFindAllLayoutConfigsSuspenseQueryOptions(tenantId,environmentId,params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const scoutingPlayerTableControllerCreateFiltersConfig = (
    tenantId: string,
    environmentId: string,
    createScoutingPlayerTableConfigFilterDto: CreateScoutingPlayerTableConfigFilterDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ScoutingPlayerTableConfigFilterDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/${environmentId}/scoutingplayertable/config/filters`,
      createScoutingPlayerTableConfigFilterDto,options
    );
  }



export const getScoutingPlayerTableControllerCreateFiltersConfigMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerCreateFiltersConfig>>, TError,{tenantId: string;environmentId: string;data: CreateScoutingPlayerTableConfigFilterDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerCreateFiltersConfig>>, TError,{tenantId: string;environmentId: string;data: CreateScoutingPlayerTableConfigFilterDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof scoutingPlayerTableControllerCreateFiltersConfig>>, {tenantId: string;environmentId: string;data: CreateScoutingPlayerTableConfigFilterDto}> = (props) => {
          const {tenantId,environmentId,data} = props ?? {};

          return  scoutingPlayerTableControllerCreateFiltersConfig(tenantId,environmentId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ScoutingPlayerTableControllerCreateFiltersConfigMutationResult = NonNullable<Awaited<ReturnType<typeof scoutingPlayerTableControllerCreateFiltersConfig>>>
    export type ScoutingPlayerTableControllerCreateFiltersConfigMutationBody = CreateScoutingPlayerTableConfigFilterDto
    export type ScoutingPlayerTableControllerCreateFiltersConfigMutationError = AxiosError<unknown>

    export const useScoutingPlayerTableControllerCreateFiltersConfig = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerCreateFiltersConfig>>, TError,{tenantId: string;environmentId: string;data: CreateScoutingPlayerTableConfigFilterDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof scoutingPlayerTableControllerCreateFiltersConfig>>,
        TError,
        {tenantId: string;environmentId: string;data: CreateScoutingPlayerTableConfigFilterDto},
        TContext
      > => {

      const mutationOptions = getScoutingPlayerTableControllerCreateFiltersConfigMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const scoutingPlayerTableControllerFindAllFilterConfigs = (
    tenantId: string,
    environmentId: string,
    params: ScoutingPlayerTableControllerFindAllFilterConfigsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ScoutingPlayerTableConfigFilterDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/scoutingplayertable/config/filters`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getScoutingPlayerTableControllerFindAllFilterConfigsQueryKey = (tenantId: string,
    environmentId: string,
    params: ScoutingPlayerTableControllerFindAllFilterConfigsParams,) => {
    return [`/v1/${tenantId}/${environmentId}/scoutingplayertable/config/filters`, ...(params ? [params]: [])] as const;
    }

    
export const getScoutingPlayerTableControllerFindAllFilterConfigsQueryOptions = <TData = Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllFilterConfigs>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    params: ScoutingPlayerTableControllerFindAllFilterConfigsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllFilterConfigs>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getScoutingPlayerTableControllerFindAllFilterConfigsQueryKey(tenantId,environmentId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllFilterConfigs>>> = ({ signal }) => scoutingPlayerTableControllerFindAllFilterConfigs(tenantId,environmentId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllFilterConfigs>>, TError, TData> & { queryKey: QueryKey }
}

export type ScoutingPlayerTableControllerFindAllFilterConfigsQueryResult = NonNullable<Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllFilterConfigs>>>
export type ScoutingPlayerTableControllerFindAllFilterConfigsQueryError = AxiosError<unknown>


export function useScoutingPlayerTableControllerFindAllFilterConfigs<TData = Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllFilterConfigs>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: ScoutingPlayerTableControllerFindAllFilterConfigsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllFilterConfigs>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllFilterConfigs>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingPlayerTableControllerFindAllFilterConfigs<TData = Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllFilterConfigs>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: ScoutingPlayerTableControllerFindAllFilterConfigsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllFilterConfigs>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllFilterConfigs>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingPlayerTableControllerFindAllFilterConfigs<TData = Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllFilterConfigs>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: ScoutingPlayerTableControllerFindAllFilterConfigsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllFilterConfigs>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useScoutingPlayerTableControllerFindAllFilterConfigs<TData = Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllFilterConfigs>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: ScoutingPlayerTableControllerFindAllFilterConfigsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllFilterConfigs>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getScoutingPlayerTableControllerFindAllFilterConfigsQueryOptions(tenantId,environmentId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getScoutingPlayerTableControllerFindAllFilterConfigsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllFilterConfigs>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    params: ScoutingPlayerTableControllerFindAllFilterConfigsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllFilterConfigs>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getScoutingPlayerTableControllerFindAllFilterConfigsQueryKey(tenantId,environmentId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllFilterConfigs>>> = ({ signal }) => scoutingPlayerTableControllerFindAllFilterConfigs(tenantId,environmentId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllFilterConfigs>>, TError, TData> & { queryKey: QueryKey }
}

export type ScoutingPlayerTableControllerFindAllFilterConfigsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllFilterConfigs>>>
export type ScoutingPlayerTableControllerFindAllFilterConfigsSuspenseQueryError = AxiosError<unknown>


export function useScoutingPlayerTableControllerFindAllFilterConfigsSuspense<TData = Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllFilterConfigs>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: ScoutingPlayerTableControllerFindAllFilterConfigsParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllFilterConfigs>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingPlayerTableControllerFindAllFilterConfigsSuspense<TData = Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllFilterConfigs>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: ScoutingPlayerTableControllerFindAllFilterConfigsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllFilterConfigs>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingPlayerTableControllerFindAllFilterConfigsSuspense<TData = Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllFilterConfigs>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: ScoutingPlayerTableControllerFindAllFilterConfigsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllFilterConfigs>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useScoutingPlayerTableControllerFindAllFilterConfigsSuspense<TData = Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllFilterConfigs>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: ScoutingPlayerTableControllerFindAllFilterConfigsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerFindAllFilterConfigs>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getScoutingPlayerTableControllerFindAllFilterConfigsSuspenseQueryOptions(tenantId,environmentId,params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const scoutingPlayerTableControllerDeleteFilterConfig = (
    tenantId: string,
    environmentId: string,
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ScoutingPlayerTableConfigFilterDto>> => {
    
    return axios.delete(
      `/v1/${tenantId}/${environmentId}/scoutingplayertable/config/filters/${id}`,options
    );
  }



export const getScoutingPlayerTableControllerDeleteFilterConfigMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerDeleteFilterConfig>>, TError,{tenantId: string;environmentId: string;id: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerDeleteFilterConfig>>, TError,{tenantId: string;environmentId: string;id: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof scoutingPlayerTableControllerDeleteFilterConfig>>, {tenantId: string;environmentId: string;id: string}> = (props) => {
          const {tenantId,environmentId,id} = props ?? {};

          return  scoutingPlayerTableControllerDeleteFilterConfig(tenantId,environmentId,id,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ScoutingPlayerTableControllerDeleteFilterConfigMutationResult = NonNullable<Awaited<ReturnType<typeof scoutingPlayerTableControllerDeleteFilterConfig>>>
    
    export type ScoutingPlayerTableControllerDeleteFilterConfigMutationError = AxiosError<unknown>

    export const useScoutingPlayerTableControllerDeleteFilterConfig = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerDeleteFilterConfig>>, TError,{tenantId: string;environmentId: string;id: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof scoutingPlayerTableControllerDeleteFilterConfig>>,
        TError,
        {tenantId: string;environmentId: string;id: string},
        TContext
      > => {

      const mutationOptions = getScoutingPlayerTableControllerDeleteFilterConfigMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const scoutingPlayerTableControllerDeleteLayoutConfig = (
    tenantId: string,
    environmentId: string,
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ScoutingPlayerTableConfigLayoutDto>> => {
    
    return axios.delete(
      `/v1/${tenantId}/${environmentId}/scoutingplayertable/config/layouts/${id}`,options
    );
  }



export const getScoutingPlayerTableControllerDeleteLayoutConfigMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerDeleteLayoutConfig>>, TError,{tenantId: string;environmentId: string;id: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerDeleteLayoutConfig>>, TError,{tenantId: string;environmentId: string;id: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof scoutingPlayerTableControllerDeleteLayoutConfig>>, {tenantId: string;environmentId: string;id: string}> = (props) => {
          const {tenantId,environmentId,id} = props ?? {};

          return  scoutingPlayerTableControllerDeleteLayoutConfig(tenantId,environmentId,id,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ScoutingPlayerTableControllerDeleteLayoutConfigMutationResult = NonNullable<Awaited<ReturnType<typeof scoutingPlayerTableControllerDeleteLayoutConfig>>>
    
    export type ScoutingPlayerTableControllerDeleteLayoutConfigMutationError = AxiosError<unknown>

    export const useScoutingPlayerTableControllerDeleteLayoutConfig = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingPlayerTableControllerDeleteLayoutConfig>>, TError,{tenantId: string;environmentId: string;id: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof scoutingPlayerTableControllerDeleteLayoutConfig>>,
        TError,
        {tenantId: string;environmentId: string;id: string},
        TContext
      > => {

      const mutationOptions = getScoutingPlayerTableControllerDeleteLayoutConfigMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const transfermarktControllerGetProposedTransfermarktTeamMatching = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TransfermarktTeamMatcherGetDto>> => {
    
    return axios.get(
      `/v1/transfermarkt/matching/transfermarkt/team`,options
    );
  }


export const getTransfermarktControllerGetProposedTransfermarktTeamMatchingQueryKey = () => {
    return [`/v1/transfermarkt/matching/transfermarkt/team`] as const;
    }

    
export const getTransfermarktControllerGetProposedTransfermarktTeamMatchingQueryOptions = <TData = Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktTeamMatching>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktTeamMatching>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTransfermarktControllerGetProposedTransfermarktTeamMatchingQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktTeamMatching>>> = ({ signal }) => transfermarktControllerGetProposedTransfermarktTeamMatching({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktTeamMatching>>, TError, TData> & { queryKey: QueryKey }
}

export type TransfermarktControllerGetProposedTransfermarktTeamMatchingQueryResult = NonNullable<Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktTeamMatching>>>
export type TransfermarktControllerGetProposedTransfermarktTeamMatchingQueryError = AxiosError<unknown>


export function useTransfermarktControllerGetProposedTransfermarktTeamMatching<TData = Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktTeamMatching>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktTeamMatching>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktTeamMatching>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTransfermarktControllerGetProposedTransfermarktTeamMatching<TData = Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktTeamMatching>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktTeamMatching>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktTeamMatching>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTransfermarktControllerGetProposedTransfermarktTeamMatching<TData = Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktTeamMatching>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktTeamMatching>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTransfermarktControllerGetProposedTransfermarktTeamMatching<TData = Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktTeamMatching>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktTeamMatching>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTransfermarktControllerGetProposedTransfermarktTeamMatchingQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTransfermarktControllerGetProposedTransfermarktTeamMatchingSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktTeamMatching>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktTeamMatching>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTransfermarktControllerGetProposedTransfermarktTeamMatchingQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktTeamMatching>>> = ({ signal }) => transfermarktControllerGetProposedTransfermarktTeamMatching({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktTeamMatching>>, TError, TData> & { queryKey: QueryKey }
}

export type TransfermarktControllerGetProposedTransfermarktTeamMatchingSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktTeamMatching>>>
export type TransfermarktControllerGetProposedTransfermarktTeamMatchingSuspenseQueryError = AxiosError<unknown>


export function useTransfermarktControllerGetProposedTransfermarktTeamMatchingSuspense<TData = Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktTeamMatching>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktTeamMatching>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTransfermarktControllerGetProposedTransfermarktTeamMatchingSuspense<TData = Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktTeamMatching>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktTeamMatching>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTransfermarktControllerGetProposedTransfermarktTeamMatchingSuspense<TData = Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktTeamMatching>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktTeamMatching>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTransfermarktControllerGetProposedTransfermarktTeamMatchingSuspense<TData = Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktTeamMatching>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktTeamMatching>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTransfermarktControllerGetProposedTransfermarktTeamMatchingSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const transfermarktControllerApproveProposedTransfermarktTeamMatching = (
    transfermarktTeamMatcherApprovePostDto: TransfermarktTeamMatcherApprovePostDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/v1/transfermarkt/matching/transfermarkt/team/approve`,
      transfermarktTeamMatcherApprovePostDto,options
    );
  }



export const getTransfermarktControllerApproveProposedTransfermarktTeamMatchingMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof transfermarktControllerApproveProposedTransfermarktTeamMatching>>, TError,{data: TransfermarktTeamMatcherApprovePostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof transfermarktControllerApproveProposedTransfermarktTeamMatching>>, TError,{data: TransfermarktTeamMatcherApprovePostDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof transfermarktControllerApproveProposedTransfermarktTeamMatching>>, {data: TransfermarktTeamMatcherApprovePostDto}> = (props) => {
          const {data} = props ?? {};

          return  transfermarktControllerApproveProposedTransfermarktTeamMatching(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TransfermarktControllerApproveProposedTransfermarktTeamMatchingMutationResult = NonNullable<Awaited<ReturnType<typeof transfermarktControllerApproveProposedTransfermarktTeamMatching>>>
    export type TransfermarktControllerApproveProposedTransfermarktTeamMatchingMutationBody = TransfermarktTeamMatcherApprovePostDto
    export type TransfermarktControllerApproveProposedTransfermarktTeamMatchingMutationError = AxiosError<unknown>

    export const useTransfermarktControllerApproveProposedTransfermarktTeamMatching = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof transfermarktControllerApproveProposedTransfermarktTeamMatching>>, TError,{data: TransfermarktTeamMatcherApprovePostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof transfermarktControllerApproveProposedTransfermarktTeamMatching>>,
        TError,
        {data: TransfermarktTeamMatcherApprovePostDto},
        TContext
      > => {

      const mutationOptions = getTransfermarktControllerApproveProposedTransfermarktTeamMatchingMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const transfermarktControllerDeclineProposedTransfermarktTeamMatching = (
    transfermarktTeamMatcherApprovePostDto: TransfermarktTeamMatcherApprovePostDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/v1/transfermarkt/matching/transfermarkt/team/decline`,
      transfermarktTeamMatcherApprovePostDto,options
    );
  }



export const getTransfermarktControllerDeclineProposedTransfermarktTeamMatchingMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof transfermarktControllerDeclineProposedTransfermarktTeamMatching>>, TError,{data: TransfermarktTeamMatcherApprovePostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof transfermarktControllerDeclineProposedTransfermarktTeamMatching>>, TError,{data: TransfermarktTeamMatcherApprovePostDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof transfermarktControllerDeclineProposedTransfermarktTeamMatching>>, {data: TransfermarktTeamMatcherApprovePostDto}> = (props) => {
          const {data} = props ?? {};

          return  transfermarktControllerDeclineProposedTransfermarktTeamMatching(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TransfermarktControllerDeclineProposedTransfermarktTeamMatchingMutationResult = NonNullable<Awaited<ReturnType<typeof transfermarktControllerDeclineProposedTransfermarktTeamMatching>>>
    export type TransfermarktControllerDeclineProposedTransfermarktTeamMatchingMutationBody = TransfermarktTeamMatcherApprovePostDto
    export type TransfermarktControllerDeclineProposedTransfermarktTeamMatchingMutationError = AxiosError<unknown>

    export const useTransfermarktControllerDeclineProposedTransfermarktTeamMatching = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof transfermarktControllerDeclineProposedTransfermarktTeamMatching>>, TError,{data: TransfermarktTeamMatcherApprovePostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof transfermarktControllerDeclineProposedTransfermarktTeamMatching>>,
        TError,
        {data: TransfermarktTeamMatcherApprovePostDto},
        TContext
      > => {

      const mutationOptions = getTransfermarktControllerDeclineProposedTransfermarktTeamMatchingMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const transfermarktControllerGetProposedTransfermarktPlayerMatching = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TransfermarktPlayerMatcherGetDto>> => {
    
    return axios.get(
      `/v1/transfermarkt/matching/transfermarkt/player`,options
    );
  }


export const getTransfermarktControllerGetProposedTransfermarktPlayerMatchingQueryKey = () => {
    return [`/v1/transfermarkt/matching/transfermarkt/player`] as const;
    }

    
export const getTransfermarktControllerGetProposedTransfermarktPlayerMatchingQueryOptions = <TData = Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktPlayerMatching>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktPlayerMatching>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTransfermarktControllerGetProposedTransfermarktPlayerMatchingQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktPlayerMatching>>> = ({ signal }) => transfermarktControllerGetProposedTransfermarktPlayerMatching({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktPlayerMatching>>, TError, TData> & { queryKey: QueryKey }
}

export type TransfermarktControllerGetProposedTransfermarktPlayerMatchingQueryResult = NonNullable<Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktPlayerMatching>>>
export type TransfermarktControllerGetProposedTransfermarktPlayerMatchingQueryError = AxiosError<unknown>


export function useTransfermarktControllerGetProposedTransfermarktPlayerMatching<TData = Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktPlayerMatching>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktPlayerMatching>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktPlayerMatching>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTransfermarktControllerGetProposedTransfermarktPlayerMatching<TData = Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktPlayerMatching>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktPlayerMatching>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktPlayerMatching>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTransfermarktControllerGetProposedTransfermarktPlayerMatching<TData = Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktPlayerMatching>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktPlayerMatching>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTransfermarktControllerGetProposedTransfermarktPlayerMatching<TData = Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktPlayerMatching>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktPlayerMatching>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTransfermarktControllerGetProposedTransfermarktPlayerMatchingQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTransfermarktControllerGetProposedTransfermarktPlayerMatchingSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktPlayerMatching>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktPlayerMatching>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTransfermarktControllerGetProposedTransfermarktPlayerMatchingQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktPlayerMatching>>> = ({ signal }) => transfermarktControllerGetProposedTransfermarktPlayerMatching({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktPlayerMatching>>, TError, TData> & { queryKey: QueryKey }
}

export type TransfermarktControllerGetProposedTransfermarktPlayerMatchingSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktPlayerMatching>>>
export type TransfermarktControllerGetProposedTransfermarktPlayerMatchingSuspenseQueryError = AxiosError<unknown>


export function useTransfermarktControllerGetProposedTransfermarktPlayerMatchingSuspense<TData = Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktPlayerMatching>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktPlayerMatching>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTransfermarktControllerGetProposedTransfermarktPlayerMatchingSuspense<TData = Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktPlayerMatching>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktPlayerMatching>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTransfermarktControllerGetProposedTransfermarktPlayerMatchingSuspense<TData = Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktPlayerMatching>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktPlayerMatching>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTransfermarktControllerGetProposedTransfermarktPlayerMatchingSuspense<TData = Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktPlayerMatching>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof transfermarktControllerGetProposedTransfermarktPlayerMatching>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTransfermarktControllerGetProposedTransfermarktPlayerMatchingSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const transfermarktControllerApproveProposedTransfermarktPlayerMatching = (
    transfermarktPlayerMatcherApprovePostDto: TransfermarktPlayerMatcherApprovePostDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/v1/transfermarkt/matching/transfermarkt/player/approve`,
      transfermarktPlayerMatcherApprovePostDto,options
    );
  }



export const getTransfermarktControllerApproveProposedTransfermarktPlayerMatchingMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof transfermarktControllerApproveProposedTransfermarktPlayerMatching>>, TError,{data: TransfermarktPlayerMatcherApprovePostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof transfermarktControllerApproveProposedTransfermarktPlayerMatching>>, TError,{data: TransfermarktPlayerMatcherApprovePostDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof transfermarktControllerApproveProposedTransfermarktPlayerMatching>>, {data: TransfermarktPlayerMatcherApprovePostDto}> = (props) => {
          const {data} = props ?? {};

          return  transfermarktControllerApproveProposedTransfermarktPlayerMatching(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TransfermarktControllerApproveProposedTransfermarktPlayerMatchingMutationResult = NonNullable<Awaited<ReturnType<typeof transfermarktControllerApproveProposedTransfermarktPlayerMatching>>>
    export type TransfermarktControllerApproveProposedTransfermarktPlayerMatchingMutationBody = TransfermarktPlayerMatcherApprovePostDto
    export type TransfermarktControllerApproveProposedTransfermarktPlayerMatchingMutationError = AxiosError<unknown>

    export const useTransfermarktControllerApproveProposedTransfermarktPlayerMatching = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof transfermarktControllerApproveProposedTransfermarktPlayerMatching>>, TError,{data: TransfermarktPlayerMatcherApprovePostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof transfermarktControllerApproveProposedTransfermarktPlayerMatching>>,
        TError,
        {data: TransfermarktPlayerMatcherApprovePostDto},
        TContext
      > => {

      const mutationOptions = getTransfermarktControllerApproveProposedTransfermarktPlayerMatchingMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const transfermarktControllerDeclineProposedTransfermarktPlayerMatching = (
    transfermarktPlayerMatcherApprovePostDto: TransfermarktPlayerMatcherApprovePostDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/v1/transfermarkt/matching/transfermarkt/player/decline`,
      transfermarktPlayerMatcherApprovePostDto,options
    );
  }



export const getTransfermarktControllerDeclineProposedTransfermarktPlayerMatchingMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof transfermarktControllerDeclineProposedTransfermarktPlayerMatching>>, TError,{data: TransfermarktPlayerMatcherApprovePostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof transfermarktControllerDeclineProposedTransfermarktPlayerMatching>>, TError,{data: TransfermarktPlayerMatcherApprovePostDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof transfermarktControllerDeclineProposedTransfermarktPlayerMatching>>, {data: TransfermarktPlayerMatcherApprovePostDto}> = (props) => {
          const {data} = props ?? {};

          return  transfermarktControllerDeclineProposedTransfermarktPlayerMatching(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TransfermarktControllerDeclineProposedTransfermarktPlayerMatchingMutationResult = NonNullable<Awaited<ReturnType<typeof transfermarktControllerDeclineProposedTransfermarktPlayerMatching>>>
    export type TransfermarktControllerDeclineProposedTransfermarktPlayerMatchingMutationBody = TransfermarktPlayerMatcherApprovePostDto
    export type TransfermarktControllerDeclineProposedTransfermarktPlayerMatchingMutationError = AxiosError<unknown>

    export const useTransfermarktControllerDeclineProposedTransfermarktPlayerMatching = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof transfermarktControllerDeclineProposedTransfermarktPlayerMatching>>, TError,{data: TransfermarktPlayerMatcherApprovePostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof transfermarktControllerDeclineProposedTransfermarktPlayerMatching>>,
        TError,
        {data: TransfermarktPlayerMatcherApprovePostDto},
        TContext
      > => {

      const mutationOptions = getTransfermarktControllerDeclineProposedTransfermarktPlayerMatchingMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantEmployeeControllerGetTenantEmployees = (
    tenantId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantEmployeeGetDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/employee`,options
    );
  }


export const getTenantEmployeeControllerGetTenantEmployeesQueryKey = (tenantId: string,) => {
    return [`/v1/${tenantId}/employee`] as const;
    }

    
export const getTenantEmployeeControllerGetTenantEmployeesQueryOptions = <TData = Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployees>>, TError = AxiosError<unknown>>(tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployees>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantEmployeeControllerGetTenantEmployeesQueryKey(tenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployees>>> = ({ signal }) => tenantEmployeeControllerGetTenantEmployees(tenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployees>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantEmployeeControllerGetTenantEmployeesQueryResult = NonNullable<Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployees>>>
export type TenantEmployeeControllerGetTenantEmployeesQueryError = AxiosError<unknown>


export function useTenantEmployeeControllerGetTenantEmployees<TData = Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployees>>, TError = AxiosError<unknown>>(
 tenantId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployees>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployees>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEmployeeControllerGetTenantEmployees<TData = Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployees>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployees>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployees>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEmployeeControllerGetTenantEmployees<TData = Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployees>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployees>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantEmployeeControllerGetTenantEmployees<TData = Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployees>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployees>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantEmployeeControllerGetTenantEmployeesQueryOptions(tenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantEmployeeControllerGetTenantEmployeesSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployees>>, TError = AxiosError<unknown>>(tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployees>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantEmployeeControllerGetTenantEmployeesQueryKey(tenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployees>>> = ({ signal }) => tenantEmployeeControllerGetTenantEmployees(tenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployees>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantEmployeeControllerGetTenantEmployeesSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployees>>>
export type TenantEmployeeControllerGetTenantEmployeesSuspenseQueryError = AxiosError<unknown>


export function useTenantEmployeeControllerGetTenantEmployeesSuspense<TData = Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployees>>, TError = AxiosError<unknown>>(
 tenantId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployees>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEmployeeControllerGetTenantEmployeesSuspense<TData = Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployees>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployees>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEmployeeControllerGetTenantEmployeesSuspense<TData = Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployees>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployees>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantEmployeeControllerGetTenantEmployeesSuspense<TData = Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployees>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployees>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantEmployeeControllerGetTenantEmployeesSuspenseQueryOptions(tenantId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantEmployeeControllerCreateTenantEmployee = (
    tenantId: string,
    tenantEmployeeCreateDto: TenantEmployeeCreateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantEmployeeGetDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/employee`,
      tenantEmployeeCreateDto,options
    );
  }



export const getTenantEmployeeControllerCreateTenantEmployeeMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeControllerCreateTenantEmployee>>, TError,{tenantId: string;data: TenantEmployeeCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeControllerCreateTenantEmployee>>, TError,{tenantId: string;data: TenantEmployeeCreateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantEmployeeControllerCreateTenantEmployee>>, {tenantId: string;data: TenantEmployeeCreateDto}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  tenantEmployeeControllerCreateTenantEmployee(tenantId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantEmployeeControllerCreateTenantEmployeeMutationResult = NonNullable<Awaited<ReturnType<typeof tenantEmployeeControllerCreateTenantEmployee>>>
    export type TenantEmployeeControllerCreateTenantEmployeeMutationBody = TenantEmployeeCreateDto
    export type TenantEmployeeControllerCreateTenantEmployeeMutationError = AxiosError<unknown>

    export const useTenantEmployeeControllerCreateTenantEmployee = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeControllerCreateTenantEmployee>>, TError,{tenantId: string;data: TenantEmployeeCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantEmployeeControllerCreateTenantEmployee>>,
        TError,
        {tenantId: string;data: TenantEmployeeCreateDto},
        TContext
      > => {

      const mutationOptions = getTenantEmployeeControllerCreateTenantEmployeeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantEmployeeControllerGetTenantEmployee = (
    tenantId: string,
    tenantEmployeeId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantEmployeeGetDto>> => {
    
    return axios.get(
      `/v1/${tenantId}/employee/${tenantEmployeeId}`,options
    );
  }


export const getTenantEmployeeControllerGetTenantEmployeeQueryKey = (tenantId: string,
    tenantEmployeeId: string,) => {
    return [`/v1/${tenantId}/employee/${tenantEmployeeId}`] as const;
    }

    
export const getTenantEmployeeControllerGetTenantEmployeeQueryOptions = <TData = Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployee>>, TError = AxiosError<unknown>>(tenantId: string,
    tenantEmployeeId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployee>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantEmployeeControllerGetTenantEmployeeQueryKey(tenantId,tenantEmployeeId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployee>>> = ({ signal }) => tenantEmployeeControllerGetTenantEmployee(tenantId,tenantEmployeeId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && tenantEmployeeId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployee>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantEmployeeControllerGetTenantEmployeeQueryResult = NonNullable<Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployee>>>
export type TenantEmployeeControllerGetTenantEmployeeQueryError = AxiosError<unknown>


export function useTenantEmployeeControllerGetTenantEmployee<TData = Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployee>>, TError = AxiosError<unknown>>(
 tenantId: string,
    tenantEmployeeId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployee>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployee>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEmployeeControllerGetTenantEmployee<TData = Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployee>>, TError = AxiosError<unknown>>(
 tenantId: string,
    tenantEmployeeId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployee>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployee>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEmployeeControllerGetTenantEmployee<TData = Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployee>>, TError = AxiosError<unknown>>(
 tenantId: string,
    tenantEmployeeId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployee>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantEmployeeControllerGetTenantEmployee<TData = Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployee>>, TError = AxiosError<unknown>>(
 tenantId: string,
    tenantEmployeeId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployee>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantEmployeeControllerGetTenantEmployeeQueryOptions(tenantId,tenantEmployeeId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantEmployeeControllerGetTenantEmployeeSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployee>>, TError = AxiosError<unknown>>(tenantId: string,
    tenantEmployeeId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployee>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantEmployeeControllerGetTenantEmployeeQueryKey(tenantId,tenantEmployeeId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployee>>> = ({ signal }) => tenantEmployeeControllerGetTenantEmployee(tenantId,tenantEmployeeId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && tenantEmployeeId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployee>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantEmployeeControllerGetTenantEmployeeSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployee>>>
export type TenantEmployeeControllerGetTenantEmployeeSuspenseQueryError = AxiosError<unknown>


export function useTenantEmployeeControllerGetTenantEmployeeSuspense<TData = Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployee>>, TError = AxiosError<unknown>>(
 tenantId: string,
    tenantEmployeeId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployee>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEmployeeControllerGetTenantEmployeeSuspense<TData = Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployee>>, TError = AxiosError<unknown>>(
 tenantId: string,
    tenantEmployeeId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployee>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEmployeeControllerGetTenantEmployeeSuspense<TData = Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployee>>, TError = AxiosError<unknown>>(
 tenantId: string,
    tenantEmployeeId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployee>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantEmployeeControllerGetTenantEmployeeSuspense<TData = Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployee>>, TError = AxiosError<unknown>>(
 tenantId: string,
    tenantEmployeeId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeControllerGetTenantEmployee>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantEmployeeControllerGetTenantEmployeeSuspenseQueryOptions(tenantId,tenantEmployeeId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantEmployeeControllerUpdateTenantEmployee = (
    tenantId: string,
    tenantEmployeeId: string,
    tenantEmployeePatchDto: TenantEmployeePatchDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantEmployeeGetDto>> => {
    
    return axios.patch(
      `/v1/${tenantId}/employee/${tenantEmployeeId}`,
      tenantEmployeePatchDto,options
    );
  }



export const getTenantEmployeeControllerUpdateTenantEmployeeMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeControllerUpdateTenantEmployee>>, TError,{tenantId: string;tenantEmployeeId: string;data: TenantEmployeePatchDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeControllerUpdateTenantEmployee>>, TError,{tenantId: string;tenantEmployeeId: string;data: TenantEmployeePatchDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantEmployeeControllerUpdateTenantEmployee>>, {tenantId: string;tenantEmployeeId: string;data: TenantEmployeePatchDto}> = (props) => {
          const {tenantId,tenantEmployeeId,data} = props ?? {};

          return  tenantEmployeeControllerUpdateTenantEmployee(tenantId,tenantEmployeeId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantEmployeeControllerUpdateTenantEmployeeMutationResult = NonNullable<Awaited<ReturnType<typeof tenantEmployeeControllerUpdateTenantEmployee>>>
    export type TenantEmployeeControllerUpdateTenantEmployeeMutationBody = TenantEmployeePatchDto
    export type TenantEmployeeControllerUpdateTenantEmployeeMutationError = AxiosError<unknown>

    export const useTenantEmployeeControllerUpdateTenantEmployee = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeControllerUpdateTenantEmployee>>, TError,{tenantId: string;tenantEmployeeId: string;data: TenantEmployeePatchDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantEmployeeControllerUpdateTenantEmployee>>,
        TError,
        {tenantId: string;tenantEmployeeId: string;data: TenantEmployeePatchDto},
        TContext
      > => {

      const mutationOptions = getTenantEmployeeControllerUpdateTenantEmployeeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantEmployeeControllerDeleteTenantEmployee = (
    tenantId: string,
    tenantEmployeeId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/v1/${tenantId}/employee/${tenantEmployeeId}`,options
    );
  }



export const getTenantEmployeeControllerDeleteTenantEmployeeMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeControllerDeleteTenantEmployee>>, TError,{tenantId: string;tenantEmployeeId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeControllerDeleteTenantEmployee>>, TError,{tenantId: string;tenantEmployeeId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantEmployeeControllerDeleteTenantEmployee>>, {tenantId: string;tenantEmployeeId: string}> = (props) => {
          const {tenantId,tenantEmployeeId} = props ?? {};

          return  tenantEmployeeControllerDeleteTenantEmployee(tenantId,tenantEmployeeId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantEmployeeControllerDeleteTenantEmployeeMutationResult = NonNullable<Awaited<ReturnType<typeof tenantEmployeeControllerDeleteTenantEmployee>>>
    
    export type TenantEmployeeControllerDeleteTenantEmployeeMutationError = AxiosError<unknown>

    export const useTenantEmployeeControllerDeleteTenantEmployee = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeControllerDeleteTenantEmployee>>, TError,{tenantId: string;tenantEmployeeId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantEmployeeControllerDeleteTenantEmployee>>,
        TError,
        {tenantId: string;tenantEmployeeId: string},
        TContext
      > => {

      const mutationOptions = getTenantEmployeeControllerDeleteTenantEmployeeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantEmployeeControllerCreateProfileImageUploadRequest = (
    tenantId: string,
    tenantEmployeeId: string,
    tenantEmployeeProfileImageUploadPostDto: TenantEmployeeProfileImageUploadPostDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantEmployeeProfileImageUploadReturnDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/employee/${tenantEmployeeId}/profile-image/uploadrequest`,
      tenantEmployeeProfileImageUploadPostDto,options
    );
  }



export const getTenantEmployeeControllerCreateProfileImageUploadRequestMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeControllerCreateProfileImageUploadRequest>>, TError,{tenantId: string;tenantEmployeeId: string;data: TenantEmployeeProfileImageUploadPostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeControllerCreateProfileImageUploadRequest>>, TError,{tenantId: string;tenantEmployeeId: string;data: TenantEmployeeProfileImageUploadPostDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantEmployeeControllerCreateProfileImageUploadRequest>>, {tenantId: string;tenantEmployeeId: string;data: TenantEmployeeProfileImageUploadPostDto}> = (props) => {
          const {tenantId,tenantEmployeeId,data} = props ?? {};

          return  tenantEmployeeControllerCreateProfileImageUploadRequest(tenantId,tenantEmployeeId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantEmployeeControllerCreateProfileImageUploadRequestMutationResult = NonNullable<Awaited<ReturnType<typeof tenantEmployeeControllerCreateProfileImageUploadRequest>>>
    export type TenantEmployeeControllerCreateProfileImageUploadRequestMutationBody = TenantEmployeeProfileImageUploadPostDto
    export type TenantEmployeeControllerCreateProfileImageUploadRequestMutationError = AxiosError<unknown>

    export const useTenantEmployeeControllerCreateProfileImageUploadRequest = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeControllerCreateProfileImageUploadRequest>>, TError,{tenantId: string;tenantEmployeeId: string;data: TenantEmployeeProfileImageUploadPostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantEmployeeControllerCreateProfileImageUploadRequest>>,
        TError,
        {tenantId: string;tenantEmployeeId: string;data: TenantEmployeeProfileImageUploadPostDto},
        TContext
      > => {

      const mutationOptions = getTenantEmployeeControllerCreateProfileImageUploadRequestMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantEmployeeControllerDeleteProfileImage = (
    tenantId: string,
    tenantEmployeeId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/v1/${tenantId}/employee/${tenantEmployeeId}/profile-image`,options
    );
  }



export const getTenantEmployeeControllerDeleteProfileImageMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeControllerDeleteProfileImage>>, TError,{tenantId: string;tenantEmployeeId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeControllerDeleteProfileImage>>, TError,{tenantId: string;tenantEmployeeId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantEmployeeControllerDeleteProfileImage>>, {tenantId: string;tenantEmployeeId: string}> = (props) => {
          const {tenantId,tenantEmployeeId} = props ?? {};

          return  tenantEmployeeControllerDeleteProfileImage(tenantId,tenantEmployeeId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantEmployeeControllerDeleteProfileImageMutationResult = NonNullable<Awaited<ReturnType<typeof tenantEmployeeControllerDeleteProfileImage>>>
    
    export type TenantEmployeeControllerDeleteProfileImageMutationError = AxiosError<unknown>

    export const useTenantEmployeeControllerDeleteProfileImage = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeControllerDeleteProfileImage>>, TError,{tenantId: string;tenantEmployeeId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantEmployeeControllerDeleteProfileImage>>,
        TError,
        {tenantId: string;tenantEmployeeId: string},
        TContext
      > => {

      const mutationOptions = getTenantEmployeeControllerDeleteProfileImageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantEmployeeDocumentControllerGetDocuments = (
    tenantId: string,
    tenantEmployeeId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantEmployeeDocumentGetDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/employee/${tenantEmployeeId}/document`,options
    );
  }


export const getTenantEmployeeDocumentControllerGetDocumentsQueryKey = (tenantId: string,
    tenantEmployeeId: string,) => {
    return [`/v1/${tenantId}/employee/${tenantEmployeeId}/document`] as const;
    }

    
export const getTenantEmployeeDocumentControllerGetDocumentsQueryOptions = <TData = Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocuments>>, TError = AxiosError<unknown>>(tenantId: string,
    tenantEmployeeId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocuments>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantEmployeeDocumentControllerGetDocumentsQueryKey(tenantId,tenantEmployeeId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocuments>>> = ({ signal }) => tenantEmployeeDocumentControllerGetDocuments(tenantId,tenantEmployeeId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && tenantEmployeeId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocuments>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantEmployeeDocumentControllerGetDocumentsQueryResult = NonNullable<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocuments>>>
export type TenantEmployeeDocumentControllerGetDocumentsQueryError = AxiosError<unknown>


export function useTenantEmployeeDocumentControllerGetDocuments<TData = Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocuments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    tenantEmployeeId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocuments>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocuments>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEmployeeDocumentControllerGetDocuments<TData = Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocuments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    tenantEmployeeId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocuments>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocuments>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEmployeeDocumentControllerGetDocuments<TData = Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocuments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    tenantEmployeeId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocuments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantEmployeeDocumentControllerGetDocuments<TData = Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocuments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    tenantEmployeeId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocuments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantEmployeeDocumentControllerGetDocumentsQueryOptions(tenantId,tenantEmployeeId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantEmployeeDocumentControllerGetDocumentsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocuments>>, TError = AxiosError<unknown>>(tenantId: string,
    tenantEmployeeId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocuments>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantEmployeeDocumentControllerGetDocumentsQueryKey(tenantId,tenantEmployeeId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocuments>>> = ({ signal }) => tenantEmployeeDocumentControllerGetDocuments(tenantId,tenantEmployeeId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && tenantEmployeeId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocuments>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantEmployeeDocumentControllerGetDocumentsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocuments>>>
export type TenantEmployeeDocumentControllerGetDocumentsSuspenseQueryError = AxiosError<unknown>


export function useTenantEmployeeDocumentControllerGetDocumentsSuspense<TData = Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocuments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    tenantEmployeeId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocuments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEmployeeDocumentControllerGetDocumentsSuspense<TData = Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocuments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    tenantEmployeeId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocuments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEmployeeDocumentControllerGetDocumentsSuspense<TData = Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocuments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    tenantEmployeeId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocuments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantEmployeeDocumentControllerGetDocumentsSuspense<TData = Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocuments>>, TError = AxiosError<unknown>>(
 tenantId: string,
    tenantEmployeeId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocuments>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantEmployeeDocumentControllerGetDocumentsSuspenseQueryOptions(tenantId,tenantEmployeeId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantEmployeeDocumentControllerGetDocumentDownloadLink = (
    tenantId: string,
    tenantEmployeeId: string,
    tenantEmployeeDocumentId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantEmployeeDocumentDownloadUrlDto>> => {
    
    return axios.get(
      `/v1/${tenantId}/employee/${tenantEmployeeId}/document/${tenantEmployeeDocumentId}/downloadrequest`,options
    );
  }


export const getTenantEmployeeDocumentControllerGetDocumentDownloadLinkQueryKey = (tenantId: string,
    tenantEmployeeId: string,
    tenantEmployeeDocumentId: string,) => {
    return [`/v1/${tenantId}/employee/${tenantEmployeeId}/document/${tenantEmployeeDocumentId}/downloadrequest`] as const;
    }

    
export const getTenantEmployeeDocumentControllerGetDocumentDownloadLinkQueryOptions = <TData = Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocumentDownloadLink>>, TError = AxiosError<unknown>>(tenantId: string,
    tenantEmployeeId: string,
    tenantEmployeeDocumentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocumentDownloadLink>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantEmployeeDocumentControllerGetDocumentDownloadLinkQueryKey(tenantId,tenantEmployeeId,tenantEmployeeDocumentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocumentDownloadLink>>> = ({ signal }) => tenantEmployeeDocumentControllerGetDocumentDownloadLink(tenantId,tenantEmployeeId,tenantEmployeeDocumentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && tenantEmployeeId && tenantEmployeeDocumentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocumentDownloadLink>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantEmployeeDocumentControllerGetDocumentDownloadLinkQueryResult = NonNullable<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocumentDownloadLink>>>
export type TenantEmployeeDocumentControllerGetDocumentDownloadLinkQueryError = AxiosError<unknown>


export function useTenantEmployeeDocumentControllerGetDocumentDownloadLink<TData = Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocumentDownloadLink>>, TError = AxiosError<unknown>>(
 tenantId: string,
    tenantEmployeeId: string,
    tenantEmployeeDocumentId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocumentDownloadLink>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocumentDownloadLink>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEmployeeDocumentControllerGetDocumentDownloadLink<TData = Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocumentDownloadLink>>, TError = AxiosError<unknown>>(
 tenantId: string,
    tenantEmployeeId: string,
    tenantEmployeeDocumentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocumentDownloadLink>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocumentDownloadLink>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEmployeeDocumentControllerGetDocumentDownloadLink<TData = Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocumentDownloadLink>>, TError = AxiosError<unknown>>(
 tenantId: string,
    tenantEmployeeId: string,
    tenantEmployeeDocumentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocumentDownloadLink>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantEmployeeDocumentControllerGetDocumentDownloadLink<TData = Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocumentDownloadLink>>, TError = AxiosError<unknown>>(
 tenantId: string,
    tenantEmployeeId: string,
    tenantEmployeeDocumentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocumentDownloadLink>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantEmployeeDocumentControllerGetDocumentDownloadLinkQueryOptions(tenantId,tenantEmployeeId,tenantEmployeeDocumentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantEmployeeDocumentControllerGetDocumentDownloadLinkSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocumentDownloadLink>>, TError = AxiosError<unknown>>(tenantId: string,
    tenantEmployeeId: string,
    tenantEmployeeDocumentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocumentDownloadLink>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantEmployeeDocumentControllerGetDocumentDownloadLinkQueryKey(tenantId,tenantEmployeeId,tenantEmployeeDocumentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocumentDownloadLink>>> = ({ signal }) => tenantEmployeeDocumentControllerGetDocumentDownloadLink(tenantId,tenantEmployeeId,tenantEmployeeDocumentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && tenantEmployeeId && tenantEmployeeDocumentId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocumentDownloadLink>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantEmployeeDocumentControllerGetDocumentDownloadLinkSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocumentDownloadLink>>>
export type TenantEmployeeDocumentControllerGetDocumentDownloadLinkSuspenseQueryError = AxiosError<unknown>


export function useTenantEmployeeDocumentControllerGetDocumentDownloadLinkSuspense<TData = Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocumentDownloadLink>>, TError = AxiosError<unknown>>(
 tenantId: string,
    tenantEmployeeId: string,
    tenantEmployeeDocumentId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocumentDownloadLink>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEmployeeDocumentControllerGetDocumentDownloadLinkSuspense<TData = Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocumentDownloadLink>>, TError = AxiosError<unknown>>(
 tenantId: string,
    tenantEmployeeId: string,
    tenantEmployeeDocumentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocumentDownloadLink>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantEmployeeDocumentControllerGetDocumentDownloadLinkSuspense<TData = Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocumentDownloadLink>>, TError = AxiosError<unknown>>(
 tenantId: string,
    tenantEmployeeId: string,
    tenantEmployeeDocumentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocumentDownloadLink>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantEmployeeDocumentControllerGetDocumentDownloadLinkSuspense<TData = Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocumentDownloadLink>>, TError = AxiosError<unknown>>(
 tenantId: string,
    tenantEmployeeId: string,
    tenantEmployeeDocumentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerGetDocumentDownloadLink>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantEmployeeDocumentControllerGetDocumentDownloadLinkSuspenseQueryOptions(tenantId,tenantEmployeeId,tenantEmployeeDocumentId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantEmployeeDocumentControllerCreateProfileImageUploadRequest = (
    tenantId: string,
    tenantEmployeeId: string,
    tenantEmployeeDocumentUploadPostDto: TenantEmployeeDocumentUploadPostDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantEmployeeDocumentUploadReturnDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/employee/${tenantEmployeeId}/document/uploadrequest`,
      tenantEmployeeDocumentUploadPostDto,options
    );
  }



export const getTenantEmployeeDocumentControllerCreateProfileImageUploadRequestMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerCreateProfileImageUploadRequest>>, TError,{tenantId: string;tenantEmployeeId: string;data: TenantEmployeeDocumentUploadPostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerCreateProfileImageUploadRequest>>, TError,{tenantId: string;tenantEmployeeId: string;data: TenantEmployeeDocumentUploadPostDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerCreateProfileImageUploadRequest>>, {tenantId: string;tenantEmployeeId: string;data: TenantEmployeeDocumentUploadPostDto}> = (props) => {
          const {tenantId,tenantEmployeeId,data} = props ?? {};

          return  tenantEmployeeDocumentControllerCreateProfileImageUploadRequest(tenantId,tenantEmployeeId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantEmployeeDocumentControllerCreateProfileImageUploadRequestMutationResult = NonNullable<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerCreateProfileImageUploadRequest>>>
    export type TenantEmployeeDocumentControllerCreateProfileImageUploadRequestMutationBody = TenantEmployeeDocumentUploadPostDto
    export type TenantEmployeeDocumentControllerCreateProfileImageUploadRequestMutationError = AxiosError<unknown>

    export const useTenantEmployeeDocumentControllerCreateProfileImageUploadRequest = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerCreateProfileImageUploadRequest>>, TError,{tenantId: string;tenantEmployeeId: string;data: TenantEmployeeDocumentUploadPostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantEmployeeDocumentControllerCreateProfileImageUploadRequest>>,
        TError,
        {tenantId: string;tenantEmployeeId: string;data: TenantEmployeeDocumentUploadPostDto},
        TContext
      > => {

      const mutationOptions = getTenantEmployeeDocumentControllerCreateProfileImageUploadRequestMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantEmployeeDocumentControllerDeleteDocument = (
    tenantId: string,
    tenantEmployeeId: string,
    tenantEmployeeDocumentId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/v1/${tenantId}/employee/${tenantEmployeeId}/document/${tenantEmployeeDocumentId}`,options
    );
  }



export const getTenantEmployeeDocumentControllerDeleteDocumentMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerDeleteDocument>>, TError,{tenantId: string;tenantEmployeeId: string;tenantEmployeeDocumentId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerDeleteDocument>>, TError,{tenantId: string;tenantEmployeeId: string;tenantEmployeeDocumentId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerDeleteDocument>>, {tenantId: string;tenantEmployeeId: string;tenantEmployeeDocumentId: string}> = (props) => {
          const {tenantId,tenantEmployeeId,tenantEmployeeDocumentId} = props ?? {};

          return  tenantEmployeeDocumentControllerDeleteDocument(tenantId,tenantEmployeeId,tenantEmployeeDocumentId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantEmployeeDocumentControllerDeleteDocumentMutationResult = NonNullable<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerDeleteDocument>>>
    
    export type TenantEmployeeDocumentControllerDeleteDocumentMutationError = AxiosError<unknown>

    export const useTenantEmployeeDocumentControllerDeleteDocument = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantEmployeeDocumentControllerDeleteDocument>>, TError,{tenantId: string;tenantEmployeeId: string;tenantEmployeeDocumentId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantEmployeeDocumentControllerDeleteDocument>>,
        TError,
        {tenantId: string;tenantEmployeeId: string;tenantEmployeeDocumentId: string},
        TContext
      > => {

      const mutationOptions = getTenantEmployeeDocumentControllerDeleteDocumentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantPerformanceTestsControllerGetPerformanceTests = (
    tenantId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantPerformanceTestGetDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/performance-test`,options
    );
  }


export const getTenantPerformanceTestsControllerGetPerformanceTestsQueryKey = (tenantId: string,) => {
    return [`/v1/${tenantId}/performance-test`] as const;
    }

    
export const getTenantPerformanceTestsControllerGetPerformanceTestsQueryOptions = <TData = Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTests>>, TError = AxiosError<unknown>>(tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTests>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantPerformanceTestsControllerGetPerformanceTestsQueryKey(tenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTests>>> = ({ signal }) => tenantPerformanceTestsControllerGetPerformanceTests(tenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTests>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantPerformanceTestsControllerGetPerformanceTestsQueryResult = NonNullable<Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTests>>>
export type TenantPerformanceTestsControllerGetPerformanceTestsQueryError = AxiosError<unknown>


export function useTenantPerformanceTestsControllerGetPerformanceTests<TData = Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTests>>, TError = AxiosError<unknown>>(
 tenantId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTests>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTests>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPerformanceTestsControllerGetPerformanceTests<TData = Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTests>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTests>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTests>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPerformanceTestsControllerGetPerformanceTests<TData = Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTests>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTests>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantPerformanceTestsControllerGetPerformanceTests<TData = Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTests>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTests>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantPerformanceTestsControllerGetPerformanceTestsQueryOptions(tenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantPerformanceTestsControllerGetPerformanceTestsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTests>>, TError = AxiosError<unknown>>(tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTests>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantPerformanceTestsControllerGetPerformanceTestsQueryKey(tenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTests>>> = ({ signal }) => tenantPerformanceTestsControllerGetPerformanceTests(tenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTests>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantPerformanceTestsControllerGetPerformanceTestsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTests>>>
export type TenantPerformanceTestsControllerGetPerformanceTestsSuspenseQueryError = AxiosError<unknown>


export function useTenantPerformanceTestsControllerGetPerformanceTestsSuspense<TData = Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTests>>, TError = AxiosError<unknown>>(
 tenantId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTests>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPerformanceTestsControllerGetPerformanceTestsSuspense<TData = Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTests>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTests>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPerformanceTestsControllerGetPerformanceTestsSuspense<TData = Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTests>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTests>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantPerformanceTestsControllerGetPerformanceTestsSuspense<TData = Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTests>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTests>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantPerformanceTestsControllerGetPerformanceTestsSuspenseQueryOptions(tenantId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantPerformanceTestsControllerCreatePerformanceTest = (
    tenantId: string,
    tenantPerformanceTestCreateDto: TenantPerformanceTestCreateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantPerformanceTestGetDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/performance-test`,
      tenantPerformanceTestCreateDto,options
    );
  }



export const getTenantPerformanceTestsControllerCreatePerformanceTestMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPerformanceTestsControllerCreatePerformanceTest>>, TError,{tenantId: string;data: TenantPerformanceTestCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantPerformanceTestsControllerCreatePerformanceTest>>, TError,{tenantId: string;data: TenantPerformanceTestCreateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantPerformanceTestsControllerCreatePerformanceTest>>, {tenantId: string;data: TenantPerformanceTestCreateDto}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  tenantPerformanceTestsControllerCreatePerformanceTest(tenantId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantPerformanceTestsControllerCreatePerformanceTestMutationResult = NonNullable<Awaited<ReturnType<typeof tenantPerformanceTestsControllerCreatePerformanceTest>>>
    export type TenantPerformanceTestsControllerCreatePerformanceTestMutationBody = TenantPerformanceTestCreateDto
    export type TenantPerformanceTestsControllerCreatePerformanceTestMutationError = AxiosError<unknown>

    export const useTenantPerformanceTestsControllerCreatePerformanceTest = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPerformanceTestsControllerCreatePerformanceTest>>, TError,{tenantId: string;data: TenantPerformanceTestCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantPerformanceTestsControllerCreatePerformanceTest>>,
        TError,
        {tenantId: string;data: TenantPerformanceTestCreateDto},
        TContext
      > => {

      const mutationOptions = getTenantPerformanceTestsControllerCreatePerformanceTestMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantPerformanceTestsControllerGetPerformanceTest = (
    tenantId: string,
    performanceTestId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantPerformanceTestGetDto>> => {
    
    return axios.get(
      `/v1/${tenantId}/performance-test/${performanceTestId}`,options
    );
  }


export const getTenantPerformanceTestsControllerGetPerformanceTestQueryKey = (tenantId: string,
    performanceTestId: string,) => {
    return [`/v1/${tenantId}/performance-test/${performanceTestId}`] as const;
    }

    
export const getTenantPerformanceTestsControllerGetPerformanceTestQueryOptions = <TData = Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTest>>, TError = AxiosError<unknown>>(tenantId: string,
    performanceTestId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTest>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantPerformanceTestsControllerGetPerformanceTestQueryKey(tenantId,performanceTestId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTest>>> = ({ signal }) => tenantPerformanceTestsControllerGetPerformanceTest(tenantId,performanceTestId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && performanceTestId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTest>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantPerformanceTestsControllerGetPerformanceTestQueryResult = NonNullable<Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTest>>>
export type TenantPerformanceTestsControllerGetPerformanceTestQueryError = AxiosError<unknown>


export function useTenantPerformanceTestsControllerGetPerformanceTest<TData = Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTest>>, TError = AxiosError<unknown>>(
 tenantId: string,
    performanceTestId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTest>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTest>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPerformanceTestsControllerGetPerformanceTest<TData = Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTest>>, TError = AxiosError<unknown>>(
 tenantId: string,
    performanceTestId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTest>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTest>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPerformanceTestsControllerGetPerformanceTest<TData = Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTest>>, TError = AxiosError<unknown>>(
 tenantId: string,
    performanceTestId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTest>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantPerformanceTestsControllerGetPerformanceTest<TData = Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTest>>, TError = AxiosError<unknown>>(
 tenantId: string,
    performanceTestId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTest>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantPerformanceTestsControllerGetPerformanceTestQueryOptions(tenantId,performanceTestId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantPerformanceTestsControllerGetPerformanceTestSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTest>>, TError = AxiosError<unknown>>(tenantId: string,
    performanceTestId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTest>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantPerformanceTestsControllerGetPerformanceTestQueryKey(tenantId,performanceTestId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTest>>> = ({ signal }) => tenantPerformanceTestsControllerGetPerformanceTest(tenantId,performanceTestId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && performanceTestId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTest>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantPerformanceTestsControllerGetPerformanceTestSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTest>>>
export type TenantPerformanceTestsControllerGetPerformanceTestSuspenseQueryError = AxiosError<unknown>


export function useTenantPerformanceTestsControllerGetPerformanceTestSuspense<TData = Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTest>>, TError = AxiosError<unknown>>(
 tenantId: string,
    performanceTestId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTest>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPerformanceTestsControllerGetPerformanceTestSuspense<TData = Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTest>>, TError = AxiosError<unknown>>(
 tenantId: string,
    performanceTestId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTest>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPerformanceTestsControllerGetPerformanceTestSuspense<TData = Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTest>>, TError = AxiosError<unknown>>(
 tenantId: string,
    performanceTestId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTest>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantPerformanceTestsControllerGetPerformanceTestSuspense<TData = Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTest>>, TError = AxiosError<unknown>>(
 tenantId: string,
    performanceTestId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestsControllerGetPerformanceTest>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantPerformanceTestsControllerGetPerformanceTestSuspenseQueryOptions(tenantId,performanceTestId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantPerformanceTestsControllerUpdatePerformanceTest = (
    tenantId: string,
    performanceTestId: string,
    tenantPerformanceTestPatchDto: TenantPerformanceTestPatchDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantPerformanceTestGetDto>> => {
    
    return axios.patch(
      `/v1/${tenantId}/performance-test/${performanceTestId}`,
      tenantPerformanceTestPatchDto,options
    );
  }



export const getTenantPerformanceTestsControllerUpdatePerformanceTestMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPerformanceTestsControllerUpdatePerformanceTest>>, TError,{tenantId: string;performanceTestId: string;data: TenantPerformanceTestPatchDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantPerformanceTestsControllerUpdatePerformanceTest>>, TError,{tenantId: string;performanceTestId: string;data: TenantPerformanceTestPatchDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantPerformanceTestsControllerUpdatePerformanceTest>>, {tenantId: string;performanceTestId: string;data: TenantPerformanceTestPatchDto}> = (props) => {
          const {tenantId,performanceTestId,data} = props ?? {};

          return  tenantPerformanceTestsControllerUpdatePerformanceTest(tenantId,performanceTestId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantPerformanceTestsControllerUpdatePerformanceTestMutationResult = NonNullable<Awaited<ReturnType<typeof tenantPerformanceTestsControllerUpdatePerformanceTest>>>
    export type TenantPerformanceTestsControllerUpdatePerformanceTestMutationBody = TenantPerformanceTestPatchDto
    export type TenantPerformanceTestsControllerUpdatePerformanceTestMutationError = AxiosError<unknown>

    export const useTenantPerformanceTestsControllerUpdatePerformanceTest = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPerformanceTestsControllerUpdatePerformanceTest>>, TError,{tenantId: string;performanceTestId: string;data: TenantPerformanceTestPatchDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantPerformanceTestsControllerUpdatePerformanceTest>>,
        TError,
        {tenantId: string;performanceTestId: string;data: TenantPerformanceTestPatchDto},
        TContext
      > => {

      const mutationOptions = getTenantPerformanceTestsControllerUpdatePerformanceTestMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantPerformanceTestsControllerDeletePerformanceTest = (
    tenantId: string,
    performanceTestId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/v1/${tenantId}/performance-test/${performanceTestId}`,options
    );
  }



export const getTenantPerformanceTestsControllerDeletePerformanceTestMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPerformanceTestsControllerDeletePerformanceTest>>, TError,{tenantId: string;performanceTestId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantPerformanceTestsControllerDeletePerformanceTest>>, TError,{tenantId: string;performanceTestId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantPerformanceTestsControllerDeletePerformanceTest>>, {tenantId: string;performanceTestId: string}> = (props) => {
          const {tenantId,performanceTestId} = props ?? {};

          return  tenantPerformanceTestsControllerDeletePerformanceTest(tenantId,performanceTestId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantPerformanceTestsControllerDeletePerformanceTestMutationResult = NonNullable<Awaited<ReturnType<typeof tenantPerformanceTestsControllerDeletePerformanceTest>>>
    
    export type TenantPerformanceTestsControllerDeletePerformanceTestMutationError = AxiosError<unknown>

    export const useTenantPerformanceTestsControllerDeletePerformanceTest = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPerformanceTestsControllerDeletePerformanceTest>>, TError,{tenantId: string;performanceTestId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantPerformanceTestsControllerDeletePerformanceTest>>,
        TError,
        {tenantId: string;performanceTestId: string},
        TContext
      > => {

      const mutationOptions = getTenantPerformanceTestsControllerDeletePerformanceTestMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const matchControllerFindOne = (
    tenantId: string,
    environmentId: string,
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<MatchDto>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/matches/${id}`,options
    );
  }


export const getMatchControllerFindOneQueryKey = (tenantId: string,
    environmentId: string,
    id: string,) => {
    return [`/v1/${tenantId}/${environmentId}/matches/${id}`] as const;
    }

    
export const getMatchControllerFindOneQueryOptions = <TData = Awaited<ReturnType<typeof matchControllerFindOne>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof matchControllerFindOne>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getMatchControllerFindOneQueryKey(tenantId,environmentId,id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof matchControllerFindOne>>> = ({ signal }) => matchControllerFindOne(tenantId,environmentId,id, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof matchControllerFindOne>>, TError, TData> & { queryKey: QueryKey }
}

export type MatchControllerFindOneQueryResult = NonNullable<Awaited<ReturnType<typeof matchControllerFindOne>>>
export type MatchControllerFindOneQueryError = AxiosError<unknown>


export function useMatchControllerFindOne<TData = Awaited<ReturnType<typeof matchControllerFindOne>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    id: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof matchControllerFindOne>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof matchControllerFindOne>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useMatchControllerFindOne<TData = Awaited<ReturnType<typeof matchControllerFindOne>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof matchControllerFindOne>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof matchControllerFindOne>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useMatchControllerFindOne<TData = Awaited<ReturnType<typeof matchControllerFindOne>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof matchControllerFindOne>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useMatchControllerFindOne<TData = Awaited<ReturnType<typeof matchControllerFindOne>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof matchControllerFindOne>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getMatchControllerFindOneQueryOptions(tenantId,environmentId,id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getMatchControllerFindOneSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof matchControllerFindOne>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof matchControllerFindOne>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getMatchControllerFindOneQueryKey(tenantId,environmentId,id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof matchControllerFindOne>>> = ({ signal }) => matchControllerFindOne(tenantId,environmentId,id, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && id), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof matchControllerFindOne>>, TError, TData> & { queryKey: QueryKey }
}

export type MatchControllerFindOneSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof matchControllerFindOne>>>
export type MatchControllerFindOneSuspenseQueryError = AxiosError<unknown>


export function useMatchControllerFindOneSuspense<TData = Awaited<ReturnType<typeof matchControllerFindOne>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    id: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof matchControllerFindOne>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useMatchControllerFindOneSuspense<TData = Awaited<ReturnType<typeof matchControllerFindOne>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof matchControllerFindOne>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useMatchControllerFindOneSuspense<TData = Awaited<ReturnType<typeof matchControllerFindOne>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof matchControllerFindOne>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useMatchControllerFindOneSuspense<TData = Awaited<ReturnType<typeof matchControllerFindOne>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof matchControllerFindOne>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getMatchControllerFindOneSuspenseQueryOptions(tenantId,environmentId,id,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const matchControllerSearch = (
    tenantId: string,
    environmentId: string,
    params?: MatchControllerSearchParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<MatchDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/matches`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getMatchControllerSearchQueryKey = (tenantId: string,
    environmentId: string,
    params?: MatchControllerSearchParams,) => {
    return [`/v1/${tenantId}/${environmentId}/matches`, ...(params ? [params]: [])] as const;
    }

    
export const getMatchControllerSearchQueryOptions = <TData = Awaited<ReturnType<typeof matchControllerSearch>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    params?: MatchControllerSearchParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof matchControllerSearch>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getMatchControllerSearchQueryKey(tenantId,environmentId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof matchControllerSearch>>> = ({ signal }) => matchControllerSearch(tenantId,environmentId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof matchControllerSearch>>, TError, TData> & { queryKey: QueryKey }
}

export type MatchControllerSearchQueryResult = NonNullable<Awaited<ReturnType<typeof matchControllerSearch>>>
export type MatchControllerSearchQueryError = AxiosError<unknown>


export function useMatchControllerSearch<TData = Awaited<ReturnType<typeof matchControllerSearch>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: undefined |  MatchControllerSearchParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof matchControllerSearch>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof matchControllerSearch>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useMatchControllerSearch<TData = Awaited<ReturnType<typeof matchControllerSearch>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params?: MatchControllerSearchParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof matchControllerSearch>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof matchControllerSearch>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useMatchControllerSearch<TData = Awaited<ReturnType<typeof matchControllerSearch>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params?: MatchControllerSearchParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof matchControllerSearch>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useMatchControllerSearch<TData = Awaited<ReturnType<typeof matchControllerSearch>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params?: MatchControllerSearchParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof matchControllerSearch>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getMatchControllerSearchQueryOptions(tenantId,environmentId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getMatchControllerSearchSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof matchControllerSearch>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    params?: MatchControllerSearchParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof matchControllerSearch>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getMatchControllerSearchQueryKey(tenantId,environmentId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof matchControllerSearch>>> = ({ signal }) => matchControllerSearch(tenantId,environmentId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof matchControllerSearch>>, TError, TData> & { queryKey: QueryKey }
}

export type MatchControllerSearchSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof matchControllerSearch>>>
export type MatchControllerSearchSuspenseQueryError = AxiosError<unknown>


export function useMatchControllerSearchSuspense<TData = Awaited<ReturnType<typeof matchControllerSearch>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params: undefined |  MatchControllerSearchParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof matchControllerSearch>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useMatchControllerSearchSuspense<TData = Awaited<ReturnType<typeof matchControllerSearch>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params?: MatchControllerSearchParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof matchControllerSearch>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useMatchControllerSearchSuspense<TData = Awaited<ReturnType<typeof matchControllerSearch>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params?: MatchControllerSearchParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof matchControllerSearch>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useMatchControllerSearchSuspense<TData = Awaited<ReturnType<typeof matchControllerSearch>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    params?: MatchControllerSearchParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof matchControllerSearch>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getMatchControllerSearchSuspenseQueryOptions(tenantId,environmentId,params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParams = (
    tenantId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantPerformanceTestMeasurementParamGetDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/performance-test-measurement-param`,options
    );
  }


export const getTenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParamsQueryKey = (tenantId: string,) => {
    return [`/v1/${tenantId}/performance-test-measurement-param`] as const;
    }

    
export const getTenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParamsQueryOptions = <TData = Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParams>>, TError = AxiosError<unknown>>(tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParams>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParamsQueryKey(tenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParams>>> = ({ signal }) => tenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParams(tenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParams>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParamsQueryResult = NonNullable<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParams>>>
export type TenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParamsQueryError = AxiosError<unknown>


export function useTenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParams<TData = Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParams>>, TError = AxiosError<unknown>>(
 tenantId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParams>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParams>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParams<TData = Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParams>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParams>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParams>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParams<TData = Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParams>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParams>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParams<TData = Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParams>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParams>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParamsQueryOptions(tenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParamsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParams>>, TError = AxiosError<unknown>>(tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParams>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParamsQueryKey(tenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParams>>> = ({ signal }) => tenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParams(tenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParams>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParamsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParams>>>
export type TenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParamsSuspenseQueryError = AxiosError<unknown>


export function useTenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParamsSuspense<TData = Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParams>>, TError = AxiosError<unknown>>(
 tenantId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParams>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParamsSuspense<TData = Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParams>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParams>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParamsSuspense<TData = Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParams>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParams>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParamsSuspense<TData = Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParams>>, TError = AxiosError<unknown>>(
 tenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParams>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantPerformanceTestMeasurementParamControllerGetAllPerformanceTestMeasurementParamsSuspenseQueryOptions(tenantId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantPerformanceTestMeasurementParamControllerCreatePerformanceTestMeasurementParam = (
    tenantId: string,
    tenantPerformanceTestMeasurementParamCreateDto: TenantPerformanceTestMeasurementParamCreateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantPerformanceTestMeasurementParamGetDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/performance-test-measurement-param`,
      tenantPerformanceTestMeasurementParamCreateDto,options
    );
  }



export const getTenantPerformanceTestMeasurementParamControllerCreatePerformanceTestMeasurementParamMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerCreatePerformanceTestMeasurementParam>>, TError,{tenantId: string;data: TenantPerformanceTestMeasurementParamCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerCreatePerformanceTestMeasurementParam>>, TError,{tenantId: string;data: TenantPerformanceTestMeasurementParamCreateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerCreatePerformanceTestMeasurementParam>>, {tenantId: string;data: TenantPerformanceTestMeasurementParamCreateDto}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  tenantPerformanceTestMeasurementParamControllerCreatePerformanceTestMeasurementParam(tenantId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantPerformanceTestMeasurementParamControllerCreatePerformanceTestMeasurementParamMutationResult = NonNullable<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerCreatePerformanceTestMeasurementParam>>>
    export type TenantPerformanceTestMeasurementParamControllerCreatePerformanceTestMeasurementParamMutationBody = TenantPerformanceTestMeasurementParamCreateDto
    export type TenantPerformanceTestMeasurementParamControllerCreatePerformanceTestMeasurementParamMutationError = AxiosError<unknown>

    export const useTenantPerformanceTestMeasurementParamControllerCreatePerformanceTestMeasurementParam = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerCreatePerformanceTestMeasurementParam>>, TError,{tenantId: string;data: TenantPerformanceTestMeasurementParamCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerCreatePerformanceTestMeasurementParam>>,
        TError,
        {tenantId: string;data: TenantPerformanceTestMeasurementParamCreateDto},
        TContext
      > => {

      const mutationOptions = getTenantPerformanceTestMeasurementParamControllerCreatePerformanceTestMeasurementParamMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParam = (
    tenantId: string,
    performanceTestMeasurementParamId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantPerformanceTestMeasurementParamGetDto>> => {
    
    return axios.get(
      `/v1/${tenantId}/performance-test-measurement-param/${performanceTestMeasurementParamId}`,options
    );
  }


export const getTenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParamQueryKey = (tenantId: string,
    performanceTestMeasurementParamId: string,) => {
    return [`/v1/${tenantId}/performance-test-measurement-param/${performanceTestMeasurementParamId}`] as const;
    }

    
export const getTenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParamQueryOptions = <TData = Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParam>>, TError = AxiosError<unknown>>(tenantId: string,
    performanceTestMeasurementParamId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParam>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParamQueryKey(tenantId,performanceTestMeasurementParamId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParam>>> = ({ signal }) => tenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParam(tenantId,performanceTestMeasurementParamId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && performanceTestMeasurementParamId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParam>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParamQueryResult = NonNullable<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParam>>>
export type TenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParamQueryError = AxiosError<unknown>


export function useTenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParam<TData = Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParam>>, TError = AxiosError<unknown>>(
 tenantId: string,
    performanceTestMeasurementParamId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParam>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParam>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParam<TData = Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParam>>, TError = AxiosError<unknown>>(
 tenantId: string,
    performanceTestMeasurementParamId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParam>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParam>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParam<TData = Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParam>>, TError = AxiosError<unknown>>(
 tenantId: string,
    performanceTestMeasurementParamId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParam>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParam<TData = Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParam>>, TError = AxiosError<unknown>>(
 tenantId: string,
    performanceTestMeasurementParamId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParam>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParamQueryOptions(tenantId,performanceTestMeasurementParamId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getTenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParamSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParam>>, TError = AxiosError<unknown>>(tenantId: string,
    performanceTestMeasurementParamId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParam>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParamQueryKey(tenantId,performanceTestMeasurementParamId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParam>>> = ({ signal }) => tenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParam(tenantId,performanceTestMeasurementParamId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && performanceTestMeasurementParamId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParam>>, TError, TData> & { queryKey: QueryKey }
}

export type TenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParamSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParam>>>
export type TenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParamSuspenseQueryError = AxiosError<unknown>


export function useTenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParamSuspense<TData = Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParam>>, TError = AxiosError<unknown>>(
 tenantId: string,
    performanceTestMeasurementParamId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParam>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParamSuspense<TData = Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParam>>, TError = AxiosError<unknown>>(
 tenantId: string,
    performanceTestMeasurementParamId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParam>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useTenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParamSuspense<TData = Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParam>>, TError = AxiosError<unknown>>(
 tenantId: string,
    performanceTestMeasurementParamId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParam>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useTenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParamSuspense<TData = Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParam>>, TError = AxiosError<unknown>>(
 tenantId: string,
    performanceTestMeasurementParamId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParam>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getTenantPerformanceTestMeasurementParamControllerGetPerformanceTestMeasurementParamSuspenseQueryOptions(tenantId,performanceTestMeasurementParamId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const tenantPerformanceTestMeasurementParamControllerUpdatePerformanceTestMeasurementParam = (
    tenantId: string,
    performanceTestMeasurementParamId: string,
    tenantPerformanceTestMeasurementParamPatchDto: TenantPerformanceTestMeasurementParamPatchDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TenantPerformanceTestMeasurementParamGetDto>> => {
    
    return axios.patch(
      `/v1/${tenantId}/performance-test-measurement-param/${performanceTestMeasurementParamId}`,
      tenantPerformanceTestMeasurementParamPatchDto,options
    );
  }



export const getTenantPerformanceTestMeasurementParamControllerUpdatePerformanceTestMeasurementParamMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerUpdatePerformanceTestMeasurementParam>>, TError,{tenantId: string;performanceTestMeasurementParamId: string;data: TenantPerformanceTestMeasurementParamPatchDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerUpdatePerformanceTestMeasurementParam>>, TError,{tenantId: string;performanceTestMeasurementParamId: string;data: TenantPerformanceTestMeasurementParamPatchDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerUpdatePerformanceTestMeasurementParam>>, {tenantId: string;performanceTestMeasurementParamId: string;data: TenantPerformanceTestMeasurementParamPatchDto}> = (props) => {
          const {tenantId,performanceTestMeasurementParamId,data} = props ?? {};

          return  tenantPerformanceTestMeasurementParamControllerUpdatePerformanceTestMeasurementParam(tenantId,performanceTestMeasurementParamId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantPerformanceTestMeasurementParamControllerUpdatePerformanceTestMeasurementParamMutationResult = NonNullable<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerUpdatePerformanceTestMeasurementParam>>>
    export type TenantPerformanceTestMeasurementParamControllerUpdatePerformanceTestMeasurementParamMutationBody = TenantPerformanceTestMeasurementParamPatchDto
    export type TenantPerformanceTestMeasurementParamControllerUpdatePerformanceTestMeasurementParamMutationError = AxiosError<unknown>

    export const useTenantPerformanceTestMeasurementParamControllerUpdatePerformanceTestMeasurementParam = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerUpdatePerformanceTestMeasurementParam>>, TError,{tenantId: string;performanceTestMeasurementParamId: string;data: TenantPerformanceTestMeasurementParamPatchDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerUpdatePerformanceTestMeasurementParam>>,
        TError,
        {tenantId: string;performanceTestMeasurementParamId: string;data: TenantPerformanceTestMeasurementParamPatchDto},
        TContext
      > => {

      const mutationOptions = getTenantPerformanceTestMeasurementParamControllerUpdatePerformanceTestMeasurementParamMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const tenantPerformanceTestMeasurementParamControllerDeletePerformanceTestMeasurementParam = (
    tenantId: string,
    performanceTestMeasurementParamId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/v1/${tenantId}/performance-test-measurement-param/${performanceTestMeasurementParamId}`,options
    );
  }



export const getTenantPerformanceTestMeasurementParamControllerDeletePerformanceTestMeasurementParamMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerDeletePerformanceTestMeasurementParam>>, TError,{tenantId: string;performanceTestMeasurementParamId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerDeletePerformanceTestMeasurementParam>>, TError,{tenantId: string;performanceTestMeasurementParamId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerDeletePerformanceTestMeasurementParam>>, {tenantId: string;performanceTestMeasurementParamId: string}> = (props) => {
          const {tenantId,performanceTestMeasurementParamId} = props ?? {};

          return  tenantPerformanceTestMeasurementParamControllerDeletePerformanceTestMeasurementParam(tenantId,performanceTestMeasurementParamId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TenantPerformanceTestMeasurementParamControllerDeletePerformanceTestMeasurementParamMutationResult = NonNullable<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerDeletePerformanceTestMeasurementParam>>>
    
    export type TenantPerformanceTestMeasurementParamControllerDeletePerformanceTestMeasurementParamMutationError = AxiosError<unknown>

    export const useTenantPerformanceTestMeasurementParamControllerDeletePerformanceTestMeasurementParam = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerDeletePerformanceTestMeasurementParam>>, TError,{tenantId: string;performanceTestMeasurementParamId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof tenantPerformanceTestMeasurementParamControllerDeletePerformanceTestMeasurementParam>>,
        TError,
        {tenantId: string;performanceTestMeasurementParamId: string},
        TContext
      > => {

      const mutationOptions = getTenantPerformanceTestMeasurementParamControllerDeletePerformanceTestMeasurementParamMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const medicalTreatmentControllerGetCategories = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<MedicalTreatmentCategoryGetDto[]>> => {
    
    return axios.get(
      `/v1/medical-treatment/category`,options
    );
  }


export const getMedicalTreatmentControllerGetCategoriesQueryKey = () => {
    return [`/v1/medical-treatment/category`] as const;
    }

    
export const getMedicalTreatmentControllerGetCategoriesQueryOptions = <TData = Awaited<ReturnType<typeof medicalTreatmentControllerGetCategories>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof medicalTreatmentControllerGetCategories>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getMedicalTreatmentControllerGetCategoriesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof medicalTreatmentControllerGetCategories>>> = ({ signal }) => medicalTreatmentControllerGetCategories({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof medicalTreatmentControllerGetCategories>>, TError, TData> & { queryKey: QueryKey }
}

export type MedicalTreatmentControllerGetCategoriesQueryResult = NonNullable<Awaited<ReturnType<typeof medicalTreatmentControllerGetCategories>>>
export type MedicalTreatmentControllerGetCategoriesQueryError = AxiosError<unknown>


export function useMedicalTreatmentControllerGetCategories<TData = Awaited<ReturnType<typeof medicalTreatmentControllerGetCategories>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof medicalTreatmentControllerGetCategories>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof medicalTreatmentControllerGetCategories>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useMedicalTreatmentControllerGetCategories<TData = Awaited<ReturnType<typeof medicalTreatmentControllerGetCategories>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof medicalTreatmentControllerGetCategories>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof medicalTreatmentControllerGetCategories>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useMedicalTreatmentControllerGetCategories<TData = Awaited<ReturnType<typeof medicalTreatmentControllerGetCategories>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof medicalTreatmentControllerGetCategories>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useMedicalTreatmentControllerGetCategories<TData = Awaited<ReturnType<typeof medicalTreatmentControllerGetCategories>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof medicalTreatmentControllerGetCategories>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getMedicalTreatmentControllerGetCategoriesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getMedicalTreatmentControllerGetCategoriesSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof medicalTreatmentControllerGetCategories>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof medicalTreatmentControllerGetCategories>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getMedicalTreatmentControllerGetCategoriesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof medicalTreatmentControllerGetCategories>>> = ({ signal }) => medicalTreatmentControllerGetCategories({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof medicalTreatmentControllerGetCategories>>, TError, TData> & { queryKey: QueryKey }
}

export type MedicalTreatmentControllerGetCategoriesSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof medicalTreatmentControllerGetCategories>>>
export type MedicalTreatmentControllerGetCategoriesSuspenseQueryError = AxiosError<unknown>


export function useMedicalTreatmentControllerGetCategoriesSuspense<TData = Awaited<ReturnType<typeof medicalTreatmentControllerGetCategories>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof medicalTreatmentControllerGetCategories>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useMedicalTreatmentControllerGetCategoriesSuspense<TData = Awaited<ReturnType<typeof medicalTreatmentControllerGetCategories>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof medicalTreatmentControllerGetCategories>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useMedicalTreatmentControllerGetCategoriesSuspense<TData = Awaited<ReturnType<typeof medicalTreatmentControllerGetCategories>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof medicalTreatmentControllerGetCategories>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useMedicalTreatmentControllerGetCategoriesSuspense<TData = Awaited<ReturnType<typeof medicalTreatmentControllerGetCategories>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof medicalTreatmentControllerGetCategories>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getMedicalTreatmentControllerGetCategoriesSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const medicalTreatmentControllerGetTypes = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<MedicalTreatmentTypeGetDto[]>> => {
    
    return axios.get(
      `/v1/medical-treatment/type`,options
    );
  }


export const getMedicalTreatmentControllerGetTypesQueryKey = () => {
    return [`/v1/medical-treatment/type`] as const;
    }

    
export const getMedicalTreatmentControllerGetTypesQueryOptions = <TData = Awaited<ReturnType<typeof medicalTreatmentControllerGetTypes>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof medicalTreatmentControllerGetTypes>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getMedicalTreatmentControllerGetTypesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof medicalTreatmentControllerGetTypes>>> = ({ signal }) => medicalTreatmentControllerGetTypes({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof medicalTreatmentControllerGetTypes>>, TError, TData> & { queryKey: QueryKey }
}

export type MedicalTreatmentControllerGetTypesQueryResult = NonNullable<Awaited<ReturnType<typeof medicalTreatmentControllerGetTypes>>>
export type MedicalTreatmentControllerGetTypesQueryError = AxiosError<unknown>


export function useMedicalTreatmentControllerGetTypes<TData = Awaited<ReturnType<typeof medicalTreatmentControllerGetTypes>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof medicalTreatmentControllerGetTypes>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof medicalTreatmentControllerGetTypes>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useMedicalTreatmentControllerGetTypes<TData = Awaited<ReturnType<typeof medicalTreatmentControllerGetTypes>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof medicalTreatmentControllerGetTypes>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof medicalTreatmentControllerGetTypes>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useMedicalTreatmentControllerGetTypes<TData = Awaited<ReturnType<typeof medicalTreatmentControllerGetTypes>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof medicalTreatmentControllerGetTypes>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useMedicalTreatmentControllerGetTypes<TData = Awaited<ReturnType<typeof medicalTreatmentControllerGetTypes>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof medicalTreatmentControllerGetTypes>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getMedicalTreatmentControllerGetTypesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getMedicalTreatmentControllerGetTypesSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof medicalTreatmentControllerGetTypes>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof medicalTreatmentControllerGetTypes>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getMedicalTreatmentControllerGetTypesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof medicalTreatmentControllerGetTypes>>> = ({ signal }) => medicalTreatmentControllerGetTypes({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof medicalTreatmentControllerGetTypes>>, TError, TData> & { queryKey: QueryKey }
}

export type MedicalTreatmentControllerGetTypesSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof medicalTreatmentControllerGetTypes>>>
export type MedicalTreatmentControllerGetTypesSuspenseQueryError = AxiosError<unknown>


export function useMedicalTreatmentControllerGetTypesSuspense<TData = Awaited<ReturnType<typeof medicalTreatmentControllerGetTypes>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof medicalTreatmentControllerGetTypes>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useMedicalTreatmentControllerGetTypesSuspense<TData = Awaited<ReturnType<typeof medicalTreatmentControllerGetTypes>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof medicalTreatmentControllerGetTypes>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useMedicalTreatmentControllerGetTypesSuspense<TData = Awaited<ReturnType<typeof medicalTreatmentControllerGetTypes>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof medicalTreatmentControllerGetTypes>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useMedicalTreatmentControllerGetTypesSuspense<TData = Awaited<ReturnType<typeof medicalTreatmentControllerGetTypes>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof medicalTreatmentControllerGetTypes>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getMedicalTreatmentControllerGetTypesSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const playerMedicalEntryControllerGetPlayerMedicalEntries = (
    tenantId: string,
    playerOnTenantId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PlayerMedicalEntryGetDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/player/${playerOnTenantId}/medical-entry`,options
    );
  }


export const getPlayerMedicalEntryControllerGetPlayerMedicalEntriesQueryKey = (tenantId: string,
    playerOnTenantId: string,) => {
    return [`/v1/${tenantId}/player/${playerOnTenantId}/medical-entry`] as const;
    }

    
export const getPlayerMedicalEntryControllerGetPlayerMedicalEntriesQueryOptions = <TData = Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntries>>, TError = AxiosError<unknown>>(tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntries>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPlayerMedicalEntryControllerGetPlayerMedicalEntriesQueryKey(tenantId,playerOnTenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntries>>> = ({ signal }) => playerMedicalEntryControllerGetPlayerMedicalEntries(tenantId,playerOnTenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && playerOnTenantId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntries>>, TError, TData> & { queryKey: QueryKey }
}

export type PlayerMedicalEntryControllerGetPlayerMedicalEntriesQueryResult = NonNullable<Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntries>>>
export type PlayerMedicalEntryControllerGetPlayerMedicalEntriesQueryError = AxiosError<unknown>


export function usePlayerMedicalEntryControllerGetPlayerMedicalEntries<TData = Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntries>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntries>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntries>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerMedicalEntryControllerGetPlayerMedicalEntries<TData = Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntries>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntries>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntries>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerMedicalEntryControllerGetPlayerMedicalEntries<TData = Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntries>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntries>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function usePlayerMedicalEntryControllerGetPlayerMedicalEntries<TData = Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntries>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntries>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getPlayerMedicalEntryControllerGetPlayerMedicalEntriesQueryOptions(tenantId,playerOnTenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getPlayerMedicalEntryControllerGetPlayerMedicalEntriesSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntries>>, TError = AxiosError<unknown>>(tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntries>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPlayerMedicalEntryControllerGetPlayerMedicalEntriesQueryKey(tenantId,playerOnTenantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntries>>> = ({ signal }) => playerMedicalEntryControllerGetPlayerMedicalEntries(tenantId,playerOnTenantId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && playerOnTenantId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntries>>, TError, TData> & { queryKey: QueryKey }
}

export type PlayerMedicalEntryControllerGetPlayerMedicalEntriesSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntries>>>
export type PlayerMedicalEntryControllerGetPlayerMedicalEntriesSuspenseQueryError = AxiosError<unknown>


export function usePlayerMedicalEntryControllerGetPlayerMedicalEntriesSuspense<TData = Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntries>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntries>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerMedicalEntryControllerGetPlayerMedicalEntriesSuspense<TData = Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntries>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntries>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerMedicalEntryControllerGetPlayerMedicalEntriesSuspense<TData = Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntries>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntries>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function usePlayerMedicalEntryControllerGetPlayerMedicalEntriesSuspense<TData = Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntries>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntries>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getPlayerMedicalEntryControllerGetPlayerMedicalEntriesSuspenseQueryOptions(tenantId,playerOnTenantId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const playerMedicalEntryControllerCreatePlayerMedicalEntry = (
    tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryCreateDto: PlayerMedicalEntryCreateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PlayerMedicalEntryGetDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/player/${playerOnTenantId}/medical-entry`,
      playerMedicalEntryCreateDto,options
    );
  }



export const getPlayerMedicalEntryControllerCreatePlayerMedicalEntryMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof playerMedicalEntryControllerCreatePlayerMedicalEntry>>, TError,{tenantId: string;playerOnTenantId: string;data: PlayerMedicalEntryCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof playerMedicalEntryControllerCreatePlayerMedicalEntry>>, TError,{tenantId: string;playerOnTenantId: string;data: PlayerMedicalEntryCreateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof playerMedicalEntryControllerCreatePlayerMedicalEntry>>, {tenantId: string;playerOnTenantId: string;data: PlayerMedicalEntryCreateDto}> = (props) => {
          const {tenantId,playerOnTenantId,data} = props ?? {};

          return  playerMedicalEntryControllerCreatePlayerMedicalEntry(tenantId,playerOnTenantId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PlayerMedicalEntryControllerCreatePlayerMedicalEntryMutationResult = NonNullable<Awaited<ReturnType<typeof playerMedicalEntryControllerCreatePlayerMedicalEntry>>>
    export type PlayerMedicalEntryControllerCreatePlayerMedicalEntryMutationBody = PlayerMedicalEntryCreateDto
    export type PlayerMedicalEntryControllerCreatePlayerMedicalEntryMutationError = AxiosError<unknown>

    export const usePlayerMedicalEntryControllerCreatePlayerMedicalEntry = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof playerMedicalEntryControllerCreatePlayerMedicalEntry>>, TError,{tenantId: string;playerOnTenantId: string;data: PlayerMedicalEntryCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof playerMedicalEntryControllerCreatePlayerMedicalEntry>>,
        TError,
        {tenantId: string;playerOnTenantId: string;data: PlayerMedicalEntryCreateDto},
        TContext
      > => {

      const mutationOptions = getPlayerMedicalEntryControllerCreatePlayerMedicalEntryMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const playerMedicalEntryControllerGetPlayerMedicalEntry = (
    tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PlayerMedicalEntryGetDto>> => {
    
    return axios.get(
      `/v1/${tenantId}/player/${playerOnTenantId}/medical-entry/${playerMedicalEntryId}`,options
    );
  }


export const getPlayerMedicalEntryControllerGetPlayerMedicalEntryQueryKey = (tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string,) => {
    return [`/v1/${tenantId}/player/${playerOnTenantId}/medical-entry/${playerMedicalEntryId}`] as const;
    }

    
export const getPlayerMedicalEntryControllerGetPlayerMedicalEntryQueryOptions = <TData = Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntry>>, TError = AxiosError<unknown>>(tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntry>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPlayerMedicalEntryControllerGetPlayerMedicalEntryQueryKey(tenantId,playerOnTenantId,playerMedicalEntryId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntry>>> = ({ signal }) => playerMedicalEntryControllerGetPlayerMedicalEntry(tenantId,playerOnTenantId,playerMedicalEntryId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && playerOnTenantId && playerMedicalEntryId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntry>>, TError, TData> & { queryKey: QueryKey }
}

export type PlayerMedicalEntryControllerGetPlayerMedicalEntryQueryResult = NonNullable<Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntry>>>
export type PlayerMedicalEntryControllerGetPlayerMedicalEntryQueryError = AxiosError<unknown>


export function usePlayerMedicalEntryControllerGetPlayerMedicalEntry<TData = Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntry>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntry>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntry>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerMedicalEntryControllerGetPlayerMedicalEntry<TData = Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntry>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntry>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntry>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerMedicalEntryControllerGetPlayerMedicalEntry<TData = Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntry>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntry>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function usePlayerMedicalEntryControllerGetPlayerMedicalEntry<TData = Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntry>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntry>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getPlayerMedicalEntryControllerGetPlayerMedicalEntryQueryOptions(tenantId,playerOnTenantId,playerMedicalEntryId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getPlayerMedicalEntryControllerGetPlayerMedicalEntrySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntry>>, TError = AxiosError<unknown>>(tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntry>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPlayerMedicalEntryControllerGetPlayerMedicalEntryQueryKey(tenantId,playerOnTenantId,playerMedicalEntryId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntry>>> = ({ signal }) => playerMedicalEntryControllerGetPlayerMedicalEntry(tenantId,playerOnTenantId,playerMedicalEntryId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && playerOnTenantId && playerMedicalEntryId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntry>>, TError, TData> & { queryKey: QueryKey }
}

export type PlayerMedicalEntryControllerGetPlayerMedicalEntrySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntry>>>
export type PlayerMedicalEntryControllerGetPlayerMedicalEntrySuspenseQueryError = AxiosError<unknown>


export function usePlayerMedicalEntryControllerGetPlayerMedicalEntrySuspense<TData = Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntry>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntry>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerMedicalEntryControllerGetPlayerMedicalEntrySuspense<TData = Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntry>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntry>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerMedicalEntryControllerGetPlayerMedicalEntrySuspense<TData = Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntry>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntry>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function usePlayerMedicalEntryControllerGetPlayerMedicalEntrySuspense<TData = Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntry>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryControllerGetPlayerMedicalEntry>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getPlayerMedicalEntryControllerGetPlayerMedicalEntrySuspenseQueryOptions(tenantId,playerOnTenantId,playerMedicalEntryId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const playerMedicalEntryControllerUpdatePlayerMedicalEntry = (
    tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string,
    playerMedicalEntryUpdateDto: PlayerMedicalEntryUpdateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PlayerMedicalEntryGetDto>> => {
    
    return axios.patch(
      `/v1/${tenantId}/player/${playerOnTenantId}/medical-entry/${playerMedicalEntryId}`,
      playerMedicalEntryUpdateDto,options
    );
  }



export const getPlayerMedicalEntryControllerUpdatePlayerMedicalEntryMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof playerMedicalEntryControllerUpdatePlayerMedicalEntry>>, TError,{tenantId: string;playerOnTenantId: string;playerMedicalEntryId: string;data: PlayerMedicalEntryUpdateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof playerMedicalEntryControllerUpdatePlayerMedicalEntry>>, TError,{tenantId: string;playerOnTenantId: string;playerMedicalEntryId: string;data: PlayerMedicalEntryUpdateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof playerMedicalEntryControllerUpdatePlayerMedicalEntry>>, {tenantId: string;playerOnTenantId: string;playerMedicalEntryId: string;data: PlayerMedicalEntryUpdateDto}> = (props) => {
          const {tenantId,playerOnTenantId,playerMedicalEntryId,data} = props ?? {};

          return  playerMedicalEntryControllerUpdatePlayerMedicalEntry(tenantId,playerOnTenantId,playerMedicalEntryId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PlayerMedicalEntryControllerUpdatePlayerMedicalEntryMutationResult = NonNullable<Awaited<ReturnType<typeof playerMedicalEntryControllerUpdatePlayerMedicalEntry>>>
    export type PlayerMedicalEntryControllerUpdatePlayerMedicalEntryMutationBody = PlayerMedicalEntryUpdateDto
    export type PlayerMedicalEntryControllerUpdatePlayerMedicalEntryMutationError = AxiosError<unknown>

    export const usePlayerMedicalEntryControllerUpdatePlayerMedicalEntry = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof playerMedicalEntryControllerUpdatePlayerMedicalEntry>>, TError,{tenantId: string;playerOnTenantId: string;playerMedicalEntryId: string;data: PlayerMedicalEntryUpdateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof playerMedicalEntryControllerUpdatePlayerMedicalEntry>>,
        TError,
        {tenantId: string;playerOnTenantId: string;playerMedicalEntryId: string;data: PlayerMedicalEntryUpdateDto},
        TContext
      > => {

      const mutationOptions = getPlayerMedicalEntryControllerUpdatePlayerMedicalEntryMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const playerMedicalEntryControllerDeletePlayerMedicalEntry = (
    tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/v1/${tenantId}/player/${playerOnTenantId}/medical-entry/${playerMedicalEntryId}`,options
    );
  }



export const getPlayerMedicalEntryControllerDeletePlayerMedicalEntryMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof playerMedicalEntryControllerDeletePlayerMedicalEntry>>, TError,{tenantId: string;playerOnTenantId: string;playerMedicalEntryId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof playerMedicalEntryControllerDeletePlayerMedicalEntry>>, TError,{tenantId: string;playerOnTenantId: string;playerMedicalEntryId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof playerMedicalEntryControllerDeletePlayerMedicalEntry>>, {tenantId: string;playerOnTenantId: string;playerMedicalEntryId: string}> = (props) => {
          const {tenantId,playerOnTenantId,playerMedicalEntryId} = props ?? {};

          return  playerMedicalEntryControllerDeletePlayerMedicalEntry(tenantId,playerOnTenantId,playerMedicalEntryId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PlayerMedicalEntryControllerDeletePlayerMedicalEntryMutationResult = NonNullable<Awaited<ReturnType<typeof playerMedicalEntryControllerDeletePlayerMedicalEntry>>>
    
    export type PlayerMedicalEntryControllerDeletePlayerMedicalEntryMutationError = AxiosError<unknown>

    export const usePlayerMedicalEntryControllerDeletePlayerMedicalEntry = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof playerMedicalEntryControllerDeletePlayerMedicalEntry>>, TError,{tenantId: string;playerOnTenantId: string;playerMedicalEntryId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof playerMedicalEntryControllerDeletePlayerMedicalEntry>>,
        TError,
        {tenantId: string;playerOnTenantId: string;playerMedicalEntryId: string},
        TContext
      > => {

      const mutationOptions = getPlayerMedicalEntryControllerDeletePlayerMedicalEntryMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const playerMedicalEntryDocumentControllerGetDocument = (
    tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PlayerMedicalEntryDocumentControllerGetDocument200>> => {
    
    return axios.get(
      `/v1/${tenantId}/player/${playerOnTenantId}/medical-entry/${playerMedicalEntryId}/document`,options
    );
  }


export const getPlayerMedicalEntryDocumentControllerGetDocumentQueryKey = (tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string,) => {
    return [`/v1/${tenantId}/player/${playerOnTenantId}/medical-entry/${playerMedicalEntryId}/document`] as const;
    }

    
export const getPlayerMedicalEntryDocumentControllerGetDocumentQueryOptions = <TData = Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocument>>, TError = AxiosError<unknown>>(tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocument>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPlayerMedicalEntryDocumentControllerGetDocumentQueryKey(tenantId,playerOnTenantId,playerMedicalEntryId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocument>>> = ({ signal }) => playerMedicalEntryDocumentControllerGetDocument(tenantId,playerOnTenantId,playerMedicalEntryId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && playerOnTenantId && playerMedicalEntryId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocument>>, TError, TData> & { queryKey: QueryKey }
}

export type PlayerMedicalEntryDocumentControllerGetDocumentQueryResult = NonNullable<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocument>>>
export type PlayerMedicalEntryDocumentControllerGetDocumentQueryError = AxiosError<unknown>


export function usePlayerMedicalEntryDocumentControllerGetDocument<TData = Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocument>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocument>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocument>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerMedicalEntryDocumentControllerGetDocument<TData = Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocument>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocument>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocument>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerMedicalEntryDocumentControllerGetDocument<TData = Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocument>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocument>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function usePlayerMedicalEntryDocumentControllerGetDocument<TData = Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocument>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocument>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getPlayerMedicalEntryDocumentControllerGetDocumentQueryOptions(tenantId,playerOnTenantId,playerMedicalEntryId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getPlayerMedicalEntryDocumentControllerGetDocumentSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocument>>, TError = AxiosError<unknown>>(tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocument>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPlayerMedicalEntryDocumentControllerGetDocumentQueryKey(tenantId,playerOnTenantId,playerMedicalEntryId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocument>>> = ({ signal }) => playerMedicalEntryDocumentControllerGetDocument(tenantId,playerOnTenantId,playerMedicalEntryId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && playerOnTenantId && playerMedicalEntryId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocument>>, TError, TData> & { queryKey: QueryKey }
}

export type PlayerMedicalEntryDocumentControllerGetDocumentSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocument>>>
export type PlayerMedicalEntryDocumentControllerGetDocumentSuspenseQueryError = AxiosError<unknown>


export function usePlayerMedicalEntryDocumentControllerGetDocumentSuspense<TData = Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocument>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocument>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerMedicalEntryDocumentControllerGetDocumentSuspense<TData = Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocument>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocument>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerMedicalEntryDocumentControllerGetDocumentSuspense<TData = Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocument>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocument>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function usePlayerMedicalEntryDocumentControllerGetDocumentSuspense<TData = Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocument>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocument>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getPlayerMedicalEntryDocumentControllerGetDocumentSuspenseQueryOptions(tenantId,playerOnTenantId,playerMedicalEntryId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const playerMedicalEntryDocumentControllerGetDocumentDownloadLink = (
    tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string,
    documentId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PlayerMedicalEntryDocumentDownloadUrlDto>> => {
    
    return axios.get(
      `/v1/${tenantId}/player/${playerOnTenantId}/medical-entry/${playerMedicalEntryId}/document/${documentId}/downloadrequest`,options
    );
  }


export const getPlayerMedicalEntryDocumentControllerGetDocumentDownloadLinkQueryKey = (tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string,
    documentId: string,) => {
    return [`/v1/${tenantId}/player/${playerOnTenantId}/medical-entry/${playerMedicalEntryId}/document/${documentId}/downloadrequest`] as const;
    }

    
export const getPlayerMedicalEntryDocumentControllerGetDocumentDownloadLinkQueryOptions = <TData = Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocumentDownloadLink>>, TError = AxiosError<unknown>>(tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string,
    documentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocumentDownloadLink>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPlayerMedicalEntryDocumentControllerGetDocumentDownloadLinkQueryKey(tenantId,playerOnTenantId,playerMedicalEntryId,documentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocumentDownloadLink>>> = ({ signal }) => playerMedicalEntryDocumentControllerGetDocumentDownloadLink(tenantId,playerOnTenantId,playerMedicalEntryId,documentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && playerOnTenantId && playerMedicalEntryId && documentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocumentDownloadLink>>, TError, TData> & { queryKey: QueryKey }
}

export type PlayerMedicalEntryDocumentControllerGetDocumentDownloadLinkQueryResult = NonNullable<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocumentDownloadLink>>>
export type PlayerMedicalEntryDocumentControllerGetDocumentDownloadLinkQueryError = AxiosError<unknown>


export function usePlayerMedicalEntryDocumentControllerGetDocumentDownloadLink<TData = Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocumentDownloadLink>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string,
    documentId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocumentDownloadLink>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocumentDownloadLink>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerMedicalEntryDocumentControllerGetDocumentDownloadLink<TData = Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocumentDownloadLink>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string,
    documentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocumentDownloadLink>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocumentDownloadLink>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerMedicalEntryDocumentControllerGetDocumentDownloadLink<TData = Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocumentDownloadLink>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string,
    documentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocumentDownloadLink>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function usePlayerMedicalEntryDocumentControllerGetDocumentDownloadLink<TData = Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocumentDownloadLink>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string,
    documentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocumentDownloadLink>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getPlayerMedicalEntryDocumentControllerGetDocumentDownloadLinkQueryOptions(tenantId,playerOnTenantId,playerMedicalEntryId,documentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getPlayerMedicalEntryDocumentControllerGetDocumentDownloadLinkSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocumentDownloadLink>>, TError = AxiosError<unknown>>(tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string,
    documentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocumentDownloadLink>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPlayerMedicalEntryDocumentControllerGetDocumentDownloadLinkQueryKey(tenantId,playerOnTenantId,playerMedicalEntryId,documentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocumentDownloadLink>>> = ({ signal }) => playerMedicalEntryDocumentControllerGetDocumentDownloadLink(tenantId,playerOnTenantId,playerMedicalEntryId,documentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && playerOnTenantId && playerMedicalEntryId && documentId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocumentDownloadLink>>, TError, TData> & { queryKey: QueryKey }
}

export type PlayerMedicalEntryDocumentControllerGetDocumentDownloadLinkSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocumentDownloadLink>>>
export type PlayerMedicalEntryDocumentControllerGetDocumentDownloadLinkSuspenseQueryError = AxiosError<unknown>


export function usePlayerMedicalEntryDocumentControllerGetDocumentDownloadLinkSuspense<TData = Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocumentDownloadLink>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string,
    documentId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocumentDownloadLink>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerMedicalEntryDocumentControllerGetDocumentDownloadLinkSuspense<TData = Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocumentDownloadLink>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string,
    documentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocumentDownloadLink>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function usePlayerMedicalEntryDocumentControllerGetDocumentDownloadLinkSuspense<TData = Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocumentDownloadLink>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string,
    documentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocumentDownloadLink>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function usePlayerMedicalEntryDocumentControllerGetDocumentDownloadLinkSuspense<TData = Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocumentDownloadLink>>, TError = AxiosError<unknown>>(
 tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string,
    documentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerGetDocumentDownloadLink>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getPlayerMedicalEntryDocumentControllerGetDocumentDownloadLinkSuspenseQueryOptions(tenantId,playerOnTenantId,playerMedicalEntryId,documentId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const playerMedicalEntryDocumentControllerCreateDocumentUploadRequest = (
    tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string,
    playerMedicalEntryDocumentUploadPostDto: PlayerMedicalEntryDocumentUploadPostDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PlayerMedicalEntryDocumentUploadReturnDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/player/${playerOnTenantId}/medical-entry/${playerMedicalEntryId}/document/uploadrequest`,
      playerMedicalEntryDocumentUploadPostDto,options
    );
  }



export const getPlayerMedicalEntryDocumentControllerCreateDocumentUploadRequestMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerCreateDocumentUploadRequest>>, TError,{tenantId: string;playerOnTenantId: string;playerMedicalEntryId: string;data: PlayerMedicalEntryDocumentUploadPostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerCreateDocumentUploadRequest>>, TError,{tenantId: string;playerOnTenantId: string;playerMedicalEntryId: string;data: PlayerMedicalEntryDocumentUploadPostDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerCreateDocumentUploadRequest>>, {tenantId: string;playerOnTenantId: string;playerMedicalEntryId: string;data: PlayerMedicalEntryDocumentUploadPostDto}> = (props) => {
          const {tenantId,playerOnTenantId,playerMedicalEntryId,data} = props ?? {};

          return  playerMedicalEntryDocumentControllerCreateDocumentUploadRequest(tenantId,playerOnTenantId,playerMedicalEntryId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PlayerMedicalEntryDocumentControllerCreateDocumentUploadRequestMutationResult = NonNullable<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerCreateDocumentUploadRequest>>>
    export type PlayerMedicalEntryDocumentControllerCreateDocumentUploadRequestMutationBody = PlayerMedicalEntryDocumentUploadPostDto
    export type PlayerMedicalEntryDocumentControllerCreateDocumentUploadRequestMutationError = AxiosError<unknown>

    export const usePlayerMedicalEntryDocumentControllerCreateDocumentUploadRequest = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerCreateDocumentUploadRequest>>, TError,{tenantId: string;playerOnTenantId: string;playerMedicalEntryId: string;data: PlayerMedicalEntryDocumentUploadPostDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerCreateDocumentUploadRequest>>,
        TError,
        {tenantId: string;playerOnTenantId: string;playerMedicalEntryId: string;data: PlayerMedicalEntryDocumentUploadPostDto},
        TContext
      > => {

      const mutationOptions = getPlayerMedicalEntryDocumentControllerCreateDocumentUploadRequestMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const playerMedicalEntryDocumentControllerDeleteDocument = (
    tenantId: string,
    playerOnTenantId: string,
    playerMedicalEntryId: string,
    documentId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/v1/${tenantId}/player/${playerOnTenantId}/medical-entry/${playerMedicalEntryId}/document/${documentId}`,options
    );
  }



export const getPlayerMedicalEntryDocumentControllerDeleteDocumentMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerDeleteDocument>>, TError,{tenantId: string;playerOnTenantId: string;playerMedicalEntryId: string;documentId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerDeleteDocument>>, TError,{tenantId: string;playerOnTenantId: string;playerMedicalEntryId: string;documentId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerDeleteDocument>>, {tenantId: string;playerOnTenantId: string;playerMedicalEntryId: string;documentId: string}> = (props) => {
          const {tenantId,playerOnTenantId,playerMedicalEntryId,documentId} = props ?? {};

          return  playerMedicalEntryDocumentControllerDeleteDocument(tenantId,playerOnTenantId,playerMedicalEntryId,documentId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PlayerMedicalEntryDocumentControllerDeleteDocumentMutationResult = NonNullable<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerDeleteDocument>>>
    
    export type PlayerMedicalEntryDocumentControllerDeleteDocumentMutationError = AxiosError<unknown>

    export const usePlayerMedicalEntryDocumentControllerDeleteDocument = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerDeleteDocument>>, TError,{tenantId: string;playerOnTenantId: string;playerMedicalEntryId: string;documentId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof playerMedicalEntryDocumentControllerDeleteDocument>>,
        TError,
        {tenantId: string;playerOnTenantId: string;playerMedicalEntryId: string;documentId: string},
        TContext
      > => {

      const mutationOptions = getPlayerMedicalEntryDocumentControllerDeleteDocumentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const meWyscoutPlayerAlertControllerGetWyscoutPlayerAlerts = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WyscoutPlayerAlertGetDto[]>> => {
    
    return axios.get(
      `/v1/user/me/wyscout-player-alert`,options
    );
  }


export const getMeWyscoutPlayerAlertControllerGetWyscoutPlayerAlertsQueryKey = () => {
    return [`/v1/user/me/wyscout-player-alert`] as const;
    }

    
export const getMeWyscoutPlayerAlertControllerGetWyscoutPlayerAlertsQueryOptions = <TData = Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlerts>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlerts>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getMeWyscoutPlayerAlertControllerGetWyscoutPlayerAlertsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlerts>>> = ({ signal }) => meWyscoutPlayerAlertControllerGetWyscoutPlayerAlerts({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlerts>>, TError, TData> & { queryKey: QueryKey }
}

export type MeWyscoutPlayerAlertControllerGetWyscoutPlayerAlertsQueryResult = NonNullable<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlerts>>>
export type MeWyscoutPlayerAlertControllerGetWyscoutPlayerAlertsQueryError = AxiosError<unknown>


export function useMeWyscoutPlayerAlertControllerGetWyscoutPlayerAlerts<TData = Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlerts>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlerts>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlerts>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useMeWyscoutPlayerAlertControllerGetWyscoutPlayerAlerts<TData = Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlerts>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlerts>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlerts>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useMeWyscoutPlayerAlertControllerGetWyscoutPlayerAlerts<TData = Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlerts>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlerts>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useMeWyscoutPlayerAlertControllerGetWyscoutPlayerAlerts<TData = Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlerts>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlerts>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getMeWyscoutPlayerAlertControllerGetWyscoutPlayerAlertsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getMeWyscoutPlayerAlertControllerGetWyscoutPlayerAlertsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlerts>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlerts>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getMeWyscoutPlayerAlertControllerGetWyscoutPlayerAlertsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlerts>>> = ({ signal }) => meWyscoutPlayerAlertControllerGetWyscoutPlayerAlerts({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlerts>>, TError, TData> & { queryKey: QueryKey }
}

export type MeWyscoutPlayerAlertControllerGetWyscoutPlayerAlertsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlerts>>>
export type MeWyscoutPlayerAlertControllerGetWyscoutPlayerAlertsSuspenseQueryError = AxiosError<unknown>


export function useMeWyscoutPlayerAlertControllerGetWyscoutPlayerAlertsSuspense<TData = Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlerts>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlerts>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useMeWyscoutPlayerAlertControllerGetWyscoutPlayerAlertsSuspense<TData = Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlerts>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlerts>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useMeWyscoutPlayerAlertControllerGetWyscoutPlayerAlertsSuspense<TData = Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlerts>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlerts>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useMeWyscoutPlayerAlertControllerGetWyscoutPlayerAlertsSuspense<TData = Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlerts>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlerts>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getMeWyscoutPlayerAlertControllerGetWyscoutPlayerAlertsSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const meWyscoutPlayerAlertControllerCreateWyscoutPlayerAlert = (
    meWyscoutPlayerAlertCreateDto: MeWyscoutPlayerAlertCreateDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WyscoutPlayerAlertGetDto>> => {
    
    return axios.post(
      `/v1/user/me/wyscout-player-alert`,
      meWyscoutPlayerAlertCreateDto,options
    );
  }



export const getMeWyscoutPlayerAlertControllerCreateWyscoutPlayerAlertMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerCreateWyscoutPlayerAlert>>, TError,{data: MeWyscoutPlayerAlertCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerCreateWyscoutPlayerAlert>>, TError,{data: MeWyscoutPlayerAlertCreateDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerCreateWyscoutPlayerAlert>>, {data: MeWyscoutPlayerAlertCreateDto}> = (props) => {
          const {data} = props ?? {};

          return  meWyscoutPlayerAlertControllerCreateWyscoutPlayerAlert(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type MeWyscoutPlayerAlertControllerCreateWyscoutPlayerAlertMutationResult = NonNullable<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerCreateWyscoutPlayerAlert>>>
    export type MeWyscoutPlayerAlertControllerCreateWyscoutPlayerAlertMutationBody = MeWyscoutPlayerAlertCreateDto
    export type MeWyscoutPlayerAlertControllerCreateWyscoutPlayerAlertMutationError = AxiosError<unknown>

    export const useMeWyscoutPlayerAlertControllerCreateWyscoutPlayerAlert = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerCreateWyscoutPlayerAlert>>, TError,{data: MeWyscoutPlayerAlertCreateDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerCreateWyscoutPlayerAlert>>,
        TError,
        {data: MeWyscoutPlayerAlertCreateDto},
        TContext
      > => {

      const mutationOptions = getMeWyscoutPlayerAlertControllerCreateWyscoutPlayerAlertMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const meWyscoutPlayerAlertControllerGetWyscoutPlayerAlert = (
    wyscoutPlayerAlertId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WyscoutPlayerAlertGetDto>> => {
    
    return axios.get(
      `/v1/user/me/wyscout-player-alert/${wyscoutPlayerAlertId}`,options
    );
  }


export const getMeWyscoutPlayerAlertControllerGetWyscoutPlayerAlertQueryKey = (wyscoutPlayerAlertId: string,) => {
    return [`/v1/user/me/wyscout-player-alert/${wyscoutPlayerAlertId}`] as const;
    }

    
export const getMeWyscoutPlayerAlertControllerGetWyscoutPlayerAlertQueryOptions = <TData = Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlert>>, TError = AxiosError<unknown>>(wyscoutPlayerAlertId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlert>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getMeWyscoutPlayerAlertControllerGetWyscoutPlayerAlertQueryKey(wyscoutPlayerAlertId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlert>>> = ({ signal }) => meWyscoutPlayerAlertControllerGetWyscoutPlayerAlert(wyscoutPlayerAlertId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(wyscoutPlayerAlertId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlert>>, TError, TData> & { queryKey: QueryKey }
}

export type MeWyscoutPlayerAlertControllerGetWyscoutPlayerAlertQueryResult = NonNullable<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlert>>>
export type MeWyscoutPlayerAlertControllerGetWyscoutPlayerAlertQueryError = AxiosError<unknown>


export function useMeWyscoutPlayerAlertControllerGetWyscoutPlayerAlert<TData = Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlert>>, TError = AxiosError<unknown>>(
 wyscoutPlayerAlertId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlert>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlert>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useMeWyscoutPlayerAlertControllerGetWyscoutPlayerAlert<TData = Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlert>>, TError = AxiosError<unknown>>(
 wyscoutPlayerAlertId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlert>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlert>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useMeWyscoutPlayerAlertControllerGetWyscoutPlayerAlert<TData = Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlert>>, TError = AxiosError<unknown>>(
 wyscoutPlayerAlertId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlert>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useMeWyscoutPlayerAlertControllerGetWyscoutPlayerAlert<TData = Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlert>>, TError = AxiosError<unknown>>(
 wyscoutPlayerAlertId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlert>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getMeWyscoutPlayerAlertControllerGetWyscoutPlayerAlertQueryOptions(wyscoutPlayerAlertId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getMeWyscoutPlayerAlertControllerGetWyscoutPlayerAlertSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlert>>, TError = AxiosError<unknown>>(wyscoutPlayerAlertId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlert>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getMeWyscoutPlayerAlertControllerGetWyscoutPlayerAlertQueryKey(wyscoutPlayerAlertId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlert>>> = ({ signal }) => meWyscoutPlayerAlertControllerGetWyscoutPlayerAlert(wyscoutPlayerAlertId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(wyscoutPlayerAlertId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlert>>, TError, TData> & { queryKey: QueryKey }
}

export type MeWyscoutPlayerAlertControllerGetWyscoutPlayerAlertSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlert>>>
export type MeWyscoutPlayerAlertControllerGetWyscoutPlayerAlertSuspenseQueryError = AxiosError<unknown>


export function useMeWyscoutPlayerAlertControllerGetWyscoutPlayerAlertSuspense<TData = Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlert>>, TError = AxiosError<unknown>>(
 wyscoutPlayerAlertId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlert>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useMeWyscoutPlayerAlertControllerGetWyscoutPlayerAlertSuspense<TData = Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlert>>, TError = AxiosError<unknown>>(
 wyscoutPlayerAlertId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlert>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useMeWyscoutPlayerAlertControllerGetWyscoutPlayerAlertSuspense<TData = Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlert>>, TError = AxiosError<unknown>>(
 wyscoutPlayerAlertId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlert>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useMeWyscoutPlayerAlertControllerGetWyscoutPlayerAlertSuspense<TData = Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlert>>, TError = AxiosError<unknown>>(
 wyscoutPlayerAlertId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerGetWyscoutPlayerAlert>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getMeWyscoutPlayerAlertControllerGetWyscoutPlayerAlertSuspenseQueryOptions(wyscoutPlayerAlertId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const meWyscoutPlayerAlertControllerUpdateWyscoutPlayerAlert = (
    wyscoutPlayerAlertId: string,
    meWyscoutPlayerAlertPatchDto: MeWyscoutPlayerAlertPatchDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WyscoutPlayerAlertGetDto>> => {
    
    return axios.patch(
      `/v1/user/me/wyscout-player-alert/${wyscoutPlayerAlertId}`,
      meWyscoutPlayerAlertPatchDto,options
    );
  }



export const getMeWyscoutPlayerAlertControllerUpdateWyscoutPlayerAlertMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerUpdateWyscoutPlayerAlert>>, TError,{wyscoutPlayerAlertId: string;data: MeWyscoutPlayerAlertPatchDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerUpdateWyscoutPlayerAlert>>, TError,{wyscoutPlayerAlertId: string;data: MeWyscoutPlayerAlertPatchDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerUpdateWyscoutPlayerAlert>>, {wyscoutPlayerAlertId: string;data: MeWyscoutPlayerAlertPatchDto}> = (props) => {
          const {wyscoutPlayerAlertId,data} = props ?? {};

          return  meWyscoutPlayerAlertControllerUpdateWyscoutPlayerAlert(wyscoutPlayerAlertId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type MeWyscoutPlayerAlertControllerUpdateWyscoutPlayerAlertMutationResult = NonNullable<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerUpdateWyscoutPlayerAlert>>>
    export type MeWyscoutPlayerAlertControllerUpdateWyscoutPlayerAlertMutationBody = MeWyscoutPlayerAlertPatchDto
    export type MeWyscoutPlayerAlertControllerUpdateWyscoutPlayerAlertMutationError = AxiosError<unknown>

    export const useMeWyscoutPlayerAlertControllerUpdateWyscoutPlayerAlert = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerUpdateWyscoutPlayerAlert>>, TError,{wyscoutPlayerAlertId: string;data: MeWyscoutPlayerAlertPatchDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerUpdateWyscoutPlayerAlert>>,
        TError,
        {wyscoutPlayerAlertId: string;data: MeWyscoutPlayerAlertPatchDto},
        TContext
      > => {

      const mutationOptions = getMeWyscoutPlayerAlertControllerUpdateWyscoutPlayerAlertMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const meWyscoutPlayerAlertControllerDeleteWyscoutPlayerAlert = (
    wyscoutPlayerAlertId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/v1/user/me/wyscout-player-alert/${wyscoutPlayerAlertId}`,options
    );
  }



export const getMeWyscoutPlayerAlertControllerDeleteWyscoutPlayerAlertMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerDeleteWyscoutPlayerAlert>>, TError,{wyscoutPlayerAlertId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerDeleteWyscoutPlayerAlert>>, TError,{wyscoutPlayerAlertId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerDeleteWyscoutPlayerAlert>>, {wyscoutPlayerAlertId: string}> = (props) => {
          const {wyscoutPlayerAlertId} = props ?? {};

          return  meWyscoutPlayerAlertControllerDeleteWyscoutPlayerAlert(wyscoutPlayerAlertId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type MeWyscoutPlayerAlertControllerDeleteWyscoutPlayerAlertMutationResult = NonNullable<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerDeleteWyscoutPlayerAlert>>>
    
    export type MeWyscoutPlayerAlertControllerDeleteWyscoutPlayerAlertMutationError = AxiosError<unknown>

    export const useMeWyscoutPlayerAlertControllerDeleteWyscoutPlayerAlert = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerDeleteWyscoutPlayerAlert>>, TError,{wyscoutPlayerAlertId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof meWyscoutPlayerAlertControllerDeleteWyscoutPlayerAlert>>,
        TError,
        {wyscoutPlayerAlertId: string},
        TContext
      > => {

      const mutationOptions = getMeWyscoutPlayerAlertControllerDeleteWyscoutPlayerAlertMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const scoutingSquadControllerFindPlayers = (
    tenantId: string,
    environmentId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ScoutingSquadPlayerOptionDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/scouting/squad/players`,options
    );
  }


export const getScoutingSquadControllerFindPlayersQueryKey = (tenantId: string,
    environmentId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/scouting/squad/players`] as const;
    }

    
export const getScoutingSquadControllerFindPlayersQueryOptions = <TData = Awaited<ReturnType<typeof scoutingSquadControllerFindPlayers>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadControllerFindPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getScoutingSquadControllerFindPlayersQueryKey(tenantId,environmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof scoutingSquadControllerFindPlayers>>> = ({ signal }) => scoutingSquadControllerFindPlayers(tenantId,environmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadControllerFindPlayers>>, TError, TData> & { queryKey: QueryKey }
}

export type ScoutingSquadControllerFindPlayersQueryResult = NonNullable<Awaited<ReturnType<typeof scoutingSquadControllerFindPlayers>>>
export type ScoutingSquadControllerFindPlayersQueryError = AxiosError<unknown>


export function useScoutingSquadControllerFindPlayers<TData = Awaited<ReturnType<typeof scoutingSquadControllerFindPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadControllerFindPlayers>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof scoutingSquadControllerFindPlayers>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingSquadControllerFindPlayers<TData = Awaited<ReturnType<typeof scoutingSquadControllerFindPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadControllerFindPlayers>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof scoutingSquadControllerFindPlayers>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingSquadControllerFindPlayers<TData = Awaited<ReturnType<typeof scoutingSquadControllerFindPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadControllerFindPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useScoutingSquadControllerFindPlayers<TData = Awaited<ReturnType<typeof scoutingSquadControllerFindPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadControllerFindPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getScoutingSquadControllerFindPlayersQueryOptions(tenantId,environmentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getScoutingSquadControllerFindPlayersSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof scoutingSquadControllerFindPlayers>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadControllerFindPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getScoutingSquadControllerFindPlayersQueryKey(tenantId,environmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof scoutingSquadControllerFindPlayers>>> = ({ signal }) => scoutingSquadControllerFindPlayers(tenantId,environmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadControllerFindPlayers>>, TError, TData> & { queryKey: QueryKey }
}

export type ScoutingSquadControllerFindPlayersSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof scoutingSquadControllerFindPlayers>>>
export type ScoutingSquadControllerFindPlayersSuspenseQueryError = AxiosError<unknown>


export function useScoutingSquadControllerFindPlayersSuspense<TData = Awaited<ReturnType<typeof scoutingSquadControllerFindPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadControllerFindPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingSquadControllerFindPlayersSuspense<TData = Awaited<ReturnType<typeof scoutingSquadControllerFindPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadControllerFindPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingSquadControllerFindPlayersSuspense<TData = Awaited<ReturnType<typeof scoutingSquadControllerFindPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadControllerFindPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useScoutingSquadControllerFindPlayersSuspense<TData = Awaited<ReturnType<typeof scoutingSquadControllerFindPlayers>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadControllerFindPlayers>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getScoutingSquadControllerFindPlayersSuspenseQueryOptions(tenantId,environmentId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const scoutingSquadControllerFindAll = (
    tenantId: string,
    environmentId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ScoutingSquadDto[]>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/scouting/squad`,options
    );
  }


export const getScoutingSquadControllerFindAllQueryKey = (tenantId: string,
    environmentId: string,) => {
    return [`/v1/${tenantId}/${environmentId}/scouting/squad`] as const;
    }

    
export const getScoutingSquadControllerFindAllQueryOptions = <TData = Awaited<ReturnType<typeof scoutingSquadControllerFindAll>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadControllerFindAll>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getScoutingSquadControllerFindAllQueryKey(tenantId,environmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof scoutingSquadControllerFindAll>>> = ({ signal }) => scoutingSquadControllerFindAll(tenantId,environmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadControllerFindAll>>, TError, TData> & { queryKey: QueryKey }
}

export type ScoutingSquadControllerFindAllQueryResult = NonNullable<Awaited<ReturnType<typeof scoutingSquadControllerFindAll>>>
export type ScoutingSquadControllerFindAllQueryError = AxiosError<unknown>


export function useScoutingSquadControllerFindAll<TData = Awaited<ReturnType<typeof scoutingSquadControllerFindAll>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadControllerFindAll>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof scoutingSquadControllerFindAll>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingSquadControllerFindAll<TData = Awaited<ReturnType<typeof scoutingSquadControllerFindAll>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadControllerFindAll>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof scoutingSquadControllerFindAll>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingSquadControllerFindAll<TData = Awaited<ReturnType<typeof scoutingSquadControllerFindAll>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadControllerFindAll>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useScoutingSquadControllerFindAll<TData = Awaited<ReturnType<typeof scoutingSquadControllerFindAll>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadControllerFindAll>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getScoutingSquadControllerFindAllQueryOptions(tenantId,environmentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getScoutingSquadControllerFindAllSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof scoutingSquadControllerFindAll>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadControllerFindAll>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getScoutingSquadControllerFindAllQueryKey(tenantId,environmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof scoutingSquadControllerFindAll>>> = ({ signal }) => scoutingSquadControllerFindAll(tenantId,environmentId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadControllerFindAll>>, TError, TData> & { queryKey: QueryKey }
}

export type ScoutingSquadControllerFindAllSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof scoutingSquadControllerFindAll>>>
export type ScoutingSquadControllerFindAllSuspenseQueryError = AxiosError<unknown>


export function useScoutingSquadControllerFindAllSuspense<TData = Awaited<ReturnType<typeof scoutingSquadControllerFindAll>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadControllerFindAll>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingSquadControllerFindAllSuspense<TData = Awaited<ReturnType<typeof scoutingSquadControllerFindAll>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadControllerFindAll>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingSquadControllerFindAllSuspense<TData = Awaited<ReturnType<typeof scoutingSquadControllerFindAll>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadControllerFindAll>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useScoutingSquadControllerFindAllSuspense<TData = Awaited<ReturnType<typeof scoutingSquadControllerFindAll>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadControllerFindAll>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getScoutingSquadControllerFindAllSuspenseQueryOptions(tenantId,environmentId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const scoutingSquadControllerCreateOne = (
    tenantId: string,
    environmentId: string,
    createUpdateScoutingSquadDto: CreateUpdateScoutingSquadDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ScoutingSquadDto>> => {
    
    return axios.post(
      `/v1/${tenantId}/${environmentId}/scouting/squad`,
      createUpdateScoutingSquadDto,options
    );
  }



export const getScoutingSquadControllerCreateOneMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingSquadControllerCreateOne>>, TError,{tenantId: string;environmentId: string;data: CreateUpdateScoutingSquadDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof scoutingSquadControllerCreateOne>>, TError,{tenantId: string;environmentId: string;data: CreateUpdateScoutingSquadDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof scoutingSquadControllerCreateOne>>, {tenantId: string;environmentId: string;data: CreateUpdateScoutingSquadDto}> = (props) => {
          const {tenantId,environmentId,data} = props ?? {};

          return  scoutingSquadControllerCreateOne(tenantId,environmentId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ScoutingSquadControllerCreateOneMutationResult = NonNullable<Awaited<ReturnType<typeof scoutingSquadControllerCreateOne>>>
    export type ScoutingSquadControllerCreateOneMutationBody = CreateUpdateScoutingSquadDto
    export type ScoutingSquadControllerCreateOneMutationError = AxiosError<unknown>

    export const useScoutingSquadControllerCreateOne = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingSquadControllerCreateOne>>, TError,{tenantId: string;environmentId: string;data: CreateUpdateScoutingSquadDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof scoutingSquadControllerCreateOne>>,
        TError,
        {tenantId: string;environmentId: string;data: CreateUpdateScoutingSquadDto},
        TContext
      > => {

      const mutationOptions = getScoutingSquadControllerCreateOneMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const scoutingSquadControllerFindOne = (
    tenantId: string,
    environmentId: string,
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ScoutingSquadDto>> => {
    
    return axios.get(
      `/v1/${tenantId}/${environmentId}/scouting/squad/${id}`,options
    );
  }


export const getScoutingSquadControllerFindOneQueryKey = (tenantId: string,
    environmentId: string,
    id: string,) => {
    return [`/v1/${tenantId}/${environmentId}/scouting/squad/${id}`] as const;
    }

    
export const getScoutingSquadControllerFindOneQueryOptions = <TData = Awaited<ReturnType<typeof scoutingSquadControllerFindOne>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadControllerFindOne>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getScoutingSquadControllerFindOneQueryKey(tenantId,environmentId,id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof scoutingSquadControllerFindOne>>> = ({ signal }) => scoutingSquadControllerFindOne(tenantId,environmentId,id, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadControllerFindOne>>, TError, TData> & { queryKey: QueryKey }
}

export type ScoutingSquadControllerFindOneQueryResult = NonNullable<Awaited<ReturnType<typeof scoutingSquadControllerFindOne>>>
export type ScoutingSquadControllerFindOneQueryError = AxiosError<unknown>


export function useScoutingSquadControllerFindOne<TData = Awaited<ReturnType<typeof scoutingSquadControllerFindOne>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    id: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadControllerFindOne>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof scoutingSquadControllerFindOne>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingSquadControllerFindOne<TData = Awaited<ReturnType<typeof scoutingSquadControllerFindOne>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadControllerFindOne>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof scoutingSquadControllerFindOne>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingSquadControllerFindOne<TData = Awaited<ReturnType<typeof scoutingSquadControllerFindOne>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadControllerFindOne>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useScoutingSquadControllerFindOne<TData = Awaited<ReturnType<typeof scoutingSquadControllerFindOne>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof scoutingSquadControllerFindOne>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getScoutingSquadControllerFindOneQueryOptions(tenantId,environmentId,id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getScoutingSquadControllerFindOneSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof scoutingSquadControllerFindOne>>, TError = AxiosError<unknown>>(tenantId: string,
    environmentId: string,
    id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadControllerFindOne>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getScoutingSquadControllerFindOneQueryKey(tenantId,environmentId,id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof scoutingSquadControllerFindOne>>> = ({ signal }) => scoutingSquadControllerFindOne(tenantId,environmentId,id, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(tenantId && environmentId && id), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadControllerFindOne>>, TError, TData> & { queryKey: QueryKey }
}

export type ScoutingSquadControllerFindOneSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof scoutingSquadControllerFindOne>>>
export type ScoutingSquadControllerFindOneSuspenseQueryError = AxiosError<unknown>


export function useScoutingSquadControllerFindOneSuspense<TData = Awaited<ReturnType<typeof scoutingSquadControllerFindOne>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    id: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadControllerFindOne>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingSquadControllerFindOneSuspense<TData = Awaited<ReturnType<typeof scoutingSquadControllerFindOne>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadControllerFindOne>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useScoutingSquadControllerFindOneSuspense<TData = Awaited<ReturnType<typeof scoutingSquadControllerFindOne>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadControllerFindOne>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useScoutingSquadControllerFindOneSuspense<TData = Awaited<ReturnType<typeof scoutingSquadControllerFindOne>>, TError = AxiosError<unknown>>(
 tenantId: string,
    environmentId: string,
    id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof scoutingSquadControllerFindOne>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getScoutingSquadControllerFindOneSuspenseQueryOptions(tenantId,environmentId,id,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const scoutingSquadControllerUpdateOne = (
    tenantId: string,
    environmentId: string,
    id: string,
    createUpdateScoutingSquadDto: CreateUpdateScoutingSquadDto, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ScoutingSquadDto>> => {
    
    return axios.put(
      `/v1/${tenantId}/${environmentId}/scouting/squad/${id}`,
      createUpdateScoutingSquadDto,options
    );
  }



export const getScoutingSquadControllerUpdateOneMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingSquadControllerUpdateOne>>, TError,{tenantId: string;environmentId: string;id: string;data: CreateUpdateScoutingSquadDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof scoutingSquadControllerUpdateOne>>, TError,{tenantId: string;environmentId: string;id: string;data: CreateUpdateScoutingSquadDto}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof scoutingSquadControllerUpdateOne>>, {tenantId: string;environmentId: string;id: string;data: CreateUpdateScoutingSquadDto}> = (props) => {
          const {tenantId,environmentId,id,data} = props ?? {};

          return  scoutingSquadControllerUpdateOne(tenantId,environmentId,id,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ScoutingSquadControllerUpdateOneMutationResult = NonNullable<Awaited<ReturnType<typeof scoutingSquadControllerUpdateOne>>>
    export type ScoutingSquadControllerUpdateOneMutationBody = CreateUpdateScoutingSquadDto
    export type ScoutingSquadControllerUpdateOneMutationError = AxiosError<unknown>

    export const useScoutingSquadControllerUpdateOne = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingSquadControllerUpdateOne>>, TError,{tenantId: string;environmentId: string;id: string;data: CreateUpdateScoutingSquadDto}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof scoutingSquadControllerUpdateOne>>,
        TError,
        {tenantId: string;environmentId: string;id: string;data: CreateUpdateScoutingSquadDto},
        TContext
      > => {

      const mutationOptions = getScoutingSquadControllerUpdateOneMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const scoutingSquadControllerDeleteOne = (
    tenantId: string,
    environmentId: string,
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ScoutingSquadDto>> => {
    
    return axios.delete(
      `/v1/${tenantId}/${environmentId}/scouting/squad/${id}`,options
    );
  }



export const getScoutingSquadControllerDeleteOneMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingSquadControllerDeleteOne>>, TError,{tenantId: string;environmentId: string;id: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof scoutingSquadControllerDeleteOne>>, TError,{tenantId: string;environmentId: string;id: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof scoutingSquadControllerDeleteOne>>, {tenantId: string;environmentId: string;id: string}> = (props) => {
          const {tenantId,environmentId,id} = props ?? {};

          return  scoutingSquadControllerDeleteOne(tenantId,environmentId,id,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ScoutingSquadControllerDeleteOneMutationResult = NonNullable<Awaited<ReturnType<typeof scoutingSquadControllerDeleteOne>>>
    
    export type ScoutingSquadControllerDeleteOneMutationError = AxiosError<unknown>

    export const useScoutingSquadControllerDeleteOne = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scoutingSquadControllerDeleteOne>>, TError,{tenantId: string;environmentId: string;id: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof scoutingSquadControllerDeleteOne>>,
        TError,
        {tenantId: string;environmentId: string;id: string},
        TContext
      > => {

      const mutationOptions = getScoutingSquadControllerDeleteOneMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
